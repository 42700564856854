import {combineReducers} from '@reduxjs/toolkit';
import {appVersionSlice} from './app-version.slice';
import {authSlice} from './auth.slice';
import {commonSlice} from './common.slice';
import {enumSlice} from './enum.slice';

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  enum: enumSlice.reducer,
  common: commonSlice.reducer,
  appVersion: appVersionSlice.reducer,
});
