import React from 'react';
import classNames from 'classnames';

export const Block = ({className, size, ...props}: any) => {
  const blockClass = classNames({
    'nk-block': true,
    [`nk-block-${size}`]: size,
    [`${className}`]: className,
  });
  return <div className={blockClass}>{props.children}</div>;
};

export const BlockHeadContent = ({className, ...props}: any) => {
  return <div className={`nk-block-head-content${className ? ' ' + className : ''}`}>{props.children}</div>;
};

export const BlockHeadSub = ({className, ...props}: any) => {
  return (
    <div className={`nk-block-head-sub ${className ? className : ''}`}>
      <span>{props.children}</span>
    </div>
  );
};

export const BlockBetween = ({className, size, ...props}: any) => {
  return <div className={`${size ? `nk-block-between-${size}` : 'nk-block-between'} ${className ? className : ''}`}>{props.children}</div>;
};
export const BlockContent = ({className, ...props}: any) => {
  const blockContentClass = classNames({
    'nk-block-content': true,
    [`${className}`]: className,
  });
  return <div className={blockContentClass}>{props.children}</div>;
};

export const BlockHead = ({className, size, wide, ...props}: any) => {
  const blockHeadClass = classNames({
    'nk-block-head': true,
    [`nk-block-head-${size}`]: size,
    [`wide-${wide}`]: wide,
    [`${className}`]: className,
  });
  return <div className={blockHeadClass}>{props.children}</div>;
};

export const BlockTitle = ({className, page, ...props}: any) => {
  const classes = [`nk-block-title d-flex align-items-center ${page ? 'page-title' : 'title'}${className ? ' ' + className : ''}`];
  const icon = props?.icon ? props?.icon : 'ni-list';
  return (
    <React.Fragment>
      {!props.tag ? (
        <h3 className={`${classes}`}>{props.children}</h3>
      ) : (
        <props.tag className={classes}>
          <i className={`me-2 icon ni ${icon}`}></i>
          {props.children}
        </props.tag>
      )}
    </React.Fragment>
  );
};
export const BlockDes = ({className, page, ...props}: any) => {
  const classes = [`nk-block-des${className ? ' ' + className : ''}`];
  return <div className={`${classes}`}>{props.children}</div>;
};
