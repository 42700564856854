import React, {SetStateAction, useState} from 'react';
import {IPrompt} from '../interfaces/IPrompt';
import {useNavigate} from 'react-router-dom';
import {promptApi} from '../../../../api';
import Icon from '../../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import Button from '../../../../components/button/Button';
import {EmptyState} from '../../../../components/shared/EmptyState';
import {useTranslation} from 'react-i18next';

interface IPromptTableProps {
  promptList: IPrompt[];
  setPromptList: React.Dispatch<SetStateAction<IPrompt[]>>;
}

const PromptTable = ({promptList, setPromptList}: IPromptTableProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [selectedPrompt, setSelectedPrompt] = useState<IPrompt>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const deletePromptHandler = async () => {
    if (selectedPrompt?.id) {
      const {
        data: {data},
      } = await promptApi.deletePrompt(selectedPrompt?.id);
      setPromptList(data);
    }
  };
  return (
    <div className="table-responsive">
      {!!promptList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.id')}</th>
              <th scope="col">Name</th>

              <th scope="col" className="text-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {promptList?.map((promptItem) => {
              const {id, prompt} = promptItem;
              return (
                <tr key={id}>
                  <th>{id}</th>
                  <td>{prompt ?? '-'}</td>
                  <td className="d-flex justify-content-end">
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => {
                        navigate(`/app/admin/prompts/update/${id}`);
                      }}
                    >
                      <Icon name="edit" />
                      Edit
                    </Button>

                    <Button
                      color="danger"
                      size="sm"
                      className="ms-1"
                      onClick={() => {
                        setIsDeleteModalOpened(true);
                        setSelectedPrompt(promptItem);
                      }}
                    >
                      <Icon name="trash" />
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedPrompt?.id as number}
        itemName={selectedPrompt?.prompt as string}
        deleteHandler={deletePromptHandler}
      />
    </div>
  );
};

export default PromptTable;
