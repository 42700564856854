import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {isUserAdmin} from '../shared/Functions';
import {RootState} from '../store';

export function PrivateRoute({component: Component, ...rest}: any) {
  const isLoggedIn = !!useSelector((state: RootState) => state.auth.token);

  const userToken = !!localStorage.getItem('token');
  if (isLoggedIn || userToken) return <Component {...rest} />;
  return <Navigate to={'/'} />;
}

export function AdminRoute({component: Component, ...rest}: any) {
  const token = localStorage.getItem('token');
  const {roles} = useSelector((state: RootState) => state?.auth?.user);
  const isAdmin = isUserAdmin(roles);

  const isLoggedIn = !!useSelector((state: RootState) => state?.auth?.token);
  if ((isLoggedIn && isAdmin) || (isAdmin && !!token?.length)) return <Component {...rest} />;
  if (!!token?.length) return <Navigate to={'/app/dashboard'} />;
  return <Navigate to={'/'} />;
}

export function OnlyPublicRoute({component: Component, ...rest}: any) {
  const isLoggedIn = !!useSelector((state: RootState) => state?.auth?.token);
  if (isLoggedIn) return <Navigate to={'/app/dashboard'} />;
  return <Component {...rest} />;
}
