import {IQualityPrice} from '../../../app/administrator/quality-price/interfaces/IQualityPrice';
import BaseApi from '../../base.api';

const QUALITY_PRICE_ROUTE = '/api/admin/quality/price';

export class QualityPriceApi extends BaseApi {
  public async getQualityPrices(): Promise<{data: {qualitys: IQualityPrice[]}}> {
    return await this.get(QUALITY_PRICE_ROUTE, '');
  }

  public async updateQualityPrices(data: IQualityPrice[]): Promise<{data: {qualitys: IQualityPrice[]}}> {
    return await this.put(
      QUALITY_PRICE_ROUTE,
      {
        quality: data,
      },
      ''
    );
  }
}
