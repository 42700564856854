import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import React, {SetStateAction, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Card, CardBody, Col, Row} from 'reactstrap';
import * as yup from 'yup';
import {campaignApi, lineItemApi} from '../../../../api';
import {ICreateSection} from '../../../../api/app/campaigns/line-item.api';
import {Block} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import ConfirmSuccessModal from '../../../../components/modal/ConfirmSuccessModal';
import {enumerateDaysBetweenDates, getMonthRangesInRange, getMonthWeeksInRange, isUserAdmin} from '../../../../shared/Functions';
import {RootState} from '../../../../store';
import {commonSlice, TypeEnum} from '../../../../store/slices/common.slice';
import {
  IChannelDeviceFormatBuild,
  IChannelDeviceFormatBuilder,
  ILineItemStats,
  ISelectedDates,
} from '../../../administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import {ICampaignOverview} from '../../interfaces/ICampaigns';
import ChangeStep from './ChangeStep';
import CalendarEmptyState from './components/CalendarEmptyState';
import CampaignStepThreeCalendar from './components/CampaignStepThreeCalendar';
import CampaignStepThreePreview from './components/CampaignStepThreePreview';
import CreateSectionModal from './components/CreateSectionModal';
import LineItemModal from './components/LineItemModal';
import {IStepperProps} from './index';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';

export const campaignDropdownSchema = yup.object({
  objective: yup.string().required('No objective provided.'),
  price: yup.string().required('No price provided.'),
  type: yup.number().typeError('No type provided.').required('No type provided.'),
  quality: yup.array().min(1).typeError('No quality provided.').required('No quality provided.'),
  targetGroup: yup.number().typeError('No target group provided.').required('No target group provided.'),
  device: yup.array().min(1).typeError('No device provided.').required('No device provided'),
  country: yup.string().min(1).required('No country provided'),
});

export interface IListSubmitData {
  channelId: number;
  formatId: number;
  creativeName: string;
  region: number[];
}

const createSectionInitial = {
  section: true,
  title: '',
  color: '#000000',
};

const mustHaveKeys = ['targetGroup', 'price', 'objective'];

const CampaignStep3 = ({campaignData, isCampaignEditable = false}: IStepperProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id, stepId} = useParams();
  const {t} = useTranslation();
  const {roles} = useSelector((allState: RootState) => allState.auth.user);
  const [currStep, setCurrStep] = useState(1);
  const [isLineItemCreateOpened, setIsLineItemCreateOpened] = useState(false);
  const [selectedLineItemId, setSelectedLineItemId] = useState<number>(0);
  const [isSectionDeleteModalOpened, setIsSectionDeleteModalOpened] = useState(false);
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
  const [isCreateSectionOpened, setIsCreateSectionOpened] = useState(false);
  const [channels, setChannels] = useState<IChannelDeviceFormatBuild[]>([]);

  const [lineItemStats, setLineItemStats] = useState<ILineItemStats>({
    ais: 0,
    priceValue: 0,
    model: '',
    minAis: 0,
    maxAis: 0,
  });
  const [campaignOverview, setCampaignOverview] = useState<ICampaignOverview | undefined>(undefined);
  const [isRegionValid, setIsRegionValid] = useState<boolean | undefined>(undefined);
  const [isCreativeValid, setIsCreativeValid] = useState<boolean | undefined>(undefined);
  const [calendarView, setCalendarView] = useState<'DAY' | 'MONTH' | 'WEEK'>('DAY');
  const [selectedLineItem, setSelectedLineItem] = useState<IChannelDeviceFormatBuild>();
  const [createSectionState, setCreateSectionState] = useState<ICreateSection>(createSectionInitial);
  const [selectedSection, setSelectedSection] = useState<number>();
  const tableDates =
    calendarView === 'MONTH'
      ? getMonthRangesInRange(campaignData?.startDate, campaignData?.endDate)
      : calendarView === 'WEEK'
      ? getMonthWeeksInRange(campaignData?.startDate, campaignData?.endDate)
      : enumerateDaysBetweenDates(campaignData?.startDate, campaignData?.endDate);
  const [savedChannels, setSavedChannels] = useState<IChannelDeviceFormatBuild[]>([]);
  const [selectedDates, setSelectedDates] = useState<ISelectedDates[] | undefined>(undefined);
  const [listSubmitData, setListSubmitData] = useState<IListSubmitData>();
  const [monthDates, setMonthDates] = useState<any[]>([]);
  const [triggerLineItem, setTriggerLineItem] = useState(false);

  const methods = useForm<IChannelDeviceFormatBuilder>({
    resolver: yupResolver(campaignDropdownSchema),
  });
  const handleSelectChannel = (channelId: number) => {
    setSelectedLineItem(channels?.find((channel) => channel.id === channelId));
  };
  const onFormSubmit = async (formData: IChannelDeviceFormatBuilder) => {
    const {data} = await campaignApi.generateChannelDeviceFormat({
      ...formData,
      quality: methods.watch('quality'),
      device: methods.watch('device'),
      price: methods.watch('price'),
      type: methods.watch('type'),
      country: methods.watch('country'),
      campaignId: Number(id),
    });
    setChannels(data);
  };

  const sendDataHandler = async (channelId: number, formatId: number, isOpenedByModal = false) => {
    if (!listSubmitData?.creativeName) {
      setIsCreativeValid(false);
    }

    if (!listSubmitData?.region?.length) {
      setIsRegionValid(false);
    }
    if (!isRegionValid || !isCreativeValid) {
      return;
    }
    if (!selectedLineItemId || isOpenedByModal) {
      const dataToSend = {
        ...methods?.getValues(),
        ...lineItemStats,
        channel: channelId,
        format: formatId,
        ...listSubmitData,
        targetGroups: methods?.getValues().targetGroup,
        calendarView,
        region: listSubmitData?.region,
        // targeting: ,
      };
      const {data} = await lineItemApi.createLineItem(dataToSend, id as string);
      if (data) {
        cancelHandler();
      }
    } else {
      setIsConfirmationOpened(true);
    }
  };

  const cancelHandler = () => {
    getLineItems();
    setCurrStep(1);
    setSelectedLineItemId(0);
    setSelectedDates([]);
    setLineItemStats({
      minAis: 0,
      maxAis: 0,
      ais: 0,
      priceValue: 0,
      model: '',
    });
    setIsLineItemCreateOpened(false);
    setSelectedLineItem(undefined);
  };

  const getLineItems = async () => {
    if (id) {
      const {data} = await lineItemApi.getLineItems(id);

      setSelectedDates(
        data?.lineItems?.map((lineItem) => ({
          lineId: lineItem.id,
          dates: lineItem.lineItemDate?.map((item) => item.date),
        }))
      );

      setCampaignOverview(data?.campaignOverview);
      setSavedChannels(data?.lineItems);
      setMonthDates(data?.valueByMonth);
    }
  };
  const getGeneratedTable = async (values?: IChannelDeviceFormatBuilder) => {
    const {data} = await campaignApi.generateChannelDeviceFormat(values);
    setChannels(data);
  };

  const handleCreateLineItem = () => {
    cancelHandler();
    setIsLineItemCreateOpened(true);
  };
  const createSection = async () => {
    if (createSectionState?.id) {
      await lineItemApi.updateLineItemSection(campaignData?.id, createSectionState?.id, createSectionState).then((response) => {
        if (response) {
          getLineItems();
          setIsCreateSectionOpened(false);
          setCreateSectionState(createSectionInitial);
        }
      });
    } else {
      if (createSectionState?.color === '' || createSectionState?.title === '') {
        dispatch(commonSlice.actions.setMessage({type: TypeEnum.warning, text: 'Name and color required'}));
        return;
      }
      !!campaignData?.id &&
        (await lineItemApi.createLineItemSection(campaignData?.id, createSectionState).then((response) => {
          if (response) {
            getLineItems();
            setIsCreateSectionOpened(false);
            setCreateSectionState(createSectionInitial);
          }
        }));
    }
  };

  const deleteSectionHandler = async () => {
    const {data} = await lineItemApi.deleteLineItemSection(String(id), String(selectedSection));

    setSavedChannels((data as any)?.lineItems);
    cancelHandler();
  };

  useEffect(() => {
    if (id && stepId) {
      getLineItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(campaignData), triggerLineItem]);

  useEffect(() => {
    if (!!selectedLineItemId && id) {
      lineItemApi.getLineItemDates(id, String(selectedLineItemId)).then((response) => {
        const {data} = response;
        setLineItemStats({
          minAis: data?.minAis,
          maxAis: data?.maxAis,
          priceValue: data?.priceValue,
          ais: data?.ais,
          model: data?.price,
          lineItemOverview: data?.lineItemOverview,
          progressBar: data?.progressBar,
          strategyPresentation: data.strategyPresentation,
        });
      });
    }
  }, [selectedLineItemId, id]);

  useEffect(() => {
    if (methods) {
      methods.reset((prev) => ({...prev, calendarView: 'day'}));
    }
  }, [methods]);

  useEffect(() => {
    if (selectedLineItemId && savedChannels) {
      const dataToReplace = savedChannels?.find((item) => item.id === selectedLineItemId);
      methods.reset({
        objective: dataToReplace?.objective,
        targetGroup: (dataToReplace as any)?.targetGroups?.id,
        price: dataToReplace?.price,
        device: dataToReplace?.lineItemDevice?.map((device) => device.device.id),
        format: dataToReplace?.format?.id,
        country: dataToReplace?.country,

        // type: dataToReplace?.type?.map((device) => device.device.id),
      });
      setSelectedLineItem(dataToReplace);
    }
  }, [selectedLineItemId, savedChannels, methods]);
  useEffect(() => {
    if (selectedLineItemId) {
      getGeneratedTable(methods.getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLineItemId, methods]);

  useEffect(() => {
    const objKeys = Object.keys(methods?.getValues());
    if (mustHaveKeys?.every((key) => objKeys?.includes(key))) {
      onFormSubmit(methods?.getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(methods?.getValues())]);

  return (
    <Block size="lg" className="mt-3 position-relative" key={campaignData?.id}>
      <div className="container-fluid">
        <ChangeStep
          campaignId={campaignData?.id}
          status={campaignData?.status}
          currentCampaignStep={campaignData?.currentStep}
          activeStep={3}
          name={campaignData?.name}
          strategyFile={campaignData?.strategyPresentation}
        />
      </div>
      <CreateSectionModal
        show={isCreateSectionOpened}
        setCreateSectionState={setCreateSectionState}
        state={createSectionState}
        handleClose={() => {
          setIsCreateSectionOpened(false);
          setCreateSectionState(createSectionInitial);
        }}
        onSave={createSection}
      />
      <ConfirmDeleteModal
        show={isSectionDeleteModalOpened}
        setShow={setIsSectionDeleteModalOpened}
        itemId={selectedSection!}
        itemName={`ID: ${selectedSection}`}
        deleteHandler={deleteSectionHandler}
      />
      <ConfirmSuccessModal
        actionName={t('general.cancelUpdating')}
        onSave={() => sendDataHandler(selectedLineItem?.channel?.id ?? 0, selectedLineItem?.format?.id ?? 0, true)}
        show={isConfirmationOpened}
        setShow={setIsConfirmationOpened}
        itemName={savedChannels?.find((channel) => channel.id === selectedLineItemId)?.channel?.name ?? ''}
      />
      <LineItemModal
        selectedLineItem={selectedLineItem}
        handleSelectChannel={handleSelectChannel}
        channels={channels}
        currStep={currStep}
        setCurrStep={setCurrStep}
        campaignData={campaignData}
        sendDataHandler={sendDataHandler}
        setIsCreativeValid={setIsCreativeValid}
        isCreativeValid={isCreativeValid}
        methods={methods}
        setListSubmitData={setListSubmitData as React.Dispatch<SetStateAction<IListSubmitData>>}
        onFormSubmit={onFormSubmit}
        isModalOpened={isLineItemCreateOpened}
        setIsModalOpened={setIsLineItemCreateOpened}
        isRegionValid={isRegionValid}
        setIsRegionValid={setIsRegionValid}
      />

      <div className="row position-relative">
        <div className="col-12 d-md-none mb-3">
          <Card className="card-preview">
            <CardBody>
              <h5 className="text-center">{t('campaigns.notSupportedOnMobile')}</h5>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-10 d-none d-md-block">
          <Card className="card-preview">
            <CardBody>
              <Row className="mt-4">
                <Col md={12} className="mb-3 position-relative">
                  {Array.isArray(selectedDates) && !!selectedDates?.length && (
                    <CampaignStepThreeCalendar
                      isCampaignEditable={isCampaignEditable}
                      calendarView={calendarView}
                      lineItemStats={lineItemStats}
                      setCalendarView={setCalendarView}
                      startDate={campaignData?.startDate}
                      setIsCreateSectionOpened={setIsCreateSectionOpened}
                      endDate={campaignData?.endDate}
                      campaignData={campaignData}
                      selectedLineItemId={selectedLineItemId}
                      cancelHandler={cancelHandler}
                      setSelectedLineItemId={setSelectedLineItemId}
                      selectedDates={selectedDates as ISelectedDates[]}
                      setSelectedDates={setSelectedDates as React.Dispatch<SetStateAction<ISelectedDates[]>>}
                      savedChannels={savedChannels}
                      handleCreateLineItem={handleCreateLineItem}
                      setSavedChannels={setSavedChannels}
                      tableDates={tableDates}
                      monthDates={monthDates}
                      setCreateSectionState={setCreateSectionState}
                      setIsSectionDeleteModalOpened={setIsSectionDeleteModalOpened}
                      setSelectedSection={setSelectedSection}
                      setTriggerLineItem={setTriggerLineItem}
                    />
                  )}
                  {Array.isArray(selectedDates) && !selectedDates?.length && (
                    <CalendarEmptyState
                      errorMessage={!campaignData?.mediaPlan ? 'general.addYourFirstLineItem' : 'general.expectMediaPlan'}
                      clickHandler={() => setIsLineItemCreateOpened(true)}
                      isButtonDisabled={(!campaignData?.mediaPlan && isCampaignEditable) || isUserAdmin(roles)}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
          {!selectedLineItemId && (
            <div className=" mt-md-3">
              <div className="d-flex justify-content-start">
                <Button
                  color="secondary"
                  outline
                  onClick={() => {
                    navigate('/app/campaigns');
                  }}
                >
                  <Icon name="arrow-long-left" />
                  {t('general.back')}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-2">
          <div className="card overview-sticky-card">
            <div className="card-body">
              <h4>Campaign overview</h4>
              <CampaignStepThreePreview campaignData={campaignData} campaignOverview={campaignOverview} />
            </div>
          </div>
        </div>
      </div>
    </Block>
  );
};

export default CampaignStep3;
