import {Route, Routes} from 'react-router-dom';
import NotFound from '../../components/error/NotFound';
import {PrivateRoute} from '../../routes/RouteGuards';

import Detail from './components/detail';
import OrderLists from './components/list';
import OrderPending from './pending';
import OrderInProgress from './in-progress';
import OrderFinished from './finished';
import OrderOnAir from './on-air';
import OrderRejected from './rejected';
import OrderDraft from './draft';

export function OrderRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute component={OrderLists} />} />
      <Route path="/pending" element={<PrivateRoute component={OrderPending} />} />
      <Route path="/in-progress" element={<PrivateRoute component={OrderInProgress} />} />
      <Route path="/finished" element={<PrivateRoute component={OrderFinished} />} />
      <Route path="/on-air" element={<PrivateRoute component={OrderOnAir} />} />
      <Route path="/rejected" element={<PrivateRoute component={OrderRejected} />} />
      <Route path="/draft" element={<PrivateRoute component={OrderDraft} />} />
      <Route path="/detail/:id" element={<PrivateRoute component={Detail} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
