import React from 'react';
import {Chart, registerables} from 'chart.js';
import {SolidLineChart} from '../../../components/charts/SolidLineChart';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import {useTranslation} from 'react-i18next';

export const solidLineChart = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  dataUnit: 'BTC',
  lineTension: 0.4,
  legend: true,
  categoryPercentage: 0.9,
  barPercentage: 0.6,
  datasets: [
    {
      label: 'Total Received',
      borderColor: '#5ce0aa',
      backgroundColor: 'white',
      pointBorderWidth: 2,
      fill: false,
      categoryPercentage: 0.9,
      barPercentage: 0.6,
      data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95],
    },
    {
      label: 'Total Send',
      backgroundColor: 'white',
      pointBorderWidth: 2,
      borderColor: '#798bff',
      fill: false,
      categoryPercentage: 0.9,
      barPercentage: 0.6,
      data: [80, 54, 105, 120, 82, 85, 60, 80, 54, 105, 120, 82],
    },
  ],
};

Chart.register(...registerables);

const BrandOverview = () => {
  const {t} = useTranslation();
  return (
    <Block size="lg" className="mt-3">
      <Row className=" g-3">
        <Col xs={12}>
          <BlockHead>
            <BlockHeadContent>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <BlockTitle tag="h4">{t('overview.brands.brands')}</BlockTitle>
                </div>
              </div>
            </BlockHeadContent>
          </BlockHead>
        </Col>
        <Col md={6}>
          <Card className="card-bordered card-preview">
            <CardBody>
              <SolidLineChart legend={true} data={solidLineChart} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Block>
  );
};

export const Data = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555,
  },
  {
    id: 5,
    year: 2020,
    userGain: 4300,
    userLost: 234,
  },
];

export default BrandOverview;
