import {IDevice, IDevicePagination} from '../../../app/administrator/devices/interfaces/IDevice';
import BaseApi from '../../base.api';

const DEVICE_ROUTE = 'api/admin/device';

export class DeviceApi extends BaseApi {
  public async getAllDevices(pagination: IDevicePagination): Promise<any> {
    return await this.get(DEVICE_ROUTE, undefined, pagination);
  }

  public async getDevice(id: number): Promise<{data: IDevice}> {
    return await this.get(`${DEVICE_ROUTE}/${id}`);
  }

  public async createDevice(data: any): Promise<any> {
    return await this.post(DEVICE_ROUTE, data);
  }

  public async deleteDevice(id: number): Promise<any> {
    return await this.delete(`${DEVICE_ROUTE}/${id}`, '');
  }

  public async updateDevice(id: number, data: IDevice): Promise<{data: IDevice}> {
    return await this.put(`${DEVICE_ROUTE}/${id}`, data, '');
  }
}
