import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {usePagination} from '../../shared/hooks/usePagination';
import {campaignApi} from '../../api';
import {ICampaign} from './interfaces/ICampaigns';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../components/block/Block';
import Button from '../../components/button/Button';
import Icon from '../../components/icon/Icon';
import {Card} from 'reactstrap';
import {Pagination} from '../../components/pagination/Pagination';
import CampaignFilters from './components/CampaignFilters';
import CampaignTable from './components/CampaignTable';
import {useTranslation} from 'react-i18next';

const Campaigns = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState<ICampaign[]>([]);
  const {changeFilterHandler, page, setTotalPages, totalPages, handlePages, pagination} = usePagination();

  useEffect(() => {
    campaignApi.getAllCampaigns(pagination).then((response) => {
      const {data} = response;
      setCampaignList(data.data);
      setTotalPages(Math.ceil(data?.count / data?.perPage));
    });
  }, [pagination, setTotalPages]);
  return (
    <>
      <Block size="lg" className="mt-3">
        <BlockHead>
          <BlockHeadContent>
            <div className="row align-items-center gx-0 gx-lg-2">
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-12">
                <BlockTitle tag="h4">{t('campaigns.campaignList')}</BlockTitle>
              </div>
              <div className="col-xxl-2 col-xl-3 col-lg-4 col-12  text-end">
                <Button
                  className="btn-block"
                  color="primary"
                  onClick={() => {
                    navigate('/app/campaigns/create');
                  }}
                >
                  <Icon name="plus-sm" />
                  {t('campaigns.addNew')}
                </Button>
              </div>
            </div>
            <CampaignFilters changeFilterHandler={changeFilterHandler} pagination={pagination} />
          </BlockHeadContent>
        </BlockHead>

        <Card className="card-bordered card-preview">
          <CampaignTable campaignList={campaignList} setCampaignList={setCampaignList} />
        </Card>
      </Block>

      <div className="d-flex justify-content-end">
        {totalPages <= 1 ? '' : <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />}
      </div>
    </>
  );
};

export default Campaigns;
