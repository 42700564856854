import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Select from 'react-select';
import {Card} from 'reactstrap';
import {targetGroupsApi} from '../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import {formatDatepickerTime} from '../../../shared/Functions';
import {themeSelect} from '../../../shared/Select2';
import {RootState} from '../../../store';
import {calculatePrice, ICountry} from '../objective-scoring';
import {IChannels, IObjectiveScoring} from '../objective-scoring/interfaces/IObjectiveScoring';
import TargetGroupScoringUpdateTable from './components/TargetGroupScoringUpdateTable';

const UpdateTargetGroupScoring = () => {
  const {t} = useTranslation();
  const {name} = useParams();
  const {country} = useSelector((state: RootState) => state.enum);
  const [targetGroupScoring, setTargetGroupScoring] = useState<IObjectiveScoring>();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    label: t('general.countryList.AT'),
    value: 'AT',
  });
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, channelId: number, indexToReplace: number, price: string) => {
    const {valueAsNumber} = event.target;

    const copyTargetGroup = targetGroupScoring?.channels;
    let itemToReplace = copyTargetGroup?.find((group) => group?.channelId === channelId && group?.price === price);
    itemToReplace = {
      ...itemToReplace,
      scoring: calculatePrice(itemToReplace?.scoring as number[], indexToReplace, valueAsNumber),
    } as IChannels;
    const combined = copyTargetGroup?.map((item) => {
      if (item.channelId === itemToReplace?.channelId && item.price === itemToReplace.price) {
        return itemToReplace;
      }
      return item;
    });
    setTargetGroupScoring((prev: any) => ({...prev, channels: combined as any}));
  };

  const exportHandler = async () => {
    const currDate = formatDatepickerTime(new Date().toISOString());
    const selectedLang = t(`general.countryList.${selectedCountry?.value}`);
    const fileName = `${currDate}-${selectedLang}-target-group-pricing.xlsx`;
    await targetGroupsApi.exportGroup(selectedCountry?.value).then((res) => {
      const url = URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  };
  const importHandler = async (event: any) => {
    const files = event.target.files;
    const formData = new FormData();
    formData.append('file', files[0]);
    const {data} = await targetGroupsApi.importGroup(formData);
    setSelectedCountry({
      value: data.country,
      label: t(`general.countryList.${data.country}`),
    });
    setTargetGroupScoring(data);
  };
  const submitHandler = async () => {
    const {data} = await targetGroupsApi.updateTargetGroupScoring(targetGroupScoring?.channels as IChannels[], selectedCountry?.value);
    setTargetGroupScoring(data);
  };
  useEffect(() => {
    const getAllTargetGroups = async () => {
      const {data} = await targetGroupsApi.getAllTargetGroupPrices(selectedCountry?.value);
      setTargetGroupScoring(data);
    };
    getAllTargetGroups();
  }, [name, selectedCountry?.value]);

  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="d-flex justify-content-between align-items-center">
            <BlockTitle tag="h4">{t('general.update')} target group pricing</BlockTitle>
            <div className="d-flex gap-3 align-items-center">
              <Select
                theme={themeSelect}
                value={selectedCountry}
                className="form-react-select"
                placeholder={t('administrator.formats.selectType')}
                options={
                  country?.map((countr) => ({
                    value: countr,
                    label: t(`general.countryList.${countr}`),
                  })) as ICountry[]
                }
                onChange={(selected) => {
                  setSelectedCountry(selected as ICountry);
                }}
              />
              <input type="file" name="import" id="import" className="d-none" onChange={importHandler}></input>
              <label htmlFor="import" className="btn btn-primary">
                {t('general.import')}
              </label>

              <Button onClick={exportHandler} color="primary">
                {t('general.export')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview calendar-scroller">
        <TargetGroupScoringUpdateTable targetGroupScoring={targetGroupScoring as IObjectiveScoring} changeHandler={changeHandler} />
      </Card>
      <div className="d-flex justify-content-end mt-3">
        <Button color="primary" onClick={submitHandler}>
          <Icon name="plus-sm" />
          {t('general.update')}
        </Button>
      </div>
    </Block>
  );
};

export default UpdateTargetGroupScoring;
