import React from 'react';
import {BlockDes, BlockHeadSub, BlockTitle} from '../../../components/block/Block';
import {Col} from 'reactstrap';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store';

const DashboardHeader = () => {
  const {
    user: {company},
  } = useSelector((state: RootState) => state.auth);
  return (
    <Col sm="12" className="mb-3">
      <BlockHeadSub>Welcome!</BlockHeadSub>
      <div className="align-center flex-wrap pb-2 gx-4 gy-3">
        <div>
          <BlockTitle tag="h2" className="mb-0" icon="ni-dashboard">
            {company.name}
          </BlockTitle>
        </div>
      </div>
      <BlockDes></BlockDes>
    </Col>
  );
};

export default DashboardHeader;
