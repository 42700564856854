import {
  IChannel,
  IChannelDeviceTargeting,
  IChannelFormat,
  IChannelPagination,
} from '../../../app/administrator/channels/interfaces/IChannel';
import BaseApi from '../../base.api';

const CHANNEL_ROUTE = '/api/admin/channel';

export class ChannelsApi extends BaseApi {
  public async getAllChannels(pagination: IChannelPagination): Promise<any> {
    return await this.get(CHANNEL_ROUTE, '', pagination);
  }

  public async getChannel(id: number): Promise<{data: IChannel}> {
    return await this.get(`${CHANNEL_ROUTE}/${id}`);
  }

  public async createChannel(data: FormData): Promise<any> {
    return await this.post(CHANNEL_ROUTE, data, undefined, true);
  }

  public async updateTargeting(data: IChannelDeviceTargeting): Promise<{data: {price: number}}> {
    return await this.patch(`${CHANNEL_ROUTE}/`, {}, '', false, data);
  }

  public async deleteChannel(id: number): Promise<any> {
    return await this.delete(`${CHANNEL_ROUTE}/${id}`, '');
  }

  public async updateChannel(id: number, data: FormData): Promise<{data: IChannel}> {
    return await this.put(`${CHANNEL_ROUTE}/${id}`, data, '', true);
  }

  public async getChannelFormat(id: number): Promise<{data: IChannelFormat[]}> {
    return await this.get(`${CHANNEL_ROUTE}/format/${id}`);
  }

  public async updateChannelFormat(id: number, data: IChannelFormat[]): Promise<{data: IChannelFormat[]}> {
    return await this.put(`${CHANNEL_ROUTE}/format/${id}`, {formatList: data}, '');
  }
}
