import React, {SetStateAction, useEffect, useRef, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {Button, Collapse} from 'reactstrap';
import {aiSettingsApi, campaignApi, promptApi} from '../../../../../api';
import UserAvatar from '../../../../../components/user/UserAvatar';
import FileInput from '../../../../../shared/FileInput';
import {adjustAiChatBox, getAvatarInitials, isUserAdmin} from '../../../../../shared/Functions';
import {adjustValues, ISelect2, themeSelect} from '../../../../../shared/Select2';
import {RootState} from '../../../../../store';
import {ICampaign} from '../../../interfaces/ICampaigns';
import {IThread} from '../CampaignStep9';
import {IPrompt} from '../../../../administrator/prompts/interfaces/IPrompt';

interface IProps {
  file: string;
  campaignData: ICampaign;
  setThreadMessages: React.Dispatch<SetStateAction<IThread[] | null>>;
  setTimeUpdated: React.Dispatch<SetStateAction<number>>;
  originalUserThreads: IThread[] | null;
  threadMessages: IThread[] | null;
}

const AIChat: React.FC<IProps> = ({threadMessages, campaignData, setTimeUpdated, file, setThreadMessages, originalUserThreads}) => {
  const {t} = useTranslation();
  const {roles} = useSelector((state: RootState) => state.auth.user);
  const isAdmin = isUserAdmin(roles);
  const methods = useForm();
  const [adminPrompt, setAdminPrompt] = useState<string>('');
  const [isOpenAccordion, setIsOpenAccordion] = useState(false);
  const [aiPrompts, setAiPrompts] = useState<IPrompt[]>();
  const [aiAssistantPrompts, setAiAssistantPrompts] = useState<IPrompt[]>();
  const [currentInput, setCurrentInput] = useState<string>('');
  const [currentAiAssistantPrompt, setCurrentAiAssistantPrompt] = useState<number>();

  const {id, profilePicture} = useSelector((state: RootState) => state.auth.user);
  const lastMessageRef = useRef<null | HTMLDivElement>(null);
  const handleUploadFile = async (campaignId: number) => {
    const formData = new FormData();
    formData.append('file', methods.getValues('file')[0]);
    formData.append('message', JSON.stringify(adminPrompt));
    formData.append('assistantMessageId', JSON.stringify(currentAiAssistantPrompt));
    const {data} = await campaignApi.uploadAIFile(campaignId, formData);
    if (data) {
      methods.reset();
      setThreadMessages((prev) => (prev || [])?.concat(data?.map((message) => adjustAiChatBox(message, Math.random()))));
      setAdminPrompt('');
      setTimeUpdated(new Date().getTime());
    }
  };
  const handleSendInput = async () => {
    setIsOpenAccordion(false);
    const myMessage = adjustAiChatBox(
      {
        isMyMessage: true,
        user: currentInput,
      },
      Math.random(),
      profilePicture
    );
    await campaignApi.sendAIMessage(campaignData?.id, currentInput).then((response) => {
      if (response) {
        const backendResponse = response?.data?.map((message: IThread, currentIndex: number) => adjustAiChatBox(message, currentIndex));
        setThreadMessages((prev) => (prev || [])?.concat(myMessage).concat(backendResponse));
        // setTimeUpdated(new Date().getTime());
        setCurrentInput('');
      }
    });
  };

  const sendMessagePressingEnter = async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents adding new line
      // Send message
      if (currentInput.trim() !== '') {
        await handleSendInput();
      }
    }
  };

  useEffect(() => {
    async function getAllPrompts() {
      const {data} = await promptApi.getAllPrompts({perPage: 0, page: 1});

      setAiPrompts(data.data);
    }

    getAllPrompts();
  }, []);
  useEffect(() => {
    async function getAllPrompts() {
      const {data} = await aiSettingsApi.getAllSettings({perPage: 0, page: 1});

      setAiAssistantPrompts(data.data);
    }

    getAllPrompts();
  }, []);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({behavior: 'smooth'});
    }
    // eslint-disable-next-line
  }, [lastMessageRef.current, threadMessages?.length]);

  return (
    <>
      <div className="border mb-5">
        <div className="nk-chat-body">
          <div className="row">
            <div className="col-10 px-0 border-right">
              <div className="nk-chat-panel p-1" style={{height: '60vh', overflowY: 'auto'}}>
                {!!file?.length ? (
                  threadMessages?.map((comment, currentIndex, commentOriginal) => {
                    const isMyMsg = comment?.isMyMessage;
                    return (
                      <React.Fragment key={comment.id}>
                        <div className="d-flex align-items-start">
                          <UserAvatar
                            className="me-1 mb-1"
                            size="sm"
                            key={id}
                            image={comment?.logo}
                            text={getAvatarInitials('A', 'I')}
                            theme="primary"
                          />
                          <div>
                            <span className="fw-bold">{isMyMsg ? 'You' : 'AI'}</span>
                            <div dangerouslySetInnerHTML={{__html: comment?.message || ''}} />
                            {comment?.path && (
                              <img src={comment?.path} className="h-50" style={{width: '80%'}} alt="File generated by AI tool" />
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div className="center-items-flex h-100">{t('campaigns.missingThread')}</div>
                )}
                <div ref={lastMessageRef} />
              </div>
            </div>
            <div
              className="col-2 px-0"
              style={{
                backgroundColor: 'rgba(235, 238, 242, 0.7)',
              }}
            >
              <div
                className="p-2 d-flex flex-column justify-content-between"
                style={{
                  maxHeight: 250,
                }}
              >
                {isAdmin && (
                  <div className="d-flex justify-content-end flex-column">
                    <h5 className="text-center">Admin</h5>
                    <FormProvider {...methods}>
                      {!!file?.length && (
                        <div className="card bg-light mb-1">
                          <div className="card-body">
                            <a href={file} target="_blank" download={file} rel="noreferrer">
                              <em
                                className="icon ni ni-file-xls  h-100 w-100 d-flex align-items-center justify-content-center display-1"
                                style={{
                                  minHeight: '75px',
                                  maxHeight: '75px',
                                  objectFit: 'cover',
                                }}
                              ></em>
                            </a>
                          </div>
                        </div>
                      )}
                      <FileInput accept="*" isMultiple={false} name="file" label="File Upload" />
                    </FormProvider>
                    {methods.getValues()?.file && (
                      <div className="mt-2">
                        <textarea
                          className="form-control min-h-65px"
                          // rows={1}
                          // id="default-textarea"
                          onChange={(e) => setAdminPrompt(e.target.value)}
                          value={adminPrompt}
                          wrap="hard"
                          placeholder="Type your message..."
                        />
                        <Select
                          theme={themeSelect}
                          className="my-2"
                          placeholder={'Select assistant prompt'}
                          options={adjustValues(aiAssistantPrompts as unknown as ISelect2[], 'assistantPrompt')}
                          onChange={(selected) => {
                            setCurrentAiAssistantPrompt(selected?.value);
                          }}
                        />
                        <Button color={'primary'} onClick={() => handleUploadFile(campaignData?.id)} className="d-block my-1 w-100">
                          Save
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                <div className="mt-2">
                  <Select
                    theme={themeSelect}
                    placeholder={'Select prompt'}
                    options={adjustValues(aiPrompts as unknown as ISelect2[], 'prompt')}
                    onChange={(selected) => {
                      setCurrentInput(String(selected?.label || ''));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-10 px-0">
              <textarea
                className="form-control min-h-65px"
                // rows={1}
                // id="default-textarea"
                onKeyPress={(e) => sendMessagePressingEnter(e)}
                onChange={(e) => setCurrentInput(e.target.value)}
                value={currentInput}
                wrap="hard"
                placeholder="Type your message..."
              />
            </div>
            <div className="col-2 d-flex align-items-center flex-column justify-content-center ai-chat-select">
              <button className="btn btn-round btn-primary btn-icon mb-1" onClick={handleSendInput}>
                <em className="icon ni ni-send-alt"></em>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isAdmin && (
        <div className="accordion mb-5">
          <div className="accordion-item">
            <div
              className={`accordion-head${isOpenAccordion ? ' collapsed' : ''} cursor-pointer`}
              onClick={() => setIsOpenAccordion((prev) => !prev)}
            >
              <h5 className="mb-0">Chat list</h5>
              <span className="accordion-icon"></span>
            </div>
            <Collapse className="accordion-body" isOpen={isOpenAccordion}>
              <div className="accordion-inner">
                {originalUserThreads?.map((comment) => {
                  return (
                    <div key={comment.id}>
                      <div className="d-flex align-items-start">
                        <UserAvatar
                          className="me-1 mb-1"
                          size="sm"
                          key={id}
                          image={comment?.logo}
                          text={getAvatarInitials('C', 'L')}
                          theme="primary"
                        />
                        <div>
                          <span className="fw-bold">{comment?.isMyMessage ? 'Client' : 'AI'}</span>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: comment?.message || '',
                            }}
                          />
                          {comment?.path && <img src={comment?.path} className="w-100" alt="File generated by AI tool" />}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </div>
        </div>
      )}
    </>
  );
};

export default AIChat;
