import React from 'react';
import {Link} from 'react-router-dom';

import PageContainer from '../../layout/page-container/PageContainer';
import {Block, BlockContent, BlockDes, BlockHead, BlockTitle} from '../../components/block/Block';
import RegisterForm from './components/RegisterForm';
import {PreviewCard} from '../../components/preview/Preview';
import LoginSlider from '../login/components/LoginSlider';

const Register = () => {
  return (
    <div className="row min-h-100vh">
      <div className="col-md-6">
        <PageContainer>
          <Block className="nk-block-middle nk-auth-body  wide-xs">
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <div className="row">
                <div className="col-12">
                  <BlockHead>
                    <BlockContent>
                      <BlockTitle tag="h4">Register</BlockTitle>
                      <BlockDes>
                        <p>Create new Account</p>
                      </BlockDes>
                    </BlockContent>
                  </BlockHead>
                </div>
              </div>
              {/* ==================== Register Form ==================== */}
              <RegisterForm />
              {/* ==================== Register Form ==================== */}
              <div className="form-note-s2 text-center pt-4">
                Already have an account?{' '}
                <Link to={`${process.env.PUBLIC_URL}/`}>
                  <strong>Sign in instead</strong>
                </Link>
              </div>
            </PreviewCard>
          </Block>
        </PageContainer>
      </div>
      <div className="col-md-6 bg-white d-flex align-items-center justify-content-center">
        <LoginSlider />
      </div>
    </div>
  );
};
export default Register;
