import React, {useEffect} from 'react';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {formatApi} from '../../../../api';
import {IFormat} from '../interfaces/IFormat';
import FormatForm from './FormatForm';
import {formatFormSchema} from './FormatCreate';
import {useTranslation} from 'react-i18next';

const FormatUpdate = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();
  const methods = useForm<IFormat>({
    resolver: yupResolver(formatFormSchema),
  });

  const onFormSubmit = async (state: IFormat) => {
    const correctTypeData = state?.type?.map((type: any) => type?.id);
    const isTypeDataEdited = correctTypeData?.some((type) => !type);
    const formData = new FormData();
    !!state.picture && formData.append('picture', (state.picture as unknown as File[])[0]);
    formData.append(
      'body',
      JSON.stringify({
        ...state,
        type: isTypeDataEdited ? state?.type : correctTypeData,
      })
    );
    const {data} = await formatApi.updateFormat(Number(id), formData);
    data && navigate(-1);
  };

  useEffect(() => {
    if (id) {
      const getFormat = async (formatId: number) => {
        const {data} = await formatApi.getFormat(+formatId);
        methods?.reset({
          ...data,
          type: data?.typeObject as any,
        });
      };
      getFormat(+id);
    }
  }, [id, methods]);

  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('administrator.formats.formatUpdate')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate('/app/admin/format');
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <FormatForm
                register={methods?.register}
                defaultState={methods?.formState?.defaultValues as IFormat}
                errors={methods?.formState?.errors}
                formState={methods?.formState}
                watch={methods?.watch}
              />
              <Row>
                <Col>
                  <Row>
                    <Col className={'text-end'}>
                      <Button color="primary">
                        <Icon name="plus-sm" />
                        {t('general.update')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default FormatUpdate;
