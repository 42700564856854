import {IType, ITypePagination} from '../../../app/administrator/type/interfaces/IType';
import BaseApi from '../../base.api';

const TYPE_ROUTE = '/api/admin/type';

export class TypeApi extends BaseApi {
  public async getAllTypes(pagination: ITypePagination): Promise<any> {
    return await this.get(TYPE_ROUTE, undefined, pagination);
  }

  public async getType(id: number): Promise<{data: IType}> {
    return await this.get(`${TYPE_ROUTE}/${id}`);
  }

  public async createType(data: any): Promise<any> {
    return await this.post(TYPE_ROUTE, data);
  }

  public async deleteType(id: number): Promise<any> {
    return await this.delete(`${TYPE_ROUTE}/${id}`, '');
  }

  public async updateType(id: number, data: IType): Promise<{data: IType}> {
    return await this.put(`${TYPE_ROUTE}/${id}`, data, '');
  }
}
