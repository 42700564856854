import React, {SetStateAction, useState} from 'react';
import {IQuality} from '../interfaces/IQuality';
import {useNavigate} from 'react-router-dom';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {EmptyState} from '../../../../components/shared/EmptyState';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import {qualityApi} from '../../../../api';
import {useTranslation} from 'react-i18next';

interface IQualityTableProps {
  qualityList: IQuality[];
  setQualityList: React.Dispatch<SetStateAction<IQuality[]>>;
}

const QualityTable = ({qualityList, setQualityList}: IQualityTableProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedQuality, setSelectedQuality] = useState<IQuality>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const deleteQualityHandler = async () => {
    if (selectedQuality?.id) {
      const {
        data: {data},
      } = await qualityApi.deleteQuality(selectedQuality?.id);
      setQualityList(data);
    }
  };
  return (
    <div className="table-responsive">
      {!!qualityList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.id')}</th>
              <th scope="col">{t('general.name')}</th>
              <th scope="col">{t('general.description')}</th>
              <th scope="col">{t('general.status')}</th>
              <th scope="col" className="text-end">
                {t('general.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {qualityList
              ?.map((quality) => {
                const {id, name, description, status} = quality;
                return (
                  <tr key={id}>
                    <th>{id}</th>
                    <td>{name}</td>
                    <td>{description ?? '-'}</td>
                    <td>{status ? 'Enabled' : 'Disabled'}</td>
                    <td className="d-flex justify-content-end">
                      <Button
                        color="warning"
                        size="sm"
                        onClick={() => {
                          navigate(`/app/admin/quality/update/${id}`);
                        }}
                      >
                        <Icon name="edit" />
                        {t('general.edit')}
                      </Button>
                      {!quality.isUsed && (
                        <Button
                          color="danger"
                          size="sm"
                          className="ms-1"
                          onClick={() => {
                            setIsDeleteModalOpened(true);
                            setSelectedQuality(quality);
                          }}
                        >
                          <Icon name="trash" />
                          {t('general.delete')}
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })
              .sort()}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedQuality?.id as number}
        itemName={selectedQuality?.name as string}
        deleteHandler={deleteQualityHandler}
      />
    </div>
  );
};

export default QualityTable;
