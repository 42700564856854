import Lottie from 'lottie-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../components/button/Button';
import Icon from '../../../../../components/icon/Icon';
import calendarIcon from '../../../../../shared/icons/calendarIcon.json';

interface Props {
  clickHandler: () => void;
  isButtonDisabled?: boolean;
  errorMessage?: string;
}

const CalendarEmptyState = ({clickHandler, isButtonDisabled = false, errorMessage = 'general.emptyCalendarList'}: Props) => {
  const {t} = useTranslation();
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <Lottie animationData={calendarIcon} loop={true} style={{width: 300}} />
      <h5>{t(errorMessage)}</h5>
      {isButtonDisabled && (
        <Button color="primary" onClick={clickHandler}>
          <Icon name="plus-sm" />
          {t('general.add')}
        </Button>
      )}
    </div>
  );
};

export default CalendarEmptyState;
