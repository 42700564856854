import React, {SetStateAction} from 'react';
import {FormGroup} from 'reactstrap';
import Icon from '../../../components/icon/Icon';
import {getCorrectCountry} from '../../../shared/Functions';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {IRegisterData} from '../../interfaces/Register';
import {useTranslation} from 'react-i18next';

interface IFormUserProps {
  errors: Partial<
    FieldErrorsImpl<{
      user: {
        firstName: string;
        lastName: string;
        email: string;
        password: string;
        contact: Partial<
          FieldErrorsImpl<{
            country: string;
          }>
        >;
      };
    }>
  >;
  register: UseFormRegister<IRegisterData>;
  setPassState: React.Dispatch<SetStateAction<boolean>>;
  passState: boolean;
}

const FormUser = ({errors, register, setPassState, passState}: IFormUserProps) => {
  const {country} = useSelector((state: RootState) => state.enum);
  const {t} = useTranslation();
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <FormGroup>
            <label className="form-label" htmlFor="firstName">
              First Name
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="reg-firstName"
                placeholder="Enter your first name"
                {...register('user.firstName')}
                className="form-control-lg form-control"
              />
              {errors.user?.firstName && <p className="invalid">{errors.user?.firstName?.message}</p>}
            </div>
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup>
            <label className="form-label" htmlFor="lastName">
              Last Name
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="reg-lastName"
                placeholder="Enter your last name"
                {...register('user.lastName')}
                className="form-control-lg form-control"
              />
              {errors.user?.lastName && <p className="invalid">{errors.user?.lastName?.message}</p>}
            </div>
          </FormGroup>
        </div>
        <div className="col-md-12">
          <FormGroup>
            <div className="form-label-group">
              <label className="form-label" htmlFor="default-01">
                Email
              </label>
            </div>
            <div className="form-control-wrap">
              <input
                type="text"
                id="default-01"
                {...register('user.email')}
                className="form-control-lg form-control"
                placeholder="Enter your email address"
              />
              {errors.user?.email && <p className="invalid">{errors.user?.email?.message}</p>}
            </div>
          </FormGroup>
          <FormGroup>
            <div className="form-label-group">
              <label className="form-label" htmlFor="password">
                Password
              </label>
            </div>
            <div className="form-control-wrap">
              <a
                href="src/authentification/login/Login#password"
                onClick={(ev) => {
                  ev.preventDefault();
                  setPassState(!passState);
                }}
                className={`form-icon lg form-icon-right passcode-switch ${passState ? 'is-hidden' : 'is-shown'}`}
              >
                <Icon name="eye" className="passcode-icon icon-show"></Icon>

                <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
              </a>
              <input
                type={passState ? 'text' : 'password'}
                id="password"
                {...register('user.password')}
                placeholder="Enter your password"
                className={`form-control-lg form-control ${passState ? 'is-hidden' : 'is-shown'}`}
              />
              {errors.user?.password && <span className="invalid">{errors.user?.password?.message}</span>}
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-label" htmlFor="phone">
              Phone number
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="reg-phone"
                placeholder="Enter your phone number"
                {...register('user.contact.phone')}
                className="form-control-lg form-control"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-label" htmlFor="country">
              Country
            </label>
            <div className="form-control-wrap ">
              <div className="form-control-select">
                <select className="form-control form-control-lg" id="default-06" {...register('user.contact.country')}>
                  <option selected disabled>
                    Enter country
                  </option>
                  {country?.map((item) => (
                    <option value={item} key={item}>
                      {getCorrectCountry(item, t)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {errors.user?.contact?.country && <span className="invalid">{errors?.user?.contact?.country?.message}</span>}
          </FormGroup>
          <FormGroup>
            <label className="form-label" htmlFor="city">
              City
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="reg-city"
                placeholder="Enter your city"
                {...register('user.contact.city')}
                className="form-control-lg form-control"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-label" htmlFor="zip">
              Zip
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="reg-zip"
                placeholder="Enter your zip"
                {...register('user.contact.zip')}
                className="form-control-lg form-control"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-label" htmlFor="address">
              Address
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="reg-address"
                placeholder="Enter your adress"
                {...register('user.contact.address')}
                className="form-control-lg form-control"
              />
            </div>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default FormUser;
