import _ from 'lodash';
import React, {SetStateAction, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import Select from 'react-select';
import {Button} from 'reactstrap';
import {lineItemApi, regionApi} from '../../../../../api';
import Icon from '../../../../../components/icon/Icon';
import {formatDatepickerTime} from '../../../../../shared/Functions';
import {adjustValues, ISelect2, themeSelect} from '../../../../../shared/Select2';
import {RootState} from '../../../../../store';
import {IChannelDeviceFormatBuild} from '../../../../administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import {IRegion} from '../../../../administrator/region/interfaces/IRegion';
import {ICampaign} from '../../../interfaces/ICampaigns';
import UpdateLineItemPreview from './UpdateLineItemPreview';

interface Props {
  clickHandler: () => void;
  lineItem: IChannelDeviceFormatBuild | undefined;
  setLineItem: React.Dispatch<SetStateAction<IChannelDeviceFormatBuild | undefined>>;
  cancelHandler: () => void;
  campaignData?: ICampaign;
  isCampaignEditable: boolean;
  setTriggerLineItem: (...args: any) => void;
}

export interface ILocalSection {
  id: number;
  title: string;
}

const UpdateLineItemForm = ({
  clickHandler,
  lineItem,
  setLineItem,
  cancelHandler,
  campaignData,
  isCampaignEditable,
  setTriggerLineItem,
}: Props) => {
  const {t} = useTranslation();
  const {id: campaignId} = useParams();
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [sections, setSections] = useState<ILocalSection[]>([]);
  const isPriceInvalid =
    (lineItem?.channel?.thresholdInvestment ?? 0) > (lineItem?.priceValue ?? 0) ||
    (lineItem?.priceValue ?? 0) > (campaignData?.plannedBudget ?? 0);
  const [isLineItemTargetingEnabled, setIsLineItemTargetingEnabled] = useState(true);
  const [isFrequencyCapEnabled, setIsFrequencyCapEnabled] = useState(false);
  const [changedValue, setChangedValue] = useState<'priceValue' | 'ais' | ''>('');
  const [strategyPresentation, setStrategyPresentation] = useState<any>();
  const {roles} = useSelector((state: RootState) => state?.auth?.user);
  const isAdmin = roles?.some((role) => role === 'ROLE_ADMIN');

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, isNumber = false, hasApi = false) => {
    const {name, value} = event.target;
    if (hasApi) {
      setChangedValue(name as 'priceValue' | 'ais');
    } else {
      setChangedValue('');
    }
    setLineItem((prev: any) => ({...prev, [name]: isNumber ? (+!!value ? +value : '') : value}));
  };

  const changeFrequencyHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, isNumber = false) => {
    const {name, value} = event.target;
    setLineItem((prev: any) => ({...prev, [name]: isNumber ? (+!!value ? +value : '') : value}));
  };

  const changeCommentHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {name, value} = event.target;
    setLineItem((prev: any) => ({...prev, [name]: value}));
  };

  const getCorrectKey = (key: string) => {
    if (key === 'CPM') return 'Ais';
    if (key === 'CPV') return 'Views';
    if (key === 'CPC') return 'Clicks';
    if (key === 'CPE') return 'Engagement';
  };

  const changeSelectHandler = (eventValues: number[], eventName: string) => {
    setLineItem((prev: any) => ({
      ...prev,
      [eventName]: eventValues,
      lineItemRegion: prev.lineItemRegion?.filter((region: {id: number}) => eventValues.includes(region?.id)),
    }));
  };

  const handleFrequencyCap = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFrequencyCapEnabled((prevState) => !prevState);
    setLineItem((prev: any) => ({...prev, frequencyCap: event.target.checked}));
  };

  const handleLineItemTargeting = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLineItem((prev: any) => ({...prev, targeting: event.target.checked}));
    setIsLineItemTargetingEnabled((prevState) => !prevState);
  };

  const updateCalculate = async () => {
    await lineItemApi.postLineItemOffMedia(lineItem?.id!, +campaignId!);
    setTriggerLineItem((prev: boolean) => !prev);
  };

  const handleSearch = useMemo(
    () =>
      _.debounce(
        (
          changedValue: string,
          campaignId: string,
          lineItemId: number,
          lineItemPriceValue: number,
          lineItemAis: number,
          channelTrashold: number
        ) => {
          // Perform the search operation here
          const sendPriceValue = changedValue === 'priceValue' ? +lineItemPriceValue : 0;
          const sendAis = changedValue === 'ais' ? +lineItemAis : 0;
          if (changedValue !== 'priceValue' || channelTrashold < sendPriceValue) {
            lineItemApi.getLineItemPrice(campaignId, lineItemId, sendPriceValue, sendAis).then((response) => {
              const {data} = response;

              setLineItem((prev: any) => ({
                ...prev,
                ...data,
                clicks: data.lineItemOverview.clicks,
                impressions: data.lineItemOverview.adImpressions,
                completedViews: data.lineItemOverview.completedViews,
                engagement: data.lineItemOverview.engagement,
                priceValue: !!data?.priceValue ? data?.priceValue : '',
                ais: !!data?.ais ? data?.ais : '',
              }));
            });
          }
        },
        500
      ),
    [setLineItem]
  );

  useEffect(() => {
    const getAllRegions = async () => {
      const {
        data: {data},
      } = await regionApi.getAllRegions({page: 1, perPage: 0});
      setRegions(data);
    };
    getAllRegions();
  }, []);

  useEffect(() => {
    if (!!lineItem?.id && campaignId) {
      lineItemApi.getAllSections(campaignId).then((response) => {
        setSections(response?.data?.map((sec) => ({id: sec.lineItem_id, title: sec.lineItem_title})));
      });
    }
  }, [lineItem?.id, campaignId]);

  useEffect(() => {
    if (
      !!(
        !!changedValue.length &&
        campaignId &&
        lineItem?.id &&
        !!handleSearch &&
        lineItem?.channel?.thresholdInvestment &&
        (!isPriceInvalid || changedValue !== 'priceValue')
      )
    ) {
      handleSearch(
        changedValue,
        campaignId,
        lineItem?.id,
        lineItem?.priceValue,
        lineItem?.ais,
        lineItem?.channel?.thresholdInvestment ?? 0
      );
    }
  }, [
    changedValue,
    campaignId,
    lineItem?.id,
    lineItem?.priceValue,
    lineItem?.ais,
    lineItem?.channel?.thresholdInvestment,
    handleSearch,
    isPriceInvalid,
  ]);

  useEffect(() => {
    setIsFrequencyCapEnabled(lineItem?.frequencyCap!);
    setIsLineItemTargetingEnabled(lineItem?.targeting!);
  }, [lineItem]);

  const onFormSubmit = async (event: any) => {
    const files = event.target.files;
    const formData = new FormData();
    for (const item of files) {
      formData.append('strategyPresentation', item);
    }
    formData.append('lineItem', `${lineItem?.id}`);
    if (!files.length) return;
    const {data} = await lineItemApi.uploadStrategyFile(formData);
    setStrategyPresentation(data[0]);
  };

  const downloadFile = () => {
    const url = URL.createObjectURL(new Blob([strategyPresentation.file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', strategyPresentation?.file.nameOriginal!);
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    setStrategyPresentation(lineItem?.strategyPresentation);
  }, [lineItem?.strategyPresentation]);

  return (
    <div
      className="mt-2 bg-lighter pb-1 line-item-form"
      style={{
        left: ' calc(var(--bs-gutter-x) * 0.5)',
        right: 'calc(var(--bs-gutter-x) * 0.5)',
        zIndex: 1,
        position: 'absolute',
      }}
    >
      <div className="row w-100">
        <div className="col-md-3">
          <label htmlFor="creativeName">{t('campaigns.enterCreativeName')}</label>
          <input
            type="text"
            onChange={changeHandler}
            value={lineItem?.creativeName ?? ''}
            name="creativeName"
            className="form-control"
            disabled={!isCampaignEditable}
          />

          <div className="my-2">
            <label htmlFor="ais">{lineItem?.format?.linearMedia ? t('general.contacts') : getCorrectKey(lineItem?.price ?? '')}</label>
            <input
              onChange={(e) => changeHandler(e, true, true)}
              name="ais"
              value={Math.round(lineItem?.ais as number)}
              className="form-control"
              disabled={!isCampaignEditable || lineItem?.format?.linearMedia}
            />
            <div className="form-control-wrap mt-1">
              <input
                type="range"
                name="ais"
                value={Math.round(lineItem?.ais as number)}
                className="form-control-slider w-100"
                onChange={(e) => changeHandler(e, true, true)}
                id="aisSlider"
                min={Math.round(lineItem?.minAis ?? 0)}
                max={Math.round(lineItem?.maxAis ?? 0)}
                step={'1000'}
                disabled={lineItem?.format?.linearMedia}
              />
            </div>
          </div>
          {!lineItem?.format?.linearMedia ? (
            <div className="my-2">
              <div className="d-flex align-items-center justify-content-between">
                <label htmlFor="targeting">{t('general.targeting')}</label>
                <div className="custom-control custom-switch mt-1">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="targeting"
                    checked={isLineItemTargetingEnabled}
                    onChange={handleLineItemTargeting}
                  />
                  <label className="custom-control-label" htmlFor="targeting" />
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <label htmlFor="frequencyCap">{t('general.frequencyCap')}</label>
                <div className="custom-control custom-switch mt-1">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="frequencyCap"
                    checked={isFrequencyCapEnabled}
                    disabled={!isCampaignEditable}
                    onChange={handleFrequencyCap}
                  />
                  <label className="custom-control-label" htmlFor="frequencyCap" />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Button onClick={() => updateCalculate()}>{t('general.update')}</Button>
            </div>
          )}
        </div>
        <div className="col-md-3">
          <div>
            <label htmlFor="region">
              {(lineItem?.country ? t(`general.countryList.${lineItem?.country}`) + ' ' : '') +
                t('general.region').toLocaleLowerCase() +
                's'}
            </label>
            <Select
              theme={themeSelect}
              placeholder={t('general.allRegions')}
              options={adjustValues(regions as unknown as ISelect2[], 'name').map((item) => ({
                ...item,
                label: item.label,
              }))}
              isClearable
              isMulti
              isDisabled={!isCampaignEditable}
              value={adjustValues(
                regions?.filter(
                  (region) =>
                    lineItem?.lineItemRegion?.some((savedRegion) => (savedRegion as any)?.region?.id === region?.id) ||
                    lineItem?.region?.includes(region?.id)
                ) as unknown as ISelect2[],
                'name'
              )}
              className="small-select2"
              onChange={(event) => {
                changeSelectHandler(
                  event?.map((region) => region.id),
                  'region'
                );
              }}
            />
          </div>

          <div className="my-2">
            <label htmlFor="price">{t('campaigns.price')} </label>
            <input
              type="number"
              onInput={(e: any) => {
                e.preventDefault();
                e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
              }}
              onChange={(e) => changeHandler(e, true, true)}
              name="priceValue"
              value={lineItem?.priceValue ?? ''}
              className={`form-control ${isPriceInvalid ? 'error' : ''}`}
              disabled={!isCampaignEditable || lineItem?.format?.linearMedia}
            />
            <div className="form-control-wrap w-100 mt-1">
              <input
                type="range"
                name="priceValue"
                value={lineItem?.priceValue ?? ''}
                className="form-control-slider w-100"
                onChange={(e) => changeHandler(e, true, true)}
                id="priceValueSlider"
                min={lineItem?.channel?.thresholdInvestment}
                max={campaignData?.plannedBudget}
                step={1000}
                disabled={lineItem?.format?.linearMedia}
              />
            </div>
            {isPriceInvalid && (
              <div className="text-end fst-italic text-danger fs-10px">
                Min: {lineItem?.channel?.thresholdInvestment}, Max: {campaignData?.plannedBudget}
              </div>
            )}
          </div>
          {isFrequencyCapEnabled && (
            <>
              <label htmlFor="price">Cap</label>

              <div className="d-flex align-items-center">
                <input
                  type="number"
                  onInput={(e: any) => {
                    e.preventDefault();
                    e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                  }}
                  onChange={(e) => changeFrequencyHandler(e, true)}
                  name="valueFrequencyCap"
                  value={lineItem?.valueFrequencyCap ?? ''}
                  className="form-control w-50"
                  disabled={!isCampaignEditable}
                />
                <span className="text-muted ms-2">Times per week</span>
              </div>
            </>
          )}

          {/* {!!campaignData?.strategyPresentation && (
            <a href={'test'} target="_blank" rel="noreferrer" download={campaignData?.strategyPresentation.path}>
              <Button color="primary" size="sm">
                <Icon name="download" className="cursor-pointer" />
                <span>{t('general.download')}</span>
              </Button>
            </a>
          )} */}
        </div>

        <div className="col-md-6">
          <div className="row mt-2">
            <UpdateLineItemPreview
              showInputFields={false}
              isFormDisabled={false}
              prices={{
                ais: lineItem?.ais ?? 0,
                priceValue: lineItem?.priceValue ?? 0,
                model: lineItem?.price as 'CPC' | 'CPM' | 'CPV',
              }}
              lineItem={lineItem}
              targetGroup={(lineItem as any)?.targetGroups?.name ?? '-'}
            />
          </div>
        </div>
        <div className="col-md-12 mb-5">
          <label htmlFor="comment" className="form-label">
            {t('general.comment')}
          </label>

          <textarea
            id="comment"
            name="comment"
            onChange={changeCommentHandler}
            value={lineItem?.comment ?? ''}
            className=" form-control form-control-sm w-100"
            disabled={!isCampaignEditable}
            style={{minHeight: 'auto'}}
            rows={3}
          />
          <div className="d-flex justify-content-between align-items-center my-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="me-2">
                <Select
                  theme={themeSelect}
                  placeholder={t('general.sections')}
                  options={adjustValues(sections as unknown as ISelect2[], 'title').map((item) => ({
                    ...item,
                    label: item.label,
                  }))}
                  isClearable
                  isDisabled={!isCampaignEditable}
                  value={adjustValues(
                    sections?.filter(
                      (section) => section.id === ((lineItem?.parent as unknown as ILocalSection)?.id || lineItem?.parent)
                    ) as unknown as ISelect2[],
                    'title'
                  )}
                  className="small-select2"
                  onChange={(selectedSection) => {
                    setLineItem((prev: any) => ({...prev, parent: selectedSection?.id}));
                  }}
                />
              </div>
              <div className="d-flex align-items-center">
                <Button color="outline-primary" onClick={cancelHandler}>
                  {t('general.cancel')}
                </Button>
                {!isPriceInvalid && (
                  <Button color="primary" className="mx-2" onClick={clickHandler} disabled={!isCampaignEditable}>
                    {t('general.update')}
                  </Button>
                )}
              </div>
              <div className="text-muted fs-10px d-flex flex-column mx-2">
                <span>
                  Edited by: {lineItem?.user?.firstName} {lineItem?.user?.lastName}
                </span>
                <span>
                  Edited at:
                  {formatDatepickerTime(lineItem?.updatedAt ?? '')}
                </span>
              </div>
              <Link to={'/terms-conditions'}>Terms and conditions</Link>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-0">
              {!!isAdmin && (
                <>
                  <input type="file" name="importLineItem" id="importLineItem" className="d-none" onChange={(e) => onFormSubmit(e)} />
                  <label htmlFor="importLineItem" className="btn btn-primary">
                    {t('campaigns.uploadStrategy')}
                  </label>
                </>
              )}

              {!!strategyPresentation && (
                <Button color="primary" className="ms-2" onClick={() => downloadFile()}>
                  <Icon name="download" className="cursor-pointer" />
                  <span>{t('campaigns.downloadStrategy')}</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateLineItemForm;
