import axios, {AxiosInstance} from 'axios';
import {customHistory} from '../index';
import {StoreKeeper} from '../store';
import {appVersionSlice} from '../store/slices/app-version.slice';
import {commonSlice} from '../store/slices/common.slice';
import Messages from '../utils/Messages';

const isErrorArray = (error: string | string[]) => {
  if (Array.isArray(error)) return error[0];
  return error;
};

interface IApiCalls {
  [key: number]: boolean;
}

let loadingTimeout: NodeJS.Timeout;
const ApiCalls: IApiCalls = {};
const requestToDisableLoading = ['/messages/inbox'];
const removeToastKeys = ['get_api_admin_chat-gpt_campaign'];
const requestToDisableNotFound = ['get_api_admin_chat-gpt_campaign'];
const shouldRemoveLoading = (apiList: IApiCalls, timeout: number) => {
  if (apiList[timeout as unknown as number] || timeout === -1) {
    // if true, remove that api call || if -1 request from requestToDisableLoading array
    apiList[timeout as unknown as number] = false;
    clearTimeout(timeout);
  }
  const isEveryApiFinished = Object.values(apiList).every((call) => !call);
  StoreKeeper.store.dispatch(commonSlice.actions.setIsLoading(!isEveryApiFinished));
};

export default class BaseApi {
  private readonly request: AxiosInstance;
  private readonly messages = new Messages(commonSlice.actions.setMessage);
  protected token: string | null = null;
  protected appVersion: string | null = null;
  protected dispatch: any = StoreKeeper.store.dispatch;

  constructor() {
    this.request = axios.create({
      baseURL:
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_BACKEND_URL_DEVELOPMENT
          : process.env.REACT_APP_BACKEND_URL_PRODUCTION,
      headers: {
        Authorization: `Bearer: ${StoreKeeper.store.getState()?.auth?.token}`,
        'app-version': this.appVersion || localStorage.getItem('appVersion'),
      },
    });

    this.request.interceptors.response.use(
      function (config) {
        return config;
      },
      (err) => {
        const {status, data} = err?.response ?? {};

        if (status === 505) {
          StoreKeeper.store.dispatch(appVersionSlice.actions.setReloadModal(true));
          localStorage.setItem('appVersion', data?.appVersion ?? '');
        }

        if (status === 403) {
          (({} as any).navigate('/403'));
          // (customNavigate as any).navigate('/403');
        }
        return Promise.reject(err);
      }
    );
    this.request.interceptors.request.use((config) => {
      loadingTimeout = setTimeout(() => {
        StoreKeeper.store.dispatch(commonSlice.actions.setIsLoading(true));
      }, 500);
      const shouldDisableLoading = requestToDisableLoading.some((text) => config.url?.includes(text ?? ''));
      if (!ApiCalls[loadingTimeout as unknown as number] && !shouldDisableLoading) {
        // if this is false (we don't have call with this key), create value inside ApiCalls map
        ApiCalls[loadingTimeout as unknown as number] = true;
        StoreKeeper.store.dispatch(commonSlice.actions.setIsLoading(true));
      } else {
        clearTimeout(loadingTimeout);
        ApiCalls[!shouldDisableLoading ? (loadingTimeout as unknown as number) : -1] = false;
      }
      return {
        ...config,
        keyReq: !shouldDisableLoading ? loadingTimeout : -1,
      };
    });
    this.request.interceptors.response.use(
      (success) => {
        shouldRemoveLoading(ApiCalls, (success.config as any)?.keyReq as number);
        // StoreKeeper.store.dispatch(commonSlice.actions.setIsLoading(false));
        const key =
          success.config.method! +
            '_' +
            success.config.url
              ?.split('/')
              .filter((s: string) => isNaN(Number(s)))
              .join('_') ?? '';
        this.messages.generateSuccess(key);
        return success;
      },
      (error) => {
        const errorStatus = error?.response?.status;
        // clearTimeout(loadingTimeout);
        // const is
        const serverErrorMessage = error?.response?.data?.message;
        // StoreKeeper.store.dispatch(commonSlice.actions.setIsLoading(false));
        shouldRemoveLoading(ApiCalls, (error?.config as any)?.keyReq as number);

        const key =
          error.config.method! +
            '_' +
            error.config.url
              ?.split('/')
              .filter((s: string) => isNaN(Number(s)))
              .join('_') ?? '';
        if (errorStatus === 403) {
          (customHistory as any).navigate('/app/403');
        }
        if (errorStatus === 404 && !requestToDisableNotFound.includes(key)) {
          (customHistory as any).navigate('/404');
        }
        if (errorStatus === 500) {
          (customHistory as any).navigate('/app/500');
        }
        this.messages.generateError(key, isErrorArray(serverErrorMessage), removeToastKeys.includes(key));
        throw error;
      }
    );
  }

  updateHeader(token: string, appVersion: string) {
    this.token = token;
    this.appVersion = appVersion;
  }

  protected headers(token: string, isMultipart?: boolean, isBlob?: boolean) {
    const storedToken = localStorage.getItem('token');
    const isTokenValid = !!storedToken?.length && storedToken;
    return {
      headers: {
        Authorization: `Bearer ${!isTokenValid ? StoreKeeper.store.getState()?.auth?.token : storedToken}`,
        'Content-Type': isMultipart ? 'multipart/form-data' : isBlob ? 'blob' : 'application/json',
        'app-version': this.appVersion ?? localStorage.getItem('appVersion'),
      },
    };
  }

  protected async get(url: string, token?: string, params?: any, isBlob?: boolean): Promise<any> {
    return this.request({
      url,
      method: 'GET',

      ...this.headers(token!, false, isBlob),
      params,
      responseType: isBlob ? 'arraybuffer' : 'json',
    });
  }

  protected async post(url: string, data: any, token?: string, isMultipart?: boolean): Promise<any> {
    return this.request({
      url,
      method: 'POST',
      data,
      ...this.headers(token!, isMultipart),
    });
  }

  protected async put(url: string, data: any, token: string, isMultipart?: boolean, params?: any): Promise<any> {
    return this.request({
      url,
      method: 'PUT',
      data,
      params,
      ...this.headers(token!, isMultipart),
    });
  }

  protected async patch(url: string, data: any, token: string, isMultipart?: boolean, params?: any): Promise<any> {
    return this.request({
      url,
      method: 'PATCH',
      data,
      params,
      ...this.headers(token, isMultipart),
    });
  }

  protected async delete(url: string, token: string): Promise<any> {
    return this.request({
      url,
      method: 'DELETE',
      ...this.headers(token),
    });
  }
}
