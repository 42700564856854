import React, {SetStateAction, useState} from 'react';
import {IType} from '../interfaces/IType';
import {useNavigate} from 'react-router-dom';
import {typeApi} from '../../../../api';
import Icon from '../../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import Button from '../../../../components/button/Button';
import {EmptyState} from '../../../../components/shared/EmptyState';
import {useTranslation} from 'react-i18next';

interface ITypeTableProps {
  typeList: IType[];
  setTypeList: React.Dispatch<SetStateAction<IType[]>>;
}

const TypeTable = ({typeList, setTypeList}: ITypeTableProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [selectedType, setSelectedType] = useState<IType>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const deleteTypeHandler = async () => {
    if (selectedType?.id) {
      const {
        data: {data},
      } = await typeApi.deleteType(selectedType?.id);
      setTypeList(data);
    }
  };
  return (
    <div className="table-responsive">
      {!!typeList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.id')}</th>
              <th scope="col">Name</th>
              <th scope="col">{t('general.description')}</th>
              <th scope="col">{t('general.status')}</th>
              <th scope="col" className="text-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {typeList?.map((type) => {
              const {name, id, description, status} = type;
              return (
                <tr key={id}>
                  <th>{id}</th>
                  <td>{name}</td>
                  <td>{description ?? '-'}</td>
                  <td>{status ? 'Enabled' : 'Disabled'}</td>
                  <td className="d-flex justify-content-end">
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => {
                        navigate(`/app/admin/types/update/${id}`);
                      }}
                    >
                      <Icon name="edit" />
                      Edit
                    </Button>
                    {!type.isUsed && (
                      <Button
                        color="danger"
                        size="sm"
                        className="ms-1"
                        onClick={() => {
                          setIsDeleteModalOpened(true);
                          setSelectedType(type);
                        }}
                      >
                        <Icon name="trash" />
                        Delete
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedType?.id as number}
        itemName={selectedType?.name as string}
        deleteHandler={deleteTypeHandler}
      />
    </div>
  );
};

export default TypeTable;
