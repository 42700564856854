import {IPagination} from '../../../shared/IPagination';
import BaseApi from '../../base.api';

const NOTIFICATION_ROUTE = '/api/notification';

export class NotificationApi extends BaseApi {
  public async getNotificationCount(pagination: IPagination): Promise<any> {
    return await this.get(`${NOTIFICATION_ROUTE}/me`, '', pagination);
  }

  public async getUnreadNotifications() {
    return await this.get(`${NOTIFICATION_ROUTE}/unread`);
  }
}
