import React from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from '../../components/error/NotFound';
import {PrivateRoute} from '../../routes/RouteGuards';

import Invoice from './index';
import InvoiceForm from './components/InvoiceForm';

export function InvoiceRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute component={Invoice} />} />
      <Route path="/:id" element={<PrivateRoute component={InvoiceForm} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
