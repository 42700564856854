import React from 'react';
import {solidLineChart} from '../brands';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {SolidLineChart} from '../../../components/charts/SolidLineChart';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import {useTranslation} from 'react-i18next';

const CompaniesOverview = () => {
  const {t} = useTranslation();
  return (
    <Block size="lg" className="mt-3">
      <Row className=" g-3">
        <Col xs={12}>
          <BlockHead>
            <BlockHeadContent>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <BlockTitle tag="h4">{t('overview.companies.companies')}</BlockTitle>
                </div>
              </div>
            </BlockHeadContent>
          </BlockHead>
        </Col>
        <Col md={6}>
          <Card className="card-bordered card-preview">
            <CardBody>
              <SolidLineChart legend={true} data={solidLineChart} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Block>
  );
};

export default CompaniesOverview;
