import React, {SetStateAction, useState} from 'react';
import {IRegion} from '../interfaces/IRegion';
import {useNavigate} from 'react-router-dom';
import {regionApi} from '../../../../api';
import Icon from '../../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import Button from '../../../../components/button/Button';
import {EmptyState} from '../../../../components/shared/EmptyState';
import {useTranslation} from 'react-i18next';
import {getCorrectCountry} from '../../../../shared/Functions';

interface IRegionTableProps {
  regionList: IRegion[];
  setRegionList: React.Dispatch<SetStateAction<IRegion[]>>;
}

const RegionTable = ({regionList, setRegionList}: IRegionTableProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedRegion, setSelectedRegion] = useState<IRegion>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const deleteTypeHandler = async () => {
    if (selectedRegion?.id) {
      const {
        data: {data},
      } = await regionApi.deleteRegion(selectedRegion?.id);
      setRegionList(data);
    }
  };
  return (
    <div className="table-responsive">
      {!!regionList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.id')}</th>
              <th scope="col">{t('general.name')}</th>
              <th scope="col">{t('general.country')}</th>
              <th scope="col" className="text-end">
                {t('general.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {regionList?.map((region) => {
              const {name, id, country} = region;

              return (
                <tr key={id}>
                  <th>{id}</th>
                  <td>{name}</td>
                  <td>{country?.map((con, i) => getCorrectCountry(con, t) + (i === country.length - 1 ? '' : ', '))}</td>
                  <td className="d-flex justify-content-end">
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => {
                        navigate(`/app/admin/regions/update/${id}`);
                      }}
                    >
                      <Icon name="edit" />
                      {t('general.edit')}
                    </Button>
                    {!region.isUsed && (
                      <Button
                        color="danger"
                        size="sm"
                        className="ms-1"
                        onClick={() => {
                          setIsDeleteModalOpened(true);
                          setSelectedRegion(region);
                        }}
                      >
                        <Icon name="trash" />
                        {t('general.delete')}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedRegion?.id as number}
        itemName={selectedRegion?.name as string}
        deleteHandler={deleteTypeHandler}
      />
    </div>
  );
};

export default RegionTable;
