import React, {SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {formatApi} from '../../../../api';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import {CustomPopover} from '../../../../components/popover/Popover';
import {EmptyState} from '../../../../components/shared/EmptyState';
import {useMultiInject} from '../../../../di';
import {truncateString} from '../../../../shared/Functions';
import {IFormat} from '../interfaces/IFormat';

interface IFormatTableProps {
  formatList: IFormat[];
  setFormatList: React.Dispatch<SetStateAction<IFormat[]>>;
}

const FormatTable = ({formatList, setFormatList}: IFormatTableProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedFormat, setSelectedFormat] = useState<IFormat>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const {dateTime} = useMultiInject();

  const deleteFormatHandler = async () => {
    if (selectedFormat?.id) {
      const {
        data: {data},
      } = await formatApi.deleteFormat(selectedFormat?.id);
      setFormatList(data);
    }
  };
  return (
    <div className="table-responsive">
      {!!formatList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.id')}</th>
              <th scope="col">{t('general.name')}</th>
              <th scope="col">{t('general.type')}</th>
              <th scope="col">{t('general.description')}</th>
              <th scope="col">{t('general.updatedAt')}</th>
              <th scope="col">{t('general.status')}</th>
              <th scope="col" className="text-end">
                {t('general.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {formatList?.map((format, index) => {
              const {id, name, description, formatType, updatedAt, status} = format;
              return (
                <tr key={id}>
                  <th>{id}</th>
                  <td>{name}</td>
                  <td>{!!formatType?.length ? formatType?.map((type) => type.type.name ?? '-').join(', ') : '-'}</td>
                  <td>
                    <CustomPopover
                      id={index}
                      title={<Icon name="book" />}
                      item={{
                        description: 'Format description',
                        text: <>{truncateString(description ?? '', 200)}</>,
                      }}
                    />
                  </td>
                  <td>{dateTime.formatDateHours(updatedAt)}</td>
                  <td>{status ? 'Enabled' : 'Disabled'}</td>
                  <td className="d-flex justify-content-end">
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => {
                        navigate(`/app/admin/format/update/${id}`);
                      }}
                    >
                      <Icon name="edit" />
                      {t('general.edit')}
                    </Button>
                    {!format.isUsed && (
                      <Button
                        color="danger"
                        size="sm"
                        className="ms-1"
                        onClick={() => {
                          setIsDeleteModalOpened(true);
                          setSelectedFormat(format);
                        }}
                      >
                        <Icon name="trash" />
                        {t('general.delete')}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedFormat?.id as number}
        itemName={selectedFormat?.name as string}
        deleteHandler={deleteFormatHandler}
      />
    </div>
  );
};

export default FormatTable;
