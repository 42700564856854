import React from 'react';
import {useTranslation} from 'react-i18next';
import {IObjectiveScoring} from '../../objective-scoring/interfaces/IObjectiveScoring';
import {capitalize} from 'lodash';

interface ITargetGroupScoringUpdateTableProps {
  targetGroupScoring: IObjectiveScoring;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>, channelId: number, indexToReplace: number, price: string) => void;
}

const TargetGroupScoringUpdateTable = ({targetGroupScoring, changeHandler}: ITargetGroupScoringUpdateTableProps) => {
  const {t} = useTranslation();

  return (
    <div className="table-container">
      <div className="table-responsive max-h-800px ">
        <table className="table table-striped table-hover">
          <thead className="position-sticky top-0 bg-white shadow" style={{zIndex: 100}}>
            <tr>
              <th scope="col" className="bg-white" style={{minWidth: 30, position: 'sticky', left: 0, zIndex: 10000}}>
                {t('general.channel')}
              </th>
              {targetGroupScoring?.groups?.map((group) => (
                <th scope="col" key={group?.id}>
                  {group?.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {targetGroupScoring?.channels?.map((channel) => {
              return (
                <tr key={`${channel?.channelId}-${channel?.price}`}>
                  <td className="bg-white" style={{minWidth: 30, position: 'sticky', left: 0, zIndex: 1}}>
                    <div>{channel?.channelName}</div>
                    <div className="text-muted small">
                      {channel?.quality} - {capitalize(channel?.price)}
                    </div>
                  </td>
                  {channel?.scoring?.map((price, index) => (
                    <td key={index}>
                      <input
                        type="number"
                        onInput={(e: any) => {
                          e.preventDefault();
                          e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                        }}
                        className="form-control w-min-100px"
                        onChange={(event) => changeHandler(event, channel?.channelId, index, channel?.price)}
                        value={price ?? 0}
                        required
                      />
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TargetGroupScoringUpdateTable;
