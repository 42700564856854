import React, {Component, ErrorInfo, ReactNode} from 'react';
import ErrorScreen from '../components/error/ErrorScreen';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: '',
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return {hasError: true, errorMessage: error.message};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({hasError: true, errorMessage: error.message});
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorScreen errorMessage={this.state.errorMessage} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
