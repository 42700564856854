import {useState} from 'react';
import {Popover, PopoverBody, PopoverHeader} from 'reactstrap';

export const CustomPopover = (props: any) => {
  const {item, id, title} = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <span id={`Popover-${id}`} className="cursor-pointer" onMouseEnter={() => toggle()} onMouseLeave={() => toggle()}>
        {title}
      </span>
      <Popover isOpen={tooltipOpen} target={`Popover-${id}`} toggle={toggle} trigger="hover">
        <PopoverHeader>{item?.description ?? ''}</PopoverHeader>
        <PopoverBody>{item?.text}</PopoverBody>
      </Popover>
    </>
  );
};
