import {IChannelDeviceFormatBuild} from '../../../app/administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import {ICampaignOverview, ILineItem, ILineItemPrice} from '../../../app/campaigns/interfaces/ICampaigns';
import {IPicture} from '../../../shared/interfaces/Picture';
import BaseApi from '../../base.api';

const LINE_ITEM_ROUTES = '/api/admin/line-item';

export interface IChartData {
  range: string[];
  name: string;
  data: number[];
}

export interface ICreateSection {
  id?: number;
  section: boolean;
  title: string;
  color: string;
}

export interface ISection {
  lineItem_id: number;
  color: string;
  lineItem_color: string;
  lineItem_title: string;
}

export class LineItemApi extends BaseApi {
  public async getLineItems(campaignId: string): Promise<{
    data: {
      lineItems: IChannelDeviceFormatBuild[];
      dataForChart?: IChartData;
      campaignOverview: ICampaignOverview;
      valueByMonth: any[];
    };
  }> {
    return await this.get(`${LINE_ITEM_ROUTES}/${campaignId}`, undefined);
  }

  public async getLineItem(campaignId: string): Promise<{data: {lineItems: ILineItem[]}}> {
    return await this.get(`${LINE_ITEM_ROUTES}/campaign/${campaignId}`);
  }

  public async deleteLineItemSection(
    campaignId: string,
    lineItemId: string
  ): Promise<{
    data: IChannelDeviceFormatBuild[];
  }> {
    return await this.delete(`${LINE_ITEM_ROUTES}-section/${campaignId}/${lineItemId}`, '');
  }

  public async createLineItemSection(
    campaignId: number,
    body: ICreateSection
  ): Promise<{
    data: {lineItems: ILineItem[]};
  }> {
    return await this.put(`${LINE_ITEM_ROUTES}-section/campaign/${campaignId}`, body, '');
  }
  public async updateLineItemSection(
    campaignId: number,
    lineItemId: number,
    body: ICreateSection
  ): Promise<{
    data: {lineItems: ILineItem[]};
  }> {
    return await this.put(`${LINE_ITEM_ROUTES}-section/${campaignId}/${lineItemId}`, body, '');
  }

  public async getAllSections(campaignId: string): Promise<{data: ISection[]}> {
    return await this.get(`${LINE_ITEM_ROUTES}-section/campaign/${campaignId}`, undefined);
  }

  public async getLineitemChart(campaignId: string, params: any): Promise<{data: IChartData[]}> {
    return await this.get(`${LINE_ITEM_ROUTES}/chart/${campaignId}`, undefined, params);
  }

  public async getLineItemDates(
    campaignId: string,
    lineItemId: string
  ): Promise<{
    data: IChannelDeviceFormatBuild;
  }> {
    return await this.get(`${LINE_ITEM_ROUTES}/${campaignId}/${lineItemId}`, undefined);
  }

  public async updateLineItemOrder(
    campaignId: string,
    LineItemsId: number[]
  ): Promise<{
    data: ILineItem[];
  }> {
    return await this.put(`${LINE_ITEM_ROUTES}/sorting/${campaignId}/`, {LineItemsId}, '');
  }

  // public async updateLineItemTargeting(lineItemId: string): Promise<{
  //   data: ILineItem[];
  // }> {
  //   return await this.put(`${LINE_ITEM_ROUTES}/sorting/${lineItemId}/`, '');
  // }

  public async getLineItemPrice(
    campaignId: string,
    lineItemId: number,
    priceValue: number,
    ais: number
  ): Promise<{
    data: ILineItemPrice;
  }> {
    return await this.patch(
      `${LINE_ITEM_ROUTES}/${campaignId}/${lineItemId}`,
      {
        priceValue,
        ais,
      },
      ''
    );
  }

  public async uploadStrategyFile(data: FormData): Promise<{data: IPicture[]}> {
    return await this.patch(`${LINE_ITEM_ROUTES}/strategyPresentation`, data, '', true);
  }

  public async createLineItem(data: any, campaignId: string): Promise<any> {
    return await this.post(`${LINE_ITEM_ROUTES}/${campaignId}`, {lineItemList: [data]});
  }

  public async postLineItemOffMedia(lineItemId: number, campaignId: number): Promise<any> {
    return await this.post(`${LINE_ITEM_ROUTES}/off-media/${campaignId}/${lineItemId}`, {});
  }

  public async updateLineItemDates(
    otherData: any,
    dates: string[],
    campaignId: string,
    lineItemId: number,
    region: number[],
    ais: number,
    priceValue: number,
    calendarView: string,
    creativeName: string,
    comment: string,
    frequencyCap: boolean,
    valueFrequencyCap: number,
    targeting: boolean | undefined
  ): Promise<{
    data: IChannelDeviceFormatBuild[];
  }> {
    return await this.put(
      `${LINE_ITEM_ROUTES}/${campaignId}`,
      {
        lineItemList: [
          {
            ...otherData,
            lineItemId,
            ais,
            priceValue,
            calendarView,
            creativeName,
            region,
            date: dates,
            comment,
            frequencyCap,
            valueFrequencyCap,
            targeting,
          },
        ],
      },
      ''
    );
  }

  public async deleteLineItem(
    campaignId: string,
    lineItemId: string
  ): Promise<{
    data: IChannelDeviceFormatBuild[];
  }> {
    return await this.delete(`${LINE_ITEM_ROUTES}/${campaignId}/${lineItemId}`, '');
  }
}
