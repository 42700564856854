import React, {SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
// import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import {EmptyState} from '../../../../components/shared/EmptyState';
import UserAvatar from '../../../../components/user/UserAvatar';
import {getAvatarInitials, isUserAdmin, isUserCompanyAdmin} from '../../../../shared/Functions';
import {RootState} from '../../../../store';
import {formatRoles} from '../../../../utils/Functions';
import {IUser} from '../interfaces/IUser';

interface IUserTableProps {
  userList: IUser[];
  setUserList: React.Dispatch<SetStateAction<IUser[]>>;
  navigateRoute: string;
  deleteUserHandler?: (selectedUser: IUser) => Promise<void>;
  isManagementList?: boolean;
}

const UserTable = ({userList, navigateRoute, deleteUserHandler, isManagementList = false}: IUserTableProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {roles} = useSelector((state: RootState) => state.auth.user);
  const canEdit = isUserCompanyAdmin(roles) || isUserAdmin(roles);
  // const [selectedUser] = useState<IUser>();
  // const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  return (
    <>
      {!!userList?.length ? (
        <div className="nk-block" data-select2-id="16">
          <div className="card card-bordered " data-select2-id="15">
            <div className="card-inner-group" data-select2-id="14">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="nk-tb-item nk-tb-head">
                    <div className="nk-tb-col nk-tb-col-check"></div>
                    <div className="nk-tb-col">
                      <span>{t('general.users')}</span>
                    </div>
                    <div className="nk-tb-col tb-col-lg">
                      <span>{t('general.email')}</span>
                    </div>
                    <div className="nk-tb-col tb-col-lg">
                      <span>{t('general.phoneNumber')}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                      <span>{t('general.status')}</span>
                    </div>
                    <div className="nk-tb-col tb-col-lg">
                      <span>{t('general.approver')}</span>
                    </div>
                    {canEdit && <div className="nk-tb-col nk-tb-col-tools">&nbsp;</div>}
                  </div>
                  {userList?.map((user) => {
                    const {id, firstName, lastName, email, roles, isActive, isApprover} = user;
                    return (
                      <div className="nk-tb-item" key={id}>
                        <div className="nk-tb-col nk-tb-col-check"></div>
                        <div className="nk-tb-col">
                          <div className="user-card cursor-pointer">
                            <UserAvatar
                              size="sm"
                              key={id}
                              image={user.profilePicture?.path}
                              text={getAvatarInitials(firstName, lastName)}
                              theme="primary"
                            />
                            <div className="user-info">
                              <span
                                className="tb-lead"
                                onClick={() => {
                                  navigate(`${navigateRoute}${id}`);
                                }}
                              >
                                {firstName} {lastName}
                                <span className="dot dot-success d-md-none ms-1"></span>
                              </span>
                              <span>{formatRoles(roles)}</span>
                            </div>
                          </div>
                        </div>
                        <div className="nk-tb-col tb-col-md">{email}</div>
                        <div className="nk-tb-col tb-col-md">{user.contact.phone}</div>
                        <div className="nk-tb-col tb-col-md">
                          <span className={`badge badge-dot bg-${isActive ? 'success' : 'danger'}`}>
                            {isActive ? 'Active' : 'Inactive'}
                          </span>
                        </div>
                        <div className="nk-tb-col tb-col-md">{isApprover ? 'Yes' : 'No'}</div>
                        <div className="nk-tb-col nk-tb-col-tools">
                          {canEdit && (
                            <ul className="nk-tb-actions gx-1">
                              <li className="">
                                <a
                                  href="#1"
                                  onClick={() => {
                                    navigate(`${navigateRoute}${id}`);
                                  }}
                                  className="btn btn-trigger btn-icon"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  aria-label="Approved"
                                  data-bs-original-title="Approved"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </a>
                              </li>
                              {/* {currentUserId !== id && deleteUserHandler && (
                                <li className="">
                                  <a
                                    href="#1"
                                    onClick={() => {
                                      setIsDeleteModalOpened(true);
                                      setSelectedUser(user);
                                    }}
                                    className="btn btn-trigger btn-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    aria-label="Rejected"
                                    data-bs-original-title="Rejected"
                                  >
                                    <em className="icon ni ni-trash"></em>
                                  </a>
                                </li>
                              )} */}
                            </ul>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyState />
      )}
      {/* <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedUser?.id as number}
        itemName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
        deleteHandler={() => deleteUserHandler && deleteUserHandler(selectedUser as IUser)}
      /> */}
    </>
  );
};

export default UserTable;
