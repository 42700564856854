import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import {Card} from 'reactstrap';
import {Pagination} from '../../../components/pagination/Pagination';
import TypeTable from './components/TypeTable';
import {typeApi} from '../../../api';
import {IType} from './interfaces/IType';
import {usePagination} from '../../../shared/hooks/usePagination';
import TypeFilters from './components/TypeFilters';
import {useTranslation} from 'react-i18next';

const Type = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [typeList, setTypeList] = useState<IType[]>([]);
  const {changeFilterHandler, page, setTotalPages, totalPages, handlePages, pagination} = usePagination();

  useEffect(() => {
    async function getAllTypes() {
      const {data} = await typeApi.getAllTypes(pagination);
      setTypeList(data.data);
      setTotalPages(Math.ceil(data?.count / data?.perPage));
    }

    getAllTypes();
  }, [pagination, setTotalPages]);
  return (
    <>
      <Block size="lg" className="mt-3">
        <BlockHead>
          <BlockHeadContent>
            <div className="row align-items-center gx-0 gx-lg-2">
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-12">
                <BlockTitle tag="h4">{t('administrator.types.typeList')}</BlockTitle>
              </div>
              <div className="col-xxl-2 col-xl-3 col-lg-4 col-12  text-end">
                <Button
                  className="btn-block"
                  color="primary"
                  onClick={() => {
                    navigate('/app/admin/types/create');
                  }}
                >
                  <Icon name="plus-sm" />
                  {t('administrator.types.addNew')}
                </Button>
              </div>
            </div>
            <TypeFilters changeFilterHandler={changeFilterHandler} pagination={pagination} />
          </BlockHeadContent>
        </BlockHead>
        <Card className="card-bordered card-preview">
          <TypeTable typeList={typeList} setTypeList={setTypeList} />
        </Card>
      </Block>

      <div className="d-flex justify-content-end">
        {totalPages <= 1 ? '' : <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />}
      </div>
    </>
  );
};

export default Type;
