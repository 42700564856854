import React, {useEffect, useState} from 'react';
import {FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {IProduct} from '../interfaces/IProduct';
import {Col, FormGroup, Row} from 'reactstrap';
import {brandApi} from '../../../../api';
import {IBrand} from '../../brands/interfaces/IBrand';
import {useTranslation} from 'react-i18next';

interface IProductFormProps {
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
      brand: number;
    }>
  >;
  register: UseFormRegister<IProduct>;
}

const ProductForm = ({errors, register}: IProductFormProps) => {
  const {t} = useTranslation();
  const [brandList, setBrandList] = useState<IBrand[]>([]);
  useEffect(() => {
    const getAllBrands = async () => {
      const {
        data: {data},
      } = await brandApi.getAllBrands({
        page: 1,
        perPage: 0,
      });
      setBrandList(data);
    };
    getAllBrands();
  }, []);
  return (
    <Row>
      <Col md={8}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="name">
                {t('general.fullName')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-type"
                  placeholder={t('management.products.enterProduct') as string}
                  {...register('name')}
                  className="form-control-lg form-control"
                />
                {errors?.name && <p className="invalid">{errors?.name?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="brand">
                {t('general.brand')}
              </label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select
                    className="form-control form-control-lg"
                    id="default-06"
                    {...register('brand', {
                      valueAsNumber: true,
                    })}
                  >
                    <option selected disabled>
                      {t('management.products.enterBrand')}
                    </option>
                    {brandList?.map((brand) => (
                      <option value={brand?.id} key={brand?.id}>
                        {brand?.name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.brand && <span className="text-danger ff-italic">{errors?.brand?.message}</span>}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProductForm;
