import BaseApi from '../../base.api';

const COMMENTS_ROUTE = '/api/admin/comment/campaign';

export class CommentsApi extends BaseApi {
  public async getAllComments(id: number): Promise<any> {
    return await this.get(`${COMMENTS_ROUTE}/${id}`);
  }

  public async createComment(data: {comment: string}, id: number): Promise<any> {
    return await this.post(`${COMMENTS_ROUTE}/${id}`, data, '');
  }
}
