import React, {useState} from 'react';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import ChannelForm from './ChannelForm';
import {useNavigate} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {IChannel} from '../interfaces/IChannel';
import {channelFormSchema} from './ChannelUpdate';
import {channelsApi} from '../../../../api';
import {useTranslation} from 'react-i18next';

const ChannelCreate = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [passState, setPassState] = useState(false);

  const methods = useForm<IChannel>({
    resolver: yupResolver(channelFormSchema),
  });

  const onFormSubmit = async (state: IChannel) => {
    const formData = new FormData();
    const pictureToSend: FileList = state.picture as FileList;
    !!state.picture && formData.append('picture', pictureToSend[0]);
    formData.append('body', JSON.stringify(state));
    const {data} = await channelsApi.createChannel(formData);
    if (data) {
      navigate('/app/admin/channels');
    }
  };
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('administrator.channels.channelCreate')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate('/app/admin/channels');
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <ChannelForm
                passState={passState}
                setPassState={setPassState}
                isTargeting={!!methods?.watch('targeting')}
                register={methods?.register}
                errors={methods?.formState?.errors}
                defaultState={methods?.formState?.defaultValues as IChannel}
              />
              <Row>
                <Col>
                  <Row>
                    <Col className={'text-end'}>
                      <Button color="primary">
                        <Icon name="plus-sm" />
                        {t('general.create')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default ChannelCreate;
