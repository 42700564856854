import React, {SetStateAction} from 'react';
import Icon from '../../../components/icon/Icon';
import {Block} from '../../../components/block/Block';
import {IUserData} from '../../../shared/interfaces/User';
import {useTranslation} from 'react-i18next';
import HeaderLanguages from '../../../layout/header/components/HeaderLanguages';

interface IPersonalInformation {
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  user: IUserData;
}

const ProfileInformationForm = ({setShowModal, user}: IPersonalInformation) => {
  const {t} = useTranslation();
  return (
    <Block>
      <div className="nk-data data-list">
        <div className="data-head">
          <h6 className="overline-title">Basics</h6>
        </div>
        <div className="data-item" onClick={() => setShowModal(true)}>
          <div className="data-col">
            <span className="data-label">{t('general.firstName')}</span>
            <span className="data-value">{user.firstName}</span>
          </div>
          <div className="data-col data-col-end">
            <span className="data-more">
              <Icon name="forward-ios"></Icon>
            </span>
          </div>
        </div>{' '}
        <div className="data-item" onClick={() => setShowModal(true)}>
          <div className="data-col">
            <span className="data-label">{t('general.lastName')}</span>
            <span className="data-value">{user.lastName}</span>
          </div>
          <div className="data-col data-col-end">
            <span className="data-more">
              <Icon name="forward-ios"></Icon>
            </span>
          </div>
        </div>
        <div className="data-item" onClick={() => setShowModal(true)}>
          <div className="data-col">
            <span className="data-label">{t('general.email')}</span>
            <span className="data-value">{user.email}</span>
          </div>
          <div className="data-col data-col-end">
            <span className="data-more">
              <Icon name="forward-ios"></Icon>
            </span>
          </div>
        </div>
        <div className="data-item" onClick={() => setShowModal(true)}>
          <div className="data-col">
            <span className="data-label">{t('general.phoneNumber')}</span>
            <span className="data-value">{user.contact.phone}</span>
          </div>
          <div className="data-col data-col-end">
            <span className="data-more">
              <Icon name="forward-ios"></Icon>
            </span>
          </div>
        </div>
        <div className="data-item" onClick={() => setShowModal(true)}>
          <div className="data-col">
            <span className="data-label">{t('general.address')}</span>
            <span className="data-value">{user.contact.address}</span>
          </div>

          <div className="data-col data-col-end">
            <span className="data-more">
              <Icon name="forward-ios"></Icon>
            </span>
          </div>
        </div>
        <div className="data-item" onClick={() => setShowModal(true)}>
          <div className="data-col">
            <span className="data-label">{t('general.city')}</span>
            <span className="data-value">{user.contact.city}</span>
          </div>

          <div className="data-col data-col-end">
            <span className="data-more">
              <Icon name="forward-ios"></Icon>
            </span>
          </div>
        </div>
        <div className="data-item" onClick={() => setShowModal(true)}>
          <div className="data-col">
            <span className="data-label">{t('general.country')}</span>
            <span className="data-value">{user.contact.country}</span>
          </div>

          <div className="data-col data-col-end">
            <span className="data-more">
              <Icon name="forward-ios"></Icon>
            </span>
          </div>
        </div>
        <div className="data-item" onClick={() => setShowModal(true)}>
          <div className="data-col">
            <span className="data-label">{t('general.zip')}</span>
            <span className="data-value">{user.contact.zip}</span>
          </div>

          <div className="data-col data-col-end">
            <span className="data-more">
              <Icon name="forward-ios"></Icon>
            </span>
          </div>
        </div>
      </div>
      <div className="nk-data data-list">
        <div className="data-head">
          <h6 className="overline-title">{t('myProfile.profileInfo.preferences')}</h6>
        </div>
        <div className="data-item">
          <div className="data-col">
            <span className="data-label">{t('myProfile.profileInfo.language')}</span>
          </div>
          <div className="data-col-end">
            <div>{t('myProfile.profileInfo.changeLanguage')}</div>
          </div>
          <li className="data-col data-col-end">
            <HeaderLanguages />
          </li>
        </div>
      </div>
    </Block>
  );
};

export default ProfileInformationForm;
