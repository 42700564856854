import React, {useEffect, useState} from 'react';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import {Card} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import QualityPriceTable from './components/QualityPriceTable';
import {qualityPriceApi} from '../../../api';
import {IQualityPrice} from './interfaces/IQualityPrice';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';

const QualityPrice = () => {
  const {t} = useTranslation();
  const [qualityPriceList, setQualityPriceList] = useState<IQualityPrice[]>([]);
  const changePriceHandler = (event: React.ChangeEvent<HTMLInputElement>, qualityId: number) => {
    const {name, valueAsNumber} = event.target;
    const copy = [...qualityPriceList];
    const indexToReplace = copy?.findIndex((qualityPrice) => qualityPrice.quality === qualityId);
    copy[indexToReplace] = {
      ...copy[indexToReplace],
      prices: {
        ...copy[indexToReplace].prices,
        [name]: valueAsNumber,
      },
    };
    setQualityPriceList(copy);
  };

  const submitHandler = async () => {
    const {data} = await qualityPriceApi.updateQualityPrices(qualityPriceList);
    setQualityPriceList(data?.qualitys);
  };
  useEffect(() => {
    const getQualityPrices = async () => {
      const {data} = await qualityPriceApi.getQualityPrices();
      setQualityPriceList(data?.qualitys);
    };
    getQualityPrices();
  }, []);
  return (
    <>
      <Block size="lg" className="mt-3">
        <BlockHead>
          <BlockHeadContent>
            <div className="row align-items-center gx-0 gx-lg-2">
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-12">
                <BlockTitle tag="h4">{t('administrator.qualityPrice.qualityPriceList')}</BlockTitle>
              </div>
            </div>
          </BlockHeadContent>
        </BlockHead>
        <Card className="card-bordered card-preview">
          <QualityPriceTable qualityPriceList={qualityPriceList} changePriceHandler={changePriceHandler} />
        </Card>
        <div className="d-flex justify-content-end mt-3">
          <Button color="primary" onClick={submitHandler}>
            <Icon name="plus-sm" />
            {t('general.update')}
          </Button>
        </div>
      </Block>
    </>
  );
};

export default QualityPrice;
