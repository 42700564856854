import Select from 'react-select';
import {themeSelect} from '../../../shared/Select2';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import {brandApi, productApi} from '../../../api';
import {RootState} from '../../../store';
import {useSelector} from 'react-redux';
import {IBrand} from '../../management/brands/interfaces/IBrand';
import {IProduct} from '../../management/products/interfaces/IProduct';
import {IDashboardFilters} from '..';

interface IDashboardFiltersProps {
  setDashboardFilters: React.Dispatch<React.SetStateAction<IDashboardFilters>>;
  dashboardFilters: IDashboardFilters;
}

const pagination = {perPage: 0, page: 1};

const DashboardFilters = ({setDashboardFilters, dashboardFilters}: IDashboardFiltersProps) => {
  const {t} = useTranslation();
  const [brands, setBrands] = useState<IBrand[]>();
  const [products, setProducts] = useState<IProduct[]>();
  const {country} = useSelector((state: RootState) => state.enum);

  useEffect(() => {
    brandApi.getAllBrands(pagination).then((res) => setBrands(res.data.data));
  }, []);
  useEffect(() => {
    productApi.getAllProduct({...pagination, brand: dashboardFilters.brand}).then((res) => setProducts(res.data.data));
  }, [dashboardFilters.brand]);

  return (
    <>
      {' '}
      {/* {!!campaignList?.filter((campaign) => campaign.status === ICampaignStatusEnum.DRAFT)?.length ? (
                      campaignList
                        ?.filter((campaign) => campaign.status === ICampaignStatusEnum.DRAFT)
                        ?.map((campaign) => <DashboardCard campaign={campaign} isDraft={true} key={campaign?.id} />)
                    ) : (
                      <div className="col-12 text-muted">{t('dashboard.noCampaignsToApprove')}</div>
                    )} */}
      <div className="d-flex gap-4  mb-3">
        <Select
          theme={themeSelect}
          placeholder={t('general.searchByBrand')}
          options={brands?.map((brand) => ({label: brand.name, value: brand.id}))}
          isClearable
          className="small-select2"
          // onInputChange={(value) => {
          //   const event: IEvent = {
          //     target: {
          //       name: 'name',
          //       value: value,
          //     },
          //   };
          //   changeFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
          // }}
          // onChange={(event) => {
          //   setSelectedCompanyId(event?.value as number);
          // }}
          onChange={(event) => {
            setDashboardFilters((prev) => ({...prev, brand: event?.value}));
          }}
        />
        <Select
          theme={themeSelect}
          placeholder={t('general.searchByProduct')}
          options={products?.map((product) => ({label: product.name, value: product.id}))}
          // options={adjustValues(companyList as unknown as ISelect2[], 'name')}
          isClearable
          isDisabled={!dashboardFilters.brand}
          className="small-select2"
          onChange={(event) => {
            setDashboardFilters((prev) => ({...prev, product: event?.value}));
          }}
          // onInputChange={(value) => {
          //   const event: IEvent = {
          //     target: {
          //       name: 'name',
          //       value: value,
          //     },
          //   };
          //   changeFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
          // }}
          // onChange={(event) => {
          //   setSelectedCompanyId(event?.value as number);
          // }}
        />
        <Select
          theme={themeSelect}
          placeholder={t('general.searchByCountry')}
          options={country.map((item) => ({label: t(`general.countryList.${item}`), value: item}))}
          isClearable
          className="small-select2"
          // onInputChange={(value) => {
          //   const event: IEvent = {
          //     target: {
          //       name: 'name',
          //       value: value,
          //     },
          //   };
          //   changeFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
          // }}
          onChange={(event) => {
            setDashboardFilters((prev) => ({...prev, country: event?.value}));
          }}
        />
      </div>
      <hr className="bg-gray mt-2" style={{height: '2px'}} />{' '}
    </>
  );
};

export default DashboardFilters;
