import React, {SetStateAction, useEffect, useState} from 'react';
import {Col, Row, FormGroup} from 'reactstrap';
import Icon from '../../../../components/icon/Icon';
import {FieldErrorsImpl, FormState, UseFormRegister} from 'react-hook-form';
import {IUserData} from '../../../../shared/interfaces/User';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store';
import {getCorrectCountry} from '../../../../shared/Functions';
import {formatRole} from '../../../../utils/Functions';
import {companyApi} from '../../../../api';
import {ICompany} from '../../company/interfaces/ICompany';
import FileInput from '../../../../shared/FileInput';
import {useTranslation} from 'react-i18next';

export interface IUserFormProps {
  errors: Partial<
    FieldErrorsImpl<{
      firstName: string;
      lastName: string;
      company: number;
      email: string;
      roles: string[];
      password: string;
      contact: Partial<
        FieldErrorsImpl<{
          phone: string;
          country: string;
          city: string;
          zip: string;
          address: string;
        }>
      >;
    }>
  >;
  register: UseFormRegister<IUserData>;
  setPassState: React.Dispatch<SetStateAction<boolean>>;
  passState: boolean;
  isPasswordHidden?: boolean;
  isApprover?: boolean;
  isAdmin: boolean;
  isCompanyAdmin?: boolean;
  formState: FormState<IUserData>;
}

const UserForm = ({
  formState,
  errors,
  register,
  setPassState,
  isAdmin,
  isCompanyAdmin = false,
  passState,
  isPasswordHidden = false,
}: IUserFormProps) => {
  const {t} = useTranslation();
  const defaultValues = formState.defaultValues as IUserData;
  const {country, userRoles, userCompanyRole} = useSelector((state: RootState) => state.enum);
  const [companyList, setCompanyList] = useState<ICompany[]>([]);

  const getCorrectRoleArray = () => {
    return isAdmin ? userRoles : userCompanyRole;
  };

  useEffect(() => {
    const getAllCompanies = async () => {
      const {
        data: {data},
      } = await companyApi.getAllCompanies({
        page: 1,
        perPage: 0,
      });
      setCompanyList(data);
    };
    isAdmin && getAllCompanies();
  }, [isAdmin]);
  return (
    <Row>
      <Col md={4} className="d-flex align-items-center justify-content-center flex-column">
        {!!defaultValues?.profilePicture?.id && (
          <img className="h-max-375px mb-4" src={defaultValues?.profilePicture?.path} alt="User logo" />
        )}
        <FileInput accept="image/*" isMultiple={false} name="file" label="File Upload" />
      </Col>
      <Col md={8}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="firstName">
                {t('general.firstName')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-firstName"
                  placeholder={t('general.enterFirstName') as string}
                  {...register('firstName')}
                  className="form-control-lg form-control"
                />
                {errors?.firstName && <p className="invalid">{errors?.firstName?.message}</p>}
              </div>
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="lastName">
                {t('general.lastName')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-lastName"
                  placeholder={t('general.enterLastName') as string}
                  {...register('lastName')}
                  className="form-control-lg form-control"
                />
                {errors?.lastName && <p className="invalid">{errors?.lastName?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  {t('general.email')}
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="default-01"
                  {...register('email')}
                  className="form-control-lg form-control"
                  placeholder={t('general.enterEmail') as string}
                />
                {errors?.email && <p className="invalid">{errors?.email?.message}</p>}
              </div>
            </FormGroup>
          </Col>

          {(isAdmin || isCompanyAdmin) && (
            <>
              <Col md={6}>
                <FormGroup>
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="password">
                      {t('general.password')}
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <div
                      onClick={(ev) => {
                        ev.preventDefault();
                        setPassState(!passState);
                      }}
                      className={`form-icon cursor-pointer lg form-icon-right passcode-switch ${passState ? 'is-hidden' : 'is-shown'}`}
                    >
                      <Icon name="eye" className="passcode-icon icon-show" />
                      <Icon name="eye-off" className="passcode-icon icon-hide" />
                    </div>
                    <input
                      type={passState ? 'text' : 'password'}
                      id="password"
                      {...register('password')}
                      placeholder={t('general.enterPassword') as string}
                      className={`form-control-lg form-control ${passState ? 'is-hidden' : 'is-shown'}`}
                    />
                    {errors?.password && <span className="invalid">{errors?.password?.message}</span>}
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-label" htmlFor="roles">
                    {t('general.role')}
                  </label>
                  <div className="d-flex align-items-center">
                    {getCorrectRoleArray()?.map((role) => {
                      return (
                        <div className="custom-control custom-control-sm custom-checkbox d-flex m-1" key={role}>
                          <input
                            type="checkbox"
                            className="custom-control-input form-control"
                            value={role}
                            id={role}
                            {...register('roles')}
                          />
                          <label className="custom-control-label" htmlFor={role}>
                            {formatRole(role)}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  {errors?.roles && <p className="text-danger">{errors?.roles?.message}</p>}
                </FormGroup>
              </Col>
            </>
          )}
          {isAdmin && (
            <Col md={6}>
              <FormGroup>
                <label className="form-label" htmlFor="company">
                  {t('general.company')}
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      id="default-06"
                      {...register('company', {
                        valueAsNumber: true,
                      })}
                    >
                      <option selected disabled>
                        {t('general.enterCompany')}
                      </option>
                      {companyList?.map((company) => (
                        <option value={company?.id} key={company?.id}>
                          {company?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.company && <span className="text-danger ff-italic">{errors?.company?.message}</span>}
                </div>
              </FormGroup>
            </Col>
          )}
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="phone">
                {t('general.phoneNumber')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="phone"
                  placeholder={t('general.enterPhone') as string}
                  {...register('contact.phone')}
                  className="form-control-lg form-control"
                />
                {errors.contact?.phone && <span className="invalid">{errors?.contact?.phone?.message}</span>}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="country">
                {t('general.country')}
              </label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select className="form-control form-control-lg" id="default-06" {...register('contact.country')}>
                    <option selected disabled>
                      {t('general.enterCountry')}
                    </option>
                    {country?.map((item) => (
                      <option value={item} key={item}>
                        {getCorrectCountry(item, t)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {errors.contact?.country && <span className="invalid">{errors?.contact?.country?.message}</span>}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="city">
                {t('general.city')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-city"
                  placeholder={t('general.enterCity') as string}
                  {...register('contact.city')}
                  className="form-control-lg form-control"
                />
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="zip">
                {t('general.zip')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-zip"
                  placeholder={t('general.enterZip') as string}
                  {...register('contact.zip')}
                  className="form-control-lg form-control"
                />
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="address">
                {t('general.address')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-address"
                  placeholder={t('general.enterAddress') as string}
                  {...register('contact.address')}
                  className="form-control-lg form-control"
                />
              </div>
            </FormGroup>
          </Col>
          {(isAdmin || isCompanyAdmin) && (
            <Col md={6}>
              <FormGroup>
                <label className=" form-label" htmlFor="customSwitch2">
                  {t('general.approver')}
                </label>
                <div className="ps-5">
                  <div className="custom-control custom-switch ps-3">
                    <input type="checkbox" className="custom-control-input" id="customSwitch2" {...register('isApprover')} />
                    <label className="custom-control-label form-label" htmlFor="customSwitch2"></label>
                  </div>
                </div>
              </FormGroup>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default UserForm;
