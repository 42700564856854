import React, {SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ConfirmSuccessModal from '../../../components/modal/ConfirmSuccessModal';
import {EmptyState} from '../../../components/shared/EmptyState';
import ConfirmDeleteModal from '../../../components/modal/ConfirmDeleteModal';
import {ICampaign, ICampaignStatusEnum} from '../interfaces/ICampaigns';
import {campaignApi} from '../../../api';
import {IProduct} from '../../management/products/interfaces/IProduct';
import {IBrand} from '../../management/brands/interfaces/IBrand';
import {getAvatarInitials, getCorrectCampaignStatus} from '../../../shared/Functions';
import {useMultiInject} from '../../../di';
import UserAvatar from '../../../components/user/UserAvatar';

interface ICampaignTableProps {
  campaignList: ICampaign[];
  setCampaignList: React.Dispatch<SetStateAction<ICampaign[]>>;
}

const CampaignTable = ({campaignList, setCampaignList}: ICampaignTableProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {dateTime} = useMultiInject();
  const [selectedCampaign, setSelectedCampaign] = useState<ICampaign>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const deleteCampaignHandler = async () => {
    if (selectedCampaign?.id) {
      const {
        data: {data},
      } = await campaignApi.deleteCampaign(selectedCampaign?.id);
      setCampaignList(data);
      setSelectedCampaign(undefined);
    }
  };
  const copyCampaignHandler = async (selectedCampaignId: number) => {
    const {data} = await campaignApi.copyCampaign(selectedCampaignId);
    setCampaignList((prev) => [...prev, data]);
    setSelectedCampaign(undefined);
  };

  return (
    <>
      {!!campaignList?.length ? (
        <div className="nk-block" data-select2-id="16">
          <div className="card card-bordered" data-select2-id="15">
            <div className="card-inner-group" data-select2-id="14">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="nk-tb-item nk-tb-head">
                    <div className="nk-tb-col nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext"></div>
                    </div>
                    <div className="nk-tb-col">
                      <span>{t('general.campaign')}</span>
                    </div>
                    <div className="nk-tb-col tb-col-lg">
                      <span>
                        {t('general.startDate')} - {t('general.endDate')}
                      </span>
                    </div>
                    <div className="nk-tb-col tb-col-lg">
                      <span>{t('general.updatedAt')}</span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                      <span>{t('general.status')}</span>
                    </div>
                    <div className="nk-tb-col tb-col-lg">
                      <span>{t('general.employees')}</span>
                    </div>

                    <div className="nk-tb-col nk-tb-col-tools">&nbsp;</div>
                  </div>
                  {campaignList?.map((campaign) => {
                    const {id, name, product, status, startDate, endDate, endSteps, currentStep, user, updatedAt} = campaign;
                    let statusColor = 'primary';

                    if (status === ICampaignStatusEnum.REJECT) {
                      statusColor = 'danger';
                    } else if (status === ICampaignStatusEnum.ON_AIR) {
                      statusColor = 'success';
                    } else if (status === ICampaignStatusEnum.IN_PROGRESS) {
                      statusColor = 'warning';
                    } else if (status === ICampaignStatusEnum.PENDING) {
                      statusColor = 'warning';
                    } else if (status === ICampaignStatusEnum.DRAFT) {
                      statusColor = 'default';
                    }

                    return (
                      <React.Fragment key={id}>
                        <div className="nk-tb-item">
                          <div className="nk-tb-col nk-tb-col-check"></div>
                          <div className="nk-tb-col">
                            <div
                              className="user-card cursor-pointer"
                              onClick={() => {
                                navigate(`/app/campaigns/${endSteps ? 3 : currentStep}/${id}`);
                              }}
                            >
                              <div className="user-avatar bg-warning">
                                <span>{getAvatarInitials(name, name?.charAt(1) ?? '')}</span>
                              </div>
                              <div className="user-info">
                                <span className="tb-lead">
                                  {name}
                                  <span className="dot dot-success d-md-none ms-1"></span>
                                </span>
                                <span>{(product as unknown as IProduct)?.name ?? '-'}</span> -
                                <span> {((product as unknown as ICampaign)?.brand as unknown as IBrand)?.name ?? '-'}</span>
                              </div>
                            </div>
                          </div>
                          <div className="nk-tb-col tb-col-lg">
                            <span className="tb-date">{`${dateTime.formatDate(startDate)} - ${dateTime.formatDate(endDate)}` ?? '-'}</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className={`tb-status text-${statusColor}`}>{dateTime.formatDate(updatedAt) ?? '-'}</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className={`tb-status text-${statusColor}`}>{getCorrectCampaignStatus(status) ?? '-'}</span>
                          </div>

                          <div className="nk-tb-col tb-col-lg w-25">
                            <div className="d-flex flex-wrap justify-content-start">
                              {(user as any[])?.map((item) => {
                                const {firstName, lastName, profilePicture} = item;
                                return (
                                  <UserAvatar
                                    className="me-1 mb-1"
                                    size="sm"
                                    key={item.user?.id}
                                    image={profilePicture?.path}
                                    text={getAvatarInitials(firstName, lastName)}
                                    theme="primary"
                                  />
                                );
                              })}
                            </div>
                          </div>

                          <div className="nk-tb-col nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1">
                              <li className="">
                                <a
                                  href="#1"
                                  className="btn btn-trigger btn-icon"
                                  onClick={() => {
                                    setShowDuplicateModal(true);
                                    setSelectedCampaign(campaign);
                                  }}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  aria-label="Quick View"
                                  data-bs-original-title="Quick View"
                                >
                                  <em className="icon ni ni-copy"></em>
                                </a>
                              </li>
                              <li className="">
                                <a
                                  href="#1"
                                  onClick={() => {
                                    navigate(`/app/campaigns/${endSteps ? 3 : currentStep}/${id}`);
                                  }}
                                  className="btn btn-trigger btn-icon"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  aria-label="Approved"
                                  data-bs-original-title="Approved"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </a>
                              </li>
                              {status === 'DRAFT' && (
                                <li className="">
                                  <a
                                    href="#1"
                                    onClick={() => {
                                      setIsDeleteModalOpened(true);
                                      setSelectedCampaign(campaign);
                                    }}
                                    className="btn btn-trigger btn-icon"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    aria-label="Rejected"
                                    data-bs-original-title="Rejected"
                                  >
                                    <em className="icon ni ni-trash"></em>
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-12">
          <div className="card card-bordered ">
            <div className="card-inner-group">
              <div className="card-inner ">
                <EmptyState />
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedCampaign?.id as number}
        itemName={selectedCampaign?.name as string}
        deleteHandler={deleteCampaignHandler}
      />
      <ConfirmSuccessModal
        itemName={selectedCampaign?.name ?? '-'}
        actionName="duplicate"
        show={showDuplicateModal}
        setShow={() => setShowDuplicateModal(false)}
        onSave={() => copyCampaignHandler(selectedCampaign?.id ?? 0)}
      />
    </>
  );
};

export default CampaignTable;
