import React, {useEffect} from 'react';
import * as yup from 'yup';
import {isUserAdmin} from '../../../../shared/Functions';
import {phoneRegExp} from '../../../../shared/Regexs';
import {useNavigate, useParams} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {companyApi} from '../../../../api';
import {ICompanyData} from '../../../../shared/interfaces/Company';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import CompanyForm from './CompanyForm';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store';

export const companyFormSchema = yup
  .object({
    contact: yup.object({
      country: yup.string().required('No country provided'),
      phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    }),
    targetGroups: yup.array(),
    name: yup.string().required('No name provided.').min(3, 'Name is too short - should be 3 chars minimum.'),
    fee: yup.number().required('No fee provided.').min(1, 'Fee is required.'),
    email: yup.string().email().required('No email provided.'),
  })
  .required();
const CompanyUpdate = () => {
  const {roles} = useSelector((state: RootState) => state?.auth?.user);
  const isAdmin = isUserAdmin(roles);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();
  const methods = useForm<ICompanyData>({
    resolver: yupResolver(companyFormSchema),
  });

  const onFormSubmit = async (currentState: ICompanyData) => {
    const correctTargetGroupsData = currentState?.targetGroups?.map((targetGroup: any) => targetGroup?.id);
    const isTargetGroupsDataEdited = correctTargetGroupsData?.some((targetGroup) => !targetGroup);

    const formData = new FormData();
    !!currentState.file && formData.append('logo', currentState.file[0]);
    formData.append(
      'body',
      JSON.stringify({
        ...currentState,
        targetGroups: isTargetGroupsDataEdited ? currentState?.targetGroups : correctTargetGroupsData,
      })
    );
    const {data} = await companyApi.updateCompany(Number(id), formData);
    data && navigate(-1);
  };

  useEffect(() => {
    if (id) {
      const getCompany = async (companyId: number) => {
        const {data} = await companyApi.getCompany(+companyId);
        const target: number[] = [];
        // eslint-disable-next-line
        data?.companyTargetGroup?.map((item: any) => {
          target.push(item?.targetGroups?.id);
        });

        methods?.reset({
          ...data,
          targetGroups: target,
        });
      };
      getCompany(+id);
    }
  }, [id, methods]);
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('administrator.company.companyUpdate')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate('/app/admin/company');
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <CompanyForm
                register={methods?.register}
                formState={methods?.formState}
                errors={methods?.formState?.errors}
                isAdmin={isAdmin}
              />
              <Row>
                <Col>
                  <Row>
                    <Col className={'text-end'}>
                      <Button color="primary">
                        <Icon name="plus-sm" />
                        {t('general.update')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default CompanyUpdate;
