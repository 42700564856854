import {IBrand, IBrandPagination} from '../../../app/management/brands/interfaces/IBrand';
import BaseApi from '../../base.api';

const BRAND_ROUTES = '/api/admin/brand';

export class BrandApi extends BaseApi {
  public async getAllBrands(pagination: IBrandPagination): Promise<{data: any}> {
    return await this.get(BRAND_ROUTES, undefined, pagination);
  }

  public async getBrand(id: number): Promise<{data: IBrand}> {
    return await this.get(`${BRAND_ROUTES}/${id}`);
  }

  public async updateBrand(id: number, data: IBrand): Promise<{data: IBrand}> {
    return await this.put(`${BRAND_ROUTES}/${id}`, data, '');
  }

  public async createBrand(data: Partial<IBrand>): Promise<any> {
    return await this.post(BRAND_ROUTES, data);
  }

  public async deleteBrand(id: number): Promise<any> {
    return await this.delete(`${BRAND_ROUTES}/${id}`, '');
  }
}
