import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Content from '../../layout/content/Content';
import UserAvatar from '../../components/user/UserAvatar';
import Icon from '../../components/icon/Icon';
import {Card} from 'reactstrap';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';
import ProfileChangePassword from './components/ProfileChangePassword';
import ProfileInformation from './components/ProfileInformation';
import {getAvatarInitials} from '../../shared/Functions';
import {useTranslation} from 'react-i18next';

const MyProfile = () => {
  const {t} = useTranslation();
  const {state} = useLocation();
  const {user} = useSelector((state: RootState) => state.auth);
  const [currentTab, setCurrentTab] = useState('profile');

  useEffect(() => {
    if (!!state) setCurrentTab(state.component);
  }, [state]);
  return (
    <Content>
      <Card className="card-bordered">
        <div className="card-aside-wrap">
          <div
            className={
              'card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg content-active d-none d-lg-block'
            }
          >
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="user-card">
                  <UserAvatar
                    image={user?.profilePicture?.path}
                    text={getAvatarInitials(user?.firstName, user?.lastName)}
                    theme="primary"
                  />

                  <div className="user-info">
                    <span className="lead-text">{`${user?.firstName} ${user?.lastName}`}</span>
                    <span className="sub-text">{user?.email}</span>
                  </div>
                </div>
              </div>

              <div className="card-inner p-0">
                <ul className="link-list-menu">
                  <li onClick={() => setCurrentTab('profile')}>
                    <Link to="/app/profile" className={currentTab === 'profile' ? 'active' : ''}>
                      <Icon name="user-fill-c"></Icon>
                      <span>{t('myProfile.profileInfo.personalInformation')}</span>
                    </Link>
                  </li>
                  <li onClick={() => setCurrentTab('changePassword')}>
                    <Link to="/app/profile" className={currentTab === 'changePassword' ? 'active' : ''}>
                      <Icon name="setting-alt"></Icon>
                      <span>{t('myProfile.password.changePassword')}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-inner card-inner-lg">{currentTab === 'profile' ? <ProfileInformation /> : <ProfileChangePassword />}</div>
        </div>
      </Card>
    </Content>
  );
};

export default MyProfile;
