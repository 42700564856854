import {createSlice} from '@reduxjs/toolkit';
import {ICompany} from '../../app/administrator/company/interfaces/ICompany';
import {IPicture} from '../../shared/interfaces/Picture';

export interface IUser {
  user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    isActive: boolean;
    roles: string[];
    contact: IContact;
    company: ICompany;
    isApprover: boolean;
    profilePicture: IPicture;
  };
  token: string;
}

export interface IContact {
  phone: string;
  country: string;
  city: string;
  zip: string;
  address: string;
}

const initialContact = {
  address: '',
  city: '',
  country: '',
  phone: '',
  zip: '',
};

const initialState: IUser = {
  user: {
    roles: [],
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    isActive: false,
    profilePicture: {
      id: 0,
      file: {
        name: '',
        nameOriginal: '',
        mime: '',
        size: 0,
      },
      path: '',
    },
    contact: initialContact,
    company: {
      id: 0,
      contact: initialContact,
      email: '',
      name: '',
      unlimitedBudget: false,
      targetGroups: [],
      companyTargetGroup: [
        {
          id: 0,
          updatedAt: '',
          createdAt: '',
          targetGroups: [],
        },
      ],
    },
    isApprover: true,
  },
  token: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state = action.payload;
      return state;
    },
    reset: (state) => {
      state = initialState;
      return state;
    },
  },
});
