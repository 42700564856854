import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Card, CardBody, Col, Progress} from 'reactstrap';
import Icon from '../../../components/icon/Icon';
import UserAvatar from '../../../components/user/UserAvatar';
import {useMultiInject} from '../../../di';
import {getAvatarInitials} from '../../../shared/Functions';
import {ICountry} from '../../campaigns/interfaces/ICampaigns';
import {IProduct} from '../../management/products/interfaces/IProduct';
import {IDashboardCampaign} from '../../../api/app/campaigns/campaign.api';

interface IDashboardCardProps {
  campaign: IDashboardCampaign;
  isDraft?: boolean;
  euro: any;
}

const DashboardCard = ({campaign, isDraft = false, euro}: IDashboardCardProps) => {
  const {t} = useTranslation();
  const {id, name, createdAt, company, status, campaignCountry, product, currentStep, endSteps} = campaign;
  const {dateTime} = useMultiInject();

  return (
    <Col md={6} xl={4} className="mt-3">
      <Link to={`/app/campaigns/${endSteps ? 3 : currentStep}/${id}`}>
        <Card className="card-bordered">
          <CardBody>
            <div className="d-flex align-items-center mb-2">
              <div className="user-avatar sq">{getAvatarInitials(name, name?.charAt(1) ?? '')}</div>
              <div className="d-flex flex-column ms-2">
                <span className="fw-bold">{name}</span>
                <span className="text-muted">
                  {company?.name} - {dateTime.formatDate(createdAt)}
                </span>
              </div>
            </div>
            <div className="text-muted d-flex align-items-center justify-content-between">
              <div>
                <Icon name="label-alt-fill" />
                <span>{t('general.countries')}</span>
              </div>
              <div>
                {(campaignCountry as unknown as ICountry[])?.map((item) => (
                  <img
                    key={item?.id}
                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${item?.country}.svg`}
                    className="flag-sm"
                    alt={`${item.country} flag`}
                  />
                ))}
              </div>
            </div>
            <div className="text-muted d-flex align-items-center justify-content-between my-1">
              <div>
                <Icon name="card-view" />
                <span>{t('general.product')}</span>
              </div>
              <span>{(product as unknown as IProduct)?.name}</span>
            </div>
            <div className="text-muted d-flex align-items-center justify-content-between my-1">
              <div>
                <Icon name="calendar-check" />
                <span>{t('general.status')}</span>
              </div>
              <span>{status}</span>
            </div>

            <div className="text-muted d-flex align-items-center justify-content-between my-1">
              <div>
                <Icon name="user-alt" />
                <span>{t('general.users')}</span>
              </div>
              <div className="d-flex">
                {campaign.users?.map((item) => {
                  const {id, firstName, lastName, profilePicture} = item.user;
                  return (
                    <UserAvatar
                      size="sm"
                      key={id}
                      image={profilePicture?.path}
                      text={getAvatarInitials(firstName, lastName)}
                      theme="primary"
                    />
                  );
                })}
              </div>
            </div>
          </CardBody>
          <CardBody className="bg-lighter">
            <div className="">
              <strong>
                <p className="text-dark ">Budget:</p>
              </strong>
              <strong>
                <p className="text-dark ">
                  {euro.format(+campaign.campaignOverview.totalBudget)} / {euro.format(+campaign.plannedBudget)}
                </p>
              </strong>
              {/* {console.log(((+campaign.campaignOverview.totalBudget - +campaign.plannedBudget) / +campaign.plannedBudget) * 100)} */}
              <Progress
                striped
                animated
                max={campaign.plannedBudget}
                color="primary"
                className={'progress-lg opacity-100 border-primary border-opacity-25'}
                // value={Number(lineItem?.progressBar?.engagement ?? 0)}
                value={+campaign.campaignOverview.totalBudget}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <strong>
                <p className="text-dark ">CPC</p>
              </strong>
              <strong>
                {/* <p className="text-dark ">{campaign.campaignOverview.progressBar.CPC}$</p> */}
                <p className="text-dark ">{euro.format(+campaign.campaignOverview.progressBar.CPC)}</p>
              </strong>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <strong>
                <p className="text-dark ">CPE</p>
              </strong>
              <strong>
                <p className="text-dark ">{euro.format(+campaign.campaignOverview.progressBar.CPE)}</p>
              </strong>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <strong>
                <p className="text-dark ">CPM</p>
              </strong>
              <strong>
                <p className="text-dark ">{euro.format(+campaign.campaignOverview.progressBar.CPM)}</p>
              </strong>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <strong>
                <p className="text-dark ">CPV</p>
              </strong>
              <strong>
                <p className="text-dark ">{euro.format(+campaign.campaignOverview.progressBar.CPV)}</p>
              </strong>
            </div>
          </CardBody>
        </Card>
      </Link>
    </Col>
  );
};

export default DashboardCard;
