import React from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from '../../components/error/NotFound';
import {PrivateRoute} from '../../routes/RouteGuards';
import Reporting from './index';
import ReportingForm from './components/ReportingForm';

export function ReportingRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute component={Reporting} />} />
      <Route path="/:id" element={<PrivateRoute component={ReportingForm} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
