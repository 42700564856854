import {ICompanyPagination} from '../../../app/administrator/company/interfaces/ICompany';
import {ICompanyData} from '../../../shared/interfaces/Company';
import BaseApi from '../../base.api';

const COMPANY_ROUTE = '/api/admin/company';

export class CompanyApi extends BaseApi {
  public async getAllCompanies(pagination: ICompanyPagination): Promise<any> {
    return await this.get(COMPANY_ROUTE, '', pagination);
  }

  public async getCompany(id: number): Promise<{data: ICompanyData}> {
    return await this.get(`${COMPANY_ROUTE}/${id}`);
  }

  public async createCompany(data: any): Promise<any> {
    return await this.post(COMPANY_ROUTE, data, '', true);
  }

  public async deleteCompany(id: number): Promise<any> {
    return await this.delete(`${COMPANY_ROUTE}/${id}`, '');
  }

  public async updateCompany(id: number, data: FormData): Promise<{data: ICompanyData}> {
    return await this.put(`${COMPANY_ROUTE}/${id}`, data, '', true);
  }
}
