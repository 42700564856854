import React, {useEffect, useState} from 'react';
import {INotification} from './interfaces/INotification';
import {notificationApi} from '../../api';
import {useTranslation} from 'react-i18next';
import {usePagination} from '../../shared/hooks/usePagination';
import {Pagination} from '../../components/pagination/Pagination';
import {getNotificationIcon} from '../../shared/Functions';
import {useNavigate} from 'react-router-dom';

interface INotificationProps {
  notification_key: string;
  campaign_id: number;
  campaignName: string;
}

const NotificationItem = (props: INotificationProps) => {
  const {notification_key, campaign_id, campaignName} = props;
  const navigate = useNavigate();
  const {t} = useTranslation();
  return (
    <div className="nk-notification-item " key={campaign_id}>
      <div className="nk-notification-icon">
        <div className="icon-circle">{getNotificationIcon(notification_key)}</div>
      </div>
      <div className="nk-notification-content">
        <div
          className="nk-notification-text"
          onClick={() => {
            navigate(`/app/campaigns/1/${campaign_id}`);
          }}
        >
          {t('general.campaign')}
          <span className="fw-bold"> {campaignName} </span>
          {t('general.hasBeenCreated')}
        </div>
      </div>
    </div>
  );
};

const Notifications = () => {
  const [notificationList, setNotificationList] = useState<INotification[]>([]);
  const {pagination, totalPages, page, handlePages, setTotalPages} = usePagination();

  useEffect(() => {
    notificationApi.getNotificationCount(pagination).then((response) => {
      setNotificationList(response.data.data);
      setTotalPages(Math.ceil(response?.data?.count / response?.data?.perPage));
    });
  }, [pagination, setTotalPages]);
  return (
    <>
      <div className="nk-block nk-block-lg mt-3">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title mt-5 mb-2">Notification History - With Action</h4>
          </div>

          <div className="card card-bordered card-preview">
            <table className="table table-ulogs">
              <thead className="table-light"></thead>
              <tbody>
                <div className="nk-notification">
                  {notificationList?.map((notification, index) => {
                    const {
                      message: {notification_key, campaign_id, campaignName},
                    } = notification;
                    return (
                      <NotificationItem
                        key={index}
                        notification_key={notification_key}
                        campaignName={campaignName}
                        campaign_id={campaign_id}
                      />
                    );
                  })}
                </div>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end">
            {totalPages <= 1 ? '' : <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
