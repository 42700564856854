import React from 'react';
import {FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {IDevice} from '../interfaces/IDevice';
import {useTranslation} from 'react-i18next';
import {Col, FormGroup, Row} from 'reactstrap';

interface IDeviceFormProps {
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
      description: string;
    }>
  >;
  register: UseFormRegister<IDevice>;
}

const DeviceForm = ({errors, register}: IDeviceFormProps) => {
  const {t} = useTranslation();
  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <label className="form-label" htmlFor="name">
            {t('general.name')}
          </label>
          <div className="form-control-wrap">
            <input
              type="text"
              id="reg-type"
              placeholder={t('administrator.devices.enterDevice') as string}
              {...register('name')}
              className="form-control-lg form-control"
            />
            {errors?.name && <p className="invalid">{errors?.name?.message}</p>}
          </div>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <label className="form-label" htmlFor="description">
            {t('general.description')}
          </label>
          <div className="form-control-wrap">
            <textarea placeholder={t('general.description') ?? ''} {...register('description')} className="form-control-lg form-control" />
            {errors?.description && <p className="invalid">{errors?.description?.message}</p>}
          </div>
        </FormGroup>
      </Col>
      <Col md={3}>
        <div className="custom-control custom-switch mt-2" style={{zIndex: -0}}>
          <input type="checkbox" className="custom-control-input" id="status" {...register('status')} defaultChecked={true} />
          <label className="custom-control-label form-label" htmlFor="status">
            Status
          </label>
        </div>
      </Col>
    </Row>
  );
};

export default DeviceForm;
