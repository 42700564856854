import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import Forbidden from '../components/error/Forbidden';
import NotFound from '../components/error/NotFound';
import ServerError from '../components/error/ServerError';
import {PrivateRoute} from '../routes/RouteGuards';
import {isUserAdmin, isUserChannelUser} from '../shared/Functions';
import {RootState} from '../store';
import {AdminRoutes} from './administrator/routes';
import {CampaignRoutes} from './campaigns/routes';
import ChannelUpdateForm from './channel-user/channels';
import ChannelUserDashboard from './channel-user/dashboard/ChannelUserDashboard';
import Dashboard from './dashboard';
import {ManagementRoutes} from './management/routes';
import MyProfile from './my-profile';
import Notifications from './notifications';
import {OrderRoutes} from './orders/routes';
import {OverviewRoutes} from './overview/routes';
import {ReportingRoutes} from './reporting/routes';
import {InvoiceRoutes} from './invoice/routes';

export function AppRoutes() {
  const {roles, id} = useSelector((state: RootState) => state.auth.user);

  const isChannelUser = isUserChannelUser(roles);
  const isAdmin = isUserAdmin(roles);
  return (
    <Routes>
      {id && (
        <>
          {!isChannelUser ? (
            <>
              <Route path="/profile" element={<PrivateRoute component={MyProfile} />} />
              <Route path="/notifications" element={<PrivateRoute component={Notifications} />} />
              <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
              <Route path="/campaigns/*" element={<CampaignRoutes />} />
              <Route path="/reporting/*" element={<ReportingRoutes />} />
              <Route path="/invoice/*" element={<InvoiceRoutes />} />
              <Route path="/management/*" element={<ManagementRoutes />} />
              <Route path="/orders/*" element={<OrderRoutes />} />

              <Route path="/overview/*" element={<OverviewRoutes />} />
              {isAdmin && <Route path="/admin/*" element={<AdminRoutes />} />}
            </>
          ) : (
            <>
              <Route path="/channel-user/update/:id" element={<PrivateRoute component={ChannelUpdateForm} />} />
              <Route path="/dashboard" element={<PrivateRoute component={ChannelUserDashboard} />} />
            </>
          )}
        </>
      )}

      <Route path="/500" element={<ServerError />} />
      <Route path="/403" element={<Forbidden />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
