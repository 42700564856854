import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Card, CardBody, Form} from 'reactstrap';
import * as yup from 'yup';
import {campaignApi} from '../../../api';
import {Block} from '../../../components/block/Block';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import {formatDatepickerTime} from '../../../shared/Functions';
import {ICampaign} from '../interfaces/ICampaigns';
import CampaignForm from './CampaignForm';
import ChangeStep from './steps/ChangeStep';

export const campaignFormSchema = yup
  .object({
    user: yup.array().min(1, 'Please select one user').required('No user provided'),
    name: yup.string().required('No name provided.').min(3, 'Name is too short - should be 3 chars minimum.'),
    country: yup.array().min(1, "You can't leave this blank.").required("You can't leave this blank.").nullable(),
    product: yup.number().required('No product provided.'),
  })
  .required();

const CampaignCreate = () => {
  const {t} = useTranslation();

  const navigate = useNavigate();
  const methods = useForm<ICampaign>({
    resolver: yupResolver(campaignFormSchema),
  });

  // const onFormSubmit = async (formData: ICampaign) => {
  //   const {data} = await campaignApi.createCampaign({
  //     ...formData,
  //     startDate: formatDatepickerTime(formData?.startDate),
  //     endDate: formatDatepickerTime(formData?.endDate),
  //   });
  //   if (data) {
  //     navigate(`/app/campaigns/2/${data?.data?.[0]?.id}`); // first element is last campaign
  //   }
  // };

  const onFormSubmit = async (formData: ICampaign) => {
    const fd = new FormData();
    const pictureToSend: FileList = formData.picture as FileList;
    !!formData.picture && fd.append('picture', pictureToSend[0]);
    formData.startDate = formatDatepickerTime(formData?.startDate);
    formData.endDate = formatDatepickerTime(formData?.endDate);

    fd.append('body', JSON.stringify(formData));

    const {data} = await campaignApi.createCampaign(fd);
    if (data) {
      navigate(`/app/campaigns/2/${data?.data?.[0]?.id}`); // first element is last campaign
    }
  };
  return (
    <Block size="lg" className="mt-3">
      <ChangeStep currentCampaignStep={1} status="CREATE" isCreate={true} activeStep={1} />

      <Card className="card-bordered card-preview mb-3">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <CampaignForm register={methods?.register} errors={methods?.formState?.errors} />
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-end">
                    <Button
                      className="me-3"
                      color="secondary"
                      onClick={() => {
                        navigate('/app/campaigns');
                      }}
                    >
                      <Icon name="arrow-long-left" />
                      {t('general.cancel')}
                    </Button>
                    <Button color="primary">
                      <Icon name="plus-sm" />
                      {t('general.create')}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};
export default CampaignCreate;
