import React, {SetStateAction} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Icon from '../icon/Icon';
import Button from '../button/Button';

interface IModalProps {
  isModalOpened: boolean;
  showSubmit?: boolean;
  setIsModalOpened: React.Dispatch<SetStateAction<boolean>>;
  title: string;
  successName?: string;
  body: JSX.Element;
  size?: 'xl' | 'lg' | 'sm' | 'md';
  saveHandler: () => void;
  haveCancel?: boolean;
  setCurrStep?: React.Dispatch<SetStateAction<number>>;
}

const CustomModal = ({
  isModalOpened,
  successName = 'Save',
  showSubmit = true,
  setIsModalOpened,
  title,
  body,
  haveCancel = true,
  size,
  saveHandler,
  setCurrStep,
}: IModalProps) => {
  const handleCloseModal = () => {
    setIsModalOpened(false);
    setCurrStep?.(1);
  };
  return (
    <Modal isOpen={isModalOpened} toggle={handleCloseModal} size={size}>
      <ModalHeader
        toggle={handleCloseModal}
        close={
          <button className="close" onClick={handleCloseModal}>
            <Icon name="cross" />
          </button>
        }
      >
        {title}
      </ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter className="bg-light">
        {haveCancel && (
          <Button
            color="danger"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Cancel
          </Button>
        )}
        {showSubmit && (
          <Button color="primary" type="submit" onClick={saveHandler}>
            {successName}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CustomModal;
