import React, {useEffect, useState} from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {availableLanguages} from '../../../utils/i18n';
import {useTranslation} from 'react-i18next';

const HeaderLanguages = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const {i18n, t} = useTranslation();

  const changeLanguageHandler = (language: string) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    localStorage.setItem('language', language);
  };

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('language');
    if (selectedLanguage && availableLanguages) {
      i18n.changeLanguage(selectedLanguage);
      setSelectedLanguage(selectedLanguage);
    }
  }, [i18n]);

  return (
    <ul>
      <li className="nav-item d-none d-md-flex align-items-center justify-content-center">
        <UncontrolledDropdown direction="up">
          <DropdownToggle color="transparent" className="dropdown-toggle dropdown-indicator has-indicator nav-link">
            <img src={getCorrectCountryFlag(selectedLanguage)} alt="Selected flag" className="language-flag" />
          </DropdownToggle>
          <DropdownMenu end className="dropdown-menu-sm">
            <ul className="language-list">
              {availableLanguages?.map((language) => (
                <li key={language}>
                  <DropdownItem
                    tag="a"
                    href="#dropdownitem"
                    onClick={(ev) => {
                      ev.preventDefault();
                      changeLanguageHandler(language);
                    }}
                    className="language-item"
                  >
                    <img src={getCorrectCountryFlag(language)} alt={`${language} flag`} className="language-flag" />
                    <span className="language-name">{getCorrectTranslation(language, t)}</span>
                  </DropdownItem>
                </li>
              ))}
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </li>
    </ul>
  );
};

const getCorrectCountryFlag = (country: string) => {
  switch (country) {
    case 'de':
      return 'https://www.worldometers.info/img/flags/gm-flag.gif';
    case 'en':
      return 'https://www.worldometers.info/img/flags/uk-flag.gif';
    default:
      return '';
  }
};

const getCorrectTranslation = (country: string, t: any) => {
  return t(`general.language.${country}`);
};

export default HeaderLanguages;
