import _debounce from 'lodash/debounce';
import React, {SetStateAction, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import Select from 'react-select';
import {Col, Row} from 'reactstrap';
import {lineItemApi, regionApi} from '../../../../../api';
import {adjustValues, ISelect2, themeSelect} from '../../../../../shared/Select2';
import {IRegion} from '../../../../administrator/region/interfaces/IRegion';
import {IListSubmitData} from '../CampaignStep3';
import {ILocalSection} from './UpdateLineItemForm';

interface Props {
  setListSubmitData: React.Dispatch<SetStateAction<IListSubmitData>>;
  methods: any;
  isRegionValid?: boolean | undefined;
  setIsRegionValid?: any;
  setIsCreativeValid?: any;
  isCreativeValid?: boolean | undefined;
  selectedCountry?: string;
}

const LineItemStep2 = ({
  setListSubmitData,
  methods,
  isRegionValid,
  setIsRegionValid,
  setIsCreativeValid,
  isCreativeValid,
  selectedCountry,
}: Props) => {
  const {t} = useTranslation();
  const [sections, setSections] = useState<ILocalSection[]>([]);
  const {id} = useParams();

  const [regions, setRegions] = useState<IRegion[]>([]);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target;
    setListSubmitData((prev) => ({...prev, [name]: value}));
    setIsCreativeValid(true);
  };

  const changeSelectHandler = _debounce((eventValues: number[], eventName: string) => {
    setListSubmitData((prev) => ({...prev, [eventName]: eventValues}));
    setIsRegionValid(true);
  }, 300);
  useEffect(() => {
    const getAllRegions = async () => {
      const {
        data: {data},
      } = await regionApi.getAllRegions({page: 1, perPage: 0, country: selectedCountry});
      setRegions(data);
    };
    getAllRegions();
  }, []);
  useEffect(() => {
    if (!!id) {
      lineItemApi.getAllSections(id).then((response) => {
        setSections(response?.data?.map((sec) => ({id: sec.lineItem_id, title: sec.lineItem_title})));
      });
    }
  }, [id]);
  return (
    <Row>
      <Col md={12} className="my-3">
        <h4>Describe your creative</h4>
      </Col>
      <Col md={4}>
        <label htmlFor="creativeName">{t('campaigns.enterCreativeName')}</label>
        <input type="text" onChange={changeHandler} name="creativeName" className="form-control" />
        {isCreativeValid === false && <p className="invalid text-danger">Invalid creative name</p>}
      </Col>
      <Col md={4}>
        <label htmlFor="region">{t('campaigns.enterRegion')}</label>
        <Select
          theme={themeSelect}
          placeholder={t('general.allRegions')}
          options={adjustValues(regions as unknown as ISelect2[], 'name')}
          isClearable
          isMulti
          className="small-select2 "
          onChange={(event) => {
            changeSelectHandler(
              event?.map((region) => region.id),
              'region'
            );
          }}
        />
        <div className="form-control d-none"></div>
        {isRegionValid === false && <p className="invalid text-danger">Invalid region</p>}
      </Col>
      <Col md={4}>
        <label htmlFor="region">{t('general.sections')}</label>
        <Select
          theme={themeSelect}
          placeholder={t('general.sections')}
          options={adjustValues(sections as unknown as ISelect2[], 'title').map((item) => ({
            ...item,
            label: item.label,
          }))}
          isClearable
          className="small-select2"
          onChange={(selectedSection) => {
            setListSubmitData((prev: any) => ({...prev, parent: selectedSection?.id}));
          }}
        />
      </Col>
    </Row>
  );
};

export default LineItemStep2;
