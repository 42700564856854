import {TypeEnum} from '../store/slices/common.slice';

export const messagesMap: {[key: string]: any} = {
  postloginSuccess: {
    text: 'Welcome back!',
    type: TypeEnum.success,
  },
  postloginError: {
    text: 'Invalid credentials',
    type: TypeEnum.error,
  },
  postregisterSuccess: {
    text: 'You have successfully register, please log in!',
    type: TypeEnum.success,
  },
  postregisterError: {
    text: 'Invalid credentials',
    type: TypeEnum.error,
  },
  postUserSuccess: {
    text: 'You have successfully created new user!',
    type: TypeEnum.success,
  },
  deleteUserSuccess: {
    text: 'You have successfully deleted user!',
    type: TypeEnum.success,
  },
  putUser: {
    text: 'You have successfully updated user!',
    type: TypeEnum.success,
  },
  postCompanySuccess: {
    text: 'You have successfully created new company!',
    type: TypeEnum.success,
  },
  deleteCompanySuccess: {
    text: 'You have successfully deleted company!',
    type: TypeEnum.success,
  },
  putCompanySuccess: {
    text: 'You have successfully updated company!',
    type: TypeEnum.success,
  },
  postTypeSuccess: {
    text: 'You have successfully created new type!',
    type: TypeEnum.success,
  },
  deleteTypeSuccess: {
    text: 'You have successfully deleted type!',
    type: TypeEnum.success,
  },
  postType: {
    text: 'You have successfully created type!',
    type: TypeEnum.success,
  },
  putType: {
    text: 'You have successfully updated type!',
    type: TypeEnum.success,
  },
  postBrand: {
    text: 'You have successfully created Brand!',
    type: TypeEnum.success,
  },
  putBrand: {
    text: 'You have successfully updated brand!',
    type: TypeEnum.success,
  },
  postFormat: {
    text: 'You have successfully created format!',
    type: TypeEnum.success,
  },
  putFormat: {
    text: 'You have successfully updated format!',
    type: TypeEnum.success,
  },
  deleteFormat: {
    text: 'You have successfully deleted format!',
    type: TypeEnum.success,
  },
  postCampaign: {
    text: 'You have successfully created campaign!',
    type: TypeEnum.success,
  },
  putCampaign: {
    text: 'You have successfully updated campaign!',
    type: TypeEnum.success,
  },
  deleteCampaign: {
    text: 'You have successfully deleted campaign!',
    type: TypeEnum.success,
  },
  postQuality: {
    text: 'You have successfully created quality!',
    type: TypeEnum.success,
  },
  putQuality: {
    text: 'You have successfully updated quality!',
    type: TypeEnum.success,
  },
  deleteQuality: {
    text: 'You have successfully deleted quality!',
    type: TypeEnum.success,
  },
  postChannel: {
    text: 'You have successfully created channel!',
    type: TypeEnum.success,
  },
  putChannel: {
    text: 'You have successfully updated channel!',
    type: TypeEnum.success,
  },
  deleteChannel: {
    text: 'You have successfully deleted channel!',
    type: TypeEnum.success,
  },
  postProduct: {
    text: 'You have successfully created product!',
    type: TypeEnum.success,
  },
  putProduct: {
    text: 'You have successfully updated product!',
    type: TypeEnum.success,
  },
  deleteProduct: {
    text: 'You have successfully deleted product!',
    type: TypeEnum.success,
  },
  postTargetGroups: {
    text: 'You have successfully created target groups!',
    type: TypeEnum.success,
  },
  putTargetGroups: {
    text: 'You have successfully updated target groups!',
    type: TypeEnum.success,
  },
  deleteTargetGroups: {
    text: 'You have successfully deleted target groups!',
    type: TypeEnum.success,
  },
  postDevice: {
    text: 'You have successfully created device!',
    type: TypeEnum.success,
  },
  putDevice: {
    text: 'You have successfully updated device!',
    type: TypeEnum.success,
  },
  deleteDevice: {
    text: 'You have successfully deleted device!',
    type: TypeEnum.success,
  },
  putPasswordChange: {
    text: 'You have successfully updated password!',
    type: TypeEnum.success,
  },
  putMe: {
    text: 'You have successfully updated information!',
    type: TypeEnum.success,
  },
  postEmailSuccess: {
    text: 'Confirmation link sent to your email',
    type: TypeEnum.success,
  },
  postEmailError: {
    text: 'Invalid credentials',
    type: TypeEnum.error,
  },
  postPasswordSuccess: {
    text: 'Your password successfully updated!',
    type: TypeEnum.success,
  },

  putChannelPrice: {
    text: 'You have successfully updated Channel - Price!',
    type: TypeEnum.success,
  },
  putDevicePrice: {
    text: 'You have successfully updated Device - Price!',
    type: TypeEnum.success,
  },
  putQualityPrice: {
    text: 'You have successfully updated Quality - Price!',
    type: TypeEnum.success,
  },
  putQualityObjective: {
    text: 'You have successfully updated Quality - Objective!',
    type: TypeEnum.success,
  },

  putObjectiveScoring: {
    text: 'You have successfully updated Objective Scoring!',
    type: TypeEnum.success,
  },
  putTargetGroupScoring: {
    text: 'You have successfully updated Target Group Scoring!',
    type: TypeEnum.success,
  },
  putApiAdminPriceAll: {
    text: 'You have successfully updated Target Group Pricing!',
    type: TypeEnum.success,
  },
  putCampaignStep: {
    text: 'You have successfully updated this campaign',
    type: TypeEnum.success,
  },
  putApiAdminChannelDeviceFormat: {
    text: 'You have successfully updated device formats for this channel',
    type: TypeEnum.success,
  },
  postApiAdminLineItem: {
    text: 'You have successfully created line item for this campaign',
    type: TypeEnum.success,
  },
  putApiAdminLineItem: {
    text: 'You have successfully updated line item for this campaign',
    type: TypeEnum.success,
  },
  putUpdateStatusSuccess: {
    text: 'You have successfully approved campaign',
    type: TypeEnum.success,
  },
  putUpdateStatusReject: {
    text: 'You have successfully rejected campaign',
    type: TypeEnum.success,
  },
  postApiAdminUserChannel: {
    text: 'You have successfully assigned channels for user',
    type: TypeEnum.success,
  },
  putApiAdminUserChannel: {
    text: 'You have successfully updated device description for this channel',
    type: TypeEnum.success,
  },
  putApiAdminCampaignPaymentStatus: {
    text: 'You have successfully updated campaign payment status',
    type: TypeEnum.success,
  },
  postRegion: {
    text: 'You have successfully created region!',
    type: TypeEnum.success,
  },
  putRegion: {
    text: 'You have successfully updated region!',
    type: TypeEnum.success,
  },
  deleteRegion: {
    text: 'You have successfully deleted region!',
    type: TypeEnum.success,
  },
  postApiAdminReport: {
    text: 'You have successfully created reporting file!',
    type: TypeEnum.success,
  },
  deleteApiAdminReport: {
    text: 'You have successfully deleted reporting file!',
    type: TypeEnum.success,
  },
  deleteApiAdminCampaignMediaPlan: {
    text: 'You have successfully deleted media plan file!',
    type: TypeEnum.success,
  },
  postApiAdminCampaignCampaignCopy: {
    text: 'You have successfully duplicated campaign!',
    type: TypeEnum.success,
  },
  deleteApiAdminCreative: {
    text: 'You have successfully deleted creative picture!',
    type: TypeEnum.success,
  },
  putApiCompanyUser: {
    text: 'You have successfully updated user!',
    type: TypeEnum.success,
  },
  patchApiAdminCampaignStrategyPresentation: {
    text: 'You have successfully uploaded file!',
    type: TypeEnum.success,
  },
  patchApiAdminLineItemStrategyPresentation: {
    text: 'You have successfully uploaded file!',
    type: TypeEnum.success,
  },
  putApiAdminLineItemSectionCampaign: {
    text: 'You have successfully created new section!',
    type: TypeEnum.success,
  },

  putApiAdminChatGptAssistantThreadCampaign: {
    text: 'You have successfully created new thread and uploaded file! Start messaging!',
    type: TypeEnum.success,
  },
};

export const successResponses: {[key: string]: string} = {
  post_api_signin: 'postloginSuccess',
  post_api_register: 'postregisterSuccess',
  post_api_admin_user: 'postUserSuccess',
  put_api_admin_user: 'putUser',
  delete_api_admin_user: 'deleteUserSuccess',
  post_api_admin_company: 'postCompanySuccess',
  delete_api_admin_company: 'deleteCompanySuccess',
  put_api_admin_company: 'putCompanySuccess',
  post_api_admin_type: 'postType',
  delete_api_admin_type: 'deleteTypeSuccess',
  put_api_admin_type: 'putType',
  post_api_admin_brand: 'postBrand',
  put_api_admin_brand: 'putBrand',
  post_api_admin_format: 'postFormat',
  put_api_admin_format: 'putFormat',
  delete_api_admin_format: 'deleteFormat',
  post_api_admin_campaign: 'postCampaign',
  put_api_admin_campaign: 'putCampaign',
  delete_api_admin_campaign: 'deleteCampaign',
  post_api_admin_quality: 'postQuality',
  put_api_admin_quality: 'putQuality',
  delete_api_admin_quality: 'deleteQuality',
  post_api_admin_channel: 'postChannel',
  put_api_admin_channel: 'putChannel',
  delete_api_admin_channel: 'deleteChannel',
  post_api_admin_product: 'postProduct',
  put_api_admin_product: 'putProduct',
  delete_api_admin_product: 'deleteProduct',
  'put_api_password-change': 'putPasswordChange',
  put_api_me: 'putMe',
  // put_api_admin_user: 'putUser',
  'post_api_password-success': 'postPasswordSuccess',
  'post_api_email-success': 'postEmailSuccess',
  'post_api_admin_target-groups': 'postTargetGroups',
  'put_api_admin_target-groups': 'putTargetGroups',
  'delete_api_admin_target-groups': 'deleteTargetGroups',
  'put_api_company-user': 'putApiCompanyUser',
  post_api_admin_device: 'postDevice',
  put_api_admin_device: 'putDevice',
  delete_api_admin_device: 'deleteDevice',
  post_api_admin_region: 'postRegion',
  put_api_admin_region: 'putRegion',
  delete_api_admin_region: 'deleteRegion',
  'post_api_forgot-password-request': 'postEmailSuccess',
  'post_api_forgot-password-change': 'postPasswordSuccess',
  put_api_admin_channel_price: 'putChannelPrice',
  put_api_admin_device_price: 'putDevicePrice',
  put_api_admin_quality_objective: 'putObjectiveScoring',
  put_api_admin_quality_price: 'putQualityPrice',
  put_api_admin_price_CPM: 'putTargetGroupScoring',
  put_api_admin_price_CPC: 'putTargetGroupScoring',
  put_api_admin_price_CPV: 'putTargetGroupScoring',
  put_api_admin_price_CPE: 'putTargetGroupScoring',
  put_api_admin_campaign_step: 'putCampaignStep',
  'put_api_admin_campaign_update-status': 'putUpdateStatusSuccess',
  'put_api_admin_campaign_reject-campaign': 'putUpdateStatusReject',
  'put_api_admin_channel_device-format': 'putApiAdminChannelDeviceFormat',
  'post_api_admin_line-item': 'postApiAdminLineItem',
  'put_api_admin_line-item': 'putApiAdminLineItem',
  'post_api_admin_user-channel': 'postApiAdminUserChannel',
  'put_api_admin_user-channel': 'putApiAdminUserChannel',
  put_api_admin_campaign_paymentStatus: 'putApiAdminCampaignPaymentStatus',
  'patch_api_admin_line-item': 'patchApiAdminLineItem',
  post_api_admin_report: 'postApiAdminReport',
  delete_api_admin_report: 'deleteApiAdminReport',
  delete_api_admin_campaign_mediaPlan: 'deleteApiAdminCampaignMediaPlan',
  'post_api_admin_campaign_campaign-copy': 'postApiAdminCampaignCampaignCopy',
  delete_api_admin_creative: 'deleteApiAdminCreative',
  put_api_admin_objective_objective_all: 'putTargetGroupScoring',
  put_api_admin_price_price_all: 'putApiAdminPriceAll',
  patch_api_admin_campaign_strategyPresentation: 'patchApiAdminCampaignStrategyPresentation',
  'patch_api_admin_line-item_strategyPresentation': 'patchApiAdminLineItemStrategyPresentation',
  'put_api_admin_line-item-section_campaign': 'putApiAdminLineItemSectionCampaign',
  'put_api_admin_chat-gpt_assistant-thread_campaign': 'putApiAdminChatGptAssistantThreadCampaign',
};

export const errorResponses: {[key: string]: string} = {
  postsignin: 'postloginError',
  postregister: 'postregisterError',
  'postemail-error': 'postEmailError',
};
