import React, {useEffect} from 'react';
import * as yup from 'yup';
import {useNavigate, useParams} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {regionApi} from '../../../../api';
import {IRegion} from '../interfaces/IRegion';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import RegionForm from './RegionForm';
import {useTranslation} from 'react-i18next';

export const regionFormSchema = yup
  .object({
    name: yup.string().required('No name provided.').min(3, 'Name is too short - should be 3 chars minimum.'),
    country: yup.array().required('No country provided').min(1, 'No country provided'),
  })
  .required();
const RegionUpdate = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();
  const methods = useForm<IRegion>({
    resolver: yupResolver(regionFormSchema),
  });

  const onFormSubmit = async (formData: IRegion) => {
    const {data} = await regionApi.updateRegion(Number(id), formData);
    data && navigate(-1);
  };

  useEffect(() => {
    if (id) {
      const getRegion = async (regionId: number) => {
        const {data} = await regionApi.getRegion(+regionId);
        methods?.reset(data);
      };
      getRegion(+id);
    }
  }, [id, methods]);
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('administrator.regions.regionUpdate')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate('/app/admin/regions');
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <RegionForm
                defaultState={methods?.formState?.defaultValues as IRegion}
                register={methods?.register}
                errors={methods?.formState?.errors}
              />
              <Row>
                <Col>
                  <Row>
                    <Col className={'text-end'}>
                      <Button color="primary">
                        <Icon name="plus-sm" />
                        {t('general.update')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default RegionUpdate;
