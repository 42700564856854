import React, {SetStateAction, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'reactstrap';
import Icon from '../../../../../components/icon/Icon';
import {CustomPopover} from '../../../../../components/popover/Popover';
import {formatNumber, formatPrice} from '../../../../../shared/Functions';
import ImageHandler from '../../../../../shared/ImageHandler';
import {IPicture} from '../../../../../shared/interfaces/Picture';
import {IChannelDeviceFormatBuild} from '../../../../administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import {IQuality} from '../../../../administrator/quality/interfaces/IQuality';
import {IListSubmitData} from '../CampaignStep3';
import {channelsApi} from '../../../../../api';

interface Props {
  channels: IChannelDeviceFormatBuild[];
  selectedLineItem: IChannelDeviceFormatBuild | undefined;
  hasData: boolean;
  selectedPrice: string;
  selectedTargetGroup: number;
  handleSelectChannel: (channelId: number) => void;
  setCurrStep: React.Dispatch<SetStateAction<number>>;
  setListSubmitData: React.Dispatch<SetStateAction<IListSubmitData>>;
  selectedCountry: string;
  setIsCountryValid: React.Dispatch<SetStateAction<undefined | boolean>>;
  methods: any;
}

const CampaignStepThreeTable = ({
  channels,
  selectedPrice,
  selectedTargetGroup,
  selectedLineItem,
  hasData,
  setListSubmitData,
  setCurrStep,
  handleSelectChannel,
  selectedCountry,
  setIsCountryValid,
  methods,
}: Props) => {
  const {t} = useTranslation();

  const [localChannels, setLocalChannels] = useState(channels);
  const sortHandler = (sortBy: 'price' | 'score') => {
    const copy = [...localChannels];
    const dataToCompare = JSON.stringify(copy?.sort((a, b) => Number(b[sortBy]) - Number(a[sortBy])));
    const isAsc = dataToCompare === JSON.stringify(localChannels);
    const sortedArr = !isAsc ? copy?.sort((a, b) => Number(b[sortBy]) - Number(a[sortBy])) : copy.reverse();
    setLocalChannels(sortedArr);
  };

  const searchHandler = (value: string, key: 'channel' | 'format') => {
    setLocalChannels(channels.filter((channel) => channel[key].name.toLowerCase().includes(value.toLowerCase())));
  };
  const switchHandler = async (event: React.ChangeEvent<HTMLInputElement>, channel: IChannelDeviceFormatBuild) => {
    await channelsApi
      .updateTargeting({
        targeting: event?.target?.checked,
        channel: channel.channel.id,
        format: channel.format.id,
        price: selectedPrice,
        targetGroup: selectedTargetGroup,
        country: selectedCountry,
      })
      .then((response) => {
        const updated = localChannels.map((localItem) => {
          if (localItem.id === channel.id) {
            return {
              ...localItem,
              price: response.data.price ?? 0,
            };
          }
          return {...localItem};
        }) as IChannelDeviceFormatBuild[];
        setLocalChannels(updated);
      });
  };
  useEffect(() => {
    if (channels) {
      setLocalChannels(channels?.sort((a, b) => +b.score - +a.score));
    }
  }, [channels]);

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <input
          type="text"
          onChange={(e) => searchHandler(e.target.value, 'channel')}
          placeholder="Search by channel"
          className="form-control"
        />

        <input
          type="text"
          onChange={(e) => searchHandler(e.target.value, 'format')}
          placeholder="Search by format"
          className="form-control ms-1"
        />
      </div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.channel')}</th>
              <th scope="col">{t('general.format')}</th>
              <th scope="col">{t('general.quality')}</th>
              <th scope="col" className="text-center">
                {t('general.threshold')}
              </th>
              <th scope="col" className="text-center">
                {t('general.targeting')}
              </th>
              <th scope="col" className="cursor-pointer text-center" onClick={() => sortHandler('score')}>
                {t('general.score')}
                <Icon name="swap-v" className="ms-1" />
              </th>
              <th scope="col" className="cursor-pointer text-center" onClick={() => sortHandler('price')}>
                {t('general.price')}
                <Icon name="swap-v" className="ms-1" />
              </th>
              <th scope="col" className="text-end"></th>
            </tr>
          </thead>
          <tbody>
            {hasData &&
              localChannels?.map((channel, index) => {
                return (
                  <tr
                    onClick={() => handleSelectChannel(channel?.id)}
                    className={`${channel?.id === selectedLineItem?.id ? 'bg-muted-light' : ''} cursor-pointer`}
                    key={channel?.id}
                  >
                    <td>
                      <span className="d-flex align-items-center justify-content-start">
                        <CustomPopover
                          id={index}
                          classes="p-0"
                          title={<Icon name="book" />}
                          item={{
                            description: `${channel?.channel?.name}`,
                            placement: 'top',
                            text: (
                              <div className="d-flex flex-column ">
                                {channel?.channel?.picture && (
                                  <ImageHandler path={(channel?.channel?.picture as IPicture)?.path} className="img-cover  popover-image" />
                                )}
                                <div>{channel?.channel?.description ?? ''}</div>
                              </div>
                            ),
                          }}
                        />
                        {channel?.channel?.name ?? '-'}
                      </span>
                    </td>
                    <td>
                      <span className="d-flex align-items-center justify-content-start">
                        <CustomPopover
                          id={(index + 1) * -1}
                          classes="p-0"
                          title={<Icon name="book" />}
                          item={{
                            placement: 'top',
                            description: `${channel?.format?.name}`,
                            text: (
                              <>
                                <div className="d-flex flex-column">
                                  {channel?.format?.picture && (
                                    <ImageHandler path={(channel?.format?.picture as IPicture)?.path} className="img-cover popover-image" />
                                  )}
                                  <div>{channel?.format?.description ?? ''}</div>
                                </div>
                              </>
                            ),
                          }}
                        />
                        {channel?.format?.name ?? '-'}
                      </span>
                    </td>
                    <td>{(channel?.channel?.quality as unknown as IQuality)?.name ?? '-'}</td>
                    <td className="text-center">{formatPrice(channel?.channel?.thresholdInvestment ?? 0, 0)}</td>
                    <td className="text-center">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          key={channel?.id}
                          className="custom-control-input"
                          id={`customSwitch-${channel?.id}`}
                          defaultChecked={channel?.channel?.targeting}
                          disabled={!channel?.channel?.targeting}
                          onChange={(e) => switchHandler(e, channel)}
                        />
                        <label className="custom-control-label" htmlFor={`customSwitch-${channel?.id}`}></label>
                      </div>
                    </td>
                    <td className="text-center">{formatNumber(channel?.score, 0)}</td>
                    <td className="text-center">{formatPrice(Number(channel?.price), 0)}</td>
                    <td className="text-end">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          setIsCountryValid(!!methods.getValues().country);
                          if (!methods.getValues().country) {
                            return;
                          }
                          setCurrStep((prev) => prev + 1);
                          setListSubmitData((prev) => ({
                            ...prev,
                            calculatePrice: channel?.price,
                            calculateScore: channel?.score,
                            channelId: channel?.channel?.id,
                            priceValue: channel?.channel?.thresholdInvestment,
                            formatId: channel?.format?.id,
                            targeting: channel?.channel?.targeting,
                          }));
                        }}
                      >
                        <Icon name="plus-sm" />
                        {t('general.add')}
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CampaignStepThreeTable;
