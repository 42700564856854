import {Route, Routes} from 'react-router-dom';
import React from 'react';
import NotFound from '../../components/error/NotFound';
import {PrivateRoute} from '../../routes/RouteGuards';
import MyCompany from './my-company';
import Brands from './brands';
import BrandCreate from './brands/components/BrandCreate';
import BrandUpdate from './brands/components/BrandUpdate';
import Products from './products';
import ProductCreate from './products/components/ProductCreate';
import ProductUpdate from './products/components/ProductUpdate';
import Users from './users';
import UserCreate from './users/components/UserCreate';
import UserUpdate from './users/components/UserUpdate';

export function ManagementRoutes() {
  return (
    <Routes>
      <Route path="/my-company" element={<PrivateRoute component={MyCompany} />} />
      <Route path="/brands" element={<PrivateRoute component={Brands} />} />
      <Route path="/brands/create" element={<PrivateRoute component={BrandCreate} />} />
      <Route path="/brands/update/:id" element={<PrivateRoute component={BrandUpdate} />} />
      <Route path="/users" element={<PrivateRoute component={Users} />} />
      <Route path="/users/create" element={<PrivateRoute component={UserCreate} />} />
      <Route path="/users/update/:id" element={<PrivateRoute component={UserUpdate} />} />
      <Route path="/products" element={<PrivateRoute component={Products} />} />
      <Route path="/products/create" element={<PrivateRoute component={ProductCreate} />} />
      <Route path="/products/update/:id" element={<PrivateRoute component={ProductUpdate} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
