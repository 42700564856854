import {
  IChannelDeviceFormat,
  IChannelDeviceFormatSend,
} from '../../../app/administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import BaseApi from '../../base.api';

const CHANNEL_DEVICE_FORMAT_ROUTE = '/api/admin/channel/device-format';

export class ChannelDeviceFormatApi extends BaseApi {
  public async getChannelDeviceFormat(channelId: string): Promise<{data: {data: IChannelDeviceFormat[]}}> {
    return await this.get(`${CHANNEL_DEVICE_FORMAT_ROUTE}/${channelId}`);
  }

  public async updateChannelDeviceFormat(data: IChannelDeviceFormatSend[], channelId: string): Promise<{data: any}> {
    return await this.put(`${CHANNEL_DEVICE_FORMAT_ROUTE}/${channelId}`, {channelFormatDeviceList: data}, '');
  }
}
