import {AiSettingsApi} from './app/administrator/ai-settings.api';
import {ChannelDeviceFormatApi} from './app/administrator/channel-device-format.api';
import {ChannelPriceApi} from './app/administrator/channel-price.api';
import {ChannelUserApi} from './app/administrator/channel-user.api';
import {ChannelsApi} from './app/administrator/channels.api';
import {CommentsApi} from './app/administrator/comments.api';
import {CompanyApi} from './app/administrator/company.api';
import {CreativeApi} from './app/administrator/creative.api';
import {DevicePriceApi} from './app/administrator/device-price.api';
import {DeviceApi} from './app/administrator/devices.api';
import {FormatApi} from './app/administrator/format.api';
import {ObjectiveApi} from './app/administrator/objective.api';
import {PromptApi} from './app/administrator/prompt.api';
import {QualityObjectiveApi} from './app/administrator/quality-objective.api';
import {QualityPriceApi} from './app/administrator/quality-price.api';
import {QualityApi} from './app/administrator/quality.api';
import {RegionApi} from './app/administrator/region.api';
import {TargetGroupsApi} from './app/administrator/target-groups.api';
import {TypeApi} from './app/administrator/type.api';
import {UsersApi} from './app/administrator/users.api';
import {CampaignApi} from './app/campaigns/campaign.api';
import {InvoiceApi} from './app/campaigns/invoice/invoice.api';
import {LineItemApi} from './app/campaigns/line-item.api';
import {EnumApi} from './app/enum.api';
import {BrandApi} from './app/management/brand.api';
import {ProductApi} from './app/management/product.api';
import {UsersManagementApi} from './app/management/users.api';
import {NotificationApi} from './app/notification/notification.api';
import {ReportingApi} from './app/reporting/reporting.api';
import {AuthApi} from './authentification/auth.api';
import BaseApi from './base.api';

export const authApi = new AuthApi();
export const baseApi = new BaseApi();
export const notificationApi = new NotificationApi();
export const userApi = new UsersApi();
export const brandApi = new BrandApi();
export const companyApi = new CompanyApi();
export const campaignApi = new CampaignApi();
export const invoiceApi = new InvoiceApi();
export const qualityApi = new QualityApi();
export const qualityObjectiveApi = new QualityObjectiveApi();
export const qualityPriceApi = new QualityPriceApi();
export const enumApi = new EnumApi();
export const typeApi = new TypeApi();
export const promptApi = new PromptApi();
export const formatApi = new FormatApi();
export const objectiveApi = new ObjectiveApi();
export const channelsApi = new ChannelsApi();
export const channelsPriceApi = new ChannelPriceApi();
export const channelDeviceFormat = new ChannelDeviceFormatApi();
export const productApi = new ProductApi();
export const aiSettingsApi = new AiSettingsApi();
export const usersManagementApi = new UsersManagementApi();
export const targetGroupsApi = new TargetGroupsApi();
export const lineItemApi = new LineItemApi();
export const deviceApi = new DeviceApi();
export const devicesPriceApi = new DevicePriceApi();
export const channelUserApi = new ChannelUserApi();
export const regionApi = new RegionApi();
export const reportingApi = new ReportingApi();
export const creativeApi = new CreativeApi();
export const commentsApi = new CommentsApi();
