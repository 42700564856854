import React from 'react';

interface IImageHandlerProps {
  path: string;
  className: string;
  errorPath?: string;
}

const ImageHandler = ({
  path,
  className,
  errorPath = 'https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg',
}: IImageHandlerProps) => {
  return (
    <img
      src={path ? path : errorPath}
      className={className}
      alt={path}
      onError={(e: any) => (e.target.onerror = null) && (e.target.src = errorPath)}
    />
  );
};

export default ImageHandler;
