import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import _debounce from 'lodash/debounce';

export const usePagination = () => {
  const navigate = useNavigate();
  const {search} = useLocation();
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: +localStorage.getItem('perPage')! || 10,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePages = (updatePage: number) => {
    navigate(`?page=${updatePage}`);
    setPagination({...pagination, page: updatePage});
    setPage(updatePage);
  };

  const changeFilterHandler = _debounce((event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target;
    localStorage.setItem('perPage', value);
    setPagination((prev) => ({...prev, page: 1, [name]: value}));
  }, 200);
  useEffect(() => {
    const searchPage = Number(search.split('=')[1]) || 1;
    setPage(searchPage);
    setPagination((prev) => ({...prev, page: searchPage}));
  }, [search]);
  return {
    pagination,
    page,
    totalPages,
    setTotalPages,
    handlePages,
    changeFilterHandler,
  };
};
