import {IUserPagination} from '../../../app/administrator/users/interfaces/IUser';
import {IUserData} from '../../../shared/interfaces/User';
import BaseApi from '../../base.api';

const USERS_ROUTE = '/api/admin/user';

export class UsersApi extends BaseApi {
  public async getAllUsers(pagination: IUserPagination): Promise<any> {
    return await this.get(USERS_ROUTE, undefined, pagination);
  }

  public async createUser(data: FormData): Promise<any> {
    return await this.post(USERS_ROUTE, data, '', true);
  }

  public async getUser(id: number): Promise<{data: IUserData}> {
    return await this.get(`${USERS_ROUTE}/${id}`);
  }

  public async deleteUser(id: number): Promise<any> {
    return await this.delete(`${USERS_ROUTE}/${id}`, '');
  }

  public async updateUser(id: number, data: FormData): Promise<{data: IUserData}> {
    return await this.put(`${USERS_ROUTE}/${id}`, data, '', true);
  }
}
