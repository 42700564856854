import Lottie from 'lottie-react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Row} from 'reactstrap';
import {campaignApi} from '../../api';
import {BlockBetween, BlockHead, BlockHeadContent} from '../../components/block/Block';
import Content from '../../layout/content/Content';
import dashboardIcon from '../../shared/icons/dashboard.json';
import DashboardCard from './components/DashboardCard';
import DashboardHeader from './components/DashboardHeader';
import {IDashboardCampaign} from '../../api/app/campaigns/campaign.api';
import DashboardFilters from './components/DashboardFilters';

export interface IDashboardFilters {
  country?: string;
  brand?: number;
  product?: number;
}

const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboardFilters, setDashboardFilters] = useState<IDashboardFilters>({});
  let euro = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  const [campaignList2, setCampaignList2] = useState<IDashboardCampaign[]>([]);

  useEffect(() => {
    campaignApi.getDashboardCampaignData(dashboardFilters).then((res) => {
      const {data} = res;
      setCampaignList2(data);
    });
  }, [dashboardFilters]);

  return (
    <Content size="lg">
      <BlockHead>
        <BlockBetween className="g-3 w-100">
          <BlockHeadContent className="w-100">
            <Row className="w-100">
              <DashboardHeader />
              {/* (isApprover || isAdmin)  =>  */}
              {!!campaignList2?.length ? (
                <div className="mb-3">
                  {/* <h5>{t('dashboard.campaignsToApprove')}</h5> */}
                  <div className="row g-2">
                    <DashboardFilters setDashboardFilters={setDashboardFilters} dashboardFilters={dashboardFilters} />
                    {campaignList2.map((campaign) => {
                      return (
                        <div className="" key={`${campaign.brand}-${campaign.id}`}>
                          <div className="row">
                            <h4 className="nk-block-title ">{campaign.brand}</h4>
                            <div className="d-flex align-items-center gap-5">
                              <h6 className="title m-0 p-0 text-muted fs-6">
                                <b>{campaign.count}</b> Campaigns
                              </h6>
                              <h6 className="title m-0 p-0 text-muted fs-6">Total budget: {euro.format(campaign.totalBudget)}</h6>
                              <h6 className="title m-0 p-0 text-muted fs-6">Ad impressions: {Math.round(campaign.totalViews)}</h6>
                              <h6 className="title m-0 p-0 text-muted fs-6">Clicks: {Math.round(campaign.totalClicks)}</h6>
                            </div>
                          </div>
                          <div className="row ">
                            {campaign?.campaigns?.map((camp) => (
                              <DashboardCard key={camp.name} campaign={camp} euro={euro} />
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="text-center my-3">
                  <div className="center-items-flex">
                    <Lottie animationData={dashboardIcon} loop={true} style={{width: 240}} />
                  </div>
                  <h4>Create your first campaign!</h4>
                  <p className="text-muted">
                    In the modern design world, Lorem Ipsum is the industry standard when placing dummy text onto an
                  </p>
                  <a className="btn btn-primary" href="#1" onClick={() => navigate('/app/campaigns/create')}>
                    + Create campaign
                  </a>
                </div>
              )}
            </Row>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
    </Content>
  );
};

export default Dashboard;
