import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Card} from 'reactstrap';
import {channelUserApi} from '../../../api';
import {Pagination} from '../../../components/pagination/Pagination';
import {usePagination} from '../../../shared/hooks/usePagination';
import ChannelTable from '../../administrator/channels/components/ChannelTable';
import {IChannel} from '../../administrator/channels/interfaces/IChannel';

const ChannelUserDashboard = () => {
  const {t} = useTranslation();
  const {page, pagination, changeFilterHandler, setTotalPages, totalPages, handlePages} = usePagination();
  const [channelList, setChannelList] = useState<IChannel[]>([]);
  useEffect(() => {
    const getAllChannels = async () => {
      const {data} = await channelUserApi.getChannelUsers(pagination);
      setTotalPages(Math.ceil(data?.count / data?.perPage));
      setChannelList(data?.data?.map((channel) => ({...channel.channel})));
    };
    getAllChannels();
  }, [pagination, setTotalPages]);
  return (
    <div className="mt-5">
      <div className="w-100 d-flex justify-content-between">
        <h4 className="mb-0">{t('dashboard.myCampaigns')}</h4>
        <div className="form-control-wrap w-25">
          <input
            type="text"
            onChange={changeFilterHandler}
            name="name"
            className="form-control"
            placeholder={t('general.searchByName') as string}
          />
        </div>
      </div>
      <Card className="card-bordered card-preview mt-2">
        <ChannelTable
          channelList={channelList}
          navigateTo="/app/channel-user/update"
          setChannelList={setChannelList}
          isMainChannel={false}
        />
      </Card>
      <div className="d-flex justify-content-end">
        {totalPages <= 1 ? '' : <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />}
      </div>
    </div>
  );
};

export default ChannelUserDashboard;
