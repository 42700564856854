import React, {ChangeEvent, SetStateAction, useEffect, useRef, useState} from 'react';
import {Controller, FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {IChannel} from '../interfaces/IChannel';
import {useTranslation} from 'react-i18next';
import {Col, FormGroup, Row} from 'reactstrap';
import Select, {SingleValue} from 'react-select';
import {adjustValues, ISelect2, themeSelect} from '../../../../shared/Select2';
import {IEvent} from '../../../../shared/interfaces/Event';
import {usePagination} from '../../../../shared/hooks/usePagination';
import {qualityApi} from '../../../../api';
import {IQuality} from '../../quality/interfaces/IQuality';
import {useDispatch, useSelector} from 'react-redux';
import {commonSlice, TypeEnum} from '../../../../store/slices/common.slice';
import {RootState} from '../../../../store';
import FileInput from '../../../../shared/FileInput';
import {IPicture} from '../../../../shared/interfaces/Picture';

interface IChannelFormProps {
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
      allCountry: boolean;
      quality: string;
      country: string[];
      description: string;
      percentageTargeting: string;
      status: boolean;
      format: string;
      thresholdInvestment: number;
      channelCheckPrice: string[];
    }>
  >;
  register: UseFormRegister<IChannel>;
  defaultState: IChannel;
  setPassState: React.Dispatch<SetStateAction<boolean>>;
  passState: boolean;
  isTargeting: boolean;
}

const ChannelForm = ({defaultState, errors, register, isTargeting}: IChannelFormProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [qualityList, setQualityList] = useState<IQuality[]>([]);
  const [selectedQuality, setSelectedQuality] = useState<SingleValue<{label: string | number; value: number}>>();
  const [selectedCountries, setSelectedCountries] = useState<{label: string; value: string | number}[]>();
  const [isCheckedCountry, setIsCheckedCountry] = useState(false);
  const {changeFilterHandler: qualityFilterHandler, pagination: qualityPagination} = usePagination();
  const defaultQuality = useRef<SingleValue<{label: string | number; value: number}>>();

  const {price, country} = useSelector((state: RootState) => state.enum);

  defaultQuality.current = adjustValues(qualityList as unknown as ISelect2[], 'name')?.find(
    (quality) => quality?.value === defaultState?.quality
  );

  useEffect(() => {
    async function getAllQualities() {
      try {
        const {data} = await qualityApi.getAllQualities({
          ...qualityPagination,
          perPage: 10,
        });
        setQualityList(data.data);
      } catch (err) {
        dispatch(
          commonSlice.actions.setMessage({
            type: TypeEnum.error,
            text: 'Error',
          })
        );
      }
    }

    getAllQualities();
  }, [qualityPagination, dispatch]);

  useEffect(() => {
    if (!!defaultState?.quality) {
      setSelectedQuality(defaultQuality?.current);
    }
  }, [defaultState?.country]);
  useEffect(() => {
    if (!!defaultState?.country) {
      setSelectedCountries(defaultState.country.map((item) => ({label: t(`general.countryList.${item}`) as string, value: item})));
    }
  }, [defaultState?.country]);

  useEffect(() => {
    if (!!defaultState?.allCountry) {
      setIsCheckedCountry(defaultState.allCountry);
    }
  }, [defaultState?.allCountry]);
  return (
    <Row>
      <Col md={4} className="d-flex align-items-center justify-content-center flex-column">
        {
          <>
            {!!defaultState?.picture ? (
              <img src={(defaultState?.picture as IPicture)?.path} className="h-max-375px mb-4" alt="Format logo" />
            ) : (
              <p>{t('general.channelNoLogo')}</p>
            )}
          </>
        }
        <FileInput accept="image/*" isMultiple={false} name="picture" label="File Upload" />
      </Col>
      <Col md={8}>
        <Row className="align-items-center">
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="name">
                {t('general.name')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-type"
                  placeholder={t('administrator.channels.enterChannel') as string}
                  {...register('name')}
                  className="form-control-lg form-control"
                />
                {errors?.name && <p className="invalid">{errors?.name?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="quality">
                {t('general.quality')}
              </label>

              <div className="form-control-wrap">
                <Controller
                  {...register('quality', {
                    valueAsNumber: true,
                  })}
                  render={({field: {onChange}}) => (
                    <Select
                      theme={themeSelect}
                      value={selectedQuality}
                      className="form-react-select"
                      placeholder={t('administrator.channels.quality')}
                      options={adjustValues(qualityList as unknown as ISelect2[], 'name')}
                      onInputChange={(value) => {
                        const event: IEvent = {
                          target: {
                            name: 'name',
                            value: value,
                          },
                        };
                        qualityFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
                      }}
                      onChange={(e) => {
                        setSelectedQuality(e);
                        onChange(e?.value);
                      }}
                    />
                  )}
                  rules={{required: true}}
                />
                {errors?.quality && <p className="invalid">{errors?.quality?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="thresholdInvestment">
                {t('general.threshold')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="number"
                  onInput={(e: any) => {
                    e.preventDefault();
                    e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                  }}
                  id="reg-type"
                  step="any"
                  placeholder={t('administrator.channels.enterThreshold') as string}
                  {...register('thresholdInvestment')}
                  className="form-control-lg form-control"
                />
                {errors?.thresholdInvestment && <p className="invalid">{errors?.thresholdInvestment?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <label className="form-label" htmlFor="channelCheckPrice">
                {t('general.prices')}
              </label>
              <div className="d-flex align-items-center">
                {price?.map((chPrice) => {
                  return (
                    <div className="custom-control custom-control-sm custom-checkbox d-flex m-1" key={chPrice} style={{zIndex: -0}}>
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        value={chPrice}
                        id={chPrice}
                        {...register('channelCheckPrice')}
                      />
                      <label className="custom-control-label" htmlFor={chPrice}>
                        {chPrice}
                      </label>
                    </div>
                  );
                })}
              </div>
              {errors?.channelCheckPrice && <p className="text-danger">{errors?.channelCheckPrice?.message}</p>}
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <label className="form-label" htmlFor="description">
                {t('general.description')}
              </label>
              <div className="form-control-wrap">
                <textarea
                  placeholder={t('general.description') ?? ''}
                  {...register('description')}
                  className="form-control-lg form-control"
                />
                {errors?.description && <p className="invalid">{errors?.description?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          {!isCheckedCountry && (
            <Col md={4}>
              <FormGroup>
                <label className="form-label" htmlFor="quality">
                  {t('general.country')}
                </label>

                <div className="form-control-wrap">
                  <Controller
                    {...register('country', {
                      valueAsNumber: true,
                    })}
                    render={({field: {onChange}}) => (
                      <Select
                        theme={themeSelect}
                        value={selectedCountries}
                        className="form-react-select"
                        isMulti={true}
                        placeholder={t('administrator.channels.country')}
                        options={country.map((item) => ({label: t(`general.countryList.${item}`) as string, value: item as any}))}
                        onChange={(e) => {
                          setSelectedCountries(e as any);
                          onChange(e.map((item) => item.value));
                        }}
                      />
                    )}
                  />
                </div>
              </FormGroup>
            </Col>
          )}
          <Col md={2}>
            <FormGroup className="mt-1">
              <label className="form-label visibility-hidden"></label>
              <div className="form-control-wrap mt-auto">
                <Controller
                  {...register('allCountry', {
                    valueAsNumber: false,
                  })}
                  render={({field: {onChange}}) => (
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        checked={isCheckedCountry}
                        onChange={(e) => {
                          let isChecked = e.target.checked;
                          setIsCheckedCountry(isChecked);
                          onChange(isChecked);
                        }}
                      />
                      <label className="custom-control-label" htmlFor="customCheck1">
                        {t('administrator.channels.allCountry')}
                      </label>
                    </div>
                  )}
                />
              </div>
            </FormGroup>
          </Col>
          <Col md={3}>
            <div className="custom-control custom-switch mt-2" style={{zIndex: -0}}>
              <input type="checkbox" className="custom-control-input" id="isTargeting" {...register('targeting')} />
              <label className="custom-control-label form-label" htmlFor="isTargeting">
                Targeting
              </label>
            </div>
            {isTargeting && (
              <div className="row pe-0 me-0  ">
                <input
                  step="any"
                  className="mt-2 form-control "
                  {...register('percentageTargeting', {valueAsNumber: true})}
                  placeholder="%"
                />
                {errors?.percentageTargeting && <p className="invalid">{errors?.percentageTargeting?.message}</p>}

                {isTargeting && <p className="col-7 mt-3 p-0">{'(example:1.2)'}</p>}
              </div>
            )}
          </Col>
          <Col md={3}>
            <div className="custom-control custom-switch mt-2" style={{zIndex: -0}}>
              <input type="checkbox" className="custom-control-input" id="estimated" {...register('estimated')} />
              <label className="custom-control-label form-label" htmlFor="estimated">
                Estimated
              </label>
            </div>
          </Col>
          <Col md={3}>
            <div className="custom-control custom-switch mt-2" style={{zIndex: -0}}>
              <input type="checkbox" className="custom-control-input" id="status" {...register('status')} defaultChecked={true} />
              <label className="custom-control-label form-label" htmlFor="status">
                Status
              </label>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ChannelForm;
