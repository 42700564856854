import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import {companyApi} from '../../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {isUserAdmin} from '../../../../shared/Functions';
import {ICompanyData} from '../../../../shared/interfaces/Company';
import {RootState} from '../../../../store';
import CompanyForm from './CompanyForm';
import {companyFormSchema} from './CompanyUpdate';

const CompanyCreate = () => {
  const {roles} = useSelector((state: RootState) => state?.auth?.user);
  const isAdmin = isUserAdmin(roles);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<ICompanyData>({
    resolver: yupResolver(companyFormSchema),
  });

  const onFormSubmit = async (state: ICompanyData) => {
    const formData = new FormData();
    !!state.file && formData.append('logo', state.file[0]);
    formData.append('body', JSON.stringify(state));
    const {data} = await companyApi.createCompany(formData);
    if (data) {
      navigate('/app/admin/company');
    }
  };
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('administrator.company.createCompany')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate('/app/admin/company');
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <CompanyForm
                register={methods?.register}
                formState={methods?.formState}
                errors={methods?.formState?.errors}
                isAdmin={isAdmin}
                isCompanyCreate={true}
              />
              <Row>
                <Col>
                  <Row>
                    <Col className={'text-end'}>
                      <Button color="primary">
                        <Icon name="plus-sm" />
                        {t('general.create')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default CompanyCreate;
