// import React from 'react';
// import {Col, Row} from 'reactstrap';
// import {Block} from '../../components/block/Block';
// import GalleryCard from './GalleryCard';

import {Col, Row} from 'reactstrap';
import {Block} from '../../../../components/block/Block';
import GalleryCard from './GalleryCard';

const GalleryCardPreview = ({
  data,
  setIsDeleteModalOpened,
  setSelectedCreativePictureId,
  setSelectedLineItemId,
  downloadImage,
  isProdutionPlan,
}: any) => {
  return (
    <Block>
      <Row className="g-gs">
        {isProdutionPlan &&
          data?.map((item: any) => {
            return (
              <Col sm={6} lg={2} key={item.id}>
                <GalleryCard
                  data={data}
                  downloadImage={downloadImage}
                  file={item}
                  fileMime={item.file.mime}
                  setIsDeleteModalOpened={setIsDeleteModalOpened}
                  setSelectedCreativePictureId={setSelectedCreativePictureId}
                  setSelectedLineItemId={setSelectedLineItemId}
                />
              </Col>
            );
          })}

        {!isProdutionPlan &&
          data.creativePicture.map((item: any) => {
            return (
              <Col sm={6} lg={3} key={item.id}>
                <GalleryCard
                  data={data}
                  downloadImage={downloadImage}
                  file={item}
                  fileMime={item.file.mime}
                  setIsDeleteModalOpened={setIsDeleteModalOpened}
                  setSelectedCreativePictureId={setSelectedCreativePictureId}
                  setSelectedLineItemId={setSelectedLineItemId}
                />
              </Col>
            );
          })}
      </Row>
    </Block>
  );
};

export default GalleryCardPreview;
