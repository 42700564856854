import PageContainer from '../../layout/page-container/PageContainer';
import {Block, BlockContent, BlockDes, BlockHead, BlockTitle} from '../../components/block/Block';
import React from 'react';
import {Link} from 'react-router-dom';
import {PreviewCard} from '../../components/preview/Preview';
import LoginSlider from './components/LoginSlider';
import LoginForm from './components/LoginForm';

const Login = () => {
  return (
    <div className="row min-h-100vh">
      <div className="col-md-6">
        <PageContainer>
          <Block className="nk-block-middle nk-auth-body  wide-xs w-100">
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">Sign-In</BlockTitle>
                  <BlockDes>
                    <p>Access Admadjic using your email and password.</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
              {/* ==================== Login Form ==================== */}
              <LoginForm />
              {/* ==================== Login Form ==================== */}
              <div className="form-note-s2 text-center pt-4">
                New on our platform? <Link to="/auth/register">Create an account</Link>
              </div>
            </PreviewCard>
          </Block>
        </PageContainer>
      </div>
      <div className="col-md-6 bg-white d-flex align-items-center justify-content-center p-0">
        <LoginSlider />
      </div>
    </div>
  );
};

export default Login;
