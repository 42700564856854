import Lottie from 'lottie-react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {invoiceApi} from '../../../../api';
import {Block} from '../../../../components/block/Block';
import noFilesIcon from '../../../../shared/icons/noFilesIcon.json';
import {IPicture} from '../../../../shared/interfaces/Picture';

import ChangeStep from './ChangeStep';
import {IStepperProps} from './index';
import InvoiceFileTable from '../../../invoice/components/InvoiceFileTable';

const CampaignStep7 = ({campaignData}: IStepperProps) => {
  const {t} = useTranslation();
  const [fileList, setFileList] = useState<IPicture[]>([]);
  const getInvoice = async (campaignId: string) => {
    const {
      data: {invoices: data},
    } = await invoiceApi.getInvoice(campaignId);
    setFileList(data);
  };

  useEffect(() => {
    if (!!campaignData?.id) {
      getInvoice(String(campaignData?.id));
    }
  }, [campaignData?.id]);
  return (
    <Block size="lg" className="mt-3">
      <ChangeStep
        currentCampaignStep={campaignData?.currentStep}
        status={campaignData?.status}
        campaignId={campaignData?.id}
        activeStep={campaignData?.openAi ? 8 : 7}
        name={campaignData?.name}
      />
      <Card className="card-bordered card-preview">
        <CardBody>
          <Row>
            <Col md={12}>
              {!!fileList?.length ? (
                <InvoiceFileTable fileList={fileList} />
              ) : (
                <div className="my-5 center-items-flex flex-column">
                  <Lottie animationData={noFilesIcon} loop={true} style={{width: 300}} />
                  <h5>No invoices</h5>
                  <p className="text-muted">{t('campaigns.noInvoicesDesc')}</p>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Block>
  );
};

export default CampaignStep7;
