import {useState} from 'react';
import {Tooltip} from 'reactstrap';

export const TooltipItem = (props: any) => {
  const {item, id, title, classes, titleClasses} = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <li className={`preview-item ${classes}`}>
      <div id={'Tooltip-' + id} className={titleClasses}>
        {title}
      </div>
      <Tooltip placement={item?.placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        <div className="text-start w-100">{item?.text}</div>
      </Tooltip>
    </li>
  );
};
