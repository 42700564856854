import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Card, CardBody} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {ICampaign} from '../../../campaigns/interfaces/ICampaigns';
import CampaignDetails from './components/details/CampaignDetails';
import {campaignApi} from '../../../../api';
import Icon from '../../../../components/icon/Icon';

const Detail = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const navigate = useNavigate();
  const [campaignDetail, setCampaignDetail] = useState<ICampaign>();
  const [modalTab, setModalTab] = useState('1');

  useEffect(() => {
    id &&
      campaignApi.getCampaign(Number(id)).then((response) => {
        setCampaignDetail(response.data);
      });
  }, [id]);

  return (
    <div className="row my-3">
      <div className="col-12">
        <Card className="card-bordered card-preview mt-2">
          <CardBody>
            <div className="d-flex justify-content-end ">
              <Button
                color="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
            <h5 className="title">
              {t('general.campaignDetails')} {campaignDetail?.name}
            </h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === '1' && 'active'}`}
                  onClick={() => {
                    setModalTab('1');
                  }}
                  href="#details"
                >
                  {t('requests.detail.details')}
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className={`tab-pane ${modalTab === '1' ? 'active' : ''}`} id="details">
                {campaignDetail && (
                  <CampaignDetails
                    detail={campaignDetail as ICampaign}
                    setCampaignDetail={setCampaignDetail as React.Dispatch<SetStateAction<ICampaign>>}
                  />
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Detail;
