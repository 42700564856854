import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Card} from 'reactstrap';
import {userApi} from '../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import {Pagination} from '../../../components/pagination/Pagination';
import {usePagination} from '../../../shared/hooks/usePagination';
import UserFilters from '../users/components/UserFilters';
import UserTable from '../users/components/UserTable';
import {IUser} from '../users/interfaces/IUser';

const ChannelUser = () => {
  const {t} = useTranslation();
  const [userList, setUserList] = useState<IUser[]>([]);
  const {pagination, setTotalPages, page, totalPages, handlePages, changeFilterHandler} = usePagination();
  useEffect(() => {
    userApi
      .getAllUsers({
        ...pagination,
        role: 'ROLE_CHANNEL_USER',
      })
      .then((response) => {
        const {data} = response;
        setUserList(data.data);
        setTotalPages(Math.ceil(data?.count / data?.perPage));
      });
  }, [pagination, setTotalPages]);
  return (
    <>
      <Block size="lg" className="mt-3">
        <BlockHead>
          <BlockHeadContent>
            <div className="row align-items-center gx-0 gx-lg-2">
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-12">
                <BlockTitle tag="h4">{t('administrator.users.userList')}</BlockTitle>
              </div>
            </div>
            <UserFilters changeFilterHandler={changeFilterHandler} pagination={pagination} />
          </BlockHeadContent>
        </BlockHead>
        <Card className="card-bordered card-preview">
          <UserTable userList={userList} setUserList={setUserList} navigateRoute="/app/admin/channel-users/assign/" />
        </Card>
      </Block>

      <div className="d-flex justify-content-end">
        {totalPages <= 1 ? '' : <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />}
      </div>
    </>
  );
};

export default ChannelUser;
