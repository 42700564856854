import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import {brandApi} from '../../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {IBrand} from '../interfaces/IBrand';
import {brandFormSchema} from './BrandCreate';
import BrandForm from './BrandForm';

const BrandUpdate = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();
  const methods = useForm<IBrand>({
    resolver: yupResolver(brandFormSchema),
  });

  const onFormSubmit = async (formData: IBrand) => {
    const {data} = await brandApi.updateBrand(Number(id), formData);
    data && navigate('/app/management/brands');
  };

  useEffect(() => {
    if (id) {
      const getBrand = async (brandId: number) => {
        const {data} = await brandApi.getBrand(+brandId);
        methods?.reset(data);
      };
      getBrand(+id);
    }
  }, [id, methods]);
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('management.brands.brandUpdate')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate('/app/management/brands');
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <BrandForm register={methods?.register} errors={methods?.formState?.errors} />
              <Row>
                <Col>
                  <Row>
                    <Col className={'text-end'}>
                      <Button color="primary">
                        <Icon name="plus-sm" />
                        {t('general.update')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default BrandUpdate;
