import {ICampaignPagination} from '../../../../app/campaigns/interfaces/ICampaigns';
import {IPicture} from '../../../../shared/interfaces/Picture';
import BaseApi from '../../../base.api';

const INVOICE_ROUTE = '/api/admin/invoice';

export class InvoiceApi extends BaseApi {
  public async getAllInvoices(pagination: ICampaignPagination, isOrders = false): Promise<{data: any}> {
    return await this.get(`${INVOICE_ROUTE}/allCampaign`, undefined, pagination);
  }

  public async getInvoice(campaignId: string): Promise<any> {
    return await this.get(`${INVOICE_ROUTE}/${campaignId}`, undefined, {campaignId});
  }

  public async deleteInvoice(campaignId: string, reportId: number): Promise<{data: {invoices: IPicture[]}}> {
    return await this.delete(`${INVOICE_ROUTE}/${campaignId}/${reportId}`, '');
  }

  public async postInvoice(data: FormData, campaignId: string): Promise<any> {
    return await this.post(`${INVOICE_ROUTE}/${campaignId}`, data, undefined, true);
  }
}
