import {capitalize} from 'lodash';
import React, {SetStateAction, useEffect, useState} from 'react';
import {Controller, FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {FormGroup} from 'reactstrap';
import {deviceApi, qualityApi, targetGroupsApi, typeApi} from '../../../../../api';
import {adjustValues, ISelect2, themeSelect} from '../../../../../shared/Select2';
import {RootState} from '../../../../../store';
import {IDevice} from '../../../../administrator/devices/interfaces/IDevice';
import {IQuality} from '../../../../administrator/quality/interfaces/IQuality';
import {ITargetGroups} from '../../../../administrator/target-groups/interfaces/ITargetGroups';
import {IType} from '../../../../administrator/type/interfaces/IType';
import {ICampaignStep2} from '../../../interfaces/ICampaigns';
import {useParams} from 'react-router-dom';

interface Props {
  register: UseFormRegister<ICampaignStep2>;
  countries?: string[];
  selectedCountry?: string;
  setSelectedCountry?: React.Dispatch<SetStateAction<string>>;
  setIsCountryValid?: React.Dispatch<SetStateAction<undefined | boolean>>;
  isCountryValid?: undefined | boolean;
  methods?: any;
  errors: Partial<
    FieldErrorsImpl<{
      quality: number[];
      type: number[];
      objective: string;
      device: number[];
      targetGroup: string;
      price: string;
      country: string;
    }>
  >;
}

const CampaignStepThreeDropdowns = ({register, errors, countries, setSelectedCountry, setIsCountryValid, isCountryValid}: Props) => {
  const {stepId} = useParams();
  const {t} = useTranslation();
  const [qualityList, setQualityList] = useState<IQuality[]>([]);
  const [typeList, setTypeList] = useState<IType[]>([]);
  const [deviceList, setDeviceList] = useState<IDevice[]>([]);
  const [targetGroupList, setTargetGroupList] = useState<ITargetGroups[]>([]);
  let mappedCountries = countries!?.map((item) => ({label: t(`general.countryList.${item}`) as string, value: item as any}));
  const {objective, price} = useSelector((state: RootState) => state.enum);

  useEffect(() => {
    const fetchAllLists = async () => {
      const [qualityListRes, targetGroupListRes, deviceListRes, typeListRes] = await Promise.all([
        qualityApi.getAllQualities({page: 1, perPage: 0, status: true}),
        targetGroupsApi.getAllTargetGroups({page: 1, perPage: 0}),
        deviceApi.getAllDevices({page: 1, perPage: 0, status: true}),
        typeApi.getAllTypes({page: 1, perPage: 0, status: true}),
      ]);
      setQualityList(qualityListRes.data.data);
      setTargetGroupList(targetGroupListRes.data.data);
      setDeviceList(deviceListRes.data.data);
      setTypeList(typeListRes.data.data);
    };

    fetchAllLists();
  }, []);

  useEffect(() => {
    countries?.length === 1 && setSelectedCountry && setSelectedCountry(countries[0]);
  }, []);

  return (
    <>
      {stepId === '3' && (
        <div className="col-md-4">
          <FormGroup>
            <label className="form-label" htmlFor="quality">
              {t('general.country')}
            </label>

            <div className="form-control-wrap">
              <Controller
                {...register('country')}
                render={({field: {onChange, value}}) => (
                  <Select
                    theme={themeSelect}
                    value={mappedCountries
                      ?.map((obj) => ({
                        value: obj.value,
                        label: capitalize(obj.label as string),
                      }))
                      ?.filter((country) => value === country.value)}
                    className="form-react-select"
                    // isDisabled={countries?.length === 1}
                    placeholder={t('administrator.channels.country')}
                    options={mappedCountries}
                    onChange={(event) => {
                      setIsCountryValid && setIsCountryValid(true);
                      setSelectedCountry && setSelectedCountry(event?.value);
                      onChange(event?.value);
                    }}
                  />
                )}
              />
              {errors?.country ? (
                <p className="invalid">{errors?.country?.message}</p>
              ) : isCountryValid === false ? (
                <p className="invalid">No country provided</p>
              ) : (
                ''
              )}
            </div>
          </FormGroup>
        </div>
      )}

      <div className="col-md-4 ps-md-0">
        <FormGroup>
          <label htmlFor="channels" className="form-label">
            {t('general.objective')}
          </label>
          <div className="form-control-wrap">
            <Controller
              {...register('objective')}
              render={({field: {onChange, value}}) => {
                return (
                  <Select
                    theme={themeSelect}
                    value={adjustValues(objective as unknown as ISelect2[])
                      ?.map((obj) => ({
                        value: obj.value,
                        label: capitalize(obj.label as string),
                      }))
                      ?.filter((singleObjective) => value === singleObjective.value)}
                    options={adjustValues(objective as unknown as ISelect2[])?.map((obj) => ({
                      value: obj?.value,
                      label: capitalize(obj?.label as string),
                    }))}
                    className="form-react-select"
                    onChange={(event) => {
                      onChange(event?.value);
                    }}
                  />
                );
              }}
              rules={{required: true}}
            />
            {errors.objective && <p className="invalid">{errors.objective?.message}</p>}
          </div>
        </FormGroup>
      </div>

      <div className="col-md-4">
        <FormGroup>
          <label htmlFor="type" className="form-label">
            {t('general.targetGroup')}
          </label>
          <div className="form-control-wrap">
            <Controller
              {...register('targetGroup', {valueAsNumber: true})}
              render={({field: {onChange, value}}) => {
                return (
                  <Select
                    theme={themeSelect}
                    value={adjustValues(targetGroupList as unknown as ISelect2[], 'name')?.filter(
                      (targetGroup) => value === targetGroup.value
                    )}
                    placeholder={t('general.pleaseChoose')}
                    options={adjustValues(targetGroupList as unknown as ISelect2[], 'name')}
                    className="form-react-select"
                    onChange={(event) => {
                      onChange(event?.value);
                    }}
                  />
                );
              }}
              rules={{required: true}}
            />
            {errors.targetGroup && <p className="invalid">{errors.targetGroup?.message}</p>}
          </div>
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup>
          <label htmlFor="channels" className="form-label">
            {t('campaigns.price')}
          </label>
          <div className="form-control-wrap">
            <Controller
              {...register('price')}
              render={({field: {onChange, value}}) => {
                return (
                  <Select
                    theme={themeSelect}
                    value={adjustValues(price as unknown as ISelect2[])?.filter((singlePrice) => value === singlePrice.value)}
                    options={adjustValues(price as unknown as ISelect2[])}
                    className="form-react-select"
                    onChange={(event) => {
                      onChange(event?.value);
                    }}
                  />
                );
              }}
              rules={{required: true}}
            />
            {errors.price && <p className="invalid">{errors.price?.message}</p>}
          </div>
        </FormGroup>
      </div>

      <div className="col-md-4 ps-md-0">
        <FormGroup>
          <label htmlFor="quality" className="form-label">
            {t('general.quality')}
          </label>
          <div className="form-control-wrap">
            <Controller
              {...register('quality')}
              render={({field: {onChange, value}}) => {
                return (
                  <Select
                    theme={themeSelect}
                    value={adjustValues(qualityList as unknown as ISelect2[], 'name')?.filter((quality) =>
                      value?.some((item: number) => item === quality?.id)
                    )}
                    options={adjustValues(qualityList as unknown as ISelect2[], 'name')}
                    isClearable
                    isMulti
                    className="form-react-select"
                    onChange={(event) => {
                      onChange(event?.map((quality) => quality.id));
                    }}
                  />
                );
              }}
              rules={{required: true}}
            />
            {errors?.quality && <p className="invalid">{errors?.quality?.message}</p>}
          </div>
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup>
          <label htmlFor="type" className="form-label">
            {t('general.type')}
          </label>
          <div className="form-control-wrap">
            <Controller
              {...register('type')}
              render={({field: {onChange, value}}) => {
                return (
                  <Select
                    theme={themeSelect}
                    value={adjustValues(typeList as unknown as ISelect2[], 'name')?.filter((type) =>
                      value?.some((item: number) => item === type?.id)
                    )}
                    options={adjustValues(typeList as unknown as ISelect2[], 'name')}
                    isClearable
                    isMulti
                    className="form-react-select"
                    onChange={(event) => {
                      onChange(event?.map((type) => type.id));
                    }}
                  />
                );
              }}
              rules={{required: true}}
            />
            {errors?.type && <p className="invalid">{errors?.type?.message}</p>}
          </div>
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup>
          <label htmlFor="device" className="form-label">
            {t('general.devices')}
          </label>
          <div className="form-control-wrap">
            <Controller
              {...register('device')}
              render={({field: {onChange, value}}) => {
                return (
                  <Select
                    theme={themeSelect}
                    value={adjustValues(deviceList as unknown as ISelect2[], 'name')?.filter((device) =>
                      value?.some((item: number) => item === device?.id)
                    )}
                    placeholder={t('administrator.devices.deviceList')}
                    options={adjustValues(deviceList as unknown as ISelect2[], 'name')}
                    isClearable
                    isMulti
                    className="form-react-select"
                    onChange={(event) => {
                      onChange(event?.map((device) => device.id));
                    }}
                  />
                );
              }}
              rules={{required: true}}
            />
            {errors?.device && <p className="invalid">{errors?.device?.message}</p>}
          </div>
        </FormGroup>
      </div>
    </>
  );
};

export default CampaignStepThreeDropdowns;
