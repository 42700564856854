import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';
import de from '../assets/locales/de-DE.json';
import en from '../assets/locales/en-EN.json';

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  defaultNS: 'translation',
  fallbackLng: 'en',
});
