import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Card, CardBody, Form} from 'reactstrap';
import {campaignApi} from '../../../../api';
import {Block} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {IBrand} from '../../../management/brands/interfaces/IBrand';
import {IProduct} from '../../../management/products/interfaces/IProduct';
import {ICampaign} from '../../interfaces/ICampaigns';
import {campaignFormSchema} from '../CampaignCreate';
import CampaignForm from '../CampaignForm';
import ChangeStep from './ChangeStep';
import {IStepperProps} from './index';

const CampaignStep1 = ({campaignData, isCampaignEditable = false}: IStepperProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id, stepId} = useParams();
  const methods = useForm<ICampaign>({
    resolver: yupResolver(campaignFormSchema),
  });

  const onFormSubmit = async (formData: ICampaign) => {
    const fd = new FormData();
    const pictureToSend: FileList = formData.picture as FileList;
    !!formData.picture && fd.append('picture', pictureToSend[0]);
    const correctUserData = formData?.user?.map((user: any) => user?.id);
    const isUserDataEdited = correctUserData?.some((user) => !user);
    formData.user = isUserDataEdited ? formData?.user : correctUserData;
    fd.append('body', JSON.stringify(formData));

    const {data} = await campaignApi.updateStep1(Number(id), fd);
    data && navigate(`/app/campaigns/${Number(stepId) + 1}/${id}`);
  };

  // const onFormSubmit = async (state: IFormat) => {
  //   const formData = new FormData();
  //   const pictureToSend: FileList = state.picture as FileList;
  //   !!state.picture && formData.append('picture', pictureToSend[0]);
  //   formData.append('body', JSON.stringify(state));
  //   const {data} = await formatApi.createFormat(formData, token);
  //   if (data) {
  //     navigate('/app/admin/format');
  //   }
  // };

  useEffect(() => {
    if (id) {
      methods?.reset({
        ...campaignData,
        user: campaignData?.user,
        product: (campaignData?.product as unknown as IProduct)?.id,
        brand: ((campaignData?.product as unknown as IProduct)?.brand as unknown as IBrand)?.id,
      });
    }
  }, [campaignData, id, methods]);

  return (
    <Block size="lg" className="mt-3">
      <ChangeStep
        campaignId={campaignData?.id}
        status={campaignData?.status}
        currentCampaignStep={campaignData?.currentStep}
        activeStep={1}
        name={campaignData?.name}
      />
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <CampaignForm
                register={methods?.register}
                state={methods?.formState}
                setState={methods.setValue}
                errors={methods?.formState?.errors}
              />
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-end">
                    <Button
                      className="me-3"
                      color="secondary"
                      onClick={() => {
                        navigate('/app/campaigns');
                      }}
                    >
                      <Icon name="arrow-long-left" />
                      {t('general.cancel')}
                    </Button>
                    {isCampaignEditable && (
                      <Button color="primary">
                        <Icon name="edit" />
                        {t('general.update')}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default CampaignStep1;
