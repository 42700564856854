import React, {SetStateAction, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {brandApi, campaignApi, companyApi, productApi, userApi} from '../../../../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../../../components/block/Block';
import Button from '../../../../../../components/button/Button';
import Icon from '../../../../../../components/icon/Icon';
import ConfirmSuccessModal from '../../../../../../components/modal/ConfirmSuccessModal';
import UserAvatar from '../../../../../../components/user/UserAvatar';
import {useMultiInject} from '../../../../../../di';
import {getCorrectCampaignStatus, getCorrectEnums} from '../../../../../../shared/Functions';
import {IUserData} from '../../../../../../shared/interfaces/User';
import {RootState} from '../../../../../../store';
import {ICampaign} from '../../../../../campaigns/interfaces/ICampaigns';
import {IBrand} from '../../../../../management/brands/interfaces/IBrand';
import {IProduct} from '../../../../../management/products/interfaces/IProduct';
import LineItemsDetails from './LineItemsDetails';
import axios, {AxiosRequestConfig} from 'axios';
import Select from 'react-select';
import {themeSelect} from '../../../../../../shared/Select2';
import CustomModal from '../../../../../../components/modal/Modal';
import {Col, FormGroup, Row} from 'reactstrap';

interface IDetailsProps {
  detail: ICampaign;
  setCampaignDetail: React.Dispatch<SetStateAction<ICampaign>>;
}

const CampaignDetails = ({detail, setCampaignDetail}: IDetailsProps) => {
  const {t} = useTranslation();
  const {dateTime} = useMultiInject();
  const navigate = useNavigate();
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [productData, setProductData] = useState<Partial<IProduct>>({
    name: '',
    brand: 0,
  });
  const [brandName, setBrandName] = useState('');
  const [statusDetail, setStatusDetail] = useState('');
  const [exportView, setExportView] = useState('DAY');
  const [triggerBrand, setTriggerBrand] = useState(false);
  const [triggerProduct, setTriggerProduct] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalProduct, setShowModalProduct] = useState(false);
  const [selectedData, setSelectedData] = useState({
    company: undefined,
    brand: undefined,
    product: undefined,
    user: [],
  });
  const [companyList, setCompanyList] = useState([]);
  const [brandList, setBrandList] = useState<any>([]);
  const [productList, setProductList] = useState<any>([]);
  const [userList, setUserList] = useState([]);
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
  const {campaignStatus, calendarView} = useSelector((state: RootState) => state.enum);
  const {
    id,
    name,
    createdAt,
    company,
    country,
    product,
    paymentStatus,
    user,
    startDate,
    endDate,
    status,
    openAi,
    mediaPlan: hasMediaPlan,
    describeMediaPlan,
  } = detail;

  useEffect(() => {
    setStatusDetail(status);
  }, [detail]);

  const changeStatusHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target;
    setStatusDetail(value);
  };

  const updateStatusHandler = async () => {
    const {data} = await campaignApi.updateAdminCampaignStatus(id, statusDetail);
    setCampaignDetail(data);
  };
  const changeAiStatus = async () => {
    const {data} = await campaignApi.changeAiStatus(id, {openAi: !openAi});
    setCampaignDetail(data);
  };

  const updatePaymentStatusHandler = async () => {
    const {data} = await campaignApi.updatePaymentStatus(id);
    if (data) {
      navigate(-1);
    }
  };
  const downloadXLSFile = async () => {
    const headers = {
      'Content-Type': 'blob',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'app-version': localStorage.getItem('appVersion'),
    };
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `${
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_BACKEND_URL_DEVELOPMENT
          : process.env.REACT_APP_BACKEND_URL_PRODUCTION
      }/api/admin/line-item/download/file/${id}`,
      responseType: 'arraybuffer',
      headers,
      params: {view: exportView},
    };
    const response = await axios(config);

    const url = URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}-${dateTime.formatDate(new Date())}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const moveHandler = async () => {
    const {data} = await campaignApi.changeOrder(id, selectedData);
    setCampaignDetail(data);
  };

  useEffect(() => {
    async function getAllCompanies() {
      const {data} = await companyApi.getAllCompanies({perPage: 0, page: 1});
      setCompanyList(data.data);
    }

    getAllCompanies();
  }, []);

  const CustomMenuBrand = ({innerRef, innerProps, isDisabled, children}: any) => {
    return !isDisabled ? (
      <div ref={innerRef} {...innerProps} className="css-lm8j94-menu">
        {children}
        <div className="p-1 pt-0">
          <button className="btn btn-outline-primary btn-sm btn-block" onClick={() => setShowModal(true)}>
            {t('general.addNew')}
          </button>
        </div>
      </div>
    ) : null;
  };

  const CustomMenuProduct = ({innerRef, innerProps, isDisabled, children}: any) => {
    return !isDisabled ? (
      <div ref={innerRef} {...innerProps} className="css-lm8j94-menu">
        {children}
        <div className="p-1 pt-0">
          <button className="btn btn-outline-primary btn-sm btn-block" onClick={() => setShowModalProduct(true)}>
            {t('general.addNew')}
          </button>
        </div>
      </div>
    ) : null;
  };

  const copyCampaignHandler = async (selectedCampaignId: number) => {
    await campaignApi.copyCampaign(selectedCampaignId);
  };

  const addBrand = async () => {
    const {data} = await brandApi.createBrand({name: brandName, company: selectedData?.company});
    setShowModal(false);
    setTriggerBrand(!triggerBrand);
    setSelectedData((prev) => ({...prev, brand: data.data[0].id}));
  };

  const addProduct = async () => {
    const {data} = await productApi.createProduct({...productData, brand: selectedData?.brand});
    setShowModalProduct(false);
    setSelectedData((prev) => ({...prev, product: data.data[0].id}));
    setTriggerProduct(!triggerProduct);
  };

  useEffect(() => {
    async function getAllBrands() {
      const {data} = await brandApi.getAllBrands({perPage: 0, page: 1, company: selectedData.company});
      setBrandList(data.data);
    }
    getAllBrands();
  }, [selectedData.company, triggerBrand]);

  useEffect(() => {
    async function getAllProducts() {
      const {data} = await productApi.getAllProduct({perPage: 0, page: 1, brand: selectedData.brand});
      setProductList(data.data);
    }
    getAllProducts();
  }, [selectedData.brand, triggerProduct]);

  return (
    <>
      <ConfirmSuccessModal
        itemName={name}
        actionName="Change payment status of campaign"
        show={isConfirmationOpened}
        setShow={setIsConfirmationOpened}
        onSave={updatePaymentStatusHandler}
      />
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2 mt-5">
            <div className="col-md-6 col-12">
              <BlockTitle tag="h4">
                <span className="text-primary">{name}</span>
              </BlockTitle>
              {hasMediaPlan ? (
                <div>
                  <Icon name="file-text" />
                  <strong>{t('campaigns.makeMediaPlanForMe')}</strong>
                  <p>{describeMediaPlan ?? ''}</p>
                </div>
              ) : (
                <h6>{t('campaigns.turnedOffMediaPlan')}</h6>
              )}
            </div>
            <div className="col-md-6 col-12 d-flex justify-content-end align-items-start">
              <div className="form-control-wrap  mt-2 mt-lg-0">
                <select value={statusDetail} name="status" className="form-select" onChange={changeStatusHandler}>
                  {campaignStatus?.map((status) => (
                    <option value={status} key={status}>
                      {getCorrectCampaignStatus(status)}
                    </option>
                  ))}
                </select>

                <select value={exportView} name="view" className="form-select mt-2" onChange={(e) => setExportView(e.target.value)}>
                  {calendarView?.map((view) => (
                    <option value={view} key={view} className="">
                      {getCorrectEnums(view)}
                    </option>
                  ))}
                </select>

                <Button color={!openAi ? 'success' : 'danger'} className="my-2" onClick={changeAiStatus}>
                  {openAi ? t('general.deactivateAi') : t('general.activateAi')}
                </Button>
              </div>

              <div className="d-flex flex-column">
                <Button color="primary" className="ms-2" onClick={updateStatusHandler}>
                  <Icon name="plus-sm" />
                  {t('general.updateStatus')}
                </Button>

                <Button color="primary" className="mt-2 ms-2 d-flex justify-content-center" onClick={downloadXLSFile}>
                  {t('general.export')}
                </Button>
              </div>

              <div className="d-flex flex-column">
                {detail.currentStep > 2 && (
                  <Button color="outline-primary" className="ms-2 mb-2" onClick={() => navigate(`/app/campaigns/3/${detail?.id}`)}>
                    <Icon name="navigate-fill me-1" />
                    {t('general.goToMediaPlan')}
                  </Button>
                )}
                <Button
                  color="danger"
                  className="d-flex justify-content-center ms-2"
                  disabled={paymentStatus === 'PAID'}
                  onClick={() => setIsConfirmationOpened(true)}
                >
                  <Icon name="file-check" />
                  {t(paymentStatus === null ? 'general.issueInvoice' : paymentStatus === 'INVOICED' ? 'general.paid' : paymentStatus)}
                </Button>
              </div>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>

      <Block className="d-flex gap-2 align-items-end h-auto">
        <div className="col-2">
          <label className="form-label" htmlFor="Company">
            {t('general.company')}
          </label>
          <Select
            theme={themeSelect}
            name="Company"
            placeholder={t('general.pleaseChoose')}
            options={companyList.map((company: any) => ({label: company.name, value: company.id}))}
            className="form-react-select"
            onChange={(event: any) => {
              setSelectedData((prev) => ({...prev, company: event.value, brand: undefined, product: undefined, user: []}));

              async function getAllUsers() {
                const {data} = await userApi.getAllUsers({perPage: 0, page: 1, company: event.value});
                setUserList(data.data);
              }
              setTriggerBrand(!triggerBrand);
              getAllUsers();
            }}
          />
        </div>

        {selectedData.company && (
          <div className="col-2">
            <label className="form-label" htmlFor="brand">
              {t('general.brand')}
            </label>
            <Select
              name="brand"
              theme={themeSelect}
              placeholder={t('general.pleaseChoose')}
              options={brandList.map((brand: any) => ({label: brand.name, value: brand.id}))}
              className="form-react-select"
              onChange={(event: any) => {
                setSelectedData((prev) => ({...prev, brand: event.value}));
                setTriggerProduct(triggerProduct);
              }}
              components={{Menu: CustomMenuBrand}}
              value={brandList.map((brand: IBrand) => (brand?.id === selectedData.brand ? {label: brand.name, value: brand.id} : null))}
            />
          </div>
        )}
        {selectedData.brand && (
          <div>
            <label className="form-label" htmlFor="product">
              {t('general.product')}
            </label>
            <Select
              name="product"
              theme={themeSelect}
              placeholder={t('general.pleaseChoose')}
              options={productList.map((product: any) => ({label: product.name, value: product.id}))}
              className="form-react-select"
              onChange={(event: any) => {
                setSelectedData((prev) => ({...prev, product: event.value}));
              }}
              components={{Menu: CustomMenuProduct}}
              value={productList.map((product: IProduct) =>
                product?.id === selectedData.product ? {label: product.name, value: product.id} : null
              )}
            />
          </div>
        )}
        {selectedData.product && (
          <div>
            <label className="form-label" htmlFor="user">
              {t('management.users.userList')}
            </label>
            <Select
              name="user"
              theme={themeSelect}
              placeholder={t('general.pleaseChoose')}
              options={userList.map((user: any) => ({label: user.firstName + ' ' + user.lastName, value: user.id}))}
              className="form-react-select"
              isMulti
              onChange={(event: any) => {
                setSelectedData((prev) => ({...prev, users: event.map((user: any) => user.value)}));
              }}
            />
          </div>
        )}

        <Button color="primary" className="ms-2" onClick={moveHandler}>
          {t('general.move')}
        </Button>
      </Block>

      <Block>
        <div className="invoice">
          <div className="invoice-wrap">
            <div className="invoice-brand">{!!company?.logo?.path && <img src={company?.logo?.path} alt="company logo" />}</div>

            <div className="invoice-head">
              <div className="invoice-contact">
                <div className="invoice-contact-info">
                  <ul className="list-plain">
                    <li>
                      <Icon name="map-pin-fill"></Icon>
                      <span>
                        {company.contact.address}
                        <br />
                        {company.contact.city} {company.contact.zip}
                      </span>
                    </li>
                    <li>
                      <Icon name="call-fill"></Icon>
                      <span>{company.contact.phone}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="invoice-desc">
                <ul className="list-plain">
                  <li className="invoice-id">
                    <span>{t('campaigns.campaignID')}</span>:<span className="text-primary">{id}</span>
                  </li>
                  <li className="invoice-date">
                    <span>{t('general.createdAt')}</span>:<span className="text-primary">{dateTime.formatDate(createdAt)}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="invoice-bills">
              <div className="table-responsive">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td>{t('campaigns.name')}</td>
                      <td>{name}</td>
                    </tr>
                    <tr>
                      <td>{t('general.company')}</td>
                      <td>{company.name}</td>
                    </tr>
                    <tr>
                      <td>{t('general.users')}</td>
                      <td className="d-flex justify-content-end">
                        {(user as unknown as IUserData[])?.map((item) => {
                          return (
                            <UserAvatar
                              size="sm"
                              key={item?.id}
                              image={
                                item?.profilePicture?.path ??
                                'https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg'
                              }
                              theme="primary"
                            />
                          );
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('general.countries')}</td>
                      <td>
                        {(country as unknown as ICampaign[])?.map((item, index) => (
                          <img
                            key={item?.id + index}
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${item}.svg`}
                            className="flag-sm"
                            alt={`${item.country} flag`}
                          />
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('general.brand')}</td>
                      <td>{((product as unknown as ICampaign)?.brand as unknown as IBrand)?.name ?? '-'}</td>
                    </tr>

                    <tr>
                      <td>{t('general.product')}</td>
                      <td>{(product as unknown as IProduct)?.name}</td>
                    </tr>
                    <tr>
                      <td>
                        {t('general.startDate')} - {t('general.endDate')}
                      </td>
                      {startDate && endDate ? (
                        <td>
                          {dateTime.formatDate(startDate)} - {dateTime.formatDate(endDate)}
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                    <tr>
                      <td>{t('general.status')}</td>
                      <td>{getCorrectCampaignStatus(status)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Block>
      <LineItemsDetails />

      <CustomModal
        isModalOpened={showModal}
        setIsModalOpened={setShowModal}
        title={t('management.brands.addNew')}
        size="lg"
        saveHandler={() => addBrand()}
        body={
          <FormGroup>
            <label className="form-label" htmlFor="name">
              {t('general.name')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                onChange={(e) => setBrandName(e.target.value)}
                placeholder={t('management.brands.enterBrand') as string}
                className="form-control-lg form-control"
              />
            </div>
          </FormGroup>
        }
      />

      <CustomModal
        isModalOpened={showModalProduct}
        setIsModalOpened={setShowModalProduct}
        title={t('management.products.productCreate')}
        size="lg"
        saveHandler={() => addProduct()}
        body={
          <Row>
            <Col md={6} className="ps-0">
              <FormGroup>
                <label className="form-label" htmlFor="name">
                  {t('general.fullName')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="reg-type"
                    placeholder={t('management.products.enterProduct') as string}
                    className="form-control-lg form-control"
                    onChange={(e) => setProductData((prev: any) => ({...prev, name: e.target.value!}))}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        }
      />
      <ConfirmSuccessModal
        itemName={name ?? '-'}
        actionName="duplicate"
        show={showDuplicateModal}
        setShow={() => setShowDuplicateModal(false)}
        onSave={() => copyCampaignHandler(id ?? 0)}
      />
    </>
  );
};

export default CampaignDetails;
