export const themeSelect = (theme: any) => {
  return {
    borderRadius: 5,
    colors: {
      ...theme.colors,
      primary: 'var(--bs-primary)',
      boxShadow: '0 0 0 3px rgb(101 118 255 / 10%)',
    },
    spacing: {
      ...theme.spacing,
      controlHeight: 43,
      border: '1px solid #dbdfea',
    },
  };
};

export interface ISelect2 {
  id: number;

  [key: string]: string | number;
}

export const adjustValues = (valueArray: ISelect2[], key?: string) => {
  return valueArray?.map((value) => {
    return {
      ...value,
      label: value?.[key as keyof ISelect2] ?? value ?? '',
      value: value?.id ?? value,
    };
  });
};
