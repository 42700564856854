// import {useTranslation} from 'react-i18next';
import React from 'react';
import {useSelector} from 'react-redux';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';
import Button from '../button/Button';

const Confirm505Err = () => {
  const {openRefreshModal} = useSelector((state: any) => state?.appVersion) ?? {};

  return (
    <div>
      <Modal isOpen={openRefreshModal} size="sm" centered>
        <ModalBody className="d-flex align-items-center flex-column">
          <h5>The version not supported</h5>
          <p className="text-center text-muted">Please update to the latest version of the application</p>
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-center">
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              window.location.reload();
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Confirm505Err;
