import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Icon from '../../components/icon/Icon';

const MenuHeader = ({item}: any) => {
  return (
    <li className="nk-menu-heading">
      <h6 className="overline-title text-primary">{item}</h6>
    </li>
  );
};

const MenuItem = ({item, headActive}: any) => {
  const {subMenu, text, link, newTab, header, badge} = item;

  if (header) {
    return <MenuHeader item={header}></MenuHeader>;
  } else
    return (
      <li
        className={`nk-menu-item ${subMenu ? 'has-sub' : ''} ${
          process.env.PUBLIC_URL + link === window.location.pathname ? 'active current-page' : ''
        } ${headActive ? 'active current-page' : ''}`}
      >
        {newTab ? (
          <Link className="nk-menu-link" target="_blank" rel="noopener noreferrer" to={`${process.env.PUBLIC_URL + link}`}>
            <span className="nk-menu-text">{text}</span>
          </Link>
        ) : subMenu ? (
          <React.Fragment>
            <a
              href="#toggle"
              className="nk-menu-link nk-menu-toggle"
              onClick={(ev) => {
                ev.preventDefault();
              }}
            >
              <span className="nk-menu-text">{text}</span>
            </a>
            <MenuSub subMenu={subMenu} />
          </React.Fragment>
        ) : (
          <Link className="nk-menu-link" to={process.env.PUBLIC_URL + link}>
            <span className="nk-menu-text">
              {text} {item.icon && <Icon name={item.icon} />}
            </span>
            {badge && <span className="nk-menu-badge">{badge}</span>}
          </Link>
        )}
      </li>
    );
};

const MenuSub = ({subMenu}: any) => {
  return (
    <ul className="nk-menu-sub">
      {subMenu.map((sub: any, index: number) => (
        <MenuItem item={sub} key={index} />
      ))}
    </ul>
  );
};

const findActiveHead = (data: any) => {
  let found;
  data &&
    data.subPanel.forEach((item: any) => {
      if (process.env.PUBLIC_URL + item.link === window.location.pathname) {
        found = item;
      } else {
        if (item.subMenu) {
          let finding = item.subMenu.find((p: any) => process.env.PUBLIC_URL + p.link === window.location.pathname);
          if (finding) {
            found = item;
          }
        }
      }
    });
  return found;
};

const InvestmentMenu = () => {
  const [head, setHead] = useState('Dashboards');
  const [data, setData] = useState();

  let findingActiveHead = findActiveHead(data);

  useEffect(() => {
    let investmentItem;
    menu?.forEach((item: any) => {
      investmentItem = item.subMenu.find((el: any) => el.text === 'Investment Panel');
      if (investmentItem) {
        setData(investmentItem);
      }
    });
  }, []);

  useEffect(() => {
    if (findingActiveHead) {
      setHead((findingActiveHead as any).text);
    }
  }, [window.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ul className="nk-menu nk-menu-main">
      {data &&
        (data as any)?.subPanel?.map((item: any, index: number) => {
          if (item.text === head) {
            return <MenuItem key={index} item={item} headActive={true} />;
          } else return <MenuItem key={index} item={item} />;
        })}
    </ul>
  );
};

export default InvestmentMenu;

export const channelUserMenu = [
  {
    text: 'Dashboard',
    link: '/app/dashboard',
    isFirstLevel: true,
    subMenu: [
      {
        text: 'Dashboard',
        link: '/app/dashboard',
      },
    ],
  },
];

export const menu = [
  {
    text: 'Dashboard',
    link: '/app/dashboard',
    isFirstLevel: true,
    subMenu: [
      {
        text: 'Dashboard',
        link: '/app/dashboard',
      },
    ],
  },
  {
    text: 'Campaigns',
    link: '/app/campaigns',
    isFirstLevel: true,
    subMenu: [
      {
        text: 'Campaigns',
        link: '/app/campaigns',
      },
    ],
  },
  {
    text: 'Management',
    subMenu: [
      {
        text: 'My company',
        link: '/app/management/my-company',
      },
      {
        text: 'Users',
        onlyCompanyAdmin: true,
        link: '/app/management/users',
      },
      {
        text: 'Brands',
        link: '/app/management/brands',
      },
      {
        text: 'Products',
        link: '/app/management/products',
      },
    ],
  },
  {
    text: 'Orders',
    link: '/app/orders',
    onlyAdmin: true,
    isFirstLevel: true,
    subMenu: [
      {
        text: 'Orders',
        link: '/app/orders',
      },
    ],
  },
  {
    text: 'Reporting',
    link: '/app/reporting',
    isFirstLevel: true,
    onlyAdmin: true,
    subMenu: [
      {
        text: 'Reporting',
        link: '/app/reporting',
      },
    ],
    // subMenu: [
    //   {
    //     text: 'Brands',
    //     link: '/app/overview/brands',
    //   },
    //   {
    //     text: 'Companies',
    //     link: '/app/overview/companies',
    //   },
    // ],
  },
  {
    text: 'Invoice',
    link: '/app/invoice',
    isFirstLevel: true,
    onlyAdmin: true,
    subMenu: [
      {
        text: 'Invoice',
        link: '/app/invoice',
      },
    ],
    // subMenu: [
    //   {
    //     text: 'Brands',
    //     link: '/app/overview/brands',
    //   },
    //   {
    //     text: 'Companies',
    //     link: '/app/overview/companies',
    //   },
    // ],
  },
  {
    text: 'Administrator',
    onlyAdmin: true,
    subMenu: [
      {
        text: 'Company',
        link: '/app/admin/company',
      },
      {
        text: 'Users',
        link: '/app/admin/users',
      },
      {
        text: 'Channel Users',
        link: '/app/admin/channel-users',
      },
      {
        text: 'Types',
        link: '/app/admin/types',
      },
      {
        text: 'Qualities',
        link: '/app/admin/qualities',
      },
      {
        text: 'Channels',
        link: 'app/admin/channels',
      },
      {
        text: 'Format',
        link: '/app/admin/format',
      },
      {
        text: 'Target Groups',
        link: '/app/admin/target-groups',
      },
      {
        text: 'Devices',
        link: '/app/admin/devices',
      },
      // {
      //   text: 'Device - Price',
      //   link: '/app/admin/device-price',
      // },
      {
        text: 'Channel Device Format',
        link: '/app/admin/channel/device-format',
      },
      {
        text: 'Channel - Price',
        link: '/app/admin/channel-price',
      },
      {
        text: 'Quality - Objective',
        link: '/app/admin/quality-objective',
      },
      // {
      //   text: 'Quality - Price',
      //   link: '/app/admin/quality-price',
      // },
      {
        text: 'Target group - scoring',
        link: '/app/admin/objective-scoring',
      },
      {
        text: 'Target group - pricing',
        link: '/app/admin/target-group-pricing',
      },
      {
        text: 'Regions',
        link: '/app/admin/regions',
      },
      {
        text: 'Prompts',
        link: '/app/admin/prompts',
      },
      {
        text: 'Ai settings',
        link: '/app/admin/ai-settings',
      },
    ],
  },
];
