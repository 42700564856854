import React, {SetStateAction} from 'react';
import {FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {Col, FormGroup} from 'reactstrap';
import Icon from '../../../components/icon/Icon';
import {IChangePassword} from '../interfaces/IChangePassword';
import {useTranslation} from 'react-i18next';

interface IChangePasswordForm {
  errors: Partial<
    FieldErrorsImpl<{
      oldPassword: string;
      password: string;
      newPassword: string;
    }>
  >;
  setPassState: React.Dispatch<
    SetStateAction<{
      oldPassword: boolean;
      password: boolean;
    }>
  >;
  passState: {
    oldPassword: boolean;
    password: boolean;
  };
  register: UseFormRegister<IChangePassword>;
}

const ProfileChangePasswordForm = ({register, errors, passState, setPassState}: IChangePasswordForm) => {
  const {t} = useTranslation();
  return (
    <>
      <Col md={6}>
        <FormGroup>
          <div className="form-label-group">
            <label className="form-label" htmlFor="currentPassword">
              {t('myProfile.password.currentPassword')}
            </label>
          </div>
          <div className="form-control-wrap">
            <div
              onClick={() => setPassState((prev) => ({...prev, oldPassword: !prev.oldPassword}))}
              className={`form-icon cursor-pointer lg form-icon-right passcode-switch ${passState.oldPassword ? 'is-hidden' : 'is-shown'}`}
            >
              <Icon name="eye" className="passcode-icon icon-show" />
              <Icon name="eye-off" className="passcode-icon icon-hide" />
            </div>
            <input
              type={passState.oldPassword ? 'text' : 'password'}
              id="oldPassword"
              {...register('oldPassword')}
              placeholder={t('myProfile.password.enterCurrentPassword') as string}
              className={`form-control-lg form-control ${passState.oldPassword ? 'is-hidden' : 'is-shown'}`}
            />
            {errors?.oldPassword && <span className="invalid">{errors?.oldPassword?.message}</span>}
          </div>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <div className="form-label-group">
            <label className="form-label" htmlFor="password">
              {t('myProfile.password.newPassword')}
            </label>
          </div>
          <div className="form-control-wrap">
            <div
              onClick={() => setPassState((prev) => ({...prev, password: !prev.password}))}
              className={`form-icon cursor-pointer lg form-icon-right passcode-switch ${passState.password ? 'is-hidden' : 'is-shown'}`}
            >
              <Icon name="eye" className="passcode-icon icon-show" />
              <Icon name="eye-off" className="passcode-icon icon-hide" />
            </div>
            <input
              type={passState.password ? 'text' : 'password'}
              id="password"
              {...register('password')}
              placeholder={t('myProfile.password.enterNewPassword') as string}
              className={`form-control-lg form-control ${passState.password ? 'is-hidden' : 'is-shown'}`}
            />
            {errors?.password && <span className="invalid">{errors?.password?.message}</span>}
          </div>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <div className="form-label-group">
            <label className="form-label" htmlFor="confirmPassword">
              {t('myProfile.password.confirmPassword')}
            </label>
          </div>
          <div className="form-control-wrap">
            <input
              type={'password'}
              id="newPassword"
              {...register('newPassword')}
              placeholder={t('myProfile.password.enterNewPassword') as string}
              className={'form-control-lg form-control is-shown'}
            />
            {errors?.newPassword && <span className="invalid">{errors?.newPassword?.message}</span>}
          </div>
        </FormGroup>
      </Col>
    </>
  );
};

export default ProfileChangePasswordForm;
