import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import React from 'react';
import './utils/i18n';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import 'reflect-metadata';
import {Provider as ReduxProvider} from 'react-redux';
import {toast} from 'react-toastify';
import App from './App';
import ContainerProvider from './di';
import reportWebVitals from './reportWebVitals';
import {store, StoreKeeper} from './store';
import {commonSlice} from './store/slices/common.slice';

export const customHistory = {
  // navigate: ,
  // location: null
};

toast.onChange((payload) => {
  payload?.status === 'removed' && StoreKeeper.store.dispatch(commonSlice.actions.setMessage(undefined));
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ContainerProvider>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReduxProvider>
    </ContainerProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your test, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
