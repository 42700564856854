import React, {SetStateAction} from 'react';
import {IDevice} from '../../devices/interfaces/IDevice';
import {IFormat} from '../../formats/interfaces/IFormat';
import {useTranslation} from 'react-i18next';
import {IChannelDeviceFormatSend} from '../interfaces/IChannelDeviceFormat';

interface Props {
  deviceList: IDevice[];
  selectedData: IChannelDeviceFormatSend | undefined;
  setDataToSend: React.Dispatch<SetStateAction<IChannelDeviceFormatSend[]>>;
  dataToSend: IChannelDeviceFormatSend[];
  selectedFormat: IFormat | undefined;
  handleCheckDevice: (formatId: number, deviceId: number) => void;
}

const ChannelDeviceFormatField = ({deviceList, setDataToSend, selectedData, dataToSend, handleCheckDevice, selectedFormat}: Props) => {
  const {t} = useTranslation();
  const changeDescriptionHandler = (value: string, deviceId: number) => {
    const copy = [...dataToSend];
    const indexToReplace = copy.findIndex((data) => data.format === selectedFormat?.id);
    copy[indexToReplace] = {
      ...copy[indexToReplace],
      data: copy[indexToReplace].data?.map((data) => {
        if (data.device === deviceId) {
          return {...data, description: value};
        }
        return {...data};
      }),
    };
    setDataToSend(copy);
  };
  const changeContactsHandler = (value: number) => {
    const copy = [...dataToSend];
    const indexToReplace = copy.findIndex((data) => data.format === selectedFormat?.id);
    copy[indexToReplace] = {
      ...copy[indexToReplace],
      contacts: value,
    };
    setDataToSend(copy);
  };

  return (
    <div className="card-bordered card-preview card my-2">
      <div className="card-body p-3">
        <div className="row">
          <div className="col-12 mb-3">
            <h5>
              {t('administrator.channelDeviceFormat.listForChannel')} {selectedFormat?.name ?? '-'}
            </h5>
          </div>

          <div className="mt-2 mb-4 col-12">
            <div className="col-3">
              <label>{t('general.contacts')}</label>
              <input
                type="number"
                onChange={(e) => changeContactsHandler(+e.target.value)}
                value={selectedData?.contacts ?? 0}
                className="form-control"
              />
            </div>
          </div>

          {deviceList?.map((device) => {
            const selectedItem = selectedData?.data?.find((dev) => dev.device === device?.id);
            return (
              <div className="col-md-2" key={device?.id}>
                <div
                  className="custom-control custom-control-sm custom-checkbox d-flex m-1"
                  onClick={() => handleCheckDevice(selectedFormat?.id as number, device?.id)}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input form-control"
                    checked={!!selectedData?.data?.filter((item) => item?.device === device?.id)?.length}
                  />
                  <label className="custom-control-label" htmlFor={`${device?.id}-device-${selectedFormat?.name}`}>
                    {device?.name ?? '-'}
                  </label>
                </div>
                {!!selectedItem && (
                  <div className="mt-2">
                    <label>
                      {t('general.description')} {device?.name ?? ''}:{' '}
                    </label>
                    <input
                      type="text"
                      onChange={(e) => changeDescriptionHandler(e.target.value, selectedItem?.device)}
                      value={selectedItem?.description ?? ''}
                      className="form-control"
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChannelDeviceFormatField;
