import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Controller, FieldErrorsImpl, FormState, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Select, {MultiValue} from 'react-select';
import {Col, FormGroup, Row} from 'reactstrap';
import {targetGroupsApi} from '../../../../api';
import FileInput from '../../../../shared/FileInput';
import {getCorrectCountry} from '../../../../shared/Functions';
import {usePagination} from '../../../../shared/hooks/usePagination';
import {ICompanyData} from '../../../../shared/interfaces/Company';
import {IEvent} from '../../../../shared/interfaces/Event';
import {adjustValues, ISelect2, themeSelect} from '../../../../shared/Select2';
import {RootState} from '../../../../store';
import {ITargetGroups} from '../../target-groups/interfaces/ITargetGroups';

interface ICompanyFormProps {
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
      fee: string;
      email: string;
      contact: Partial<
        FieldErrorsImpl<{
          country: string;
          phone: string;
        }>
      >;
      targetGroups: number[];
    }>
  >;
  register: UseFormRegister<ICompanyData>;
  formState: FormState<ICompanyData>;
  isAdmin: boolean;
  isCompanyAdmin?: boolean;
  isCompanyCreate?: boolean;
  defaultState?: ICompanyData;
}

interface ITarget {
  label: string;
  value: number;
}

const CompanyForm = ({errors, register, formState, isAdmin, isCompanyAdmin, isCompanyCreate, defaultState}: ICompanyFormProps) => {
  const {t} = useTranslation();
  const {changeFilterHandler, pagination} = usePagination();
  const [targetGroupList, setTargetGroupList] = useState<ITargetGroups[]>([]);
  const defaultValues = formState.defaultValues as ICompanyData;
  const {country} = useSelector((state: RootState) => state.enum);

  const defaultTargetGroup = useRef<MultiValue<{label: string | number; value: number}>>();

  const [selectedTargetGroups, setSelectedTargetGroups] = useState<
    MultiValue<{
      label: string | number;
      value: number;
    }>
  >();
  defaultTargetGroup.current = defaultState && adjustValues(defaultState?.targetGroups as unknown as ISelect2[], 'name');

  useEffect(() => {
    async function getAllTargetGroups() {
      try {
        const {data} = await targetGroupsApi.getAllTargetGroups({
          ...pagination,
          perPage: 1000,
        });

        setTargetGroupList(data?.data);
      } catch (err) {}
    }

    getAllTargetGroups();
    // eslint-disable-next-line
  }, [JSON.stringify(pagination)]);

  useEffect(() => {
    if (!!formState?.defaultValues?.companyTargetGroup?.length) {
      const target: ITarget[] = [];
      // eslint-disable-next-line
      formState?.defaultValues?.companyTargetGroup?.map((item: any) => {
        target.push({
          label: item?.targetGroups?.name,
          value: item?.targetGroups?.id,
        });
      });
      setSelectedTargetGroups(target);
    }
  }, [formState?.defaultValues?.companyTargetGroup]);
  return (
    <Row>
      <Col md={4} className="d-flex align-items-center justify-content-center flex-column">
        {!isCompanyCreate && (
          <>
            {!!defaultValues?.logo?.id ? (
              <img className="h-max-375px mb-4" src={defaultValues?.logo?.path} alt="Company logo" />
            ) : (
              <p>{t('general.companyNoLogo')}</p>
            )}
          </>
        )}
        {(isAdmin || !!isCompanyAdmin) && <FileInput accept="image/*" isMultiple={false} name="file" label="File Upload" />}
      </Col>
      <Col md={8}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="name">
                {t('general.name')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-firstName"
                  placeholder={t('general.enterCompany') as string}
                  {...register('name')}
                  className="form-control-lg form-control"
                />
                {errors?.name && <p className="invalid">{errors?.name?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  {t('general.email')}
                </label>
              </div>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="default-01"
                  {...register('email')}
                  className="form-control-lg form-control"
                  placeholder={t('general.enterEmail') as string}
                />
                {errors?.email && <p className="invalid">{errors?.email?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="phone">
                {t('general.phoneNumber')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="phone"
                  placeholder={t('general.enterPhone') as string}
                  {...register('contact.phone')}
                  className="form-control-lg form-control"
                />
                {errors.contact?.phone && <span className="invalid">{errors?.contact?.phone?.message}</span>}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="country">
                {t('general.country')}
              </label>
              <div className="form-control-wrap ">
                <div className="form-control-select">
                  <select className="form-control form-control-lg" id="default-06" {...register('contact.country')}>
                    <option selected disabled>
                      {t('general.enterCountry')}
                    </option>
                    {country?.map((item) => (
                      <option value={item} key={item}>
                        {getCorrectCountry(item, t)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {errors.contact?.country && <span className="invalid">{errors?.contact?.country?.message}</span>}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="city">
                {t('general.city')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-city"
                  placeholder={t('general.enterCity') as string}
                  {...register('contact.city')}
                  className="form-control-lg form-control"
                />
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="zip">
                {t('general.zip')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-zip"
                  placeholder={t('general.enterZip') as string}
                  {...register('contact.zip')}
                  className="form-control-lg form-control"
                />
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="address">
                {t('general.address')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-address"
                  placeholder={t('general.enterAddress') as string}
                  {...register('contact.address')}
                  className="form-control-lg form-control"
                />
              </div>
            </FormGroup>
          </Col>

          {isAdmin && (
            <>
              <Col md={3}>
                <FormGroup>
                  <label className="form-label" htmlFor="fee">
                    {t('general.fee')}
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      onInput={(e: any) => {
                        e.preventDefault();
                        e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                      }}
                      id="fee"
                      placeholder={t('general.fee') as string}
                      {...register('fee', {valueAsNumber: true})}
                      className="form-control-lg form-control"
                    />
                    {errors?.fee && <p className="invalid">{errors?.fee?.message}</p>}
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-label" htmlFor="name">
                    {t('general.targetGroup')}
                  </label>

                  <div className="form-control-wrap">
                    <Controller
                      {...register('targetGroups', {
                        valueAsNumber: true,
                      })}
                      render={({field: {onChange}}) => (
                        <Select
                          theme={themeSelect}
                          value={selectedTargetGroups}
                          isMulti
                          className="form-react-select"
                          placeholder={t('administrator.targetGroups.selectTargetGroups')}
                          options={adjustValues(targetGroupList as unknown as any[], 'name') as any}
                          onInputChange={(value) => {
                            const event: IEvent = {
                              target: {
                                name: 'name',
                                value: value,
                              },
                            };
                            if (!!value.length || (pagination as any)?.name) {
                              changeFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
                            }
                          }}
                          onChange={(e) => {
                            setSelectedTargetGroups(e);
                            onChange(e?.map((event) => event?.value));
                          }}
                        />
                      )}
                      rules={{required: true}}
                    />
                    {errors?.targetGroups && <p className="invalid">{errors?.targetGroups?.message}</p>}
                  </div>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <label className=" form-label" htmlFor="customSwitch2">
                    {t('general.unlimitedBudget')}
                  </label>
                  <div className="ps-5">
                    <div className="custom-control custom-switch ps-3">
                      <input type="checkbox" className="custom-control-input" id="customSwitch2" {...register('unlimitedBudget')} />
                      <label className="custom-control-label form-label" htmlFor="customSwitch2"></label>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default CompanyForm;
