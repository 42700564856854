import React from 'react';
import {FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {IPrompt} from '../interfaces/IPrompt';
import {useTranslation} from 'react-i18next';
import {Col, FormGroup, Row} from 'reactstrap';

interface IPromptFormProps {
  errors: Partial<
    FieldErrorsImpl<{
      prompt: string;
    }>
  >;
  register: UseFormRegister<IPrompt>;
}

const PromptForm = ({errors, register}: IPromptFormProps) => {
  const {t} = useTranslation();
  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <label className="form-label" htmlFor="name">
            {t('general.prompt')}
          </label>
          <div className="form-control-wrap">
            <input
              type="text"
              id="reg-type"
              placeholder={t('administrator.prompts.enterPrompt') as string}
              {...register('prompt')}
              className="form-control-lg form-control"
            />
            {errors?.prompt && <p className="invalid">{errors?.prompt?.message}</p>}
          </div>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default PromptForm;
