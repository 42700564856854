import React, {useEffect, useState} from 'react';
import DualListBox, {Option} from 'react-dual-listbox';
import Icon from '../../../components/icon/Icon';
import {IFormat} from '../formats/interfaces/IFormat';
import {channelDeviceFormat, deviceApi, formatApi} from '../../../api';
import {usePagination} from '../../../shared/hooks/usePagination';
import {useNavigate, useParams} from 'react-router-dom';
import ChannelDeviceFormatField from './components/ChannelDeviceFormatField';
import {IDevice} from '../devices/interfaces/IDevice';
import {IChannelDeviceFormatSend} from './interfaces/IChannelDeviceFormat';
import {Button} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import useQuerySearch from '../../../shared/hooks/useQuerySearch';

const ChannelDeviceFormat = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {channelId} = useParams();
  const channelName = useQuerySearch('name');
  const [dataToSend, setDataToSend] = useState<IChannelDeviceFormatSend[]>([]);
  const [formatList, setFormatList] = useState<IFormat[]>([]);
  const [selected, setSelected] = useState<string[] | readonly string[] | undefined>([]);
  const {pagination} = usePagination();
  const [deviceList, setDeviceList] = useState<IDevice[]>([]);

  const onChange = (selectedItem: string[] | readonly string[] | undefined) => {
    setSelected(selectedItem);
    const idToAdd = selectedItem?.find((item) => selected?.some((selItem) => selItem !== item)) ?? selectedItem?.[0]; // set first element if response is NaN (first entry)
    setDataToSend((prev) => prev.concat({format: Number(idToAdd), contacts: 0, data: []}));
  };
  const handleCheckDevice = (formatId: number, deviceId: number) => {
    const copy = [...dataToSend];

    const isFormatSelected = copy?.some((format) => format.format === formatId);
    if (!isFormatSelected) {
      // add item to last index if we don't have that format id in array
      const newData = {
        format: formatId,
        contacts: copy[copy.length - 1].contacts,
        data: [
          {
            device: deviceId,
            description: '',
          },
        ],
      };
      copy.push(newData);
      setDataToSend(copy);
    } else {
      setDataToSend(
        copy?.map((data) => {
          // check if it's clicked format
          if (data.format === formatId) {
            const hasItem = !!data?.data?.filter((item) => item?.device === deviceId)?.length; //check if current device have clicked device
            return {
              format: formatId,
              contacts: copy[copy.length - 1].contacts,
              data: hasItem
                ? data?.data.filter((device) => device.device !== deviceId)
                : data?.data.concat([
                    {
                      device: deviceId,
                      description: '',
                    },
                  ]), // if we have, remove that ID from array, else add that item to array
            };
          }
          return {
            // else -> return that data without changing it
            ...data,
          };
        }) as IChannelDeviceFormatSend[]
      );
    }
  };
  const submitHandler = async () => {
    const removeEmptyValues = dataToSend
      // ?.filter((channel) => !!channel.data?.filter((list) => list?.device)?.length)
      ?.filter((channel) => selected?.some((selectedFormat) => +selectedFormat === channel.format)); //logic for removing items (deleting it)

    const {data} = await channelDeviceFormat.updateChannelDeviceFormat(removeEmptyValues, channelId as string);
    if (data) {
      navigate(-1);
    }
  };
  useEffect(() => {
    formatApi.getAllFormats({...pagination, perPage: 0}).then((response) => {
      const {data} = response;
      setFormatList(data.data);
    });
  }, [pagination]);
  useEffect(() => {
    async function getAllDevices() {
      const {data} = await deviceApi.getAllDevices({page: 1, perPage: 0});
      setDeviceList(data.data);
    }

    getAllDevices();
  }, []);
  useEffect(() => {
    channelId &&
      channelDeviceFormat.getChannelDeviceFormat(channelId).then((response) => {
        const resData = response.data.data;

        const filteredData = formatList?.filter((format) => resData.some((data) => data.format === format?.id));
        setDataToSend(
          resData?.map((item) => {
            return {
              format: item.format as number,
              contacts: +item.contacts as number,

              data: item?.devices?.map((dev) => {
                return {
                  device: dev.device,
                  description: dev.description ?? '',
                };
              }),
            };
          })
        );
        setSelected(filteredData?.map((item) => item?.id) as unknown as string[] | readonly string[] | undefined);
      });
  }, [channelId, formatList]);
  return (
    <div className="mt-3">
      <h4>
        {t('general.channel')}: {channelName}
      </h4>
      <div>
        <DualListBox
          options={
            formatList?.map((format) => ({
              label: format?.name ?? '-',
              value: format?.id,
            })) as unknown as readonly Option<string>[] | Option<string>[]
          }
          icons={{
            moveLeft: <Icon name="chevron-left" />,
            moveAllLeft: <Icon name="chevrons-left" />,
            moveRight: <Icon name="chevron-right" />,
            moveAllRight: <Icon name="chevrons-right" />,
          }}
          selected={selected}
          onChange={onChange}
        />
        {selected?.map((item) => (
          <ChannelDeviceFormatField
            handleCheckDevice={handleCheckDevice}
            selectedFormat={formatList?.find((format) => format.id === +item)}
            deviceList={deviceList}
            selectedData={dataToSend?.find((data) => data.format === +item)}
            setDataToSend={setDataToSend}
            dataToSend={dataToSend}
            key={item}
          />
        ))}
        <div className="d-flex justify-content-end mt-2">
          <Button color="secondary" className="me-1" onClick={() => navigate(-1)}>
            {t('general.cancel')}
          </Button>
          {!!dataToSend?.length && (
            <Button color="primary" onClick={submitHandler}>
              {t('general.update')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChannelDeviceFormat;
