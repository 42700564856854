import {IQuality, IQualityPagination} from '../../../app/administrator/quality/interfaces/IQuality';
import BaseApi from '../../base.api';

const QUALITY_ROUTES = '/api/admin/quality';

export class QualityApi extends BaseApi {
  public async getAllQualities(pagination: IQualityPagination): Promise<{data: any}> {
    return await this.get(QUALITY_ROUTES, undefined, pagination);
  }

  public async getQuality(id: number): Promise<{data: IQuality}> {
    return await this.get(`${QUALITY_ROUTES}/${id}`);
  }

  public async updateQuality(id: number, data: IQuality): Promise<{data: IQuality}> {
    return await this.put(`${QUALITY_ROUTES}/${id}`, data, '');
  }

  public async createQuality(data: IQuality): Promise<any> {
    return await this.post(QUALITY_ROUTES, data);
  }

  public async deleteQuality(id: number): Promise<any> {
    return await this.delete(`${QUALITY_ROUTES}/${id}`, '');
  }
}
