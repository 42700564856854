import React, {useEffect, useState} from 'react';
import {Form, FormGroup} from 'reactstrap';
import Button from '../../../components/button/Button';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {authApi} from '../../../api';
import Icon from '../../../components/icon/Icon';

import {IResetPassword} from '../../interfaces/ResetPassword';
import * as yup from 'yup';
import useQuerySearch from '../../../shared/hooks/useQuerySearch';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const schema = yup.object({
  isFirstStepFinished: yup.boolean().required().default(false),
  email: yup.string().email().required('No email provided.'),
  password: yup.string().when('isFirstStepFinished', {
    is: true,
    then: yup
      .string()
      .required('No password provided.')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  }),
  repeatPassword: yup.string().when('isFirstStepFinished', {
    is: true,
    then: yup
      .string()
      .oneOf([yup.ref('password'), null], "Passwords don't match!")
      .required('No password provided.')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  }),
});

const ForgotPasswordForm = () => {
  const {t} = useTranslation();
  const [passState, setPassState] = useState(false);

  const navigate = useNavigate();
  const token = useQuerySearch('token');
  const queryEmail = useQuerySearch('email');
  const methods = useForm<IResetPassword>({
    resolver: yupResolver(schema),
  });

  const onFormSubmit = async (formData: IResetPassword) => {
    if (formData.isFirstStepFinished) {
      const {data} = await authApi.changePasswordEmail(formData);
      data && navigate('/');
    } else {
      const {data} = await authApi.reset(formData);
      data && navigate('/');
    }
  };

  useEffect(() => {
    if (queryEmail && token) {
      methods.reset({
        token,
        email: queryEmail,
        isFirstStepFinished: true,
      });
    }
  }, [queryEmail, token, methods]);

  return (
    <Form onSubmit={methods?.handleSubmit(onFormSubmit)}>
      <FormGroup>
        <div className="form-label-group">
          <label className="form-label" htmlFor="default-01">
            Email
          </label>
        </div>
        <div className="form-control-wrap">
          <input
            type="text"
            {...methods.register('email')}
            id="fv-full-email"
            disabled={!!token?.length}
            placeholder={t('general.enterEmail') as string}
            className="form-control-lg form-control"
          />
          {methods.formState.errors.email && (
            <span id="fv-full-email-error" className="invalid">
              {methods.formState.errors.email?.message}
            </span>
          )}
        </div>
      </FormGroup>

      {!!token?.length && (
        <>
          <FormGroup>
            <div className="form-label-group">
              <label className="form-label" htmlFor="default-01">
                {t('forgotPassword.newPassword')}
              </label>
            </div>
            <div className="form-control-wrap">
              <a
                href="src/authentication/forgot-password/New#password"
                onClick={(ev) => {
                  ev.preventDefault();
                  setPassState(!passState);
                }}
                className={`form-icon lg form-icon-right passcode-switch ${passState ? 'is-hidden' : 'is-shown'}`}
              >
                <Icon name="eye" className="passcode-icon icon-show" />

                <Icon name="eye-off" className="passcode-icon icon-hide" />
              </a>
              <input
                type={passState ? 'text' : 'password'}
                {...methods.register('password')}
                id="fv-full-password"
                placeholder={t('myProfile.password.enterNewPassword') as string}
                className="form-control-lg form-control"
              />
              {methods.formState.errors.password?.message && (
                <span id="fv-full-email-error" className="invalid">
                  {methods.formState.errors.password?.message}
                </span>
              )}
            </div>
          </FormGroup>

          <FormGroup>
            <div className="form-label-group">
              <label className="form-label" htmlFor="confirmPassword">
                {t('myProfile.password.confirmPassword')}
              </label>
            </div>
            <div className="form-control-wrap">
              <input
                type="password"
                id="confirmPassword"
                {...methods.register('repeatPassword')}
                placeholder={t('myProfile.password.enterNewPassword') as string}
                className={'form-control-lg form-control is-shown'}
              />
              {methods.formState.errors.repeatPassword && (
                <span className="invalid">{methods.formState.errors.repeatPassword.message}</span>
              )}
            </div>
          </FormGroup>
        </>
      )}
      <FormGroup>
        <Button color="primary" size="lg" className="btn-block">
          {t('forgotPassword.sendResetLink')}
        </Button>
      </FormGroup>
    </Form>
  );
};
export default ForgotPasswordForm;
