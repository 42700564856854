import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import {productApi} from '../../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {IProduct} from '../interfaces/IProduct';
import ProductForm from './ProductForm';
import {productFormSchema} from './ProductUpdate';

const ProductCreate = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<IProduct>({
    resolver: yupResolver(productFormSchema),
  });

  const onFormSubmit = async (formData: IProduct) => {
    const {data} = await productApi.createProduct(formData);
    if (data) {
      navigate('/app/management/products');
    }
  };
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('management.products.productCreate')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate('/app/management/products');
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <ProductForm register={methods?.register} errors={methods?.formState?.errors} />
              <Row>
                <Col>
                  <Row>
                    <Col className={'text-end'}>
                      <Button color="primary">
                        <Icon name="plus-sm" />
                        {t('general.create')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default ProductCreate;
