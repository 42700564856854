import {IUser} from '../../app/administrator/users/interfaces/IUser';
import {IChangePassword} from '../../app/my-profile/interfaces/IChangePassword';
import {ILoginData} from '../../authentification/interfaces/Login';
import {IRegisterData} from '../../authentification/interfaces/Register';
import {IResetPassword} from '../../authentification/interfaces/ResetPassword';
import BaseApi from '../base.api';

export class AuthApi extends BaseApi {
  public async login(data: ILoginData): Promise<{data: {token: string; user: IUser; appVersion: string}}> {
    return await this.post('/api/signin', data);
  }

  public async register(data: IRegisterData): Promise<any> {
    return await this.post('/api/register', data);
  }

  public async me(token: string): Promise<any> {
    return await this.get('/api/me', token);
  }

  public async updateMe(data: FormData, token: string): Promise<any> {
    return await this.put('/api/me', data, token, true);
  }

  public async changePassword(data: IChangePassword, token: string): Promise<any> {
    return await this.put('/api/password-change', data, token);
  }

  public async reset(data: IResetPassword): Promise<any> {
    return await this.post('/api/forgot-password-request', data);
  }

  public async changePasswordEmail(data: IResetPassword): Promise<any> {
    return await this.post('/api/forgot-password-change', data);
  }
}
