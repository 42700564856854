import Lottie from 'lottie-react';
import React, {SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';
import confirmIcon from '../../shared/icons/confirmIcon.json';
import Button from '../button/Button';

interface Props {
  itemName: string;
  actionName: string;
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
  onSave: () => void;
  itemId?: number;
}

const ConfirmSuccessModal = ({itemName, show, setShow, onSave, actionName = 'Cancel Updating'}: Props) => {
  const handleClose = () => setShow(false);
  const {t} = useTranslation();

  return (
    <div>
      <Modal isOpen={show} toggle={handleClose} size="sm" centered>
        <ModalBody className="d-flex align-items-center flex-column">
          <Lottie animationData={confirmIcon} loop={true} style={{width: 100}} />
          <h5>{t('general.areYouSure')}</h5>
          <p
            className="text-center text-muted"
            dangerouslySetInnerHTML={{
              __html: t('general.confirmAction', {
                action: `${actionName}`,
                itemName,
              }) as unknown as string,
            }}
          />
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-center">
          <Button color="light" size="sm" onClick={handleClose}>
            {t('general.cancel')}
          </Button>
          <Button
            color="secondary"
            size="sm"
            onClick={() => {
              handleClose();
              onSave();
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmSuccessModal;
