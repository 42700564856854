import {IPrompt, IPromptPagination} from '../../../app/administrator/prompts/interfaces/IPrompt';
import BaseApi from '../../base.api';

const PROMPT_ROUTE = '/api/admin/prompt';

export class PromptApi extends BaseApi {
  public async getAllPrompts(pagination: IPromptPagination): Promise<any> {
    return await this.get(PROMPT_ROUTE, undefined, pagination);
  }

  public async getPrompt(id: number): Promise<{data: IPrompt}> {
    return await this.get(`${PROMPT_ROUTE}/${id}`);
  }

  public async createPrompt(data: any): Promise<any> {
    return await this.post(PROMPT_ROUTE, data);
  }

  public async deletePrompt(id: number): Promise<any> {
    return await this.delete(`${PROMPT_ROUTE}/${id}`, '');
  }

  public async updatePrompt(id: number, data: IPrompt): Promise<{data: IPrompt}> {
    return await this.put(`${PROMPT_ROUTE}/${id}`, data, '');
  }
}
