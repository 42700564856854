import Lottie from 'lottie-react';
import React from 'react';
import {Link} from 'react-router-dom';
import PageContainer from '../../layout/page-container/PageContainer';
import serverErrorIcon from '../../shared/icons/serverErrorIcon.json';
import {Block, BlockContent} from '../block/Block';
import Button from '../button/Button';

const ServerError = () => {
  return (
    <PageContainer>
      <Block className="nk-block-middle wide-md mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <Lottie animationData={serverErrorIcon} loop={true} style={{width: '50%'}} className="my-2 mx-auto" />
          <div className="wide-xs mx-auto">
            <h3 className="nk-error-title">Oops! Server error - Error 500</h3>
            <p className="nk-error-text">
              We apologize for the inconvenience. It seems our server didn't receive a timely response. Our team is working to resolve this
              issue. Thank you for your patience.
            </p>
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <Button color="primary" size="lg" className="mt-2">
                Back To Home
              </Button>
            </Link>
          </div>
        </BlockContent>
      </Block>
    </PageContainer>
  );
};

export default ServerError;
