import React, {ChangeEvent, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getCorrectCampaignStatus} from '../../../shared/Functions';
import {RootState} from '../../../store';
import {IFiltersProps} from '../../administrator/company/components/CompanyFilters';
import Select from 'react-select';
import {adjustValues, ISelect2, themeSelect} from '../../../shared/Select2';
import {brandApi, productApi} from '../../../api';
import {usePagination} from '../../../shared/hooks/usePagination';
import {IBrand} from '../../management/brands/interfaces/IBrand';
import {useTranslation} from 'react-i18next';
import {IProduct} from '../../management/products/interfaces/IProduct';
import {IEvent} from '../../../shared/interfaces/Event';

const CampaignFilters = ({changeFilterHandler, pagination}: IFiltersProps) => {
  const {t} = useTranslation();
  const {campaignStatus} = useSelector((state: RootState) => state?.enum);
  const {changeFilterHandler: changeBrandFilterHandler, pagination: brandPagination} = usePagination();
  const {changeFilterHandler: changeProductFilterHandler, pagination: productPagination} = usePagination();
  const [brandList, setBrandList] = useState<IBrand[]>([]);
  const [productList, setProductList] = useState<IProduct[]>([]);
  const [brandID, setBrandID] = useState<undefined | number>(undefined);
  useEffect(() => {
    brandApi.getAllBrands(brandPagination).then((response) => {
      const {data} = response;
      setBrandList(data.data);
    });
  }, [brandPagination]);
  useEffect(() => {
    productApi.getAllProduct({...productPagination, brand: brandID}).then((response) => {
      const {data} = response;
      setProductList(data.data);
    });
  }, [productPagination, brandID]);
  return (
    <div className="mt-3 row gx-0 gx-lg-2 align-items-center justify-content-end">
      <div className="form-control-wrap col-lg-2 col-12">
        <input
          type="text"
          onChange={changeFilterHandler}
          name="name"
          className="form-control"
          placeholder={t('general.searchByName') as string}
        />
      </div>
      <div className="form-control-wrap  col-lg-2 col-12 me-md-0 my-2 my-lg-0">
        <Select
          theme={themeSelect}
          placeholder={t('general.enterBrand')}
          options={adjustValues(brandList as unknown as ISelect2[], 'name')}
          isClearable
          className="small-select2"
          onInputChange={(value) => {
            const event: IEvent = {
              target: {
                name: 'name',
                value: value,
              },
            };
            changeBrandFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
          }}
          onChange={(event) => {
            setBrandID(event!.id);
            const combinedEvent: any = {
              target: {
                name: 'brand',
                value: event?.value,
              },
            };
            changeFilterHandler(combinedEvent);
          }}
        />
      </div>
      <div className="form-control-wrap col-lg-2 col-12">
        <Select
          theme={themeSelect}
          placeholder={t('general.enterProduct')}
          options={adjustValues(productList as unknown as ISelect2[], 'name')}
          isClearable
          className="small-select2"
          onInputChange={(value) => {
            const event: IEvent = {
              target: {
                name: 'name',
                value: value,
              },
            };
            changeProductFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
          }}
          onChange={(event) => {
            const combinedEvent: any = {
              target: {
                name: 'product',
                value: event?.value,
              },
            };

            changeFilterHandler(combinedEvent);
          }}
        />
      </div>
      <div className="form-control-wrap col-lg-2 col-12 mt-2 mt-lg-0">
        <select name="status" onChange={changeFilterHandler} className="form-select">
          <option value="">All</option>
          {campaignStatus?.map((status) => (
            <option value={status} key={status}>
              {getCorrectCampaignStatus(status)}
            </option>
          ))}
        </select>
      </div>

      <div className="form-control-wrap col-lg-2 col-12 mt-2 mt-lg-0">
        <select name="perPage" onChange={changeFilterHandler} defaultValue={pagination?.perPage} className="form-select">
          <option hidden>Per page</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
      </div>
    </div>
  );
};

export default CampaignFilters;
