import React, {useState} from 'react';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import UserAvatar from '../../../components/user/UserAvatar';
import {LinkItem, LinkList} from '../../../components/links/Links';
import Icon from '../../../components/icon/Icon';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {formatRoles} from '../../../utils/Functions';
import {Link} from 'react-router-dom';
import {authSlice} from '../../../store/slices/auth.slice';
import {getAvatarInitials, isUserChannelUser} from '../../../shared/Functions';

const User = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const {
    user: {firstName, lastName, roles, email, profilePicture},
  } = useSelector((state: RootState) => state.auth);
  const isChannelUser = isUserChannelUser(roles);
  const logoutHandler = () => {
    localStorage.clear();
    dispatch(authSlice.actions.reset());
  };

  const toggle = () => setOpen((prevState) => !prevState);
  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon={!profilePicture?.path && 'user-alt'} image={profilePicture?.path} theme="primary" className="sm" />
          <div className="user-info d-none d-xl-block">
            <div className="user-status invest">{formatRoles(roles)}</div>
            <div className="user-name dropdown-indicator">
              {firstName} {lastName}
            </div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <UserAvatar image={profilePicture?.path} text={getAvatarInitials(firstName, lastName)} theme="primary" />
            <div className="user-info">
              <span className="lead-text">
                {firstName} {lastName}
              </span>
              <span className="sub-text">{email}</span>
            </div>
          </div>
        </div>
        {!isChannelUser && (
          <div className="dropdown-inner">
            <LinkList>
              <LinkItem link={'/app/profile'} state={{component: 'profile'}} icon="user-alt" onClick={() => toggle()}>
                My Profile
              </LinkItem>
              <LinkItem link={'/app/profile'} state={{component: 'changePassword'}} icon="setting-alt" onClick={() => toggle()}>
                Change Password
              </LinkItem>
            </LinkList>
          </div>
        )}
        <div className="dropdown-inner">
          <LinkList>
            <Link onClick={logoutHandler} to="/">
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </Link>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
