import React from 'react';
import {IFiltersProps} from '../../company/components/CompanyFilters';
import {useTranslation} from 'react-i18next';

const PromptFilters = ({changeFilterHandler, pagination}: IFiltersProps) => {
  const {t} = useTranslation();
  return (
    <div className="mt-3 d-flex align-items-center justify-content-between justify-content-md-end">
      <div className="form-control-wrap mx-md-2">
        <input
          type="text"
          onChange={changeFilterHandler}
          name="name"
          className="form-control"
          placeholder={t('general.searchByPrompt') as string}
        />
      </div>
      <div className="form-control-wrap ">
        <select name="perPage" onChange={changeFilterHandler} defaultValue={pagination?.perPage} className="form-select">
          <option hidden>Per page</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
      </div>
    </div>
  );
};

export default PromptFilters;
