import React, {useEffect, useState} from 'react';
import {usePagination} from '../../shared/hooks/usePagination';
import {invoiceApi} from '../../api';
import {ICampaign} from '../campaigns/interfaces/ICampaigns';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../components/block/Block';
import {Card} from 'reactstrap';
import {Pagination} from '../../components/pagination/Pagination';
import CampaignFilters from '../campaigns/components/CampaignFilters';
import {useTranslation} from 'react-i18next';

import InvoiceTable from './components/InvoiceTable';

const Invoice = () => {
  const {t} = useTranslation();
  const [campaignList, setCampaignList] = useState<ICampaign[]>([]);
  const {changeFilterHandler, page, setTotalPages, totalPages, handlePages, pagination} = usePagination();

  useEffect(() => {
    invoiceApi.getAllInvoices(pagination).then((response) => {
      const {data} = response;

      setCampaignList(data.data);
      setTotalPages(Math.ceil(data?.count / data?.perPage));
    });
  }, [pagination, setTotalPages]);
  return (
    <>
      <Block size="lg" className="mt-3">
        <BlockHead>
          <BlockHeadContent>
            <div className="row align-items-center gx-0 gx-lg-2">
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-12">
                <BlockTitle tag="h4">{t('reporting.campaignList')}</BlockTitle>
              </div>
            </div>
            <CampaignFilters changeFilterHandler={changeFilterHandler} />
          </BlockHeadContent>
        </BlockHead>

        <Card className="card-bordered card-preview">
          <InvoiceTable campaignList={campaignList} />
        </Card>
      </Block>

      <div className="d-flex justify-content-end">
        {totalPages <= 1 ? '' : <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />}
      </div>
    </>
  );
};

export default Invoice;
