import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import ChangeStep from './ChangeStep';
import {IStepperProps} from './index';
import {commentsApi} from '../../../../api';
import Icon from '../../../../components/icon/Icon';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store';
import {isUserAdmin} from '../../../../shared/Functions';

const CampaignStep6 = ({campaignData}: IStepperProps) => {
  const {id} = useParams();
  const [commentText, setCommentText] = useState<{comment: string}>({comment: ''});
  const [comments, setComments] = useState<any[]>([]);
  const last: any = useRef();
  const {roles, id: userId} = useSelector((state: RootState) => state.auth.user);
  const isAdmin = isUserAdmin(roles);
  const changeHandler = (e: any) => {
    setCommentText({comment: e.target.value});
  };

  const isAssignedUser = (campaignData?.user as any)?.some((campaignUser: any) => campaignUser.id === userId);

  const postComment = async () => {
    const response = await commentsApi.createComment(commentText, +id!);
    setComments(response.data);
    setCommentText({comment: ''});
    last.current.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
  };

  useEffect(() => {
    const getComments = async () => {
      if (id) {
        const {data} = await commentsApi.getAllComments(+id);
        setComments(data);
      }
    };
    getComments();
  }, [id]);
  useEffect(() => {
    if (last.current) {
      last.current.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
    }
    // eslint-disable-next-line
  }, [last.current]);
  return (
    <>
      <ChangeStep
        campaignId={campaignData?.id}
        status={campaignData?.status}
        currentCampaignStep={campaignData?.currentStep}
        activeStep={campaignData?.openAi ? 9 : 8}
        name={campaignData?.name}
      />

      <div className="card-bordered card-preview card">
        <div className="card-body">
          <form className="py-3">
            <div className="container-xl wide-xl ">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div
                    className="nk-msg "
                    style={{
                      maxHeight: 0,
                      minHeight: 'calc(100vh - 600px)',
                      borderBottom: 0,
                    }}
                  >
                    <div className="nk-msg-body bg-white">
                      <div data-simplebar="init" className="nk-msg-reply nk-reply">
                        <div className="simplebar-wrapper" style={{margin: 0}}>
                          <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer" />
                          </div>
                          <div className="simplebar-mask">
                            <div className="simplebar-offset" style={{right: 0, bottom: 0}}>
                              <div
                                className="simplebar-content-wrapper"
                                tabIndex={0}
                                role="region"
                                aria-label="scrollable content"
                                style={{height: '100%', overflow: 'hidden scroll'}}
                              >
                                <div className="simplebar-content" style={{padding: 0}}>
                                  <div className="nk-msg-head py-4 d-lg-none">
                                    <h4 className="title">Unable to select currency when order</h4>
                                    <ul className="nk-msg-tags">
                                      <li>
                                        <span className="label-tag">
                                          <em className="icon ni ni-flag-fill" /> <span>Technical Problem</span>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  {comments.map((comment, idx) => {
                                    return (
                                      <div
                                        className="nk-reply-item"
                                        ref={idx === comments.length - 1 ? last : undefined}
                                        id={idx === comments.length - 1 ? 'last' : ''}
                                      >
                                        <div className="nk-reply-header">
                                          <div className="user-card">
                                            <div className="user-avatar user-avatar-sm">
                                              {!comment.companyLogo && <Icon name="user-alt" />}
                                              {comment.companyLogo && (
                                                <img
                                                  alt="User profile"
                                                  src={comment.companyLogo.path}
                                                  className="w-100 h-100 "
                                                  style={{objectFit: 'cover'}}
                                                />
                                              )}
                                            </div>
                                            <div className="user-name">
                                              {comment.companyName + ' '} {`(${comment.userFirstName + ' ' + comment.userLastName})`}
                                            </div>
                                          </div>
                                          <div className="date-time">{moment(comment.createdAt).format('LLLL')}</div>
                                        </div>
                                        <div className="nk-reply-body">
                                          <div className="nk-reply-entry entry">{comment.comment}</div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nk-reply-form w-100 m-auto bg-white mb-5">
                  <div className="nk-reply-form-header">
                    <ul className="nav nav-tabs-s2 nav-tabs nav-tabs-sm">
                      <li className="nav-item">
                        <a className="nav-link active" href="#tab">
                          Reply
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane active">
                      <div className="nk-reply-form-editor">
                        <div className="nk-reply-form-field">
                          <textarea
                            className="form-control form-control-simple no-resize"
                            placeholder="Enter your comment here"
                            defaultValue={''}
                            onChange={changeHandler}
                            value={commentText.comment}
                          />
                        </div>
                        <div className="nk-reply-form-tools">
                          <ul className="nk-reply-form-actions g-1">
                            <li className="me-2">
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={postComment}
                                disabled={!isAdmin && !isAssignedUser}
                              >
                                Reply
                              </button>
                            </li>
                          </ul>
                          <div className="alert alert-warning w-100 p-1" role="alert">
                            The following comments provided are for general purposes only and shouldn't be considered as legal advice or
                            legally binding.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CampaignStep6;
