import type {Identifier, XYCoord} from 'dnd-core';
import update from 'immutability-helper';
import _ from 'lodash';
import moment from 'moment';
import React, {SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {lineItemApi} from '../../../../../api';
import '../../../../../assets/scss/_daypicker.scss';
import {ISection} from '../../../../../api/app/campaigns/line-item.api';
import Icon from '../../../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../../../components/modal/ConfirmDeleteModal';
import {CustomPopover} from '../../../../../components/popover/Popover';
import {TooltipItem} from '../../../../../components/tooltip/Tooltips';
import {
  addNNumberOfDays,
  checkDatesWithinWeek,
  filterDatesByWeek,
  formatPrice,
  getCorrectModelKey,
  getMonthDates,
  getMonthDatesInRange,
  getSelectedWeekDates,
  ICalendarRanges,
  isDayWeekend,
  isWeekInFuture,
  renderCalendarDay,
  renderCalendarWeek,
  truncateString,
} from '../../../../../shared/Functions';
import ImageHandler from '../../../../../shared/ImageHandler';
import {IPicture} from '../../../../../shared/interfaces/Picture';
import {
  IChannelDeviceFormatBuild,
  ILineItemStats,
  ISelectedDates,
} from '../../../../administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import {ICampaign} from '../../../interfaces/ICampaigns';
import UpdateLineItemForm from './UpdateLineItemForm';

interface Props {
  isCampaignEditable: boolean;
  tableDates: ICalendarRanges;
  savedChannels: IChannelDeviceFormatBuild[];
  handleCreateLineItem: () => void;
  setSelectedLineItemId: React.Dispatch<SetStateAction<number>>;
  selectedDates: ISelectedDates[];
  setSelectedDates: React.Dispatch<SetStateAction<ISelectedDates[]>>;
  selectedLineItemId: number;
  lineItemStats: ILineItemStats;
  setSavedChannels: React.Dispatch<SetStateAction<IChannelDeviceFormatBuild[]>>;
  calendarView: 'DAY' | 'MONTH' | 'WEEK';
  setCalendarView: React.Dispatch<SetStateAction<'DAY' | 'MONTH' | 'WEEK'>>;
  cancelHandler: () => void;
  startDate: string;
  setIsCreateSectionOpened: React.Dispatch<SetStateAction<boolean>>;
  setCreateSectionState: React.Dispatch<SetStateAction<any>>;
  setIsSectionDeleteModalOpened: React.Dispatch<SetStateAction<boolean>>;
  endDate: string;
  monthDates: any[];
  campaignData?: ICampaign;
  setSelectedSection: React.Dispatch<SetStateAction<number | undefined>>;
  setTriggerLineItem: (...args: any) => void;
}

const views = ['FULL', 'PARTIAL'];

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const DraggableWrapper = ({id, children, index, moveCard, className, campaignId, ids}: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{handlerId}, drop] = useDrop<DragItem, void, {handlerId: Identifier | null}>({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{isDragging}, drag] = useDrag({
    type: 'card',
    item: () => {
      return {id, index};
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      lineItemApi.updateLineItemOrder(campaignId, ids);
      return;
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{opacity}} className={className} data-handler-id={handlerId}>
      {children}
    </div>
  );
};

const CampaignStepThreeCalendar = ({
  tableDates,
  setSelectedDates,
  lineItemStats,
  savedChannels,
  startDate,
  setIsCreateSectionOpened,
  endDate,
  calendarView,
  cancelHandler,
  handleCreateLineItem,
  setCalendarView,
  setSelectedLineItemId,
  selectedLineItemId,
  selectedDates,
  setSavedChannels,
  campaignData,
  isCampaignEditable,
  setCreateSectionState,
  setSelectedSection,
  setIsSectionDeleteModalOpened,
  setTriggerLineItem,
}: Props) => {
  const tableRef = useRef(null);
  const {t} = useTranslation();
  const {id} = useParams();
  const [updateLineItem, setUpdateLineItem] = useState<IChannelDeviceFormatBuild | undefined>(undefined);
  const [isScrollVisible, setIsScrollVisible] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const getTableSize = (tableDates: ICalendarRanges) => {
    const yearKeys = Object.keys(tableDates);
    let fractions: string[] = [];
    yearKeys.forEach((year) => {
      const datesPerYear = tableDates[year];
      const currentFractionOfYear = Object.values(datesPerYear).map((date) => `${date.length}fr`);
      fractions = fractions.concat(currentFractionOfYear);
    });
    return fractions.join(' ');
  };

  const handleSelectDay = (day: string, lineId: number) => {
    const monthDates = getMonthDatesInRange(startDate, endDate, day);
    const weekDates = filterDatesByWeek(startDate, endDate, [day]);

    const hasSelectedDayView =
      selectedDates?.some(
        (date) => date.lineId === +lineId && date.dates.some((singeDate) => singeDate === day) && date?.lineId === lineId
      ) && calendarView === 'DAY';

    const hasSelectedMonth =
      selectedDates?.some((date) => date.lineId === +lineId && date.dates.some((singleDate) => monthDates.some((b) => b === singleDate))) &&
      calendarView === 'MONTH';
    const hasSelectedWeek =
      selectedDates?.some((date) => date.lineId === +lineId && date.dates.some((singleDate) => weekDates.some((b) => b === singleDate))) &&
      calendarView === 'WEEK';
    let copy = [...selectedDates];

    const indexToReplace = copy.findIndex((lineItem) => lineItem.lineId === lineId) as number;
    const correctUpdateArr = calendarView === 'WEEK' ? weekDates : calendarView === 'MONTH' ? monthDates : [day];
    const correctRemoveArr =
      calendarView === 'WEEK'
        ? copy[indexToReplace]?.dates?.filter((date) => !weekDates.some((b) => b === date)) // WEEK VIEW REMOVER
        : calendarView === 'MONTH'
        ? copy[indexToReplace]?.dates?.filter((date) => !monthDates.some((b) => b === date)) // MONTH VIEW REMOVER
        : copy[indexToReplace].dates.filter((date) => date !== day); // DAY VIEW REMOVER
    if (!hasSelectedDayView && !hasSelectedMonth && !hasSelectedWeek) {
      // if we don't have clicked date in array, add to existing arr
      copy[indexToReplace] = {
        ...copy?.[indexToReplace],
        dates: copy[indexToReplace].dates.concat(correctUpdateArr),
      };
    } else {
      // remove that item from array
      copy[indexToReplace] = {
        ...copy[indexToReplace],
        dates: correctRemoveArr,
      };
    }
    setSelectedDates(copy);
  };

  const deleteChannelHandler = async () => {
    const {data} = await lineItemApi.deleteLineItem(String(id), String(selectedLineItemId));
    setSavedChannels(data);
    cancelHandler();
  };
  const checkIfIsSelected = (day: string, lineItemId: number) => {
    const correctList = selectedDates?.find((date) => date.lineId === lineItemId);
    const currDates = correctList?.dates ?? [];
    // const savedView = savedChannels?.find((lineItem) => lineItem.id === lineItemId)?.calendarView; //
    if (!currDates?.length) {
      // best case => if true, don't do anything, we dont have selected dates
      return '';
    }

    if (calendarView === 'MONTH') {
      switch (getMonthDates(day, startDate, endDate, currDates)) {
        case 'FULL':
          return 'selected FULL';
        case 'PART':
          return 'selected PARTIAL';
        default:
          return '';
      }
    }

    if (calendarView === 'WEEK') {
      switch (checkDatesWithinWeek(currDates, endDate, day)) {
        case 'FULL':
          return `selected FULL ${day}`;
        case 'PART':
          return 'selected PARTIAL';
        default:
          return '';
      }
    }
    if (calendarView === 'DAY') {
      return currDates.some((date) => date === day) ? 'selected FULL' : '';
    }
  };
  const handleSelectView = (view: 'DAY' | 'MONTH' | 'WEEK') => {
    setCalendarView(view);
  };
  const updateDataHandler = async () => {
    if (selectedLineItemId && id) {
      const dates = selectedDates?.find((lineItem) => lineItem.lineId === selectedLineItemId)?.dates as string[];
      const datesToSend = calendarView === 'WEEK' ? filterDatesByWeek(startDate, endDate, dates) : dates;
      const regionToSave = updateLineItem?.region
        ? updateLineItem?.region
        : updateLineItem?.lineItemRegion?.map((region) => (region as any)?.region?.id);
      const {data} = await lineItemApi.updateLineItemDates(
        updateLineItem,
        datesToSend,
        id,
        updateLineItem?.id ?? 0,
        regionToSave ?? [],
        updateLineItem?.ais ?? 0,
        updateLineItem?.priceValue ?? 0,
        calendarView,
        updateLineItem?.creativeName ?? '',
        updateLineItem?.comment ?? '',
        updateLineItem?.frequencyCap ?? false,
        updateLineItem?.valueFrequencyCap ?? 0,
        updateLineItem?.targeting ?? false
      );
      setSelectedDates(
        data?.map((lineItem) => ({
          lineId: lineItem?.id,
          dates: lineItem?.lineItemDate?.map((date) => date.date),
        }))
      );
      cancelHandler();
    }
  };

  useEffect(() => {
    const element = tableRef.current;
    if (element) {
      const hasScroll = (element as HTMLElement).scrollWidth > (element as HTMLElement).clientWidth;
      setIsScrollVisible(hasScroll);
    }
  }, [id, calendarView]);

  useEffect(() => {
    if (!!selectedLineItemId && !!savedChannels?.length && lineItemStats) {
      const correctChannel = savedChannels?.find((lineItem) => lineItem.id === Number(selectedLineItemId));

      const correctData = {
        CPC: lineItemStats?.lineItemOverview?.CPM,
        CPE: lineItemStats?.lineItemOverview?.CPE,
        CPM: lineItemStats?.lineItemOverview?.CPM,
        CPV: lineItemStats?.lineItemOverview?.CPV,

        adImpressions: correctChannel?.impressions,
        clicks: correctChannel?.clicks,
        completedViews: correctChannel?.completedViews,
        engagement: correctChannel?.engagement,
        [correctChannel!.price]: correctChannel?.priceAmount,
      };
      const shouldPrefillValues = (correctChannel?.priceValue ?? 0) < (correctChannel?.channel?.thresholdInvestment ?? 0);
      lineItemApi.getLineItemPrice(id ?? '', correctChannel?.id ?? 0, correctChannel?.channel?.thresholdInvestment ?? 0, 0).then((res) => {
        const defaultAis = res?.data?.ais;

        setUpdateLineItem({
          ...correctChannel,
          priceValue: updateLineItem?.format.linearMedia
            ? correctChannel?.priceValue
            : !shouldPrefillValues
            ? correctChannel?.priceValue
            : correctChannel?.channel?.thresholdInvestment,
          ais: updateLineItem?.format.linearMedia ? correctChannel?.ais : !shouldPrefillValues ? correctChannel?.ais : defaultAis,
          progressBar: lineItemStats?.progressBar,
          lineItemOverview: correctData,
          minAis: lineItemStats?.minAis,
          maxAis: lineItemStats?.maxAis,
          strategyPresentation: lineItemStats?.strategyPresentation,
          // lineItemOverview: lineItemStats?.lineItemOverview,
        } as unknown as IChannelDeviceFormatBuild);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLineItemId, savedChannels, lineItemStats]);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSavedChannels((prevCards: any[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as any],
          ],
        })
      );
    },
    [setSavedChannels]
  );

  const checkCalendarContent = (date: string, isSelected: boolean, currView: string, isPartialSelected = false) => {
    const isToday = moment(date).isSame(moment(), 'day');
    const isInPast = moment(date).diff(new Date(), 'days') < 0;
    //
    if (currView === 'WEEK' && isSelected && isWeekInFuture(Number(date.split('-')[1]), Number(date?.split('-')[0].split('week').at(-1)))) {
      // for week view show if week is completed and week is in the past
      return <Icon name="check-thick icon-primary-darker" />;
    }

    if (
      currView === 'WEEK' &&
      isPartialSelected &&
      isWeekInFuture(Number(date.split('-')[1]), Number(date?.split('-')[0].split('week').at(-1)))
    ) {
      // for week view show if week is partialy selected and is in the past
      return <Icon name="check-thick icon-primary-lighter" />;
    }

    if (isSelected && isInPast) {
      // for day view show icon if it's selected and in the past
      return <Icon name="check-thick icon-primary-darker" />;
    }
    if (isToday && isSelected) {
      // for day view show history icon if it's current date
      return <Icon name="history" />;
    }
    return '';
  };

  const calculateGridColumn = (left: number, right: number) => {
    return `${left} / ${right}`;
  };

  const getGridSize = (isFirstEntrance: boolean, size: number) => {
    if (isFirstEntrance) {
      return calculateGridColumn(1, size);
    } else {
      return calculateGridColumn(size, -1);
    }
  };

  const currentDayRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (currentDayRef?.current) {
      currentDayRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [currentDayRef?.current]);

  return (
    <>
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedLineItemId}
        itemName={`ID: ${selectedLineItemId}`}
        deleteHandler={deleteChannelHandler}
      />
      <div className={`d-grid calendar-scroller ${isScrollVisible ? 'hasScroll' : ''}`} style={{gridTemplateColumns: '275px auto'}}>
        <div className={`d-grid ${isScrollVisible ? 'pt-15px' : ''}`}>
          <div style={{height: 40}} className="d-flex align-items-center justify-content-evenly">
            <div onClick={() => handleSelectView('DAY')} className={`calendar-view-button ${calendarView === 'DAY' ? 'day' : ''}`}>
              <TooltipItem
                id="day-view"
                titleClasses="btn"
                classes="p-0"
                title={<Icon name="dot-box" />}
                item={{
                  placement: 'bottom',
                  text: 'Day view',
                }}
              />
            </div>
            <div onClick={() => handleSelectView('WEEK')} className={`calendar-view-button ${calendarView === 'WEEK' ? 'week' : ''}`}>
              <TooltipItem
                id="week-view"
                classes="p-0"
                titleClasses="btn"
                title={<Icon name="row-view" />}
                item={{
                  placement: 'bottom',
                  text: 'Week view',
                }}
              />
            </div>
            <div onClick={() => handleSelectView('MONTH')} className={`calendar-view-button ${calendarView === 'MONTH' ? 'month' : ''}`}>
              <TooltipItem
                titleClasses="btn"
                id="month-view"
                classes="p-0"
                title={<Icon name="col-view" />}
                item={{
                  placement: 'bottom',
                  text: 'Month view',
                }}
              />
            </div>
          </div>
          <div className="calendar-height" />
          <DndProvider backend={HTML5Backend}>
            {savedChannels?.map((lineItem, index) => {
              const isSection = !!lineItem?.section;

              return (
                <React.Fragment key={lineItem?.id}>
                  <DraggableWrapper
                    key={lineItem?.id}
                    ids={savedChannels?.map((lineItem) => lineItem.id)}
                    id={lineItem?.id}
                    campaignId={id}
                    index={index}
                    moveCard={moveCard}
                    className="row calendar-height overflow-auto align-items-center bg-lighter border justify-content-around pe-1 cursor-pointer "
                  >
                    <div
                      className="col-10 d-flex align-items-center justify-content-between"
                      style={{
                        height: '100%',
                        borderLeft: `${isSection ? 8 : 4}px solid ${
                          isSection ? lineItem?.color : (lineItem?.parent as unknown as ISection)?.color
                        }`,
                      }}
                      onClick={() => {
                        if (!isSection) {
                          setCalendarView((lineItem?.calendarView ?? 'DAY') as 'WEEK' | 'DAY' | 'MONTH');
                          setSelectedLineItemId(lineItem?.id);
                        }
                      }}
                    >
                      <div>
                        <div title={isSection ? lineItem?.title : lineItem?.channel?.name}>
                          <span className="d-flex align-items-center justify-content-start fw-bold p-0">
                            {!isSection && (
                              <CustomPopover
                                id={`${(index + 1) * -1}-line-item-channel`}
                                classes="p-0"
                                title={
                                  <ImageHandler
                                    path={(lineItem?.channel?.picture as IPicture)?.path}
                                    className="img-cover calendar-icon-image"
                                  />
                                }
                                item={{
                                  placement: 'top',
                                  description: `${lineItem?.channel?.name}`,
                                  text: (
                                    <>
                                      <div className="d-flex flex-column">
                                        {(lineItem?.channel?.picture as IPicture)?.path && (
                                          <ImageHandler
                                            path={(lineItem?.channel?.picture as IPicture)?.path}
                                            className="img-cover popover-image"
                                          />
                                        )}
                                        <div>{lineItem?.channel?.description ?? ''}</div>
                                      </div>
                                    </>
                                  ),
                                }}
                              />
                            )}
                            {truncateString(isSection ? lineItem?.title : lineItem?.channel?.name, 20)}
                          </span>
                        </div>
                        {!isSection && (
                          <TooltipItem
                            id={`${index}-creative-name`}
                            classes="p-0"
                            titleClasses="text-muted text-right fs-10px dots-text"
                            title={`${truncateString(lineItem?.creativeName ?? '', 15)} - ${lineItem?.format?.name}`}
                            item={{
                              placement: 'bottom',
                              text: (
                                <>
                                  {truncateString(lineItem?.creativeName ?? '', 15)} - {lineItem?.format?.name}
                                </>
                              ),
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {isSection && (
                      <div className="col-2 px-0">
                        <div className="d-flex align-items-center justify-content-end flex-column">
                          <div className="d-flex w-100 justify-content-end">
                            <Icon
                              name="trash"
                              onClick={() => {
                                setSelectedSection(lineItem?.id);
                                setIsSectionDeleteModalOpened(true);
                              }}
                              className="text-danger cursor-pointer me-1"
                            />
                            <Icon
                              name="edit"
                              onClick={() => {
                                setIsCreateSectionOpened(true);
                                setCreateSectionState((prev: any) => ({
                                  ...prev,
                                  title: lineItem.title,
                                  color: lineItem?.color,
                                  id: lineItem.id,
                                }));
                              }}
                              className="text-warning cursor-pointer"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-2 px-0">
                      {isCampaignEditable && !isSection && (
                        <div className="d-flex align-items-center justify-content-end flex-column">
                          <div className="d-flex w-100 justify-content-end">
                            <Icon
                              name="trash"
                              onClick={() => {
                                setSelectedLineItemId(lineItem?.id);
                                setIsDeleteModalOpened(true);
                              }}
                              className="text-danger cursor-pointer me-1"
                            />
                            <Icon
                              name="edit"
                              onClick={() => {
                                setCalendarView((lineItem?.calendarView ?? 'DAY') as 'WEEK' | 'DAY' | 'MONTH');
                                setSelectedLineItemId(lineItem?.id);
                              }}
                              className="text-warning cursor-pointer"
                            />
                          </div>
                        </div>
                      )}
                      {!isSection && (
                        <span className="w-100 text-end d-flex fs-12px fw-bold justify-content-end">
                          {formatPrice(!!lineItem.priceValue ? lineItem.priceValue : lineItem?.channel?.thresholdInvestment, 0)}
                        </span>
                      )}
                    </div>
                  </DraggableWrapper>

                  {selectedLineItemId === lineItem?.id && (
                    <div className="bg-lighter" style={{height: 496}}>
                      <UpdateLineItemForm
                        setTriggerLineItem={setTriggerLineItem}
                        lineItem={updateLineItem}
                        setLineItem={setUpdateLineItem}
                        campaignData={campaignData}
                        clickHandler={updateDataHandler}
                        cancelHandler={cancelHandler}
                        isCampaignEditable={isCampaignEditable}
                      />
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </DndProvider>
        </div>

        <div className="table-responsive cursor-pointer remove-scroller reversed-scroll" ref={tableRef}>
          <div className="w-100 d-grid child" style={{gridTemplateColumns: '1fr'}}>
            <div className="d-grid" style={{gridTemplateColumns: getTableSize(tableDates ?? {})}}>
              {Object.keys(tableDates)?.map((year) => {
                return (
                  <React.Fragment key={year}>
                    {Object.keys(tableDates[year])?.map((month) => {
                      return (
                        <div
                          style={{height: 40}}
                          key={`${month}-${year}`}
                          className="border d-flex align-items-center justify-content-center text-center fs-12px"
                        >
                          {calendarView === 'MONTH' ? (
                            <>
                              {month.slice(0, 3)} - {year}
                            </>
                          ) : (
                            <>
                              {month} - {year}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </div>
            {savedChannels?.map((channel, channelIndex) => {
              return (
                <React.Fragment key={channel?.id}>
                  <div
                    className={`d-grid ${channel?.id === selectedLineItemId ? 'bg-lighter' : ''} `}
                    key={channel?.id}
                    style={{gridTemplateColumns: getTableSize(tableDates ?? {})}}
                  >
                    {channelIndex === 0 &&
                      Object.keys(tableDates)?.map((year, yearIndex) => {
                        const firstYear = Object.keys(tableDates)[0];
                        const correctGridColumnSize = isScrollVisible
                          ? `${yearIndex + 1} / ${yearIndex + 2}`
                          : getGridSize(yearIndex === 0, Object.keys(tableDates?.[firstYear])?.length + 1);
                        return (
                          <React.Fragment key={year}>
                            <div key={year} className={'table-calendar'} style={{gridColumn: correctGridColumnSize}}>
                              {Object.values(tableDates[year])?.map((date, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {date?.map((day) => {
                                      return (
                                        <span
                                          key={day}
                                          className={`table-calendar-date fs-${
                                            calendarView === 'DAY' ? 10 : 12
                                          }px text-center center-items-flex flex-column border disabled ${
                                            isDayWeekend(day as unknown as Date) ? 'isWeekend' : ''
                                          }`}
                                        >
                                          {calendarView === 'DAY'
                                            ? renderCalendarDay(day)
                                            : calendarView === 'WEEK'
                                            ? renderCalendarWeek(day)
                                            : day}
                                        </span>
                                      );
                                    })}
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </React.Fragment>
                        );
                      })}
                    {Object.keys(tableDates)?.map((year, index) => {
                      const firstYear = Object.keys(tableDates)[0];
                      const correctGridColumnSize = channel.section
                        ? calculateGridColumn(1, Object.keys(tableDates?.[firstYear])?.length + 1)
                        : isScrollVisible
                        ? `${index + 1} / ${index + 2}`
                        : getGridSize(index === 0, Object.keys(tableDates?.[firstYear])?.length + 1);
                      return (
                        <React.Fragment key={year}>
                          <div
                            className={`table-calendar ${channel?.section ? 'bg-lighter border-top border-bottom' : ''}`}
                            style={{gridColumn: correctGridColumnSize}}
                          >
                            {_.flatten(Object.values(tableDates[year]))?.map((day) => {
                              const raw = Object.keys(channel.valueByMonth);
                              const mappedChannel = channel?.valueByMonth[raw.find((item) => item == year) ?? ''];
                              const month =
                                mappedChannel &&
                                Object.keys(mappedChannel)?.find((item: string) => {
                                  item = item.length === 1 ? '0' + item : item;

                                  return item === day?.slice(0, 2);
                                });
                              if (channel?.section) {
                                return (
                                  <div
                                    style={{
                                      width: 55,
                                      background: 'red',
                                      height: 45,
                                    }}
                                    className="bg-lighter"
                                  ></div>
                                );
                              }
                              return (
                                <span
                                  key={day}
                                  // title={calendarView === 'WEEK' && checkIfIsSelected(day, channel?.id) === 'selected PARTIAL' ?
                                  //  `${getSelectedWeekDates( selectedDates?.find((date) => date.lineId === channel?.id)?.dates || [],endDate,day)}` : ''}
                                  onClick={() => channel?.id === selectedLineItemId && handleSelectDay(day, channel?.id)}
                                  className={`table-calendar-date ${channel.section ? 'w-100' : ''} text-center border ${checkIfIsSelected(
                                    day,
                                    channel?.id
                                  )} ${isDayWeekend(day as unknown as Date) ? 'isWeekend' : ''} position-relative`}
                                >
                                  {calendarView === 'MONTH' ? (
                                    <div className="fs-10px">
                                      {checkIfIsSelected(day, channel?.id)
                                        ? (getCorrectModelKey(channel?.price, t) === 'Ad Impressions'
                                            ? 'Ais'
                                            : getCorrectModelKey(channel?.price, t)) +
                                          ': ' +
                                          (getCorrectModelKey(channel?.price, t) === 'Ad Impressions' ||
                                          getCorrectModelKey(channel?.price, t) === 'Engagement'
                                            ? Math.round(mappedChannel?.[month]) // Month view without decimals for views
                                            : mappedChannel?.[month])
                                        : ''}
                                    </div>
                                  ) : (
                                    <div
                                      ref={
                                        addNNumberOfDays(new Date().toISOString()) === day && channelIndex === 0 ? currentDayRef : undefined
                                      }
                                      // className={ `today ${channelIndex}` : ''}
                                    >
                                      {checkCalendarContent(
                                        day,
                                        checkIfIsSelected(day, channel?.id) === 'selected FULL',
                                        calendarView,
                                        checkIfIsSelected(day, channel?.id) === 'selected PARTIAL'
                                      )}
                                      {calendarView === 'WEEK' && checkIfIsSelected(day, channel?.id) === 'selected PARTIAL' && (
                                        <TooltipItem
                                          id={`${channel?.id}-${day}`}
                                          classes="p-0 h-100 w-100 start-0 top-0 position-absolute"
                                          titleClasses="btn h-100 w-100"
                                          item={{
                                            placement: 'bottom',
                                            text: `${getSelectedWeekDates(
                                              selectedDates?.find((date) => date.lineId === channel?.id)?.dates || [],
                                              endDate,
                                              day
                                            )}`,
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                </span>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                  {selectedLineItemId === channel?.id && <div style={{height: 496}} className="overflow-auto p-1 bg-lighter" />}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="mt-2 calendar-height">
          {isCampaignEditable && (
            <>
              <a
                href="#1"
                onClick={() => {
                  handleCreateLineItem();
                  setUpdateLineItem(undefined);
                }}
                className="text-decoration-underline"
              >
                {t('general.newAdChannel')}
              </a>
              <a href="#2" onClick={() => setIsCreateSectionOpened(true)} className="text-decoration-underline ms-2">
                {t('general.addSection')}
              </a>
            </>
          )}
        </div>
        <div className="d-flex justify-content-end mt-2">
          {views?.map((view) => {
            return (
              <div className="calendar-legend" key={view}>
                <div className={`box ${view}`} />
                <span>{view}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default CampaignStepThreeCalendar;
