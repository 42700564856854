import React from 'react';
import {Route} from 'react-router-dom';
import NotFound from '../components/error/NotFound';
import {OnlyPublicRoute} from '../routes/RouteGuards';
import ForgotPassword from './forgot-password';
import Login from './login';
import Register from './register';

import {Routes} from 'react-router-dom';
import Terms from '../app/terms/Terms';

export function AuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<OnlyPublicRoute component={Login} />} />
      <Route path="/auth-reset" element={<OnlyPublicRoute component={ForgotPassword} />} />
      <Route path="/auth/register" element={<OnlyPublicRoute component={Register} />} />
      <Route path="/terms-conditions" element={<Terms />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
