import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from 'react';
import {Col, FormGroup, Row} from 'reactstrap';
import {Controller, FieldErrorsImpl, FormState, UseFormRegister, UseFormSetValue} from 'react-hook-form';
import {ICampaign} from '../interfaces/ICampaigns';
import {brandApi, productApi, usersManagementApi} from '../../../api';
import {IBrand} from '../../management/brands/interfaces/IBrand';
import Select, {MultiValue, PropsValue, SingleValue} from 'react-select';
import {adjustValues, ISelect2, themeSelect} from '../../../shared/Select2';
import {usePagination} from '../../../shared/hooks/usePagination';
import {IProduct} from '../../management/products/interfaces/IProduct';
import {useTranslation} from 'react-i18next';
import {IEvent} from '../../../shared/interfaces/Event';
//@ts-ignore
import countryList from 'react-select-country-list';
import {IPicture} from '../../../shared/interfaces/Picture';
import ImageHandler from '../../../shared/ImageHandler';
import FileInput from '../../../shared/FileInput';
import {RootState} from '../../../store';
import {useSelector} from 'react-redux';
import {getCorrectCountry} from '../../../shared/Functions';

interface ICampaignFormProps {
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
      country: string[];
      user: number[];
      product: string;
      description: string;
      startDate: string;
      endDate: string;
    }>
  >;
  register: UseFormRegister<ICampaign>;
  state?: FormState<ICampaign>;
  setState?: UseFormSetValue<ICampaign>;
}

const getAllProducts = (brandId: number) => {
  return productApi
    .getAllProduct({
      page: 1,
      perPage: 0,
      brand: brandId,
    })
    .then((response) => {
      const {data} = response;
      return data;
    });
};

function getUniqueCountries(arr1: string[], arr2: any[]) {
  const merged = arr2.concat(arr1);
  return merged.filter((item) => arr1?.indexOf(item.value) !== -1);
}

const CampaignForm = ({errors, register, state}: ICampaignFormProps) => {
  const {country} = useSelector((state: RootState) => state.enum);
  const {t} = useTranslation();
  const countries = useMemo(() => {
    const countryArray = countryList().getData();
    const austriaIndex = countryArray.findIndex((country: {value: string}) => country.value === 'AT');
    // Move the Austria object to the beginning of the array
    if (austriaIndex > -1) {
      const austria = countryArray.splice(austriaIndex, 1)[0];
      countryArray.unshift(austria);
    }
    return countryArray;
  }, []);
  const [userList, setUserList] = useState<MultiValue<{label: string | number; value: number}>>([]);
  const defaultUsers = useRef<any>();
  const defaultCountries = useRef<PropsValue<{value: string; label: string}> | undefined>();
  const defaultValues = state?.defaultValues;
  const [brandList, setBrandList] = useState<IBrand[]>([]);
  const {pagination, changeFilterHandler} = usePagination();
  const {pagination: userPagination, changeFilterHandler: changeUserFilterHandler} = usePagination();
  const [productList, setProductList] = useState<IProduct[]>([]);
  const [selectedUsers, setSelectedUsers] =
    useState<MultiValue<{value: number; lastName: string; firstName: string; profilePicture: IPicture}>>();
  const [selectedCountries, setSelectedCountries] = useState<
    | PropsValue<{
        value: string;
        label: string;
      }>
    | undefined
  >([]);
  const [selectedBrand, setSelectedBrand] = useState<SingleValue<{label: string | number; value: number}>>();
  const [selectedProduct, setSelectedProduct] = useState<SingleValue<{label: string | number; value: number}>>();
  defaultUsers.current = adjustValues(defaultValues?.user as unknown as ISelect2[], 'firstName');
  const defaultProduct = adjustValues(productList as unknown as ISelect2[], 'name')?.find(
    (product) => product.value === defaultValues?.product
  );
  const defaultBrand = adjustValues(brandList as unknown as ISelect2[], 'name')?.find((brand) => brand.value === defaultValues?.brand);
  defaultCountries.current = getUniqueCountries(defaultValues?.country as string[], countries);
  const changeBrandHandler = async (event: SingleValue<{label: string | number; value: number}>) => {
    setSelectedBrand(event);
    const {data} = await getAllProducts(event?.value as number);
    setProductList(data);
  };
  useEffect(() => {
    brandApi.getAllBrands(pagination).then((response) => {
      const {data} = response;
      setBrandList(data.data);
    });
  }, [pagination]);
  useMemo(() => {
    if (defaultBrand?.id) {
      setSelectedBrand(defaultBrand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultBrand)]);
  useMemo(() => {
    if (defaultProduct?.id) {
      setSelectedProduct(defaultProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultProduct)]);

  useMemo(() => {
    if (!!defaultValues?.user) {
      setSelectedUsers(defaultUsers?.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues?.user]);

  useMemo(() => {
    if (!!defaultValues?.country) {
      setSelectedCountries(defaultCountries.current);
    }
  }, [defaultValues?.country]);
  useEffect(() => {
    const getProducts = async () => {
      const {data} = await getAllProducts(defaultValues?.brand as number);
      setProductList(data);
    };
    getProducts();
  }, [defaultValues?.brand]);

  useEffect(() => {
    const getUsers = async () => {
      const {data} = await usersManagementApi.getAllUsers(userPagination);
      setUserList(data.data);
    };

    getUsers();
  }, [userPagination]);

  return (
    <Row>
      <Col md={4}>
        <Col md={12} className="d-flex align-items-center justify-content-center flex-column">
          <>
            {!!defaultValues?.picture ? (
              <img className="h-max-375px mb-4" src={(defaultValues?.picture as IPicture)?.path} alt="Format logo" />
            ) : (
              <p>{t('general.campaignNoLogo')}</p>
            )}
          </>

          <FileInput accept="image/*" isMultiple={false} name="picture" label="File Upload" />
        </Col>
      </Col>
      <Col md={8}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="name">
                {t('general.name')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-type"
                  placeholder={`${t('general.enterName')}`}
                  {...register('name')}
                  className="form-control-lg form-control"
                />
                {errors?.name && <p className="invalid">{errors?.name?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="users">
                {t('general.users')}
              </label>
              <div className="form-control-wrap">
                <Controller
                  {...register('user')}
                  render={({field: {onChange}}) => (
                    <Select
                      theme={themeSelect}
                      isMulti
                      options={adjustValues(userList as unknown as ISelect2[], 'firstName') as any}
                      value={selectedUsers as MultiValue<any>}
                      className="form-react-select"
                      formatOptionLabel={(user: {value: number; lastName: string; firstName: string; profilePicture: IPicture}) => (
                        <div className="country-option">
                          <ImageHandler path={user.profilePicture?.path} className="user-select" />
                          <span>
                            {user?.firstName} {user?.lastName}
                          </span>
                        </div>
                      )}
                      onChange={(e) => {
                        setSelectedUsers(e);
                        onChange(e?.map((user) => user.value));
                      }}
                      onInputChange={(value) => {
                        const event: IEvent = {
                          target: {
                            name: 'name',
                            value: value,
                          },
                        };
                        changeUserFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
                      }}
                    />
                  )}
                  rules={{required: true}}
                />
                {errors?.user && <p className="invalid">{errors?.user?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="brand">
                {t('general.brand')}
              </label>
              <Controller
                {...register('brand')}
                render={() => (
                  <Select
                    theme={themeSelect}
                    value={selectedBrand}
                    placeholder={t('general.enterBrand')}
                    options={adjustValues(brandList as unknown as ISelect2[], 'name')}
                    onInputChange={(value) => {
                      const event: IEvent = {
                        target: {
                          name: 'brand',
                          value: value,
                        },
                      };
                      changeFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
                    }}
                    onChange={changeBrandHandler}
                  />
                )}
                rules={{required: true}}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="product">
                {t('general.product')}
              </label>
              <Controller
                {...register('product')}
                render={({field: {onChange}}) => (
                  <Select
                    theme={themeSelect}
                    value={selectedProduct}
                    className="form-react-select"
                    placeholder={t('general.enterProduct')}
                    isDisabled={!selectedBrand}
                    options={adjustValues(productList as unknown as ISelect2[], 'name')}
                    onChange={(val) => {
                      setSelectedProduct(val);
                      onChange(val?.value);
                    }}
                  />
                )}
              />
              {errors?.product && <p className="invalid">{errors?.product?.message}</p>}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="description">
                {t('general.description')}
              </label>
              <div className="form-control-wrap">
                <textarea
                  placeholder={`${t('general.description')}`}
                  {...register('description')}
                  className="form-control-lg form-control"
                />
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="country">
                {t('general.country')}
              </label>
              <div className="form-control-wrap">
                <Controller
                  {...register('country')}
                  render={({field: {onChange}}) => (
                    <Select
                      theme={themeSelect}
                      options={country.map((con) => ({label: getCorrectCountry(con, t), value: con}))}
                      isMulti
                      value={selectedCountries}
                      className="form-react-select"
                      formatOptionLabel={(country: {value: string; label: string}) => (
                        <div className="country-option">
                          <img
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country?.value}.svg`}
                            alt="country-flag"
                            className="country-select"
                          />
                          <span>{country?.label}</span>
                        </div>
                      )}
                      onChange={(e) => {
                        setSelectedCountries(e);
                        onChange(e?.map((country) => country.value));
                      }}
                    />
                  )}
                  rules={{required: true}}
                />

                {errors?.country && <p className="invalid">{errors?.country?.message}</p>}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CampaignForm;
