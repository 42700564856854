import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Card, CardBody, Form} from 'reactstrap';
import {invoiceApi} from '../../../api';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import FileInput from '../../../shared/FileInput';
import {IPicture} from '../../../shared/interfaces/Picture';
import InvoiceFileTable from './InvoiceFileTable';

export interface IInvoiceFiles {
  invoice: File[];
  campaign?: string;
}

const InvoiceForm = () => {
  const {id} = useParams();
  const {t} = useTranslation();
  const methods = useForm<IInvoiceFiles>();
  const [fileList, setFileList] = useState<IPicture[]>([]);

  const onFormSubmit = async (state: IInvoiceFiles) => {
    const formData = new FormData();
    for (const item of state.invoice) {
      formData.append('invoice', item);
    }
    formData.append('campaign', `${id}`);
    const {data} = await invoiceApi.postInvoice(formData, id ?? '');
    if (data) {
      methods.reset();
      getInvoice(id ?? '');
    }
  };

  const getInvoice = async (campaignId: string) => {
    const {
      data: {invoices: data},
    } = await invoiceApi.getInvoice(campaignId);
    setFileList(data);
  };

  useEffect(() => {
    if (!!id) {
      getInvoice(id);
    }
  }, [id]);
  return (
    <div className="row my-3">
      <div className="col-12">
        <Card className="card-bordered card-preview mt-2">
          <CardBody>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onFormSubmit)} className="py-3">
                <FileInput
                  accept=".pdf,.msword,.png,.jpeg,.gif,.webm,.quicktime,.mp4,.zip,.msvideo,.avi,.vnd.rar,.x-zip-compressed, .x-matroska',"
                  isMultiple={false}
                  name="invoice"
                  label="File Upload"
                />

                {!!methods.watch('invoice') && (
                  <div className="d-flex justify-content-end ">
                    <Button color="primary">
                      <Icon name="plus-sm" />
                      {t('general.upload')}
                    </Button>
                  </div>
                )}
              </Form>
            </FormProvider>
            <div className=" mt-3">
              {!!fileList?.length ? (
                <InvoiceFileTable showDelete fileList={fileList} setFileList={setFileList} />
              ) : (
                <div className="my-5 text-center">No invoice yet.</div>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default InvoiceForm;
