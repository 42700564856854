import {IPicture} from '../../../shared/interfaces/Picture';
import BaseApi from '../../base.api';

const REPORTING_ROUTE = '/api/admin/report';

export class ReportingApi extends BaseApi {
  public async getAllReportings(campaignId: string): Promise<any> {
    return await this.get(`${REPORTING_ROUTE}/${campaignId}`, undefined, {campaignId});
  }

  public async deleteReporting(campaignId: string, reportId: number): Promise<{data: {reports: IPicture[]}}> {
    return await this.delete(`${REPORTING_ROUTE}/${campaignId}/${reportId}`, '');
  }

  public async uploadReportingFile(data: FormData, campaignId: string): Promise<any> {
    return await this.post(`${REPORTING_ROUTE}/${campaignId}`, data, undefined, true);
  }
}
