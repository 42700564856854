import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {isUserAdmin} from '../../../shared/Functions';
import {ICompanyData} from '../../../shared/interfaces/Company';
import {yupResolver} from '@hookform/resolvers/yup';
import {companyFormSchema} from '../../administrator/company/components/CompanyUpdate';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import CompanyForm from '../../administrator/company/components/CompanyForm';
import {authApi, companyApi} from '../../../api';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {useTranslation} from 'react-i18next';
import {ICompany} from '../../administrator/company/interfaces/ICompany';

const MyCompany = () => {
  const {t} = useTranslation();
  const [company, setCompany] = useState<ICompany>();
  const {token} = useSelector((state: RootState) => state.auth);
  const {roles} = useSelector((state: RootState) => state?.auth?.user);
  const isCompanyAdmin = roles.some((role) => role === 'ROLE_COMPANY_ADMIN');
  const isAdmin = isUserAdmin(roles);
  const showButton = isCompanyAdmin || isAdmin;
  const methods = useForm<ICompanyData>({
    resolver: yupResolver(companyFormSchema),
  });

  const onFormSubmit = async (state: ICompanyData) => {
    const formData = new FormData();
    !!state.file && formData.append('logo', state.file[0]);
    formData.append('body', JSON.stringify(state));
    await companyApi.updateCompany(company?.id!, formData);
  };

  useEffect(() => {
    const getMe = async () => {
      const {data} = await authApi.me(token);
      setCompany(data.user.company);
      methods?.reset(data.user.company);
    };
    getMe();
  }, [methods, token]);

  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <BlockTitle tag="h4">{t('management.company.myCompany')}</BlockTitle>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <CompanyForm
                register={methods?.register}
                formState={methods?.formState}
                errors={methods?.formState?.errors}
                isAdmin={isAdmin}
                isCompanyAdmin={isCompanyAdmin}
              />
              {showButton && (
                <Row>
                  <Col>
                    <Row>
                      <Col className={'text-end'}>
                        <Button color="primary">
                          <Icon name="plus-sm" />
                          {t('general.update')}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default MyCompany;
