import React, {SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {brandApi} from '../../../../api';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import {EmptyState} from '../../../../components/shared/EmptyState';
import {IBrand} from '../interfaces/IBrand';

interface IBrandTableProps {
  brandList: IBrand[];
  setBrandList: React.Dispatch<SetStateAction<IBrand[]>>;
}

const BrandTable = ({brandList, setBrandList}: IBrandTableProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [selectedBrand, setSelectedBrand] = useState<IBrand>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const deleteBrandHandler = async () => {
    if (selectedBrand?.id) {
      const {
        data: {data},
      } = await brandApi.deleteBrand(selectedBrand?.id);
      setBrandList(data);
    }
  };
  return (
    <div className="table-responsive">
      {!!brandList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.id')}</th>
              <th scope="col">{t('general.name')}</th>
              <th scope="col" className="text-end">
                {t('general.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {brandList?.map((brand) => {
              const {id, name} = brand;
              return (
                <tr key={id}>
                  <th>{id}</th>
                  <td>{name}</td>
                  <td className="d-flex justify-content-end">
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => {
                        navigate(`/app/management/brands/update/${id}`);
                      }}
                    >
                      <Icon name="edit" />
                      {t('general.edit')}
                    </Button>
                    <Button
                      color="danger"
                      size="sm"
                      className="ms-1"
                      onClick={() => {
                        setIsDeleteModalOpened(true);
                        setSelectedBrand(brand);
                      }}
                    >
                      <Icon name="trash" />
                      {t('general.delete')}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedBrand?.id as number}
        itemName={selectedBrand?.name as string}
        deleteHandler={deleteBrandHandler}
      />
    </div>
  );
};

export default BrandTable;
