import React, {useEffect, useState} from 'react';
import Icon from '../../../components/icon/Icon';
import {Button} from 'reactstrap';
import {Block, BlockBetween, BlockHeadContent, BlockTitle, BlockDes} from '../../../components/block/Block';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../../../store';
import * as yup from 'yup';
import {phoneRegExp} from '../../../shared/Regexs';
import {FormProvider, useForm} from 'react-hook-form';
import {IUserData} from '../../../shared/interfaces/User';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import ProfileUpdateForm from './ProfileUpdateForm';
import {authApi} from '../../../api';
import {authSlice} from '../../../store/slices/auth.slice';
import CustomModal from '../../../components/modal/Modal';
import ProfileInformationForm from './ProfileInformationForm';
import {useTranslation} from 'react-i18next';

export const profileUpdateSchema = yup
  .object({
    contact: yup.object({
      country: yup.string().required('No country provided'),
      phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      city: yup.string().required('No city provided'),
      zip: yup.string().required('No zip code provided'),
      address: yup.string().required('No street address provided'),
    }),
    firstName: yup.string().required('No first name provided.').min(2, 'First name is too short - should be 2 chars minimum.'),
    lastName: yup.string().required('No last name provided.').min(2, 'Last name is too short - should be 2 chars minimum.'),
    email: yup.string().email().required('No email provided.'),
  })
  .required();
const ProfileInformation = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {user, token} = useSelector((state: RootState) => state.auth);
  const [modalTab, setModalTab] = useState('1');
  const methods = useForm<IUserData>({
    resolver: yupResolver(profileUpdateSchema),
  });

  const [showModal, setShowModal] = useState(false);
  const submitForm = async (state: IUserData) => {
    const formData = new FormData();
    !!state.file && formData.append('picture', state.file[0]);
    formData.append('body', JSON.stringify(state));
    setShowModal(false);
    const {data} = await authApi.updateMe(formData, token);
    if (data.email !== user.email) {
      localStorage.clear();
      dispatch(authSlice.actions.reset());
    } else {
      dispatch(authSlice.actions.login({user: data, token: token}));
    }
  };

  useEffect(() => {
    if (user) {
      methods.reset(user as unknown as IUserData);
    }
  }, [user, methods]);

  return (
    <React.Fragment>
      <Block size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">{t('myProfile.profileInfo.personalInformation')}</BlockTitle>
            <BlockDes>
              <p>{t('myProfile.profileInfo.basicInfo')}</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button className={'toggle btn btn-icon btn-trigger mt-n1 active d-none d-lg-block'}>
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </Block>

      <ProfileInformationForm setShowModal={setShowModal} user={user as unknown as IUserData} />

      <CustomModal
        isModalOpened={showModal}
        setIsModalOpened={setShowModal}
        title={t('myProfile.profileInfo.profileUpdate')}
        size="lg"
        saveHandler={methods.handleSubmit(submitForm)}
        body={
          <>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === '1' && 'active'}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab('1');
                  }}
                  href="#personal"
                >
                  {t('myProfile.profileInfo.personal')}
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className={`tab-pane ${modalTab === '1' ? 'active' : ''}`} id="personal">
                <FormProvider {...methods}>
                  <ProfileUpdateForm
                    register={methods.register}
                    currentState={methods?.formState?.defaultValues as IUserData}
                    errors={methods.formState.errors}
                  />
                </FormProvider>
              </div>
            </div>
          </>
        }
      />
    </React.Fragment>
  );
};
export default ProfileInformation;
