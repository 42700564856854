import React, {SetStateAction, useState} from 'react';
import {IDevice} from '../interfaces/IDevice';
import {useNavigate} from 'react-router-dom';
import {deviceApi} from '../../../../api';
import Icon from '../../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import Button from '../../../../components/button/Button';
import {EmptyState} from '../../../../components/shared/EmptyState';
import {useTranslation} from 'react-i18next';

interface IDeviceTableProps {
  deviceList: IDevice[];
  setDeviceList: React.Dispatch<SetStateAction<IDevice[]>>;
}

const DeviceTable = ({deviceList, setDeviceList}: IDeviceTableProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedDevice, setSelectedDevice] = useState<IDevice>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const deleteTypeHandler = async () => {
    if (selectedDevice?.id) {
      const {
        data: {data},
      } = await deviceApi.deleteDevice(selectedDevice?.id);
      setDeviceList(data);
    }
  };
  return (
    <div className="table-responsive">
      {!!deviceList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.id')}</th>
              <th scope="col">{t('general.name')}</th>
              <th scope="col">{t('general.description')}</th>
              <th scope="col">{t('general.status')}</th>
              <th scope="col" className="text-end">
                {t('general.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {deviceList?.map((device) => {
              const {name, id, description, status} = device;
              return (
                <tr key={id}>
                  <th>{id}</th>
                  <td>{name}</td>
                  <td>{description ?? '-'}</td>
                  <td>{status ? 'Enabled' : 'Disabled'}</td>
                  <td className="d-flex justify-content-end">
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => {
                        navigate(`/app/admin/devices/update/${id}`);
                      }}
                    >
                      <Icon name="edit" />
                      {t('general.edit')}
                    </Button>
                    {!device.isUsed && (
                      <Button
                        color="danger"
                        size="sm"
                        className="ms-1"
                        onClick={() => {
                          setIsDeleteModalOpened(true);
                          setSelectedDevice(device);
                        }}
                      >
                        <Icon name="trash" />
                        {t('general.delete')}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedDevice?.id as number}
        itemName={selectedDevice?.name as string}
        deleteHandler={deleteTypeHandler}
      />
    </div>
  );
};

export default DeviceTable;
