import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {formatApi} from '../../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import FormatForm from './FormatForm';
import {IFormat} from '../interfaces/IFormat';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {RootState} from '../../../../store';
import {useSelector} from 'react-redux';
// import {IPicture} from '../../../../shared/interfaces/Picture';

export const formatFormSchema = yup
  .object({
    type: yup.array().min(1, 'Please select one type').required('No type provided'),
    CPM: yup.number().typeError('Amount must be a number').required('Please enter CPM'),
    CPV: yup.number().typeError('Amount must be a number').required('Please enter CPV'),
    CPC: yup.number().typeError('Amount must be a number').required('Please enter CPC'),
    CPE: yup.number().typeError('Amount must be a number').required('Please enter CPE'),
    awareness: yup.number().typeError('Amount must be a number').required('Please enter awareness'),
    branding: yup.number().typeError('Amount must be a number').required('Please enter branding'),
    engagement: yup.number().typeError('Amount must be a number').required('Please enter engagement-'),
    performance: yup.number().typeError('Amount must be a number').required('Please enter performance'),
    name: yup.string().required('No name provided.').min(3, 'Name is too short - should be 3 chars minimum.'),
    description: yup.string().required('No description provided.').min(10, 'Description is too short - should be 10 chars minimum.'),
    status: yup.boolean().required(),
  })
  .required();
const FormatCreate = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {token} = useSelector((state: RootState) => state.auth);
  const methods = useForm<IFormat>({
    resolver: yupResolver(formatFormSchema),
  });
  const onFormSubmit = async (state: IFormat) => {
    const formData = new FormData();
    const pictureToSend: FileList = state.picture as FileList;
    !!state.picture && formData.append('picture', pictureToSend[0]);
    formData.append('body', JSON.stringify(state));
    const {data} = await formatApi.createFormat(formData, token);
    if (data) {
      navigate('/app/admin/format');
    }
  };
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('administrator.formats.formatCreate')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate('/app/admin/format');
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <FormatForm
                register={methods?.register}
                errors={methods?.formState?.errors}
                formState={methods?.formState}
                watch={methods?.watch}
              />
              <Row>
                <Col>
                  <Row>
                    <Col className={'text-end'}>
                      <Button color="primary">
                        <Icon name="plus-sm" />
                        {t('general.create')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default FormatCreate;
