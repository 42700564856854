import moment from 'moment';
import React from 'react';
import {IThread} from '../app/campaigns/components/steps/CampaignStep9';
import Icon from '../components/icon/Icon';
import {IPicture} from './interfaces/Picture';
import {EPrices} from './IPrices';

interface WeekView {
  weekNumber: number;
  startDate: string;
  endDate: string;
}

export interface ICalendarRanges {
  [key: string]: {
    [key: string]: string[];
  };
}

export const getCorrectCountry = (country: string, t: any) => {
  return t(`general.countryList.${country.toUpperCase()}`);
};

export const formatDatepickerTime = (date: string) => {
  return moment(date).format('YYYY-MM-DD');
};

export function isFileApplication(fileName: string) {
  const applicationExtensions = ['.pdf', '.xls', '.doc', '.docx'];
  const extension = fileName?.substring(fileName.lastIndexOf('.'));
  return applicationExtensions.includes(extension?.toLowerCase()) ?? false;
}

export function enumerateDaysBetweenDates(startDate: string, endDate: string) {
  let years: any = {};
  while (moment(startDate) <= moment(endDate)) {
    const month: string = moment(startDate).format('MMMM');
    const year: string = moment(startDate).format('YYYY');
    if (!years[year]) {
      years[year] = [];
    }
    if (!years[year][month]) {
      years[year][month] = [];
    }
    years[year][month] = [...(years[year][month] ?? []), moment(startDate).format('YYYY-MM-DD')];
    startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
  }
  return years;
}

export const getMonthRangesInRange = (startDate: string, endDate: string): ICalendarRanges => {
  const years: any = {};
  let currentDate = moment(startDate).startOf('month');
  while (currentDate.isSameOrBefore(endDate)) {
    const monthName = currentDate.format('MMMM');
    const currentYear = currentDate.format('YYYY');
    const monthStart = currentDate.format('MM.YYYY');
    if (!years[currentYear]) {
      years[currentYear] = [];
    }
    if (!years[currentYear][monthName]) {
      years[currentYear][monthName] = [];
    }
    years[currentYear][monthName] = years[currentYear][monthName].concat(monthStart);
    currentDate = moment(currentDate).add(1, 'month');
  }
  return years;
};

export function checkDatesWithinWeek(dateList: string[], endDate: string, week: string) {
  const onlyWeek = week.split('-')[0];
  const year = Number(week.split('-')[1]);
  const weekDates = getDatesForWeek(onlyWeek, endDate, year);
  if (weekDates.length > 0 && weekDates.every((date) => dateList.some((dateItem) => dateItem === date))) {
    return 'FULL';
  } else if (weekDates.length > 0 && weekDates.some((date) => dateList.some((dateItem) => dateItem === date))) {
    return 'PART';
  }
  return 'NONE';
}

export function getSelectedWeekDates(dateList: string[], endDate: string, week: string) {
  const onlyWeek = week.split('-')[0];
  const year = Number(week.split('-')[1]);
  const weekDates = getDatesForWeek(onlyWeek, endDate, year);
  return dateList.filter((date) => weekDates.some((dateItem) => dateItem === date));
}

export function getDatesForWeek(week: string, endDate: string, year: number) {
  const weekNumber = Number(week.slice(4));
  const end = moment(endDate);
  // Find the first Monday of the specified year
  const startOfWeek = moment().year(year).isoWeek(weekNumber).startOf('isoWeek');
  // Find the end date of the specified week
  const endOfWeek = startOfWeek.clone().endOf('isoWeek');

  const dates = [];
  let currentDate = startOfWeek.clone();

  // Iterate through each day of the week and add it to the dates array
  while (currentDate.isSameOrBefore(endOfWeek) && currentDate.isSameOrBefore(end)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate.add(1, 'day');
  }

  return dates;
}

export function getMonthDates(monthYear: string, startDate: string, endDate: string, currDates: string[]) {
  const [month, year] = monthYear.split('.');
  const start = moment(startDate);
  const end = moment(endDate);
  const dates = [];

  // Loop from the start date to the end date
  for (let current = moment(start); current.isSameOrBefore(end); current.add(1, 'day')) {
    if (current.month() === parseInt(month) - 1 && current.year() === parseInt(year)) {
      dates.push(current.format('YYYY-MM-DD'));
    }
  }
  if (dates?.every((date) => currDates.some((currDate) => currDate === date))) {
    return 'FULL';
  } else if (dates?.some((date) => currDates.some((currDate) => currDate === date))) {
    return 'PART';
  }
  return '';
}

export const getMonthDatesInRange = (startDate: string, endDate: string, monthYear: string): string[] => {
  const dates: string[] = [];
  const monthStart = moment(monthYear, 'MM.YYYY').startOf('month');
  const monthEnd = moment(monthYear, 'MM.YYYY').endOf('month');
  let currentDate = moment(startDate, 'YYYY-MM-DD');
  const end = moment(endDate, 'YYYY-MM-DD');
  while (currentDate.isSameOrBefore(end, 'day')) {
    if (currentDate.isBetween(monthStart, monthEnd, 'month', '[]')) {
      dates.push(currentDate.format('YYYY-MM-DD'));
    }
    currentDate.add(1, 'day');
  }
  return dates;
};

export function filterDatesByWeek(startDate: string, endDate: string, dates: string[]): string[] {
  // definiranje formata datuma
  const dateFormat: string = 'YYYY-MM-DD';

  // pretvaranje ulaznih datuma u Moment objekte
  const start = moment(startDate, dateFormat).startOf('isoWeek');
  const end = moment(endDate, dateFormat).endOf('isoWeek');

  // niz za spremanje generiranih datuma
  const generatedDates: string[] = [];

  // prolazak kroz ulazni niz datuma i generiranje novih datuma za weekWW
  for (const date of dates) {
    if (date.startsWith('week')) {
      // izdvajanje broja sedmice iz niza
      const weekNumber = Number(date.substr(4).split('-')[0]) - 1; // Convert to number
      const year = date.split('-')[1];

      // Calculate the start and end of the week correctly
      const firstDayOfYear = moment(`${year}-01-01`).startOf('isoWeek');
      const startOfWeek = firstDayOfYear.clone().add(weekNumber * 7, 'days');
      const endOfWeek = startOfWeek.clone().endOf('isoWeek');

      // dodavanje svih datuma iz trenutne sedmice u niz, s tim da se uzima u obzir početni i krajnji datum
      let currentDate = start.clone().isBefore(startOfWeek) ? startOfWeek.clone() : start.clone();
      while (currentDate.isSameOrBefore(endOfWeek) && currentDate.isSameOrBefore(end)) {
        generatedDates.push(currentDate.format(dateFormat));
        currentDate.add(1, 'day');
      }
    } else {
      // dodavanje pojedinačnog datuma u niz, s tim da se uzima u obzir početni i krajnji datum
      const currentDate = moment(date, dateFormat);
      if (currentDate.isSameOrAfter(start) && currentDate.isSameOrBefore(end)) {
        generatedDates.push(date);
      }
    }
  }

  const uniqueDates = generatedDates.filter((date, index) => {
    return generatedDates.indexOf(date) === index;
  });

  // sortiranje datuma po datumu
  uniqueDates.sort((a, b) => moment(a, dateFormat).diff(moment(b, dateFormat)));

  return uniqueDates ?? [];
}

export function isWeekInFuture(year: number, weekNumber: number) {
  const currDate = new Date().getTime();
  const targetWeek = new Date(moment().year(year).week(weekNumber).toDate()).getTime();
  return targetWeek < currDate;
}

export const getMonthWeeksInRange = (startDate: string, endDate: string): ICalendarRanges => {
  const years: ICalendarRanges = {};
  let currentDate = moment(startDate).startOf('week');
  while (currentDate.isSameOrBefore(endDate)) {
    const month = currentDate.format('MMMM');
    const year = currentDate.format('YYYY');
    const weekNumber = currentDate.isoWeek();
    const currentWeek: WeekView = {
      weekNumber,
      startDate: moment(currentDate).startOf('week').format('YYYY-MM-DD'),
      endDate: moment(currentDate).endOf('week').format('YYYY-MM-DD'),
    };
    if (!Object.keys(years).includes(year)) {
      years[year] = {};
    }
    if (!years[year][month]) {
      years[year][month] = [];
    }
    years[year][month] = years[year][month].concat(`week${currentWeek.weekNumber}-${year}`);
    currentDate = currentDate.add(1, 'week');
  }
  return years;
};

export const getCorrectModelKey = (model: string, t: any) => {
  switch (model) {
    case EPrices.CPC:
      return t('campaigns.clicks');
    case EPrices.CPM:
      return t('campaigns.adImpressions');
    case EPrices.CPV:
      return t('campaigns.views');
    case EPrices.CPE:
      return t('campaigns.engagement');
    default:
      return undefined;
  }
};
export const formatNumber = (
  value: number | bigint,
  decSpaces?: 0 | 1 | 4 | 2 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | undefined
) => {
  return value?.toLocaleString('de-DE', {
    minimumFractionDigits: decSpaces ?? 2,
    maximumFractionDigits: decSpaces ?? 2,
  });
};

export const formatPrice = (price: number | bigint, decSpaces = 2) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: decSpaces,
    maximumFractionDigits: decSpaces,
  }).format(price ?? 0);
};

export const getCorrectCampaignStatus = (status: string) => {
  switch (status) {
    case 'DRAFT':
      return 'Draft';
    case 'REJECT':
      return 'Rejected';
    case 'PENDING':
      return 'Pending';
    case 'IN_PROGRESS':
      return 'In progress';
    case 'FINISHED':
      return 'Finished';
    case 'ON_AIR':
      return 'On Air';
    default:
      return status;
  }
};

export const getCorrectPaymentStatus = (payment: string) => {
  switch (payment) {
    case 'INVOICED':
      return 'Invoiced';
    case 'PAID':
      return 'Paid';
    default:
      return '-';
  }
};

export const getCorrectEnums = (param: string) => {
  let res = '';

  if (!!param.includes('_')) {
    const arrWords = param.split('_');
    arrWords.map((item: string) => (res = res + ' ' + item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()));
  } else {
    res = param.charAt(0).toUpperCase() + param.slice(1).toLocaleLowerCase();
  }

  return res;
};

export const getAvatarInitials = (firstName: string, lastName: string) => {
  return `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}`;
};

export const getDayName = (date: string) => {
  return moment(date).format('dddd');
};

export const addNNumberOfDays = (inputDateStr: string, nbToAdd = 7) => {
  // Parse input date using Moment.js
  const inputDate = moment(inputDateStr, 'YYYY-MM-DD');

  // Add 7 days
  const outputDate = inputDate.add(nbToAdd, 'days');

  // Return the result as a formatted date string
  return outputDate.format('YYYY-MM-DD');
};

export const renderCalendarWeek = (date: string) => {
  return date.split('-')[0].split('week').join('W ');
};

export const renderCalendarDay = (day: string) => {
  return (
    <>
      {moment(day).format('DD')}.
      <br />
      <span>{getDayName(day)}</span>
    </>
  );
};

export const isDayWeekend = (date: Date) => {
  const dayOfWeek = new Date(date)?.getDay();
  return [0, 6].includes(dayOfWeek);
};

export const getNotificationIcon = (status: string) => {
  switch (status) {
    case 'CAMPAIGN_CREATED':
      return <Icon name="info-fill" />;

    default:
      return null;
  }
};

export const isUserAdmin = (roles: string[]) => {
  return roles?.some((role) => role === 'ROLE_ADMIN');
};
export const isUserCompanyAdmin = (roles: string[]) => {
  return roles?.some((role) => role === 'COLE_COMPANY_ADMIN');
};

export const isUserChannelUser = (roles: string[]) => {
  return roles?.every((role) => role === 'ROLE_CHANNEL_USER');
};

export const adjustAiChatBox = (message: IThread, index: number, profilePicture?: IPicture) => {
  const isMyMessage = Object.keys(message).some((msgKey) => msgKey === 'user');
  return {
    ...message,
    id: index,
    message: message?.user || message?.assistant,
    isMyMessage,
    logo: isMyMessage ? profilePicture?.path : 'https://www.svgrepo.com/show/306500/openai.svg',
  };
};
export const truncateString = (string: string, nbChar: number) => {
  return string?.length > nbChar ? string.slice(0, nbChar) + '...' : string;
};
