import React, {useState} from 'react';
import {Form, FormGroup} from 'reactstrap';
import {Link, useNavigate} from 'react-router-dom';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/button/Button';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {authApi} from '../../../api';
import {useDispatch} from 'react-redux';
import {authSlice} from '../../../store/slices/auth.slice';
import {ILoginData} from '../../interfaces/Login';

const schema = yup.object({
  email: yup.string().email().required('No email provided.').required(),
});
const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passState, setPassState] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<ILoginData>({
    resolver: yupResolver(schema),
  });

  const onFormSubmit = async (formData: ILoginData) => {
    const {data} = await authApi.login(formData);

    dispatch(authSlice.actions.login(data));
    localStorage.setItem('token', data.token);
    localStorage.setItem('appVersion', data.appVersion);
    localStorage.setItem('roles', JSON.stringify(data?.user?.roles));
    navigate('/');
  };
  return (
    <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
      <FormGroup>
        <div className="form-label-group">
          <label className="form-label" htmlFor="default-01">
            Email or Username
          </label>
        </div>
        <div className="form-control-wrap">
          <input
            type="text"
            {...register('email')}
            id="fv-full-email"
            placeholder="Enter your email address or username"
            className="form-control-lg form-control"
          />
          {errors.email && (
            <span id="fv-full-email-error" className="invalid">
              {errors.email?.message}
            </span>
          )}
        </div>
      </FormGroup>
      <FormGroup>
        <div className="form-label-group">
          <label className="form-label" htmlFor="password">
            Password
          </label>
          <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
            Forgot Password?
          </Link>
        </div>
        <div className="form-control-wrap">
          <a
            href="src/authentification/login/Login#password"
            onClick={(ev) => {
              ev.preventDefault();
              setPassState(!passState);
            }}
            className={`form-icon lg form-icon-right passcode-switch ${passState ? 'is-hidden' : 'is-shown'}`}
          >
            <Icon name="eye" className="passcode-icon icon-show" />

            <Icon name="eye-off" className="passcode-icon icon-hide" />
          </a>
          <input
            type={passState ? 'text' : 'password'}
            id="fv-full-password"
            {...register('password')}
            placeholder="Enter your password"
            className={`form-control-lg form-control ${passState ? 'is-hidden' : 'is-shown'}`}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <Button size="lg" className="btn-block" type="submit" color="primary">
          Sign in
        </Button>
      </FormGroup>
    </Form>
  );
};

export default LoginForm;
