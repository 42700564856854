import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Nav, NavItem, NavLink} from 'reactstrap';
import {campaignApi} from '../../../../api';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {RootState} from '../../../../store';
import CustomModal from '../../../../components/modal/Modal';
import {ICampaign} from '../../interfaces/ICampaigns';

interface Props {
  campaignId?: number;
  activeStep: number;
  currentCampaignStep: number;
  isCreate?: boolean;
  status: string;
  name?: string;
  strategyFile?: any;
}

export interface IStrategyFile {
  strategyPresentation: File[];
  campaign?: string;
}

const OpenAiIcon = () => {
  return (
    <svg fill="#526484" width="28px" height="28px" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg">
      <title>OpenAI icon</title>
      <path d="M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z" />
    </svg>
  );
};

const ChangeStep = ({campaignId, activeStep = 1, currentCampaignStep = 1, isCreate = false, status, name, strategyFile}: Props) => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {isApprover, roles} = useSelector((state: RootState) => state?.auth?.user);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [strategyPresentation, setStrategyPresentation] = useState<any>();
  const [campaignData, setCampaignData] = useState<ICampaign>();
  const isAdmin = roles?.some((role) => role === 'ROLE_ADMIN');
  const campaignSteps = campaignData?.openAi
    ? ['Details', 'Objective', 'Media Plan', 'Chart', 'Creatives', 'Reporting', 'AI', 'Invoice', 'Comments']
    : ['Details', 'Objective', 'Media Plan', 'Chart', 'Creatives', 'Reporting', 'Invoice', 'Comments'];

  const handleChangeStep = (stepId: number) => {
    if (isTabDisabled(stepId)) {
      return;
    }
    !isCreate && navigate(`/app/campaigns/${stepId}/${campaignId}`);
  };
  const isTabDisabled = (stepId: number) => {
    if (stepId > currentCampaignStep && currentCampaignStep < 3) {
      return 'opacity-25 cursor-not-allowed';
    }
  };

  const approveCampaignHandler = async () => {
    await campaignApi.updateCampaignStatus(Number(campaignId));
    navigate('/app/campaigns');
  };

  const rejectCampaignHandler = async () => {
    await campaignApi.rejectCampaign(Number(campaignId));
    navigate('/app/campaigns');
  };

  const getCorrectCampaignIcon = (stepId: string) => {
    switch (stepId) {
      case 'Details':
        return 'book-fill';
      case 'Objective':
        return 'calendar-fill';
      case 'Media Plan':
        return 'coin-alt-fill';
      case 'Chart':
        return 'chart-up';
      case 'Creatives':
        return 'box';
      case 'Reporting':
        return 'chart-up';
      case 'Android':
        return 'android';
      case 'Invoice':
        return 'file-docs';
      case 'Comments':
        return 'msg';
      default:
        return 'book-fill';
    }
  };

  const onFormSubmit = async (event: any) => {
    const files = event.target.files;
    const formData = new FormData();
    for (const item of files) {
      formData.append('strategyPresentation', item);
    }
    formData.append('campaign', `${campaignId}`);
    if (!files.length) return;
    const {data} = await campaignApi.uploadStrategyFile(formData);
    setStrategyPresentation(data[0]);
  };

  const downloadFile = () => {
    const url = URL.createObjectURL(new Blob([strategyPresentation.file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', strategyPresentation?.file.nameOriginal!);
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    if (id) {
      const getCampaign = async () => {
        const {data} = await campaignApi.getCampaign(+id!);
        setCampaignData(data);
      };
      getCampaign();
    }
  }, []);

  useEffect(() => {
    setStrategyPresentation(strategyFile);
  }, [strategyFile]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-top my-2 mx-1">
        <h4 className="nk-block-title">{name}</h4>
        <div>
          {isApprover && status === 'DRAFT' && (
            <div className="text-end mb-2">
              <div className="gap-3 h-auto">
                <Button color="danger" className="me-2 btn-dim" onClick={rejectCampaignHandler}>
                  <Icon name="property-remove" className="pe-1" />
                  {t('general.rejectCampaign')}
                </Button>
                <Button color="success" onClick={() => setIsModalOpened(true)}>
                  <Icon name="check" className="pe-1 btn-dim" />
                  {t('general.approveCampaign')}
                </Button>
              </div>
            </div>
          )}

          {activeStep === 3 && (
            <div className="d-flex align-items-center justify-content-end mb-0">
              {!!isAdmin && (
                <>
                  <input type="file" name="import" id="import" className="d-none" onChange={(e) => onFormSubmit(e)}></input>
                  <label htmlFor="import" className="btn btn-primary">
                    {t('campaigns.uploadStrategy')}
                  </label>
                </>
              )}

              {!!strategyPresentation && (
                <Button color="primary" className="ms-2" onClick={() => downloadFile()}>
                  <Icon name="download" className="cursor-pointer" />
                  <span>{t('campaigns.downloadStrategy')}</span>
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <Nav tabs className="nav-tabs-s2">
        {campaignSteps?.map((step, index) => {
          return (
            <>
              <NavItem key={step}>
                <NavLink
                  tag="a"
                  className={activeStep === index + 1 ? 'active' : `cursor-pointer ${isTabDisabled(index + 1)}`}
                  onClick={() => handleChangeStep(index + 1)}
                >
                  {index + 1 === 7 && campaignData?.openAi ? (
                    <div className="me-1">
                      <OpenAiIcon />
                    </div>
                  ) : (
                    <Icon name={getCorrectCampaignIcon(step)} className="fs-2" />
                  )}
                  <span>{step}</span>
                </NavLink>
              </NavItem>
            </>
          );
        })}
      </Nav>
      <CustomModal
        setIsModalOpened={setIsModalOpened}
        saveHandler={approveCampaignHandler}
        successName={t('general.approve') as string}
        size="xl"
        isModalOpened={isModalOpened}
        title="Unsere Bedingungen"
        body={
          <p>
            {process.env.REACT_APP_PROJECT_NAME} ist eine wertschöpfungsorientierte Omnichannel-Media-Lösung. Die Kombination der
            Geschäftsbeziehungen von {process.env.REACT_APP_PROJECT_NAME} mit Medien und Vermarktern, der Skalierbarkeit unserer Daten,
            unseren Talenten und unseren innovativen Technologien ermöglicht es, für unsere Kunden, maßgeschneiderte Lösungen und zählbare
            Vorteile zu liefern, die auf die Ziele der Kunden einzahlen.Unser Prinzip {process.env.REACT_APP_PROJECT_NAME} nutzt
            spezialisierte Ressourcen aus allen Bereichen der digitalen und analogen Mediaplanung, wobei sich die einzelnen Komponenten je
            nach Kunde, Kampagne und Taktik unterscheiden können. Die Komponenten, die von {process.env.REACT_APP_PROJECT_NAME} zur
            Verfügung gestellt werden, entsprechen den einzelnen Line-Items im Planungsteil des Tools. Diese Komponenten sind im
            Wesentlichen Media-Flächen, die von {process.env.REACT_APP_PROJECT_NAME} auf eigene Kosten und eigenes Risiko erworben werden
            und gegebenenfalls durch den Einsatz von Technologie und Daten verbessert werden. Das heißt:{' '}
            {process.env.REACT_APP_PROJECT_NAME} agiert im eigenen Namen und auf eigene Rechnung und übernimmt daher das Lieferrisiko
            gegenüber dem Kunden.Die maßgeschneiderte Mischung der Komponenten, die wir für Sie zusammenstellen, wird im Planungsteil des
            Tools festgehalten. Komponenten, für die {process.env.REACT_APP_PROJECT_NAME} keine Garantie übernimmt, werden im Tool separat
            markiert. Wenn Sie einen Media-Plan freigeben, sehen Sie die Kosten, zu deren Zahlung Sie sich verpflichten (zzgl. Steuern). Die
            Kosten enthalten alle Nachlässe und Rabatte sowie die Werbeabgabe oder Digitalsteuer. Die {process.env.REACT_APP_PROJECT_NAME}{' '}
            zugrundeliegenden Kosten, Rechnungen und Transaktionen sind nicht einsichtbar und nicht auditierbar. Wir führen ständig ein
            Benchmarking unserer Leistungen durch und vereinbaren mit unseren Kunden im Voraus, wie die Leistung transparent evaluiert
            werden kann.
          </p>
        }
      ></CustomModal>
    </>
  );
};

export default ChangeStep;
