import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {authApi, baseApi, enumApi} from './api';
import {AppRoutes} from './app/routes';
import {AuthRoutes} from './authentification/routes';
import Loader from './components/shared/Loader';
import Toaster from './components/shared/Toaster';
import {customHistory} from './index';
import './index.scss';
import Layout from './layout';
import ErrorBoundary from './shared/ErrorBoundary';
import {authSlice} from './store/slices/auth.slice';
import {enumSlice} from './store/slices/enum.slice';
import Confirm505Err from './components/modal/Confirm550Err';

function App() {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  (customHistory as any).navigate = useNavigate();
  useEffect(() => {
    const initializeUser = async (token: string) => {
      try {
        const {data} = await authApi.me(token);

        localStorage.setItem('roles', JSON.stringify(data?.user?.roles));
        baseApi.updateHeader(token, data?.appVersion);

        dispatch(
          authSlice.actions.login({
            user: data.user,
            token,
          })
        );
      } catch {
        localStorage.clear();
      }
    };
    const token = localStorage.getItem('token');
    if (token) {
      initializeUser(token);
    }
  }, [dispatch]);

  useEffect(() => {
    enumApi.getEnums().then((response) => {
      const {data} = response;
      dispatch(enumSlice.actions.setEnums(data));
    });
  }, [dispatch]);

  return (
    <>
      <Loader />
      <Toaster />
      <Confirm505Err />
      <Layout>
        <ErrorBoundary key={pathname}>
          <Routes>
            <Route path="/app/*" element={<AppRoutes />} />
            <Route path="/*" element={<AuthRoutes />} />
          </Routes>
        </ErrorBoundary>
      </Layout>
    </>
  );
}

export default App;
