import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Card} from 'reactstrap';
import {brandApi} from '../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import {Pagination} from '../../../components/pagination/Pagination';
import {usePagination} from '../../../shared/hooks/usePagination';
import BrandFilters from './components/BrandFilters';
import BrandTable from './components/BrandTable';
import {IBrand} from './interfaces/IBrand';

const Brands = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [brandList, setBrandList] = useState<IBrand[]>([]);

  const {changeFilterHandler, page, setTotalPages, totalPages, handlePages, pagination} = usePagination();
  useEffect(() => {
    brandApi.getAllBrands(pagination).then((response) => {
      const {data} = response;
      setBrandList(data.data);
      setTotalPages(Math.ceil(data?.count / data?.perPage));
    });
  }, [pagination, setTotalPages]);
  return (
    <>
      <Block size="lg" className="mt-3">
        <BlockHead>
          <BlockHeadContent>
            <div className="row align-items-center gx-0 gx-lg-2">
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-12">
                <BlockTitle tag="h4">{t('management.brands.brandList')}</BlockTitle>
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-4 col-12  text-end">
                <Button
                  className="btn-block"
                  color="primary"
                  onClick={() => {
                    navigate('/app/management/brands/create');
                  }}
                >
                  <Icon name="plus-sm" />
                  {t('management.brands.addNew')}
                </Button>
              </div>
            </div>
            <BrandFilters changeFilterHandler={changeFilterHandler} pagination={pagination} />
          </BlockHeadContent>
        </BlockHead>
        <Card className="card-bordered card-preview">
          <BrandTable brandList={brandList} setBrandList={setBrandList} />
        </Card>
      </Block>

      <div className="d-flex justify-content-end">
        {totalPages <= 1 ? '' : <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />}
      </div>
    </>
  );
};

export default Brands;
