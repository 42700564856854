import React, {SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Button} from 'reactstrap';
import {reportingApi} from '../../../api';
import Icon from '../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../components/modal/ConfirmDeleteModal';
import {IPicture} from '../../../shared/interfaces/Picture';

interface Props {
  fileList: IPicture[];
  showDelete?: boolean;
  setFileList?: React.Dispatch<SetStateAction<IPicture[]>>;
}

const ReportingFileTable = ({fileList, showDelete = false, setFileList}: Props) => {
  const {t} = useTranslation();
  const {id} = useParams();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(0);
  const deleteFileHandler = async () => {
    const {data} = await reportingApi.deleteReporting(String(id), selectedFileId);
    if (data && setFileList) {
      setIsDeleteModalOpened(false);
      setSelectedFileId(0);
      setFileList(data.reports);
    }
  };
  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">{t('general.name')}</th>

              <th scope="col" className="text-end">
                {t('general.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {fileList?.map((file) => {
              return (
                <tr key={file?.id}>
                  <td>{file?.id}</td>
                  <td>{file?.file?.nameOriginal ?? '-'}</td>

                  <td className="text-end">
                    <a href={file?.path} target="_blank" rel="noreferrer">
                      <Button color="primary" size="sm">
                        <Icon name="download" className="cursor-pointer" />
                        <span>{t('general.download')}</span>
                      </Button>
                    </a>
                    {showDelete && (
                      <Icon
                        name="trash"
                        onClick={() => {
                          setIsDeleteModalOpened(true);
                          setSelectedFileId(file?.id);
                        }}
                        className="text-danger ms-2 cursor-pointer"
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedFileId}
        itemName={`ID: ${selectedFileId}`}
        deleteHandler={deleteFileHandler}
      />
    </>
  );
};

export default ReportingFileTable;
