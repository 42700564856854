import React from 'react';
import {FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {IBrand} from '../interfaces/IBrand';
import {Col, FormGroup, Row} from 'reactstrap';
import {useTranslation} from 'react-i18next';

interface IBrandFormProps {
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
    }>
  >;
  register: UseFormRegister<IBrand>;
}

const BrandForm = ({errors, register}: IBrandFormProps) => {
  const {t} = useTranslation();
  return (
    <Row>
      <Col md={8}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="name">
                {t('general.name')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-type"
                  {...register('name')}
                  placeholder={t('management.brands.enterBrand') as string}
                  className="form-control-lg form-control"
                />
                {errors?.name && <p className="invalid">{errors?.name?.message}</p>}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BrandForm;
