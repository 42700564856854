import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Select from 'react-select';
import {Card} from 'reactstrap';
import {objectiveApi} from '../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import {formatDatepickerTime} from '../../../shared/Functions';
import {themeSelect} from '../../../shared/Select2';
import {RootState} from '../../../store';
import ObjectiveScoringUpdateTable from './components/ObjectiveScoringUpdateTable';
import {IChannels, IObjectiveScoring} from './interfaces/IObjectiveScoring';

export const calculatePrice = (arr: number[], index: number, valueToUpdate: number) => {
  return arr?.map((item, ind) => {
    if (ind === index) {
      return valueToUpdate;
    }
    return item;
  });
};

export interface ICountry {
  label: string;
  value: string;
}

const UpdateObjectiveScoring = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    label: t('general.countryList.AT'),
    value: 'AT',
  });
  const [objectiveList, setObjectiveList] = useState<IObjectiveScoring>();
  const {country} = useSelector((state: RootState) => state.enum);
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, channelId: number, indexToReplace: number, objective: string) => {
    const {valueAsNumber} = event.target;

    const copyChannels = objectiveList?.channels;
    let itemToReplace = copyChannels?.find((channel) => channel?.channelId === channelId && channel.objective === objective);
    itemToReplace = {
      ...itemToReplace,
      scoring: calculatePrice(itemToReplace?.scoring as number[], indexToReplace, valueAsNumber),
    } as IChannels;
    const combined = copyChannels?.map((item) => {
      if (item.channelId === itemToReplace?.channelId && item.objective === itemToReplace.objective) {
        return itemToReplace;
      }
      return item;
    });
    setObjectiveList((prev: any) => ({...prev, channels: combined as any}));
  };

  const exportHandler = async () => {
    const currDate = formatDatepickerTime(new Date().toISOString());
    const selectedLang = t(`general.countryList.${selectedCountry?.value}`);
    const fileName = `${currDate}-${selectedLang}-objectives.xlsx`;
    await objectiveApi.exportObjective(selectedCountry?.value).then((res) => {
      const url = URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  };

  const importHandler = async (event: any) => {
    const files = event.target.files;
    const formData = new FormData();
    formData.append('file', files[0]);

    const {data} = await objectiveApi.importObjective(formData);
    setSelectedCountry({
      value: data.country,
      label: t(`general.countryList.${data.country}`),
    });
    setObjectiveList(data);
  };

  const submitHandler = async () => {
    const {data} = await objectiveApi.updateObjective(objectiveList?.channels as IChannels[], selectedCountry?.value);
    setObjectiveList(data);
    navigate('/app/admin/objective-scoring');
  };

  useEffect(() => {
    const getAllObjectives = async () => {
      const {data} = await objectiveApi.getObjective(selectedCountry?.value);
      setObjectiveList(data);
    };
    getAllObjectives();
  }, [selectedCountry?.value]);
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="d-flex justify-content-between align-items-center">
            <BlockTitle tag="h4">{t('general.update')} objective scoring</BlockTitle>
            <div className="d-flex gap-3 align-items-center h-auto">
              <Select
                theme={themeSelect}
                value={selectedCountry}
                className="form-react-select"
                placeholder={t('administrator.formats.selectType')}
                options={
                  country?.map((countr) => ({
                    value: countr,
                    label: t(`general.countryList.${countr}`),
                  })) as ICountry[]
                }
                onChange={(selected) => {
                  setSelectedCountry(selected as ICountry);
                }}
              />
              <input type="file" name="import" id="import" className="d-none" onChange={importHandler}></input>
              <label htmlFor="import" className="btn btn-primary">
                {t('general.import')}
              </label>

              <Button onClick={exportHandler} color="primary">
                {t('general.export')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview calendar-scroller">
        <ObjectiveScoringUpdateTable objectiveList={objectiveList as IObjectiveScoring} changeHandler={changeHandler} />
      </Card>
      <div className="d-flex justify-content-end mt-3">
        <Button color="primary" onClick={submitHandler}>
          <Icon name="plus-sm" />
          {t('general.update')}
        </Button>
      </div>
    </Block>
  );
};

export default UpdateObjectiveScoring;
