import React, {useEffect, useState} from 'react';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import {Card} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import QualityObjectiveTable from './components/QualityObjectiveTable';
import {qualityObjectiveApi} from '../../../api';
import {IQualityObjective} from './interfaces/IQualityObjective';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';

const QualityObjective = () => {
  const {t} = useTranslation();
  const [qualityObjectiveList, setQualityObjectiveList] = useState<IQualityObjective[]>([]);
  const changeQualityHandler = (event: React.ChangeEvent<HTMLInputElement>, qualityId: number) => {
    const {name, valueAsNumber} = event.target;
    const copy = [...qualityObjectiveList];
    const indexToReplace = copy?.findIndex((qualityObjective) => qualityObjective.quality === qualityId);
    copy[indexToReplace] = {
      ...copy[indexToReplace],
      objectives: {
        ...copy[indexToReplace].objectives,
        [name]: valueAsNumber,
      },
    };
    setQualityObjectiveList(copy);
  };

  const submitHandler = async () => {
    const {data} = await qualityObjectiveApi.updateQualityObjectives(qualityObjectiveList);
    setQualityObjectiveList(data?.quality);
  };
  useEffect(() => {
    const getQualityObjective = async () => {
      const {data} = await qualityObjectiveApi.getQualityObjectives();
      setQualityObjectiveList(data?.quality);
    };
    getQualityObjective();
  }, []);
  return (
    <>
      <Block size="lg" className="mt-3">
        <BlockHead>
          <BlockHeadContent>
            <div className="row align-items-center gx-0 gx-lg-2">
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-12">
                <BlockTitle tag="h4">{t('administrator.qualityObjective.qualityObjectiveList')}</BlockTitle>
              </div>
            </div>
          </BlockHeadContent>
        </BlockHead>
        <Card className="card-bordered card-preview calendar-scroller">
          <QualityObjectiveTable qualityObjectiveList={qualityObjectiveList} changeObjectiveHandler={changeQualityHandler} />
        </Card>
        <div className="d-flex justify-content-end mt-3">
          <Button color="primary" onClick={submitHandler}>
            <Icon name="plus-sm" />
            {t('general.update')}
          </Button>
        </div>
      </Block>
    </>
  );
};

export default QualityObjective;
