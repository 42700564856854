import React from 'react';
import {useTranslation} from 'react-i18next';
import Select from 'react-select';
import {themeSelect} from '../../../../shared/Select2';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store';
import {ICountry} from '../../objective-scoring';

export interface IFiltersProps {
  changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  pagination: {perPage: number; page: number};
  setSelectedCountry: any;
}

const RegionFilters = ({changeFilterHandler, pagination, setSelectedCountry}: IFiltersProps) => {
  const {t} = useTranslation();
  const {country} = useSelector((state: RootState) => state.enum);

  return (
    <div className="mt-3 d-flex align-items-center justify-content-between justify-content-md-end">
      <div className="form-control-wrap mx-md-2">
        <input
          type="text"
          onChange={changeFilterHandler}
          name="name"
          className="form-control"
          placeholder={t('general.searchByName') as string}
        />
      </div>

      <div className="form-control-wrap ">
        <select name="perPage" onChange={changeFilterHandler} defaultValue={pagination.perPage} className="form-select">
          <option hidden>Per page</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
      </div>
      <div className="d-flex justify-content-between align-items-center ms-3">
        <div className="d-flex gap-3 align-items-center">
          <Select
            theme={themeSelect}
            className="form-react-select"
            placeholder={t('administrator.formats.selectType')}
            options={[
              {label: t('general.allCountries'), value: ''},
              ...(country?.map((countr) => ({
                value: countr,
                label: t(`general.countryList.${countr}`),
              })) as ICountry[]),
            ]}
            onChange={(selected) => {
              setSelectedCountry(selected as ICountry);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RegionFilters;
