import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import UserForm from './UserForm';
import {FormProvider, useForm} from 'react-hook-form';
import {IUserData} from '../../../../shared/interfaces/User';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {userApi} from '../../../../api';
import * as yup from 'yup';
import {phoneRegExp} from '../../../../shared/Regexs';
import {ICompany} from '../../company/interfaces/ICompany';
import {useTranslation} from 'react-i18next';

export const userUpdateSchema = yup
  .object({
    contact: yup.object({
      country: yup.string().required('No country provided'),
      phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    }),
    company: yup.number().typeError('No company provided.').required(),
    firstName: yup.string().required('No first name provided.').min(2, 'First name is too short - should be 2 chars minimum.'),
    email: yup.string().email().required('No email provided.'),
    roles: yup.array().min(1, 'Select on of roles').required('No roles provided.'),
  })
  .required();
const UserUpdate = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [passState, setPassState] = useState(false);
  const {id} = useParams();
  const methods = useForm<IUserData>({
    resolver: yupResolver(userUpdateSchema),
  });

  const onFormSubmit = async (currentState: IUserData) => {
    const formData = new FormData();
    !!currentState.file && formData.append('picture', currentState.file[0]);
    formData.append('body', JSON.stringify(currentState));
    const {data} = await userApi.updateUser(Number(id), formData);
    if (data) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (id) {
      const getUser = async (userId: number) => {
        const {data} = await userApi.getUser(+userId);
        methods.reset({
          ...data,
          company: (data.company as unknown as ICompany)?.id,
        });
      };
      getUser(+id);
    }
  }, [methods, id]);
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('administrator.users.userUpdate')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onFormSubmit)} className="py-3">
              <UserForm
                passState={passState}
                formState={methods.formState}
                setPassState={setPassState}
                register={methods.register}
                errors={methods.formState.errors}
                isPasswordHidden
                isAdmin
              />
              <Row>
                <Col>
                  <Row>
                    <Col className={'text-end'}>
                      <Button color="primary">
                        <Icon name="plus-sm" />
                        {t('general.update')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default UserUpdate;
