import React, {SetStateAction, useState} from 'react';
import {formatPrice, getCorrectCountry} from '../../../../shared/Functions';
import {IChannel} from '../interfaces/IChannel';
import {useNavigate} from 'react-router-dom';
import {channelsApi} from '../../../../api';
import Icon from '../../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import Button from '../../../../components/button/Button';
import {EmptyState} from '../../../../components/shared/EmptyState';
import {useTranslation} from 'react-i18next';
import {IQuality} from '../../quality/interfaces/IQuality';

interface IChannelTableProps {
  channelList: IChannel[];
  setChannelList: React.Dispatch<SetStateAction<IChannel[]>>;
  isMainChannel: boolean;
  navigateTo: string;
}

const ChannelTable = ({channelList, setChannelList, isMainChannel, navigateTo}: IChannelTableProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedChannel, setSelectedChannel] = useState<IChannel>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const deleteTypeHandler = async () => {
    if (selectedChannel?.id) {
      const {
        data: {data},
      } = await channelsApi.deleteChannel(selectedChannel?.id);
      setChannelList(data);
    }
  };
  return (
    <div className="table-responsive">
      {!!channelList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.id')}</th>
              <th scope="col">{t('general.name')}</th>
              <th scope="col">{t('general.quality')}</th>
              <th scope="col">{t('general.threshold')}</th>
              <th scope="col">{t('general.country')}</th>
              <th scope="col">{t('general.status')}</th>
              <th scope="col" className="text-end">
                {t('general.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {channelList?.map((channel) => {
              const {name, id, quality, thresholdInvestment, country, allCountry, status} = channel;

              return (
                <tr key={id}>
                  <th>{id}</th>
                  <td>{name ?? '-'}</td>
                  <td>{(quality as unknown as IQuality)?.name ?? '-'}</td>
                  <td>{formatPrice(thresholdInvestment, 0)}</td>
                  <td>
                    {allCountry
                      ? t('general.allCountries')
                      : country?.map((con, i) => getCorrectCountry(con, t) + (i === country.length - 1 ? '' : ', '))}
                  </td>
                  <td>{status ? 'Enabled' : 'Disabled'}</td>
                  <td className="d-flex justify-content-end">
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => {
                        navigate(`${navigateTo}/${id}?name=${!isMainChannel ? name : ''}`);
                      }}
                    >
                      <Icon name="edit" />
                      {t('general.edit')}
                    </Button>
                    {isMainChannel && !channel.isUsed && (
                      <Button
                        color="danger"
                        size="sm"
                        className="ms-1"
                        onClick={() => {
                          setIsDeleteModalOpened(true);
                          setSelectedChannel(channel);
                        }}
                      >
                        <Icon name="trash" />
                        {t('general.delete')}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedChannel?.id as number}
        itemName={selectedChannel?.name as string}
        deleteHandler={deleteTypeHandler}
      />
    </div>
  );
};

export default ChannelTable;
