import React from 'react';
import {Card} from 'reactstrap';

export const PreviewCard = ({className, bodyClass, ...props}: any) => {
  return (
    <Card className={`card-preview ${className ? className : ''}`}>
      <div className={`card-inner ${bodyClass ? bodyClass : ''}`}>{props.children}</div>
    </Card>
  );
};

export const PreviewAltCard = ({className, bodyClass, ...props}: any) => {
  return (
    <Card className={`card-bordered ${className ? className : ''}`}>
      <div className={`card-inner ${bodyClass ? bodyClass : ''}`}>{props.children}</div>
    </Card>
  );
};
