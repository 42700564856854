import React from 'react';
import {FormGroup} from 'reactstrap';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {IRegisterData} from '../../interfaces/Register';
import {getCorrectCountry} from '../../../shared/Functions';
import {useTranslation} from 'react-i18next';

interface IFormCompanyProps {
  errors: Partial<
    FieldErrorsImpl<{
      company: {
        name: string;
        email: string;
        contact: Partial<
          FieldErrorsImpl<{
            country: string;
          }>
        >;
      };
    }>
  >;
  register: UseFormRegister<IRegisterData>;
}

const FormCompany = ({errors, register}: IFormCompanyProps) => {
  const {country} = useSelector((state: RootState) => state.enum);
  const {t} = useTranslation();
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <FormGroup>
            <div className="form-label-group">
              <label className="form-label" htmlFor="default-01">
                Name
              </label>
            </div>
            <div className="form-control-wrap">
              <input
                type="text"
                id="default-01"
                {...register('company.name')}
                className="form-control-lg form-control"
                placeholder="Enter your company name"
              />
              {errors.company?.name && <p className="invalid">{errors.company?.name?.message}</p>}
            </div>
          </FormGroup>
          <FormGroup>
            <div className="form-label-group">
              <label className="form-label" htmlFor="default-01">
                Email
              </label>
            </div>
            <div className="form-control-wrap">
              <input
                type="text"
                id="default-01"
                {...register('company.email')}
                className="form-control-lg form-control"
                placeholder="Enter your email address"
              />
              {errors.company?.email && <p className="invalid">{errors.company?.email?.message}</p>}
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-label" htmlFor="phone">
              Phone number
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="reg-phone"
                placeholder="Enter your phone number"
                {...register('company.contact.phone')}
                className="form-control-lg form-control"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-label" htmlFor="country">
              Country
            </label>
            <div className="form-control-wrap ">
              <div className="form-control-select">
                <select className="form-control form-control-lg" id="default-06" {...register('company.contact.country')}>
                  <option selected disabled>
                    Enter country
                  </option>
                  {country?.map((item) => (
                    <option value={item} key={item}>
                      {getCorrectCountry(item, t)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {errors.company?.contact?.country && <span className="invalid">{errors?.company?.contact?.country?.message}</span>}
          </FormGroup>
          <FormGroup>
            <label className="form-label" htmlFor="city">
              City
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="reg-city"
                placeholder="Enter your city"
                {...register('company.contact.city')}
                className="form-control-lg form-control"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-label" htmlFor="zip">
              Zip
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="reg-zip"
                placeholder="Enter your zip"
                {...register('company.contact.zip')}
                className="form-control-lg form-control"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-label" htmlFor="address">
              Address
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="reg-address"
                placeholder="Enter your address"
                {...register('company.contact.address')}
                className="form-control-lg form-control"
              />
            </div>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default FormCompany;
