import {injectable} from 'inversify';
import moment from 'moment';

@injectable()
class DateTime {
  private readonly dateTime: typeof moment = moment;

  public formatDate(date: Date | string) {
    return this.dateTime(date).format('DD.MM.YYYY');
  }

  public formatDateHours(date: Date | string) {
    return this.dateTime(date).format('DD.MM.YYYY - HH:mm');
  }

  public formatDateForPicker(date: Date | string) {
    return this.dateTime(date).format('YYYY-MM-DD');
  }
}

export default DateTime;
