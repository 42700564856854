import React, {useEffect, useState} from 'react';
import DualListBox, {Option} from 'react-dual-listbox';
import {Controller, FieldErrorsImpl, UseFormRegister, UseFormReset} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Col, FormGroup, Row} from 'reactstrap';
import {channelsApi, channelUserApi} from '../../../../api';
import Icon from '../../../../components/icon/Icon';
import {usePagination} from '../../../../shared/hooks/usePagination';
import {IChannel} from '../../channels/interfaces/IChannel';
import {ISingleChannel} from '../interfaces/IChannelUser';

interface Props {
  register: UseFormRegister<ISingleChannel>;
  reset: UseFormReset<ISingleChannel>;
  errors: Partial<
    FieldErrorsImpl<{
      channel: string[];
    }>
  >;
}

const ChannelUserForm = ({register, errors, reset}: Props) => {
  const {id} = useParams();
  const {t} = useTranslation();
  const [channelList, setChannelList] = useState<IChannel[]>([]);
  const {pagination} = usePagination();
  // const onChange = (selectedItem: string[] | readonly string[] | undefined) => {};
  useEffect(() => {
    async function getAllChannels() {
      const {data} = await channelsApi.getAllChannels({...pagination, perPage: 0});
      setChannelList(data.data);
    }

    getAllChannels();
  }, [pagination]);
  useEffect(() => {
    const getChannelById = async () => {
      const {data} = await channelUserApi.getChannelUserId(String(id));
      reset({
        channel: data.map((userData) => userData?.channel?.id),
      });
    };
    if (!!Number(id)) {
      getChannelById();
    }
  }, [id, reset]);
  return (
    <Row>
      <Col md={12}>
        <FormGroup>
          <label className="form-label" htmlFor="name">
            {t('general.channel')}
          </label>
          <div className="form-control-wrap">
            <Controller
              {...register('channel')}
              render={({field: {onChange, value}}) => (
                <DualListBox
                  {...register('channel')}
                  options={
                    channelList?.map((channel) => ({
                      label: channel?.name ?? '-',
                      value: channel?.id,
                    })) as unknown as readonly Option<string>[] | Option<string>[]
                  }
                  icons={{
                    moveLeft: <Icon name="chevron-left" />,
                    moveAllLeft: <Icon name="chevrons-left" />,
                    moveRight: <Icon name="chevron-right" />,
                    moveAllRight: <Icon name="chevrons-right" />,
                  }}
                  selected={value}
                  onChange={onChange}
                />
              )}
            />

            {errors?.channel && <p className="invalid text-danger fst-italic">{errors?.channel?.message}</p>}
          </div>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default ChannelUserForm;
