import {createSlice} from '@reduxjs/toolkit';

export interface IEnum {
  userRoles: string[];
  country: string[];
  userCompanyRole: string[];
  campaignStatus: string[];
  calendarView: string[];
  objective: [];
  price: [];
  paymentStatus: string[];
  priceResult: string[];
}

const initialState: IEnum = {
  calendarView: [],
  userRoles: [],
  userCompanyRole: [],
  country: [],
  campaignStatus: [],
  objective: [],
  price: [],
  paymentStatus: [],
  priceResult: [],
};

export const enumSlice = createSlice({
  name: 'enum',
  initialState,
  reducers: {
    setEnums: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});
