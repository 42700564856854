export const formatRole = (role: string) => {
  return role.trim().replace('ROLE_', ' ').replace('_', ' ');
};

export const formatRoles = (roles: string[]) => {
  const formatter = new (Intl as any).ListFormat('en', {style: 'long', type: 'conjunction'});
  const clearedArray: string[] = [];
  roles?.forEach((role) => {
    clearedArray.push(formatRole(role).replace('_', ' ').trim());
  });
  return formatter.format(clearedArray);
};
