import React, {useState, useEffect, useReducer} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Collapse} from 'reactstrap';
import {Block} from '../../../../components/block/Block';
import ChangeStep from './ChangeStep';
import {IStepperProps} from './index';
import {creativeApi} from '../../../../api';
// import {useDropzone} from 'react-dropzone';
import Icon from '../../../../components/icon/Icon';
import {CustomPopover} from '../../../../components/popover/Popover';
import ImageHandler from '../../../../shared/ImageHandler';
import GalleryCardPreview from './GalleryCardPreview';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import {IPicture} from '../../../../shared/interfaces/Picture';
import Step5Dropdown from './components/Step5Dropdown';
import {commonSlice, TypeEnum} from '../../../../store/slices/common.slice';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {useMultiInject} from '../../../../di';
import uuid from 'react-uuid';
export interface IListSubmitData {
  channelId: number;
  formatId: number;
  creativeName: string;
  region: number;
}

export interface ICreative {
  lineItem_id: number;
  additionalUrls: {url: string; id?: number | undefined; key?: number | string | undefined}[];
  lineItem_creative_name: string;
  channel_id: number;
  channel_name: string;
  quality_id: number;
  quality_name: string;
  format_id: number;
  format_name: string;
  devices: IDevice[];
  lineItem_url: string;
  creativePicture: any[];
  channel_description: string;
  channelPicture: {path: string};
  formatPicture: {path: string};
  format_description: string;
  dates: {
    minDate: string;
    maxDate: string;
  }[];
}

export interface IDevice {
  id: number;
  name: string;
  description: string;
}

export interface IProductionPlan {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  status: string;
  objective: string | null;
  price: number | null;
  paymentStatus: string | null;
  plannedBudget: number;
  describeMediaPlan: string | null;
  mediaPlan: boolean;
  currentStep: number;
  endSteps: boolean;
  isDeleted: boolean | null;
  productionPlan: IProductionFile[] | [];
}

export interface IProductionFile {
  id: number;
  createdAt: string;
  updatedAt: string;
  file: {
    name: string;
    nameOriginal: string;
    mime: string;
    size: number;
  };
  path: string;
}

interface IAction {
  type: ACTIONS;
  payload?: any;
}

enum ACTIONS {
  ADD_URL = 'ADD_URL',
  DELETE_URL = 'DELETE_URL',
  DELETE_IMAGE = 'DELETE_IMAGE',
  UPDATE_LINK = 'UPDATE_LINK',
  CHANGE_URL = 'CHANGE_URL',
  SAVE_LINK = 'SAVE_LINK',
  SAVE_ADDITIONAL = 'SAVE_ADDITIONAL',
  SAVE_PICTURES = 'SAVE_PICTURES',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
}

const reducer = (state: ICreative[], {type, payload}: IAction): ICreative[] => {
  switch (type) {
    case ACTIONS.ADD_URL:
      return state.map((item) =>
        item.lineItem_id === payload.id ? {...item, additionalUrls: [...item.additionalUrls, {url: '', key: uuid()}]} : item
      );

    case ACTIONS.DELETE_URL:
      return state.map((item) =>
        item.lineItem_id === payload.id ? {...item, additionalUrls: item.additionalUrls.filter((_, index) => index !== payload.idx)} : item
      );

    case ACTIONS.DELETE_IMAGE:
      return state.map((item) => {
        return item.lineItem_id === payload.lineId
          ? {
              ...item,
              creativePicture: item.creativePicture.filter((pic) => pic.id !== payload.picId),
            }
          : item;
      });
    case ACTIONS.SAVE_LINK:
      return state.map((lineItem) => (lineItem.lineItem_id === payload.id ? {...lineItem, lineItem_url: payload.obj!.text} : lineItem));

    case ACTIONS.SAVE_PICTURES:
      return state.map((item) => {
        return item.lineItem_id === payload.data.lineItem.id
          ? {
              ...item,
              creativePicture: payload.data.creativePicture,
            }
          : item;
      });

    case ACTIONS.UPDATE_LINK:
      return state.map((item) => (item.lineItem_id === payload.id ? {...item, lineItem_url: payload.url} : item));

    case ACTIONS.CHANGE_URL:
      return state.map((lineItem) => (lineItem.lineItem_id === payload.id ? payload.line! : lineItem));

    case ACTIONS.SET_INITIAL_STATE:
      return payload;

    default:
      return state;
  }
};

const CampaignStep5 = ({campaignData, isCampaignEditable = false}: IStepperProps) => {
  const [lineItems, dispatchLineItems] = useReducer(reducer, []);
  const [isOpen, setIsOpen] = useState('1');
  const [disabled, setIsDisabled] = useState(false);
  const {id} = useParams();
  const {dateTime} = useMultiInject();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isProductionDeleteModalOpened, setIsProductionDeleteModalOpened] = useState(false);
  const [selectedCreativePictureId, setSelectedCreativePictureId] = useState<number>(0);
  const [selectedLineItemId, setSelectedLineItemId] = useState<number>(0);
  const [links, setLinks] = useState<{text: string; id: number}[]>([]);
  // const [lineItems, setLineItems] = useState<ICreative[]>([]);
  // const [additionalUrls, setAdditionalUrls] = useState<string[]>(['']);
  const [productionPlanCards, setProductionPlanCards] = useState<IProductionPlan>();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const onDrop = useCallback((acceptedFiles: any, rejections: any, event: any) => {
    const submitPictures = async () => {
      const formData = new FormData();
      formData.append('campaign', id!);
      for (const file of Array.from(acceptedFiles)) {
        formData.append('productionPlan', file as Blob);
      }
      if (id) {
        const response = await creativeApi.createProductionPlan(formData, +id);

        setProductionPlanCards(response.data);
      }
    };
    submitPictures();
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: {},
    onDrop,
  });

  const toggleCollapse = (param: string) => {
    if (param === isOpen) {
      setIsOpen('0');
    } else {
      setIsOpen(param);
    }
  };

  const deleteImage = async (lineId: number, picId: number) => {
    if (disabled) return;
    setIsDisabled(true);
    await creativeApi.deleteCreativeImage(lineId, picId);
    setIsDisabled(false);

    dispatchLineItems({type: ACTIONS.DELETE_IMAGE, payload: {lineId, picId}});
  };
  const deleteProductionImage = async (campaignId: number, picId: number) => {
    if (disabled) return;
    setIsDisabled(true);
    await creativeApi.deleteProductionImage(campaignId, picId);
    setIsDisabled(false);
    setProductionPlanCards((prev: any) => ({
      ...prev,
      productionPlan: prev.productionPlan.filter((file: IProductionFile) => file.id !== picId),
    }));
  };

  const onChangeLink = (e: any, id: number) => {
    let linkObj = links.find((link) => link.id === id);
    if (linkObj) {
      linkObj.text = e.target.value;
      setLinks((prev) => [...prev.filter((link) => link.id !== id), linkObj as {id: number; text: string}]);
    } else {
      setLinks((prev) => [...prev, {text: e.target.value, id}]);
    }
  };

  async function downloadImage(imageSrc: any, imageName: string) {
    const response = await fetch(imageSrc);
    const blobImage = await response.blob();
    const href = URL.createObjectURL(blobImage);
    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    anchorElement.download = '';
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  }

  const saveLink = (id: number) => {
    const obj = links.find((link) => link.id === id);
    creativeApi.updateLink(obj!.id, {url: obj!.text}).then((res) => {
      dispatchLineItems({type: ACTIONS.SAVE_LINK, payload: {id, obj}});
      dispatch(commonSlice.actions.setMessage({type: TypeEnum.success, text: 'Succsefuly updated link'}));
    });
  };

  const onChangeUrl = (e: any, id: number, idx: number) => {
    const line = lineItems.find((lineItem) => lineItem.lineItem_id === id);
    line!.additionalUrls[idx].url = e.target.value;
    dispatchLineItems({type: ACTIONS.CHANGE_URL, payload: {id, line}});
  };

  const saveAdditional = async (id: number) => {
    const line = lineItems.find((lineItem) => lineItem.lineItem_id === id);
    const reworked = line?.additionalUrls.map((additionalUrl) => additionalUrl.url);
    const {data} = await creativeApi.createAdditionalUrls({additionalUrls: reworked}, +id);
    dispatchLineItems({type: ACTIONS.SAVE_ADDITIONAL, payload: {line, data}});
    // setLineItems((prev) => prev.map((lineItem) => (lineItem.lineItem_id === id ? {...lineItem, additionalUrls: data} : lineItem)));
  };

  const addUrlField = (id: number) => {
    dispatchLineItems({type: ACTIONS.ADD_URL, payload: {id}});
  };

  const deleteAdditional = async (id: number, idx: number, additionalUrlId?: number) => {
    if (additionalUrlId) {
      //  CASE IF WE DELETING SAVED ITEM
      const {data} = await creativeApi.deleteAdditionalUrl(id, additionalUrlId);
      dispatchLineItems({type: ACTIONS.DELETE_URL, payload: {id, idx, data}});
    } else {
      // CASE IF WE DELETING NOT SAVED ITEM
      dispatchLineItems({type: ACTIONS.DELETE_URL, payload: {id, idx}});
    }
  };

  const submitFiles = async (acceptedFiles: any, id: number) => {
    const formData = new FormData();
    for (const file of Array.from(acceptedFiles)) {
      formData.append('pictures', file as Blob);
    }
    if (id) {
      const {data} = await creativeApi.createCreativeImages(formData, id);
      dispatchLineItems({type: ACTIONS.SAVE_PICTURES, payload: {data}});
    }
  };

  const getLineItem = useCallback(async () => {
    if (id) {
      const {data} = await creativeApi.getAllCreatives(+id);

      dispatchLineItems({
        type: ACTIONS.SET_INITIAL_STATE,
        payload: data.map((lineItem: ICreative) => ({
          ...lineItem,
          additionalUrls: lineItem.additionalUrls.length ? lineItem.additionalUrls : [{url: ''}],
        })),
      });
    }
  }, [id]);

  useEffect(() => {
    getLineItem();
  }, [getLineItem]);

  useEffect(() => {
    const getLineItem = async () => {
      if (id) {
        const {data} = await creativeApi.getAllProductionPlans(+id);

        setProductionPlanCards(data);
      }
    };
    if (id) {
      getLineItem();
    }
  }, [id]);

  return (
    <>
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedLineItemId!}
        itemName={`ID: ${selectedCreativePictureId}`}
        deleteHandler={deleteImage}
        pictureId={selectedCreativePictureId}
      />
      <ConfirmDeleteModal
        show={isProductionDeleteModalOpened}
        setShow={setIsProductionDeleteModalOpened}
        itemId={+id!}
        itemName={`ID: ${selectedCreativePictureId}`}
        deleteHandler={deleteProductionImage}
        pictureId={selectedCreativePictureId}
      />
      <Block size="lg" className="mt-3 position-relative" key={campaignData?.id}>
        <div className="container-fluid">
          <ChangeStep
            campaignId={campaignData?.id}
            status={campaignData?.status}
            currentCampaignStep={campaignData?.currentStep}
            activeStep={5}
            name={campaignData?.name}
          />
          <h4 className="mb-3">{t('general.productionPlan')}</h4>
          <div
            {...getRootProps({
              // onClick: (event: any) => {
              //   currId.current = item.lineItem_id;
              // },
              role: 'button',
              'aria-label': 'drag and drop area',
            })}
            role="button"
            className="dropzone "
            id="file-input"
          >
            <input {...getInputProps()} />
            {isDragActive ? <p>{t('general.dropFiles')}</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
          </div>

          <div className="col">
            <GalleryCardPreview
              downloadImage={downloadImage}
              data={productionPlanCards?.productionPlan}
              setSelectedLineItemId={setSelectedLineItemId}
              setSelectedCreativePictureId={setSelectedCreativePictureId}
              setIsDeleteModalOpened={setIsProductionDeleteModalOpened}
              isProdutionPlan={true}
            />
          </div>

          <div className="accordion mt-3">
            {lineItems.map((item, i) => {
              const tempUrl = links.find((link) => link.id === item.lineItem_id);

              const firstChoiceUrl = tempUrl ? (tempUrl.text.includes('http') ? tempUrl.text : '//' + tempUrl.text) : undefined;

              return (
                <div className="accordion-item">
                  <div
                    className={`accordion-head${+isOpen !== i + 1 ? ' collapsed' : ''} cursor-pointer`}
                    onClick={() => toggleCollapse(String(i + 1))}
                  >
                    <div className="row align-items-center">
                      <div className="col-3">
                        <h5 className="title">{item.lineItem_creative_name}</h5>
                        <div className="text-muted fs-13px">
                          {`${dateTime.formatDate(item.dates[0].minDate)} - ${dateTime.formatDate(item.dates[0].maxDate)}`}
                        </div>
                      </div>
                      <div className="col-3 d-flex">
                        <div title={item?.channel_name}>
                          <span className="d-flex align-items-center justify-content-start fw-bold p-0">
                            <CustomPopover
                              id={`${i + 1}`}
                              classes="p-0"
                              title={<Icon name="book" />}
                              item={{
                                placement: 'top',
                                description: `${item?.channel_name}`,
                                text: (
                                  <>
                                    <div className="d-flex flex-column">
                                      {item?.channelPicture?.path && (
                                        <ImageHandler path={item?.channelPicture?.path} className="img-cover popover-image" />
                                      )}
                                      <div>{item?.channel_description ?? ''}</div>
                                    </div>
                                  </>
                                ),
                              }}
                            />
                          </span>
                        </div>
                        {item.channel_name}
                      </div>
                      <div className="col-3 d-flex">
                        <div title={item.format_name}>
                          <span className="d-flex align-items-center justify-content-start fw-bold p-0">
                            <CustomPopover
                              id={(i + 1) * -1}
                              classes="p-0"
                              title={<Icon name="book" />}
                              item={{
                                placement: 'top',
                                description: `${item?.format_name}`,
                                text: (
                                  <>
                                    <div className="d-flex flex-column">
                                      {item?.formatPicture && (
                                        <ImageHandler path={(item?.formatPicture as IPicture)?.path} className="img-cover popover-image" />
                                      )}
                                      <div>{item?.format_description ?? ''}</div>
                                    </div>
                                  </>
                                ),
                              }}
                            />
                          </span>
                        </div>{' '}
                        {item.format_name}
                      </div>
                    </div>
                    <span className="accordion-icon"></span>
                  </div>

                  <Collapse className="accordion-body" isOpen={+isOpen === i + 1 ? true : false}>
                    <div className="accordion-inner">
                      <div className="row">
                        <div className="col-3">
                          {item.devices.map((device) => (
                            <>
                              <h6>{device.name}</h6>
                              <p>{device.description}</p>
                            </>
                          ))}
                        </div>
                        <div className="col-2">
                          <Step5Dropdown id={item.lineItem_id} submitPictures={submitFiles} />
                        </div>
                        <div className="col">
                          <GalleryCardPreview
                            downloadImage={downloadImage}
                            data={item}
                            setSelectedLineItemId={setSelectedLineItemId}
                            setSelectedCreativePictureId={setSelectedCreativePictureId}
                            setIsDeleteModalOpened={setIsDeleteModalOpened}
                          />
                        </div>
                        <div className="col-12">
                          <div className="mt-2 d-flex align-items-center">
                            <div className="col-12 p-3">
                              <label className="mb-3">Transfer link</label>
                              <div className="d-flex gap-3 align-items-center">
                                <input
                                  type="text"
                                  defaultValue={item.lineItem_url}
                                  onChange={(e) => onChangeLink(e, item.lineItem_id)}
                                  className="form-control"
                                />
                                <a
                                  href={
                                    firstChoiceUrl
                                      ? firstChoiceUrl
                                      : item.lineItem_url?.includes('http')
                                      ? item.lineItem_url
                                      : '//' + item.lineItem_url
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t('general.open')}
                                </a>
                                <Button color="primary" onClick={() => saveLink(item.lineItem_id)}>
                                  {t('general.save')}
                                </Button>
                                <i className="ni ni-plus me-1 fs- cursor-pointer"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mt-2 d-flex align-items-center">
                            <div className="col-12 p-3 ">
                              <label className="mb-3"> {t('general.additionalUrls')}</label>
                              {item.additionalUrls.map((urlItem, idx) => {
                                return (
                                  <div className="d-flex gap-3 align-items-center h-100 mb-3" key={urlItem?.id || urlItem?.key || ''}>
                                    <input
                                      type="text"
                                      defaultValue={urlItem.url}
                                      onChange={(e) => onChangeUrl(e, item.lineItem_id, idx)}
                                      className="form-control"
                                    />

                                    <a
                                      href={urlItem.url?.includes('http') ? urlItem.url : '//' + urlItem.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {t('general.open')}
                                    </a>
                                    {((item.additionalUrls.length === 1 && urlItem.url) || item.additionalUrls.length > 1) && (
                                      <Button
                                        color="danger"
                                        size="sm"
                                        className="ms-1"
                                        onClick={() => {
                                          if (item.additionalUrls.length === 1) {
                                            deleteAdditional(item.lineItem_id, idx, urlItem.id);
                                            addUrlField(item.lineItem_id);
                                          } else {
                                            deleteAdditional(item.lineItem_id, idx, urlItem.id);
                                          }
                                        }}
                                      >
                                        <Icon name="trash" />
                                        {t('general.delete')}
                                      </Button>
                                    )}
                                  </div>
                                );
                              })}

                              <div className="d-flex gap-2 justify-content-end align-items-center">
                                <Button color="primary" className="mt-4 " onClick={() => addUrlField(item.lineItem_id)}>
                                  <i className="ni ni-plus me-1 fs- cursor-pointer"></i>
                                  {t('general.addNew')}
                                </Button>
                                <Button color="primary" className="mt-4 " onClick={() => saveAdditional(item.lineItem_id)}>
                                  {t('general.save')}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </div>
        </div>
      </Block>
    </>
  );
};

export default CampaignStep5;
