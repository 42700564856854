import {IChannelDeviceFormatPagination} from '../../../app/administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import {IThread} from '../../../app/campaigns/components/steps/CampaignStep9';
import {ICampaign, ICampaignPagination, ICampaignStep2, ILineItem} from '../../../app/campaigns/interfaces/ICampaigns';
import {IDashboardFilters} from '../../../app/dashboard';
import {IPicture} from '../../../shared/interfaces/Picture';
import BaseApi from '../../base.api';

const CAMPAIGN_ROUTES = '/api/admin/campaign';
const CHAT_GPT_ROUTES = '/api/admin/chat-gpt';
const REPORTING_ROUTES = '/api/admin/report';
const CHANNEL_DEVICE_FORMAT_ROUTES = '/api/admin/channel/device/format';

export interface IDashboardCampaign {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  startDate: string;
  brand: string;
  endDate: string;
  description: string;
  status: string;
  campaigns: any[];
  count: number;
  objective: string | number;
  price: string | number;
  paymentStatus: string | number;
  plannedBudget: string | number;
  describeMediaPlan: string | number;
  mediaPlan: boolean;
  currentStep: string | number;
  totalViews: number;
  totalClicks: number;
  endSteps: boolean;
  totalBudget: number;
  isDeleted: boolean;
  product: {
    id: string | number;
    createdAt: string | number;
    updatedAt: string | number;
    name: string | number;
    brand: {
      id: string | number;
      createdAt: string | number;
      updatedAt: string | number;
      name: string | number;
    };
  };
  picture: any;
  company: {
    id: string | number;
    createdAt: string | number;
    updatedAt: string | number;
    name: string | number;
    email: string | number;
    unlimitedBudget: boolean;
    fee: string | number;
    contact: {
      phone: string | number;
      country: string | number;
      city: string | number;
      zip: string | number;
      address: string | number;
    };
  };
  parent: any;
  campaignCountry: [
    {
      id: string | number;
      createdAt: string | number;
      updatedAt: string | number;
      country: string | number;
    }
  ];
  lineItem: {
    id: string | number;
    createdAt: string | number;
    updatedAt: string | number;
    objective: string | number;
    creativeName: string | number;
    price: string | number;
    country: string | number;
    calendarView: string | number;
    priceValue: string | number;
    ais: string | number;
    fee: string | number;
    comment: string | number;
    calculateScore: string | number;
    calculatePrice: string | number;
    frequencyCap: boolean;
    valueFrequencyCap: string | number;
    targeting: boolean;
    position: string | number;
  }[];
  users: {
    user: {
      id: string | number;
      createdAt: string;
      updatedAt: string;
      firstName: string;
      lastName: string;
      email: string;
      isApprover: boolean;
      isActive: boolean;
      roles: string[];
      profilePicture: {
        id: string | number;
        createdAt: string | number;
        updatedAt: string | number;
        file: {
          name: string | number;
          nameOriginal: string | number;
          mime: string | number;
          size: string | number;
        };
        path: string | number;
      };
    };
  }[];
  campaignOverview: {
    totalBudget: string | number;
    adImpressions: string | number;
    clicks: string | number;
    engagement: string | number;
    completedViews: string | number;
    progressBar: {
      awareness: string | number;
      branding: string | number;
      engagement: string | number;
      performance: string | number;
      CPM: string | number;
      CPC: string | number;
      CPV: string | number;
      CPE: string | number;
    };
  };
}

export class CampaignApi extends BaseApi {
  public async getAllCampaigns(pagination: ICampaignPagination, isOrders = false): Promise<{data: any}> {
    return await this.get(`${CAMPAIGN_ROUTES}${isOrders ? '/orders' : ''}`, undefined, pagination);
  }

  public async getAllReportingCampaigns(pagination: ICampaignPagination, isOrders = false): Promise<{data: any}> {
    return await this.get(`${REPORTING_ROUTES}/allCampaign`, undefined, pagination);
  }

  public async getCampaign(id: number): Promise<{data: ICampaign}> {
    return await this.get(`${CAMPAIGN_ROUTES}/${id}`);
  }

  public async exportCampaign(id: number): Promise<{data: ICampaign}> {
    return await this.get(`${CAMPAIGN_ROUTES}/orders/download/${id}`);
  }

  public async getDashboardCampaignData(filters: IDashboardFilters): Promise<{data: IDashboardCampaign[]}> {
    return await this.get(`${CAMPAIGN_ROUTES}/dashboard`, undefined, filters);
  }

  public async updateStep1(id: number, data: any): Promise<{data: ICampaign}> {
    return await this.put(`${CAMPAIGN_ROUTES}/step/1/${id}`, data, '', true);
  }

  public async changeAiStatus(id: number, data: any): Promise<any> {
    return await this.put(`${CAMPAIGN_ROUTES}/order/openAi/campaign/${id}`, data, '', false);
  }

  public async updateAdminCampaignStatus(id: number, status: string): Promise<{data: ICampaign}> {
    return await this.patch(`${CAMPAIGN_ROUTES}/order/${id}/${status}`, {id, status}, '');
  }

  public async changeOrder(id: number, data: any): Promise<any> {
    return await this.patch(`${CAMPAIGN_ROUTES}/order/changeCompany/campaign/${id}`, data, '', false);
  }

  public async updateStep2(id: number, data: ICampaignStep2): Promise<{data: ICampaign}> {
    return await this.put(`${CAMPAIGN_ROUTES}/step/2/${id}`, data, '');
  }

  public async uploadMediaFile(data: FormData): Promise<{data: ICampaign}> {
    return await this.patch(`${CAMPAIGN_ROUTES}/mediaPlan`, data, '', true);
  }

  public async uploadStrategyFile(data: FormData): Promise<{data: IPicture[]}> {
    return await this.patch(`${CAMPAIGN_ROUTES}/strategyPresentation`, data, '', true);
  }

  public async getDefaultFilters(campaignId: number): Promise<{data: ILineItem[]}> {
    return await this.get(`${CAMPAIGN_ROUTES}/defaultFilter/${campaignId}`, '');
  }

  public async rejectCampaign(id: number): Promise<any> {
    return await this.put(`${CAMPAIGN_ROUTES}/reject-campaign/${id}`, {}, '');
  }

  public async createCampaign(data: any): Promise<any> {
    return await this.post(CAMPAIGN_ROUTES, data, '', true);
  }

  public async deleteCampaign(id: number): Promise<any> {
    return await this.delete(`${CAMPAIGN_ROUTES}/${id}`, '');
  }

  public async deleteMediaPlanId(mediaPlanId: number, campaignId: number): Promise<any> {
    return await this.delete(`${CAMPAIGN_ROUTES}/mediaPlan/${campaignId}/${mediaPlanId}`, '');
  }

  public async generateChannelDeviceFormat(pagination?: IChannelDeviceFormatPagination) {
    return await this.get(CHANNEL_DEVICE_FORMAT_ROUTES, '', pagination);
  }

  public async copyCampaign(id: number): Promise<any> {
    return await this.post(`${CAMPAIGN_ROUTES}/campaign-copy/${id}`, {});
  }

  public async updateCampaignStatus(id: number): Promise<any> {
    return await this.put(`${CAMPAIGN_ROUTES}/update-status/${id}`, {}, '');
  }

  public async updatePaymentStatus(id: number): Promise<any> {
    return await this.put(`${CAMPAIGN_ROUTES}/paymentStatus/${id}`, {}, '');
  }

  public async getAIThread(campaignId: number): Promise<{data: {data: IThread[]; fileGpt: string}}> {
    return await this.get(`${CHAT_GPT_ROUTES}/campaign/${campaignId}`, '');
  }

  public async uploadAIFile(campaignId: number, file: FormData): Promise<{data: IThread[]}> {
    return await this.put(`${CHAT_GPT_ROUTES}/assistant-thread/campaign/${campaignId}`, file, '', true);
  }

  public async sendAIMessage(campaignId: number, message: string): Promise<{data: IThread[]}> {
    return await this.post(`${CHAT_GPT_ROUTES}/communication/campaign/${campaignId}`, {message});
  }
}
