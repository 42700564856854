import React, {ChangeEvent, useEffect, useState} from 'react';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import {Card} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import ChannelPriceTable from './components/ChannelPriceTable';
import {channelsPriceApi, companyApi} from '../../../api';
import {IChannelPrice} from './interfaces/IChannelPrice';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import {adjustValues, ISelect2, themeSelect} from '../../../shared/Select2';
import {IEvent} from '../../../shared/interfaces/Event';
import Select from 'react-select';
import {usePagination} from '../../../shared/hooks/usePagination';
import {ICompany} from '../company/interfaces/ICompany';

const ChannelPrice = () => {
  const {t} = useTranslation();
  const {pagination, changeFilterHandler} = usePagination();
  const [companyList, setCompanyList] = useState<ICompany[]>([]);
  const [allChannelPriceList, setAllChannelPriceList] = useState<IChannelPrice[]>([]);
  const [channelPriceList, setChannelPriceList] = useState<IChannelPrice[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(null);
  const changePriceHandler = (event: React.ChangeEvent<HTMLInputElement>, channelId: number) => {
    const {name, valueAsNumber} = event.target;
    const copy = [...channelPriceList];
    const indexToReplace = copy?.findIndex((channelPrice) => channelPrice.channel === channelId);
    copy[indexToReplace] = {
      ...copy[indexToReplace],
      prices: {
        ...copy[indexToReplace].prices,
        [name]: valueAsNumber,
      },
    };
    setChannelPriceList(copy);
  };
  const submitHandler = async () => {
    const {data} = await channelsPriceApi.updateChannelPrices(
      !selectedCompanyId ? channelPriceList : channelPriceList?.filter((priceList) => priceList.changed && priceList?.isOverridden),
      selectedCompanyId
    );
    if (data) {
      getChannelPrices();
    }
  };
  const handleSearchByChannel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setChannelPriceList(allChannelPriceList?.filter((channel) => channel.name.toLowerCase().includes(value.toLowerCase())));
  };
  useEffect(() => {
    const getAllCompanies = async () => {
      const {
        data: {data},
      } = await companyApi.getAllCompanies(pagination);
      setCompanyList(data);
    };
    getAllCompanies();
  }, [pagination]);
  const getChannelPrices = async () => {
    const {data} = await channelsPriceApi.getChannelPrices(selectedCompanyId);
    const adjustedData = selectedCompanyId
      ? data?.channels?.map((channel) => ({
          ...channel,
          changed: !!channel.isOverridden,
        }))
      : data.channels;
    setChannelPriceList(adjustedData);
    setAllChannelPriceList(adjustedData);
  };
  useEffect(() => {
    getChannelPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyId]);
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-xxl-10 col-xl-9 col-lg-8 col-12 mb-3">
              <BlockTitle tag="h4">{t('administrator.channelPrice.channelPriceList')}</BlockTitle>
            </div>
            <div className="col-md-4 ps-md-0">
              <Select
                theme={themeSelect}
                placeholder={t('general.searchByCompany')}
                options={adjustValues(companyList as unknown as ISelect2[], 'name')}
                isClearable
                className="small-select2"
                onInputChange={(value) => {
                  const event: IEvent = {
                    target: {
                      name: 'name',
                      value: value,
                    },
                  };
                  changeFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
                }}
                onChange={(event) => {
                  setSelectedCompanyId(event?.value as number);
                }}
              />
            </div>
            <div className="col-md-4">
              <input type="text" placeholder="Search by Channel" onChange={handleSearchByChannel} className="form-control" />
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <ChannelPriceTable
          isCompanySelected={!!selectedCompanyId}
          channelPriceList={channelPriceList}
          setChannelPriceList={setChannelPriceList}
          changePriceHandler={changePriceHandler}
        />
      </Card>
      <div className="d-flex justify-content-end mt-3">
        <Button color="primary" onClick={submitHandler}>
          <Icon name="plus-sm" />
          {t('general.update')}
        </Button>
      </div>
    </Block>
  );
};

export default ChannelPrice;
