import axios, {AxiosInstance} from 'axios';
import {injectable} from 'inversify';

@injectable()
class Api {
  private readonly request: AxiosInstance = axios;

  public get() {
    return this.request.get('/');
  }
}

export default Api;
