import React from 'react';
import {Link} from 'react-router-dom';
import PageContainer from '../../layout/page-container/PageContainer';
import {Block, BlockContent} from '../block/Block';
import Button from '../button/Button';

const Forbidden = () => {
  return (
    <PageContainer>
      <Block className="nk-block-middle wide-md mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <img src="/images/error-403.png" className="nk-error-gfx" alt="403 error" />
          <div className="wide-xs mx-auto">
            <h3 className="nk-error-title">Oops! Access Denied - Error 403</h3>
            <p className="nk-error-text">
              Sorry, but you don't have permission to access the requested resource. Please double-check the URL or contact our support team
              for assistance.{' '}
            </p>
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <Button color="primary" size="lg" className="mt-2">
                Back To Home
              </Button>
            </Link>
          </div>
        </BlockContent>
      </Block>
    </PageContainer>
  );
};

export default Forbidden;
