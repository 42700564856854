import React from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from '../../components/error/NotFound';
import {PrivateRoute} from '../../routes/RouteGuards';
import CompaniesOverview from './companies';
import BrandOverview from './brands';

export function OverviewRoutes() {
  return (
    <Routes>
      <Route path="/brands" element={<PrivateRoute component={BrandOverview} />} />
      <Route path="/companies" element={<PrivateRoute component={CompaniesOverview} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
