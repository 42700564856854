import React, {useEffect, useState} from 'react';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import {Card} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import DevicePriceTable from './components/DevicePriceTable';
import {devicesPriceApi} from '../../../api';
import {IDevicePrice} from './interfaces/IDevicePrice';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';

const DevicePrice = () => {
  const {t} = useTranslation();
  const [devicePriceList, setDevicePriceList] = useState<IDevicePrice[]>([]);
  const changePriceHandler = (event: React.ChangeEvent<HTMLInputElement>, deviceId: number) => {
    const {name, valueAsNumber} = event.target;
    const copy = [...devicePriceList];
    const indexToReplace = copy?.findIndex((devicePrice) => devicePrice.device === deviceId);
    copy[indexToReplace] = {
      ...copy[indexToReplace],
      prices: {
        ...copy[indexToReplace].prices,
        [name]: valueAsNumber,
      },
    };
    setDevicePriceList(copy);
  };

  const submitHandler = async () => {
    const {data} = await devicesPriceApi.updateDevicePrices(devicePriceList);
    setDevicePriceList(data?.devices);
  };
  useEffect(() => {
    const getDevicePrices = async () => {
      const {data} = await devicesPriceApi.getDevicePrices();
      setDevicePriceList(data?.devices);
    };
    getDevicePrices();
  }, []);
  return (
    <>
      <Block size="lg" className="mt-3">
        <BlockHead>
          <BlockHeadContent>
            <div className="row align-items-center gx-0 gx-lg-2">
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-12">
                <BlockTitle tag="h4">{t('administrator.devicePrice.devicePriceList')}</BlockTitle>
              </div>
            </div>
          </BlockHeadContent>
        </BlockHead>
        <Card className="card-bordered card-preview">
          <DevicePriceTable devicePriceList={devicePriceList} changePriceHandler={changePriceHandler} />
        </Card>
        <div className="d-flex justify-content-end mt-3">
          <Button color="primary" onClick={submitHandler}>
            <Icon name="plus-sm" />
            {t('general.update')}
          </Button>
        </div>
      </Block>
    </>
  );
};

export default DevicePrice;
