import React, {SetStateAction, useState} from 'react';
import {IAiSettings} from '../interfaces/IAiSettings';
import {useNavigate} from 'react-router-dom';
import {aiSettingsApi} from '../../../../api';
import Icon from '../../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import Button from '../../../../components/button/Button';
import {EmptyState} from '../../../../components/shared/EmptyState';
import {useTranslation} from 'react-i18next';

interface IAiSettingsTableProps {
  aiSettingsList: IAiSettings[];
  setAiSettingsList: React.Dispatch<SetStateAction<IAiSettings[]>>;
}

const AiSettingsTable = ({aiSettingsList, setAiSettingsList}: IAiSettingsTableProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [selectedPrompt, setSelectedPrompt] = useState<IAiSettings>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const deletePromptHandler = async () => {
    if (selectedPrompt?.id) {
      const {
        data: {data},
      } = await aiSettingsApi.deleteSettings(selectedPrompt?.id);
      setAiSettingsList(data);
    }
  };
  return (
    <div className="table-responsive">
      {!!aiSettingsList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.id')}</th>
              <th scope="col">Name</th>

              <th scope="col" className="text-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {aiSettingsList?.map((aiItem) => {
              const {id, assistantPrompt} = aiItem;
              return (
                <tr key={id}>
                  <th>{id}</th>
                  <td>{assistantPrompt ?? '-'}</td>
                  <td className="d-flex justify-content-end">
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => {
                        navigate(`/app/admin/ai-settings/update/${id}`);
                      }}
                    >
                      <Icon name="edit" />
                      Edit
                    </Button>

                    <Button
                      color="danger"
                      size="sm"
                      className="ms-1"
                      onClick={() => {
                        setIsDeleteModalOpened(true);
                        setSelectedPrompt(aiItem);
                      }}
                    >
                      <Icon name="trash" />
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedPrompt?.id as number}
        itemName={selectedPrompt?.assistantPrompt as string}
        deleteHandler={deletePromptHandler}
      />
    </div>
  );
};

export default AiSettingsTable;
