import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Card, CardBody} from 'reactstrap';
import {channelDeviceFormat, channelUserApi} from '../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import useQuerySearch from '../../../shared/hooks/useQuerySearch';
import {IChannelUserDevice} from '../../administrator/channel-device-format/interfaces/IChannelDeviceFormat';

const ChannelUpdateForm = () => {
  const navigate = useNavigate();
  const channelName = useQuerySearch('name');
  const {id} = useParams();
  const {t} = useTranslation();
  const [deviceFormats, setDeviceFormats] = useState<IChannelUserDevice[]>([]);

  const changeDescriptionHandler = (value: string, deviceId: number, formatId: number) => {
    // Locate the index of the deviceFormat object using the findIndex method
    const deviceFormatIndex = deviceFormats.findIndex((deviceFormat) => deviceFormat.format === formatId);

    // Check if the deviceFormat exists in the array
    if (deviceFormatIndex !== -1) {
      // Locate the device object using the find method
      const deviceIndex = deviceFormats[deviceFormatIndex]?.data?.findIndex((device) => device.device === deviceId);

      // Check if the device exists in the deviceFormat object
      if (deviceIndex !== -1) {
        // Create a copy of the deviceFormats state object
        const copy = [...deviceFormats];
        // Update the description property of the device object
        copy[deviceFormatIndex].data[deviceIndex].description = value;
        // Update the deviceFormats state object with the updated copy
        setDeviceFormats(copy);
      }
    }
  };

  const updateHandler = async () => {
    const {data} = await channelUserApi.updateChannelDescription(deviceFormats, id ?? '');
    if (data) {
      navigate(-1);
    }
  };
  useEffect(() => {
    const getData = async () => {
      channelDeviceFormat.getChannelDeviceFormat(id ?? '').then((response) => {
        setDeviceFormats(
          response?.data?.data?.map((res) => ({
            format: res?.format,
            data: res.devices,
            name: res?.name,
          })) as IChannelUserDevice[]
        );
      });
    };
    id && getData();
  }, [id]);
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('administrator.devices.deviceUpdate')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <div className="row">
            <div className="col-12 mb-3">
              <h5>
                <>
                  {t('administrator.channelDeviceFormat.listForChannel')}
                  {channelName ?? ''}
                </>
              </h5>
            </div>
            {deviceFormats?.map((item) => {
              return (
                <div className="col-md-4" key={item?.format}>
                  {item?.data?.map((device) => {
                    return (
                      <React.Fragment key={device?.id}>
                        <label>
                          {device?.name} - {item?.name} {t('general.description')}:{' '}
                        </label>
                        <textarea
                          onChange={(e) => changeDescriptionHandler(e.target.value, device?.device, item?.format)}
                          value={device?.description ?? ''}
                          className="form-control mb-5"
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            })}
            <div className="col-12 mt-3 d-flex justify-content-end">
              <Button color="primary" onClick={updateHandler}>
                <Icon name="plus-sm" />
                {t('general.update')}
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </Block>
  );
};

export default ChannelUpdateForm;
