import React, {SetStateAction} from 'react';
import {IChannelPrice} from '../interfaces/IChannelPrice';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store';

interface IChannelPriceTableProps {
  channelPriceList: IChannelPrice[];
  setChannelPriceList: React.Dispatch<SetStateAction<IChannelPrice[]>>;
  isCompanySelected: boolean;
  changePriceHandler: (event: React.ChangeEvent<HTMLInputElement>, channelId: number) => void;
}

const ChannelPriceTable = ({channelPriceList, changePriceHandler, isCompanySelected, setChannelPriceList}: IChannelPriceTableProps) => {
  const {t} = useTranslation();
  const {price} = useSelector((state: RootState) => state.enum);
  const enableEditingHandler = (channelPriceId: number, isEnabled: boolean) => {
    const adjusted = channelPriceList?.map((chPrice) => {
      return {
        ...chPrice,
        changed: chPrice?.channel === channelPriceId ? true : chPrice?.changed,
        isOverridden: chPrice?.channel === channelPriceId ? !isEnabled : chPrice?.isOverridden,
      };
    });
    setChannelPriceList(adjusted);
  };

  return (
    <div className="table-responsive max-h-800px">
      <table className="table table-striped table-hover">
        <thead className="position-sticky bg-white top-0">
          <tr>
            <th scope="col">{t('general.channel')}</th>
            <th scope="col">{t('general.quality')}</th>
            {price?.map((item: string) => (
              <th scope="col" className="text-center">
                {t(`administrator.channelFormats.${item?.toLowerCase()}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {channelPriceList?.map((channelPrice, index) => {
            const {prices, channel, name, isOverridden, quality} = channelPrice;
            return (
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center justify-content-between">
                    <span>{name}</span>
                    {isCompanySelected && (
                      <div
                        className="custom-control custom-control-sm custom-checkbox d-flex m-1"
                        onClick={() => enableEditingHandler(channel, !!isOverridden)}
                      >
                        <input type="checkbox" className="custom-control-input form-control" checked={isOverridden} />
                        <label className="custom-control-label" htmlFor="f" />
                      </div>
                    )}
                  </div>
                </td>
                <td>{quality ?? '-'}</td>
                {price?.map((singlePrice) => {
                  return (
                    <td key={singlePrice}>
                      <input
                        type="number"
                        onInput={(e: any) => {
                          e.preventDefault();
                          e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                        }}
                        onChange={(event) => changePriceHandler(event, channel)}
                        name={singlePrice}
                        disabled={!isOverridden && isCompanySelected}
                        value={prices?.[singlePrice] ?? 0}
                        className="form-control"
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default ChannelPriceTable;
