import React, {useEffect, useState} from 'react';
import {campaignApi} from '../../../../api';
import {adjustAiChatBox} from '../../../../shared/Functions';
import ChangeStep from './ChangeStep';
import AIChat from './components/AIChat';
import {IStepperProps} from './index';

export interface IThread {
  id?: number;
  user?: string;
  logo?: string;
  isMyMessage?: boolean;
  path?: string;
  message?: string;
  assistant?: string;
}

const CampaignStep9 = ({campaignData}: IStepperProps) => {
  const [threadMessages, setThreadMessages] = useState<null | IThread[]>(null);
  const [originalUserThreads, setOriginalUserThreads] = useState<null | IThread[]>(null);
  const [file, setFile] = useState<string>('');
  const [timeUpdated, setTimeUpdated] = useState<number>(new Date().getTime());
  useEffect(() => {
    if (!!campaignData?.id) {
      const getThreads = async (campaignId: number) => {
        const {
          data: {fileGpt, data},
        } = await campaignApi.getAIThread(campaignId);
        setFile(fileGpt);
        setOriginalUserThreads((data || [])?.map((msg, index) => adjustAiChatBox(msg, index)));
      };
      getThreads(campaignData.id);
    }
    // eslint-disable-next-line
  }, [campaignData?.id, timeUpdated]);
  return (
    <>
      <ChangeStep
        campaignId={campaignData?.id}
        status={campaignData?.status}
        currentCampaignStep={campaignData?.currentStep}
        activeStep={7}
        name={campaignData?.name}
      />
      <AIChat
        originalUserThreads={originalUserThreads}
        threadMessages={threadMessages}
        setThreadMessages={setThreadMessages}
        file={file}
        setTimeUpdated={setTimeUpdated}
        campaignData={campaignData}
      />
    </>
  );
};

export default CampaignStep9;
