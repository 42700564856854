import React from 'react';
import Icon from '../../../../../components/icon/Icon';

interface Props {
  icon: string;
  text: string;
  title: string;
  isSelected: boolean;
  className?: string;
  onClick?: () => void;
}

const MediaPlanCard = ({icon, text, onClick, title, className = '', isSelected}: Props) => {
  return (
    <div
      onClick={onClick}
      className={`border border-${isSelected ? 'primary' : 'muted'} ${className} h-100 cursor-pointer border-dashed rounded-2`}
    >
      <div className={`card ${isSelected ? ' bg-soft-primary' : ''} h-100`}>
        <div className="card-body">
          <div className="row py-2">
            <div className="col-3">
              <Icon name={icon} className="display-6" />
            </div>
            <div className="col-9 ps-0">
              <h6>{title}</h6>
              <span className="text-muted">{text}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaPlanCard;
