import {capitalize} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {lineItemApi} from '../../../../../../api';
import {Block} from '../../../../../../components/block/Block';
import {useMultiInject} from '../../../../../../di';
import {ILineItem} from '../../../../../campaigns/interfaces/ICampaigns';
import {IQuality} from '../../../../../administrator/quality/interfaces/IQuality';

const LineItemsDetails = () => {
  const {id} = useParams();
  const {t} = useTranslation();
  const {dateTime} = useMultiInject();
  const [lineItems, setLineItems] = useState<ILineItem[]>([]);

  useEffect(() => {
    const getLineItem = async () => {
      if (id) {
        const {data} = await lineItemApi.getLineItem(id);
        setLineItems(data.lineItems);
      }
    };
    if (id) {
      getLineItem();
    }
  }, [id]);
  return (
    <Block>
      <div className="invoice">
        <div className="invoice-wrap">
          <div className="invoice-brand text-center"></div>

          <div className="invoice-head">
            <div className="invoice-contact">
              <span className="overline-title">{t('general.lineItems')}</span>
              <div className="invoice-contact-info"></div>
            </div>
          </div>

          <div className="invoice-bills">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{t('general.channel')}</th>
                    <th>{t('general.format')}</th>
                    <th>{t('general.objective')}</th>
                    <th>{t('general.quality')}</th>
                    <th>{t('general.targetGroup')}</th>
                    <th>{t('general.country')}</th>
                    <th>{t('general.region')}</th>
                    <th>{t('general.targeting')}</th>
                    <th>{t('general.priceModel')}</th>
                    <th>{t('general.priceAmount')}</th>
                    <th>{t('general.frequencyCap')}</th>
                    <th>{t('general.price')}</th>
                    <th>{t('order.ourCost')}</th>
                    <th>{t('general.device')}</th>
                    <th>{t('general.date')}</th>
                  </tr>
                </thead>
                <tbody>
                  {lineItems?.map((item) => {
                    return (
                      <tr key={item?.id}>
                        <td>{item?.channel?.name ?? '-'}</td>
                        <td>{item?.format?.name ?? '-'}</td>
                        <td>{capitalize(item?.objective)}</td>
                        <td>{(item?.channel?.quality as unknown as IQuality)?.name ?? '-'}</td>
                        <td>{item?.targetGroups?.name}</td>
                        <td>{(item as any).country ? t(`general.countryList.${(item as any).country}`) : '-'}</td>
                        <td>
                          {(item as any).lineItemRegion?.[0]?.region?.name
                            ? (item as any).lineItemRegion?.map((reg: any, i: number) => <p key={i}>{reg?.region?.name}</p>)
                            : '-'}
                        </td>
                        <td>{item?.channel?.targeting ? 'Yes' : 'No'}</td>
                        <td>{item?.price}</td>
                        <td>{item?.priceAmount ?? 0}</td>
                        <td>{item?.valueFrequencyCap ? item?.valueFrequencyCap : '-'}</td>
                        <td>{item?.priceValue} €</td>
                        <td>{item?.fee} €</td>
                        <td>
                          {item?.lineItemDevice.map((lineDevice) => (
                            <p key={lineDevice?.device?.id}>{lineDevice.device.name}</p>
                          ))}
                        </td>
                        <td>
                          {item?.lineItemDate?.at(0)?.date ? dateTime.formatDate(String(item?.lineItemDate?.at(0)?.date)) : '-'}
                          {item?.lineItemDate?.at(-1)?.date ? `- ${dateTime?.formatDate(String(item?.lineItemDate?.at(-1)?.date))}` : '-'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Block>
  );
};

export default LineItemsDetails;
