import Lottie from 'lottie-react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import errorIcon from '../../shared/icons/errorIcon.json';

interface Props {
  errorMessage: string;
}

const ErrorScreen = ({errorMessage}: Props) => {
  const navigate = useNavigate();

  return (
    <div className="container d-flex align-items-center flex-column justify-content-center h-60vh ">
      <Lottie animationData={errorIcon} loop={true} style={{width: '50%'}} className="my-2" />
      <h6>Ooops! You have an error, please contact support!</h6>
      <p className="text-muted">Error: {errorMessage}</p>
      <button className="btn btn-primary" onClick={() => navigate(-1)}>
        Back
      </button>
    </div>
  );
};

export default ErrorScreen;
