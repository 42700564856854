import {IDevicePrice} from '../../../app/administrator/device-price/interfaces/IDevicePrice';
import BaseApi from '../../base.api';

const DEVICE_PRICE_ROUTE = '/api/admin/device/price';

export class DevicePriceApi extends BaseApi {
  public async getDevicePrices(companyID?: number | null): Promise<{data: {devices: IDevicePrice[]}}> {
    return await this.get(DEVICE_PRICE_ROUTE, '', {companyID: companyID ?? null});
  }

  public async updateDevicePrices(data: IDevicePrice[], companyID?: number | null): Promise<{data: {devices: IDevicePrice[]}}> {
    return await this.put(
      DEVICE_PRICE_ROUTE,
      {
        devices: data,
      },
      '',
      false,
      {
        companyID: companyID ?? null,
      }
    );
  }
}
