import React, {SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {targetGroupsApi} from '../../../../api';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import {EmptyState} from '../../../../components/shared/EmptyState';
import {ITargetGroups} from '../interfaces/ITargetGroups';

interface ITargetGroupsTableProps {
  targetGroupsList: ITargetGroups[];
  setTargetGroupsList: React.Dispatch<SetStateAction<ITargetGroups[]>>;
}

const TargetGroupsTable = ({targetGroupsList, setTargetGroupsList}: ITargetGroupsTableProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedTargetGroups, setSelectedTargetGroups] = useState<ITargetGroups>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const sortHandler = () => {
    const copy = [...targetGroupsList];
    const dataToCompare = JSON.stringify(copy?.sort((a, b) => Number(b.id) - Number(a.id)));
    const isAsc = dataToCompare === JSON.stringify(targetGroupsList);
    const sortedArr = !isAsc ? copy?.sort((a, b) => Number(b.id) - Number(a.id)) : copy.reverse();
    setTargetGroupsList(sortedArr);
  };

  const deleteTypeHandler = async () => {
    if (selectedTargetGroups?.id) {
      const {
        data: {data},
      } = await targetGroupsApi.deleteTargetGroups(selectedTargetGroups?.id);
      setTargetGroupsList(data);
    }
  };
  return (
    <div className="table-responsive">
      {!!targetGroupsList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">
                {t('general.id')}
                <Icon name="swap-v" onClick={() => sortHandler()} className="ms-1 cursor-pointer" />
              </th>
              <th scope="col">{t('general.name')}</th>
              <th scope="col">{t('general.description')}</th>
              <th scope="col">{t('general.default')}</th>
              <th scope="col" className="text-end">
                {t('general.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {targetGroupsList?.map((targetGroups) => {
              const {name, id, description, default: isDefault} = targetGroups;
              return (
                <tr key={id}>
                  <th>{id}</th>
                  <td>{name}</td>
                  <td>{Boolean(description) ? description : '-'}</td>
                  <td>{isDefault ? t('general.yes') : t('general.no')}</td>
                  <td className="d-flex justify-content-end">
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => {
                        navigate(`/app/admin/target-groups/update/${id}`);
                      }}
                    >
                      <Icon name="edit" />
                      {t('general.edit')}
                    </Button>
                    {!targetGroups.isUsed && (
                      <Button
                        color="danger"
                        size="sm"
                        className="ms-1"
                        onClick={() => {
                          setIsDeleteModalOpened(true);
                          setSelectedTargetGroups(targetGroups);
                        }}
                      >
                        <Icon name="trash" />
                        {t('general.delete')}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedTargetGroups?.id as number}
        itemName={selectedTargetGroups?.name as string}
        deleteHandler={deleteTypeHandler}
      />
    </div>
  );
};

export default TargetGroupsTable;
