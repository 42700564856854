import React, {SetStateAction, useEffect, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {campaignApi} from '../../../../../api';
import CustomModal from '../../../../../components/modal/Modal';
import {IChannelDeviceFormatBuilder} from '../../../../administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import {IType} from '../../../../administrator/type/interfaces/IType';
import {ICampaign} from '../../../interfaces/ICampaigns';
import {IListSubmitData} from '../CampaignStep3';
import LineItemStep1 from './LineItemStep1';
import LineItemStep2 from './LineItemStep2';

export interface ILineItemStep1 {
  methods: UseFormReturn<IChannelDeviceFormatBuilder>;
  onFormSubmit: any;
  currStep?: number;
  setCurrStep: React.Dispatch<SetStateAction<number>>;
  channels: any;
  selectedLineItem: any;
  handleSelectChannel: any;
  setListSubmitData: React.Dispatch<SetStateAction<IListSubmitData>>;
  sendDataHandler?: any;
  campaignData: ICampaign;
  setSelectedCountry?: React.Dispatch<SetStateAction<string | any>>;
  selectedCountry?: string;
}

interface Props extends ILineItemStep1 {
  isModalOpened: boolean;
  setIsModalOpened: React.Dispatch<SetStateAction<boolean>>;

  isRegionValid?: boolean;
  setIsRegionValid?: any;
  setIsCreativeValid?: any;
  isCreativeValid: boolean | undefined;
}

const LineItemModal = ({
  isModalOpened,
  onFormSubmit,
  setIsModalOpened,
  selectedLineItem,
  campaignData,
  handleSelectChannel,
  sendDataHandler,
  setCurrStep,
  currStep,
  isRegionValid,
  setIsRegionValid,
  channels,
  methods,
  isCreativeValid,
  setIsCreativeValid,

  setListSubmitData,
}: Props) => {
  const {t} = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState<string>();

  useEffect(() => {
    if (!!campaignData?.id && methods.setValue && isModalOpened) {
      const getDefaultFilters = async () => {
        const [filtersResponse] = await Promise.all([campaignApi.getDefaultFilters(campaignData?.id)]);
        const filters = filtersResponse.data?.[0];
        methods.setValue('targetGroup', filters?.targetGroups?.id);
        methods.setValue(
          'type',
          (filters?.type as unknown as IType[])?.map((type) => type.id)
        );
        methods.setValue(
          'quality',
          filters?.quality?.map((device) => device.id)
        );
        campaignData.country.length === 1 && methods.setValue('country', campaignData.country[0]);
        methods.setValue('objective', filters?.objective);
        methods.setValue('price', filters?.price);
        methods.setValue(
          'device',
          filters?.device?.map((device) => device.id)
        );
      };

      getDefaultFilters();
    }
  }, [campaignData?.id, methods, isModalOpened]);

  return (
    <CustomModal
      saveHandler={() => sendDataHandler(selectedLineItem?.channel?.id ?? 0, selectedLineItem?.format?.id ?? 0)}
      size="xl"
      key={campaignData?.id}
      isModalOpened={isModalOpened}
      setIsModalOpened={setIsModalOpened}
      showSubmit={Number(currStep) === 2}
      title={t('general.createLineItem')}
      setCurrStep={setCurrStep}
      body={
        <>
          <h5 className="text-center bg-light p-2 radius-5px">Step {currStep}/2</h5>
          {currStep === 1 ? (
            <LineItemStep1
              selectedLineItem={selectedLineItem}
              handleSelectChannel={handleSelectChannel}
              channels={channels}
              methods={methods}
              setCurrStep={setCurrStep}
              setListSubmitData={setListSubmitData}
              onFormSubmit={onFormSubmit}
              campaignData={campaignData}
              setSelectedCountry={setSelectedCountry!}
              selectedCountry={selectedCountry}
            />
          ) : (
            <LineItemStep2
              setListSubmitData={setListSubmitData}
              methods={methods}
              isRegionValid={isRegionValid}
              setIsRegionValid={setIsRegionValid}
              setIsCreativeValid={setIsCreativeValid}
              isCreativeValid={isCreativeValid}
              selectedCountry={selectedCountry}
            />
          )}
        </>
      }
    />
  );
};

export default LineItemModal;
