import {IChannels} from '../../../app/administrator/objective-scoring/interfaces/IObjectiveScoring';
import BaseApi from '../../base.api';

const OBJECTIVE_ROUTE = '/api/admin/objective/objective/all';

export class ObjectiveApi extends BaseApi {
  public async getObjective(country: string): Promise<any> {
    return await this.get(OBJECTIVE_ROUTE, '', {country});
  }

  public async exportObjective(country: string): Promise<any> {
    return await this.get('/api/admin/objective/download', '', {country}, true);
  }

  public async importObjective(data: any): Promise<any> {
    return await this.put('/api/admin/objective/file/upload', data, '', true);
  }

  public async updateObjective(data: IChannels[], country: string): Promise<any> {
    return await this.put(
      `${OBJECTIVE_ROUTE}?country=${country}`,
      {
        channels: data,
      },
      ''
    );
  }
}

// GET: /api/admin/objective/download
// PUT: /api/admin/objective/file/upload
