import React, {SetStateAction} from 'react';
import Lottie from 'lottie-react';
import {useTranslation} from 'react-i18next';
import trashIcon from '../../shared/icons/trashIcon.json';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';
import Button from '../button/Button';

export interface IConfirmModalProps {
  itemName: string;
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
  deleteHandler: (lineId: number, picId: number) => void;
  itemId: number;
  pictureId?: number;
}

const ConfirmDeleteModal = ({itemName, show, setShow, deleteHandler, itemId, pictureId}: IConfirmModalProps) => {
  const handleClose = () => setShow(false);
  const {t} = useTranslation();
  return (
    <div>
      <Modal isOpen={show} toggle={handleClose} size="sm" centered>
        <ModalBody className="d-flex align-items-center flex-column">
          <Lottie animationData={trashIcon} loop={true} style={{width: 100}} />
          <h5>{t('general.areYouSure')}</h5>
          <p
            className="text-center text-muted"
            dangerouslySetInnerHTML={{
              __html: t('general.confirmAction', {
                action: 'remove',
                itemName,
              }) as unknown as string,
            }}
          />
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-center">
          <Button color="light" size="sm" onClick={handleClose}>
            {t('general.cancel')}
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={() => {
              handleClose();
              deleteHandler(itemId, pictureId!);
            }}
          >
            {t('general.yesDelete')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmDeleteModal;
