import React from 'react';
import classNames from 'classnames';

// import {IntrinsicAttributes} from "react";

// interfaces IButton {
//   color: string;
//   size: string;
//   className: string;
//   outline?: string;
//   type?: string;
//   disabled?: boolean;
//   children: ReactNode;
// }

const Button = ({color, size, className, outline, disabled, ...props}: any) => {
  const buttonClass = classNames({
    btn: true,
    [`btn-${color}`]: !outline,
    [`btn-outline-${color}`]: outline,
    [`btn-${size}`]: size,
    disabled: disabled,
    [`${className}`]: className,
  });
  return (
    <button className={buttonClass} {...props}>
      {props.children}
    </button>
  );
};
export default Button;
