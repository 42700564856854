import React from 'react';
import {FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {IAiSettings} from '../interfaces/IAiSettings';
import {useTranslation} from 'react-i18next';
import {Col, FormGroup, Row} from 'reactstrap';

interface IAiSettingsFormProps {
  errors: Partial<
    FieldErrorsImpl<{
      assistantPrompt: string;
    }>
  >;
  register: UseFormRegister<IAiSettings>;
}

const AiSettingsForm = ({errors, register}: IAiSettingsFormProps) => {
  const {t} = useTranslation();
  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <label className="form-label" htmlFor="name">
            {t('general.assistantPrompt')}
          </label>
          <div className="form-control-wrap">
            <input
              type="text"
              id="reg-type"
              placeholder={t('administrator.aiSettings.enterAiPrompt') as string}
              {...register('assistantPrompt')}
              className="form-control-lg form-control"
            />
            {errors?.assistantPrompt && <p className="invalid">{errors?.assistantPrompt?.message}</p>}
          </div>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default AiSettingsForm;
