import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Alert, Card, CardBody, Col, Form, FormGroup, Row} from 'reactstrap';
import * as yup from 'yup';
import {campaignApi} from '../../../../api';
import {Block} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import FileInput from '../../../../shared/FileInput';
import {formatDatepickerTime, isFileApplication, isUserAdmin} from '../../../../shared/Functions';
import {RootState} from '../../../../store';
import {commonSlice, TypeEnum} from '../../../../store/slices/common.slice';
import {IType} from '../../../administrator/type/interfaces/IType';
import {ICampaignStep2} from '../../interfaces/ICampaigns';

import ChangeStep from './ChangeStep';
import CampaignStepThreeDropdowns from './components/CampaignStepThreeDropdowns';
import MediaPlanCard from './components/MediaPlanCard';
import {IStepperProps} from './index';
import CustomModal from '../../../../components/modal/Modal';

export const campaignStep2Form = yup.object({
  startDate: yup.date().required('No start date provided').typeError('You need to enter start date'),
  endDate: yup
    .date()
    .typeError('You need to enter end date')
    .test('same_dates_test', 'End date needs to be after start date', function (value) {
      const {startDate} = this.parent;
      return value ? value?.getTime() >= startDate?.getTime() : false;
    }),
  plannedBudget: yup.number().typeError('No planned budget provided.').min(1).required('Planned budget required'),
  objective: yup.string().required('No objective provided.'),
  price: yup.string().required('No price provided.'),
  type: yup.number().typeError('No type provided.').required('No type provided.'),
  quality: yup.array().min(1).typeError('No quality provided.').required('No quality provided.'),
  targetGroup: yup.number().typeError('No target group provided.').required('No target group provided.'),
  device: yup.array().min(1).typeError('No device provided.').required('No device provided'),

  describeMediaPlan: yup
    .string()
    .nullable(true)
    .when('mediaPlan', {
      is: true,
      then: yup.string().min(100, 'Minimum 100 characters').required('Describe media plan required when media plan selected'),
    }),
});

const CampaignStep2 = ({campaignData, isCampaignEditable = false, setCampaignData}: IStepperProps) => {
  const {t} = useTranslation();
  const {stepId, id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const {
    user: {roles},
  } = useSelector((state: RootState) => state.auth);
  const isAdmin = isUserAdmin(roles);

  const methods = useForm<ICampaignStep2>({
    resolver: yupResolver(campaignStep2Form),
  });

  const onFormSubmit = async (state: ICampaignStep2, e: any) => {
    const fileToSave = (state.mediaPlanFile as unknown as File[])?.[0];
    if (state?.mediaPlanFile) {
      if (!isFileApplication(fileToSave?.name)) {
        dispatch(commonSlice.actions.setMessage({type: TypeEnum.error, text: 'Invalid file extension'}));
        return;
      }
      const formData = new FormData();
      formData.append('mediaPlan', fileToSave);
      formData.append('campaign', `${id}`);
      await campaignApi.uploadMediaFile(formData);
    }
    const {data} = await campaignApi.updateStep2(Number(id), {
      ...state,
      type: methods.watch('type'),
      startDate: formatDatepickerTime(state?.startDate),
      endDate: formatDatepickerTime(state?.endDate),
      targetGroups: state?.targetGroup, // obrisati ovo i na interface kada miroljub ispravi da je jednina
    });
    data && navigate(`/app/campaigns/${Number(stepId) + 1}/${id}`);
  };

  const handleDeleteMediaPlanFile = async () => {
    const {data} = await campaignApi.deleteMediaPlanId(campaignData?.mediaPlanFile?.id ?? 0, campaignData?.id);
    if (data) {
      setCampaignData && setCampaignData((prev) => ({...prev, mediaPlanFile: undefined}));
    }
  };

  useEffect(() => {
    if (!!campaignData?.id) {
      const getDefaultFilters = async () => {
        const {data} = await campaignApi.getDefaultFilters(campaignData?.id);
        const filters = data[0];
        methods.reset({
          ...(campaignData as any),
          targetGroup: filters?.targetGroups?.id,
          type: (filters?.type as unknown as IType[])?.map((quality) => quality.id),
          quality: filters?.quality?.map((quality) => quality.id),
          objective: filters?.objective,
          price: filters?.price,
          device: filters?.device?.map((device) => device.id),
        });
      };
      getDefaultFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData?.id, methods, campaignData?.endDate, campaignData?.startDate]);
  const firstSelectedMonth = !isAdmin
    ? formatDatepickerTime(moment(new Date()).add(1, 'month').toISOString().split('T')[0])
    : formatDatepickerTime(new Date().toISOString().split('T')[0]);

  return (
    <Block size="lg" className="mt-3">
      <ChangeStep
        currentCampaignStep={campaignData?.currentStep}
        status={campaignData?.status}
        campaignId={campaignData?.id}
        activeStep={2}
        name={campaignData?.name}
      />
      <FormProvider {...methods}>
        <Form onSubmit={methods?.handleSubmit(onFormSubmit)}>
          <Card className="card-bordered card-preview">
            <CardBody>
              <Col xs={12} className="mb-2">
                <h5>{t('general.campaignDuration')}</h5>
              </Col>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <label className="form-label" htmlFor="startDate">
                      {t('general.startDate')}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        id="reg-type"
                        // disabled={campaignData?.lineItems}
                        placeholder={t('general.enterStartDate') as string}
                        {...methods.register('startDate')}
                        min={!methods.getValues().startDate ? firstSelectedMonth : undefined}
                        className="form-control-lg form-control"
                        onInput={(event) => campaignData?.lineItems && setIsOpen(true)}
                      />
                      {methods?.formState?.errors?.startDate && <p className="invalid">{methods?.formState?.errors?.startDate?.message}</p>}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label className="form-label" htmlFor="endDate">
                      {t('general.endDate')}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        id="reg-type"
                        placeholder={t('general.enterEndDate') as string}
                        {...methods.register('endDate')}
                        min={firstSelectedMonth}
                        className="form-control-lg form-control"
                        onInput={(event) => campaignData?.lineItems && setIsOpen(true)}
                      />
                      {methods?.formState?.errors?.endDate && <p className="invalid">{methods?.formState?.errors?.endDate?.message}</p>}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <label className="form-label" htmlFor="plannedBudget">
                      {t('campaigns.plannedBudget')}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        onInput={(e: any) => {
                          e.preventDefault();
                          e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                        }}
                        id="plannedBudget"
                        placeholder={t('campaigns.plannedBudget') ?? ''}
                        {...methods.register('plannedBudget', {valueAsNumber: true})}
                        className="form-control-lg form-control"
                      />
                      {methods?.formState?.errors?.plannedBudget && (
                        <p className="invalid">{methods?.formState?.errors?.plannedBudget?.message}</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <div className="custom-control custom-switch form-label" style={{zIndex: -0}}>
                    <input type="checkbox" className="custom-control-input" id="tax" {...methods.register('tax')} />
                    <label className="custom-control-label form-label" htmlFor="tax">
                      Tax
                    </label>
                  </div>
                  {!!methods.watch('tax') && (
                    <div className="row pe-0 me-0">
                      <input
                        type="number"
                        className="form-control-lg form-control"
                        {...methods.register('taxValue', {valueAsNumber: true})}
                        placeholder="%"
                      />
                      {methods?.formState?.errors?.taxValue && <p className="invalid">{methods?.formState?.errors?.taxValue?.message}</p>}
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="align-items-baseline">
                <Col md={9} className="my-2" />
                <Col md={4}>
                  <MediaPlanCard
                    onClick={() => methods.reset((prev) => ({...prev, mediaPlan: false}))}
                    title={t('campaigns.personalAccount')}
                    isSelected={!methods?.watch('mediaPlan')}
                    icon="account-setting-fill"
                    text={t('campaigns.personalDesc')}
                  />
                </Col>
                <Col md={4}>
                  <MediaPlanCard
                    onClick={() => methods.reset((prev) => ({...prev, mediaPlan: true}))}
                    title={t('campaigns.corporateAccount', {project_name: process.env.REACT_APP_PROJECT_NAME})}
                    className=""
                    text={t('campaigns.corporateDesc')}
                    isSelected={methods?.watch('mediaPlan')}
                    icon="note-add-fill-c"
                  />
                </Col>
                {methods?.watch('mediaPlan') && (
                  <>
                    <Col md={8}>
                      <FormGroup className="mt-2">
                        <label className="form-label" htmlFor="endDate">
                          {t('campaigns.whatToBook')}
                        </label>
                        <textarea
                          rows={10}
                          className="form-control"
                          {...methods.register('describeMediaPlan')}
                          placeholder="Write something..."
                        />
                        {methods?.formState?.errors?.describeMediaPlan && (
                          <p className="invalid">{methods?.formState?.errors?.describeMediaPlan?.message}</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={4} className="mt-5">
                      <Alert color="warning">
                        <h6 className="text-warning">{t('campaigns.requestedPoints')}</h6>
                        <ul className="list-style-points fs-11px text-warning">
                          <li>Specify your brand/product</li>
                          <li>Specify your objectives & relevant kpis</li>
                          <li>Specify target group</li>
                          <li>Specify your planned total budget</li>
                          <li>Do you prefer any special allocations of your budget ?</li>
                          <li>Specify timing</li>
                          <li>Any preferred channels / media</li>
                          <li>You have/prefer any ads?: such as Sitebar, UAP Set, Carousel, etc</li>
                        </ul>
                      </Alert>
                    </Col>
                    <Col md={8}>
                      <FileInput accept="application/*" isMultiple={false} name="mediaPlanFile" label="File Upload" />
                      <p className="text-end text-muted">Accepted files: .pdf, .xls, .doc, .ppt</p>
                    </Col>
                    {campaignData?.mediaPlanFile?.id && (
                      <Col md={4} className="d-flex">
                        <a href={campaignData?.mediaPlanFile?.path}>
                          <img
                            src="https://d9-wret.s3.us-west-2.amazonaws.com/assets/palladium/production/s3fs-public/thumbnails/image/file.jpg"
                            alt="Media plan file"
                            width={200}
                            height={200}
                            className="  img-cover default-radius"
                          />
                        </a>
                        <Icon name="trash" className="fs-25px text-danger cursor-pointer" onClick={() => handleDeleteMediaPlanFile()} />
                      </Col>
                    )}
                  </>
                )}
                <Col xs={12} className="mt-4">
                  <h5>{t('general.defaultFiltering')}</h5>
                  <div className="row">
                    <CampaignStepThreeDropdowns errors={methods?.formState?.errors} register={methods?.register} />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="me-3"
                      color="secondary"
                      onClick={() => {
                        navigate('/app/campaigns');
                      }}
                    >
                      <Icon name="arrow-long-left" />
                      {t('general.cancel')}
                    </Button>
                    {isCampaignEditable && (
                      <Button color="primary">
                        <Icon name="edit" />
                        {t('general.update')}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </FormProvider>
      <CustomModal
        setIsModalOpened={setIsOpen}
        saveHandler={() => setIsOpen(false)}
        successName={t('general.continue') as string}
        size="sm"
        isModalOpened={isOpen}
        title="Alert"
        body={<p>Please be advised that line items are already in place.</p>}
        haveCancel={false}
      ></CustomModal>
    </Block>
  );
};

export default CampaignStep2;
