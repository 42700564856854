import {IFormat, IFormatPagination} from '../../../app/administrator/formats/interfaces/IFormat';
import {IType} from '../../../shared/interfaces/Type';
import BaseApi from '../../base.api';

const FORMAT_ROUTE = '/api/admin/format';

export class FormatApi extends BaseApi {
  public async getAllFormats(pagination: IFormatPagination): Promise<any> {
    return await this.get(FORMAT_ROUTE, '', pagination);
  }

  public async getFormat(id: number): Promise<{data: IFormat}> {
    return await this.get(`${FORMAT_ROUTE}/${id}`);
  }

  public async createFormat(data: FormData, token: string): Promise<any> {
    return await this.post(FORMAT_ROUTE, data, token, true);
  }

  public async deleteFormat(id: number): Promise<any> {
    return await this.delete(`${FORMAT_ROUTE}/${id}`, '');
  }

  public async updateFormat(id: number, data: FormData): Promise<{data: IType}> {
    return await this.put(`${FORMAT_ROUTE}/${id}`, data, '', true);
  }
}
