import {IRegion, IRegionPagination} from '../../../app/administrator/region/interfaces/IRegion';
import BaseApi from '../../base.api';

const REGION_ROUTE = 'api/admin/region';

export class RegionApi extends BaseApi {
  public async getAllRegions(pagination: IRegionPagination): Promise<any> {
    return await this.get(REGION_ROUTE, undefined, pagination);
  }

  public async getRegion(id: number): Promise<{data: IRegion}> {
    return await this.get(`${REGION_ROUTE}/${id}`);
  }

  public async createRegion(data: any): Promise<any> {
    return await this.post(REGION_ROUTE, data);
  }

  public async deleteRegion(id: number): Promise<any> {
    return await this.delete(`${REGION_ROUTE}/${id}`, '');
  }

  public async updateRegion(id: number, data: IRegion): Promise<{data: IRegion}> {
    return await this.put(`${REGION_ROUTE}/${id}`, data, '');
  }
}
