import React from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from '../../components/error/NotFound';
import {PrivateRoute} from '../../routes/RouteGuards';
import CampaignCreate from './components/CampaignCreate';
import CampaignStepper from './components/steps';
import Campaigns from './index';

export function CampaignRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute component={Campaigns} />} />
      <Route path="/create" element={<PrivateRoute component={CampaignCreate} />} />
      <Route path="/:stepId/:id" element={<PrivateRoute component={CampaignStepper} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
