import React from 'react';

import {Card, CardBody, CardImg} from 'reactstrap';
import Icon from '../../../../components/icon/Icon';
import ImageContainer from '../ImageContainer';

const GalleryCard = ({
  data,
  file,
  downloadImage,
  deleteImage,
  setSelectedLineItemId,
  setSelectedCreativePictureId,
  setIsDeleteModalOpened,
  fileMime,
}: any) => {
  let Jsx;

  switch (true) {
    case fileMime.includes('excel') || fileMime.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet'):
      Jsx = (
        <em
          className="icon ni ni-file-xls  h-100 w-100 d-flex align-items-center justify-content-center display-1"
          style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
        ></em>
      );
      break;
    case fileMime.includes('word'):
      Jsx = (
        <em
          className="icon ni ni-file-doc  h-100 w-100 d-flex align-items-center justify-content-center display-1"
          style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
        ></em>
      );
      break;
    case fileMime.includes('zip'):
      Jsx = (
        <em
          className="icon ni ni-file-zip  h-100 w-100 d-flex align-items-center justify-content-center display-1"
          style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
        ></em>
      );
      break;
    case fileMime.includes('pdf'):
      Jsx = (
        <em
          className="icon ni ni-file-pdf  h-100 w-100 d-flex align-items-center justify-content-center display-1"
          style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
        ></em>
      );
      break;
    case fileMime.includes('text'):
      Jsx = (
        <em
          className="icon ni ni-file-text  h-100 w-100 d-flex align-items-center justify-content-center display-1"
          style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
        ></em>
      );
      break;
    case fileMime.includes('video'):
      Jsx = (
        <em
          className="icon ni ni-video  h-100 w-100 d-flex align-items-center justify-content-center display-1"
          style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
        ></em>
      );
      break;
    case fileMime.includes('image'):
      Jsx = <CardImg className="h-100" top style={{maxHeight: '75px', objectFit: 'cover'}} src={`${file.path}`} alt="" />;
      break;
    case fileMime.includes('application'):
      Jsx = (
        <em
          className="icon ni ni-file  h-100 w-100 d-flex align-items-center justify-content-center display-1"
          style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
        ></em>
      );
      break;
  }
  return (
    <div className="col mb-3" style={{maxHeight: '110px'}}>
      <Card className="card-bordered h-100 ">
        {Jsx}
        <CardBody className="card-inner p-1 d-flex ">
          {(fileMime.includes('image') || fileMime.includes('pdf') || fileMime.includes('video')) && (
            <div className="col text-center cursor-pointer">
              <ImageContainer className="h-100" path={file.path} mime={fileMime} />
            </div>
          )}
          <div className="col text-center cursor-pointer" onClick={() => downloadImage(file.path, file.file.name)}>
            <Icon name="download cursor-pointer text-secondary" />
          </div>
          <div className="col text-center cursor-pointer ">
            <Icon
              onClick={() => {
                setSelectedLineItemId(data?.lineItem_id);
                setSelectedCreativePictureId(file?.id);
                setIsDeleteModalOpened(true);
              }}
              name="cross text-danger"
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default GalleryCard;
