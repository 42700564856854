import React, {SetStateAction, useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {campaignApi} from '../../../../api';
import {ICampaign, ICampaignStatusEnum} from '../../interfaces/ICampaigns';
import CampaignStep1 from './CampaignStep1';
import CampaignStep2 from './CampaignStep2';
import CampaignStep3 from './CampaignStep3';
import CampaignStep4 from './CampaignStep4';
import CampaignStep5 from './CampaignStep5';
import CampaignStep6 from './CampaignStep6';
import {isUserAdmin} from '../../../../shared/Functions';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store';
import CampaignStep7 from './CampaignStep7';
import CampaignStep8 from './CampaignStep8';
import CampaignStep9 from './CampaignStep9';

export interface IStepperProps {
  handleGoBack?: () => void;
  campaignData: ICampaign;
  setCampaignData?: React.Dispatch<SetStateAction<ICampaign>>;
  isCampaignEditable: boolean;
}

const CampaignStepper = () => {
  const {stepId, id} = useParams<Record<string, string | undefined>>();
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState<ICampaign>();
  const {roles} = useSelector((state: RootState) => state?.auth?.user);
  const isAdmin = isUserAdmin(roles);

  const isCampaignEditable =
    ([ICampaignStatusEnum.PENDING, ICampaignStatusEnum.DRAFT] ?? []).includes(campaignData?.status as ICampaignStatusEnum) ||
    (isAdmin && ICampaignStatusEnum.IN_PROGRESS === campaignData?.status);

  useEffect(() => {
    if (id) {
      const getCampaign = async (campaignId: number) => {
        const {data} = await campaignApi.getCampaign(+campaignId);
        setCampaignData(data);
      };
      getCampaign(+id);
    }
  }, [id, stepId]);

  const CurrentStep = useCallback(() => {
    const handleGoBack = () => {
      navigate(`/app/campaigns/${Number(stepId) - 1}/${id}`);
    };

    const step: StepperType = campaignData?.openAi
      ? {
          1: <CampaignStep1 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} />,
          2: (
            <CampaignStep2
              campaignData={campaignData as ICampaign}
              setCampaignData={setCampaignData as React.Dispatch<SetStateAction<ICampaign>>}
              isCampaignEditable={isCampaignEditable}
              handleGoBack={handleGoBack}
            />
          ),
          3: <CampaignStep3 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
          4: <CampaignStep8 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
          5: <CampaignStep5 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
          7: <CampaignStep9 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
          6: <CampaignStep6 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
          8: <CampaignStep7 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
          9: <CampaignStep4 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
        }
      : {
          1: <CampaignStep1 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} />,
          2: (
            <CampaignStep2
              campaignData={campaignData as ICampaign}
              setCampaignData={setCampaignData as React.Dispatch<SetStateAction<ICampaign>>}
              isCampaignEditable={isCampaignEditable}
              handleGoBack={handleGoBack}
            />
          ),
          3: <CampaignStep3 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
          4: <CampaignStep8 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
          5: <CampaignStep5 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,

          6: <CampaignStep6 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
          7: <CampaignStep7 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
          8: <CampaignStep4 campaignData={campaignData as ICampaign} isCampaignEditable={isCampaignEditable} handleGoBack={handleGoBack} />,
        };

    return step[Number(stepId)];
  }, [stepId, id, navigate, campaignData, isCampaignEditable]);

  return <CurrentStep />;
};
export type StepperType = Record<number, JSX.Element>;
export default CampaignStepper;
