import {IProduct, IProductPagination} from '../../../app/management/products/interfaces/IProduct';
import BaseApi from '../../base.api';

const PRODUCT_ROUTES = '/api/admin/product';

export class ProductApi extends BaseApi {
  public async getAllProduct(pagination: IProductPagination): Promise<{data: any}> {
    return await this.get(PRODUCT_ROUTES, '', pagination);
  }

  public async getProduct(id: number): Promise<{data: IProduct}> {
    return await this.get(`${PRODUCT_ROUTES}/${id}`);
  }

  public async updateProduct(id: number, data: IProduct): Promise<{data: IProduct}> {
    return await this.put(`${PRODUCT_ROUTES}/${id}`, data, '');
  }

  public async createProduct(data: Partial<IProduct>): Promise<any> {
    return await this.post(PRODUCT_ROUTES, data);
  }

  public async deleteProduct(id: number): Promise<any> {
    return await this.delete(`${PRODUCT_ROUTES}/${id}`, '');
  }
}
