import React, {useState} from 'react';
import {Form, FormGroup} from 'reactstrap';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormUser from './FormUser';
import FormCompany from './FormCompany';
import Button from '../../../components/button/Button';
import {IRegisterData} from '../../interfaces/Register';
import {authApi} from '../../../api';

const schema = yup
  .object({
    user: yup.object({
      contact: yup.object({
        country: yup.string().required('No country provided'),
      }),
      firstName: yup.string().required('No first name provided.').min(2, 'First name is too short - should be 2 chars minimum.'),
      email: yup.string().email().required('No email provided.'),
      password: yup
        .string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    }),
    company: yup.object({
      contact: yup.object({
        country: yup.string().required('No country provided'),
      }),
      name: yup.string().required('No name provided.').min(3, 'Name is too short - should be 3 chars minimum.'),
      email: yup.string().email().required('No email provided.'),
    }),
  })
  .required();
const RegisterForm = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [passState, setPassState] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IRegisterData>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onFormSubmit = async (formData: IRegisterData) => {
    await authApi.register(formData);
    navigate('/');
  };

  const setStep = () => {
    if (currentStep === 1) {
      !errors.user && setCurrentStep((prev) => prev + 1);
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  };

  return (
    <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
      {currentStep === 1 ? (
        <FormUser passState={passState} setPassState={setPassState} register={register} errors={errors} />
      ) : (
        <FormCompany register={register} errors={errors} />
      )}

      <div className="row">
        <FormGroup className="row justify-content-between col-12">
          <Button type="button" onClick={setStep} color="secondary" size="lg" className="col-auto">
            {currentStep === 1 ? 'Next' : 'Back'}
          </Button>
          {currentStep === 2 && (
            <Button type="submit" color="primary" size="lg" className="col-auto">
              Register
            </Button>
          )}
        </FormGroup>
      </div>
    </Form>
  );
};

export default RegisterForm;
