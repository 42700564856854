import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {EmptyState} from '../../../components/shared/EmptyState';
import UserAvatar from '../../../components/user/UserAvatar';
import {useMultiInject} from '../../../di';
import {getAvatarInitials, getCorrectCampaignStatus} from '../../../shared/Functions';
import {ICampaign, ICampaignStatusEnum, IUserDashboardCard} from '../../campaigns/interfaces/ICampaigns';
import {IBrand} from '../../management/brands/interfaces/IBrand';
import {IProduct} from '../../management/products/interfaces/IProduct';

interface ICampaignTableProps {
  campaignList: ICampaign[];
}

const ReportingTable = ({campaignList}: ICampaignTableProps) => {
  const navigate = useNavigate();
  const {dateTime} = useMultiInject();
  const {t} = useTranslation();
  return (
    <>
      {!!campaignList?.length ? (
        <div className="nk-block" data-select2-id="16">
          <div className="card card-bordered" data-select2-id="15">
            <div className="card-inner-group" data-select2-id="14">
              <div className="card-inner p-0">
                <div className="nk-tb-list nk-tb-ulist">
                  <div className="nk-tb-item nk-tb-head">
                    <div className="nk-tb-col nk-tb-col-check">
                      <div className="custom-control custom-control-sm custom-checkbox notext"></div>
                    </div>
                    <div className="nk-tb-col">
                      <span>{t('general.campaign')}</span>
                    </div>
                    <div className="nk-tb-col tb-col-lg">
                      <span>
                        {t('general.startDate')} - {t('general.endDate')}
                      </span>
                    </div>
                    <div className="nk-tb-col tb-col-md">
                      <span>{t('general.status')}</span>
                    </div>
                    <div className="nk-tb-col tb-col-lg">
                      <span>{t('general.employees')}</span>
                    </div>
                    <div className="nk-tb-col nk-tb-col-tools">&nbsp;</div>
                  </div>
                  {campaignList?.map((campaign) => {
                    const {id, name, product, status, startDate, endDate, user} = campaign;
                    let statusColor = 'primary';

                    if (status === ICampaignStatusEnum.REJECT) {
                      statusColor = 'danger';
                    } else if (status === ICampaignStatusEnum.ON_AIR) {
                      statusColor = 'success';
                    } else if (status === ICampaignStatusEnum.IN_PROGRESS) {
                      statusColor = 'warning';
                    } else if (status === ICampaignStatusEnum.PENDING) {
                      statusColor = 'warning';
                    } else if (status === ICampaignStatusEnum.DRAFT) {
                      statusColor = 'default';
                    }

                    return (
                      <>
                        <div className="nk-tb-item" key={id}>
                          <div className="nk-tb-col nk-tb-col-check"></div>
                          <div className="nk-tb-col">
                            <div
                              className="user-card cursor-pointer"
                              onClick={() => {
                                navigate(`/app/reporting/${id}`);
                              }}
                            >
                              <div className="user-avatar bg-warning">
                                <span>{getAvatarInitials(name, name?.charAt(1) ?? '')}</span>
                              </div>
                              <div className="user-info">
                                <span className="tb-lead">
                                  {name}
                                  <span className="dot dot-success d-md-none ms-1"></span>
                                </span>
                                <span>{(product as unknown as IProduct)?.name ?? '-'}</span> -
                                <span> {((product as unknown as ICampaign)?.brand as unknown as IBrand)?.name ?? '-'}</span>
                              </div>
                            </div>
                          </div>
                          <div className="nk-tb-col tb-col-lg">
                            <span className="tb-date">{`${dateTime.formatDate(startDate)} - ${dateTime.formatDate(endDate)}` ?? '-'}</span>
                          </div>
                          <div className="nk-tb-col tb-col-md">
                            <span className={`tb-status text-${statusColor}`}>{getCorrectCampaignStatus(status) ?? '-'}</span>
                          </div>
                          <div className="nk-tb-col tb-col-lg w-25">
                            <div className="d-flex flex-wrap justify-content-start">
                              {(user as unknown as IUserDashboardCard[])?.map((item) => {
                                const {id, firstName, lastName, profilePicture} = item.user;
                                return (
                                  <UserAvatar
                                    className="me-1 mb-1"
                                    size="sm"
                                    key={id}
                                    image={profilePicture?.path}
                                    text={getAvatarInitials(firstName, lastName)}
                                    theme="primary"
                                  />
                                );
                              })}
                            </div>
                          </div>
                          <div className="nk-tb-col nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1">
                              <li className="">
                                <a
                                  href="#1"
                                  onClick={() => {
                                    navigate(`/app/reporting/${id}`);
                                  }}
                                  className="btn btn-trigger btn-icon"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  aria-label="Edit"
                                  data-bs-original-title="Edit"
                                >
                                  <em className="icon ni ni-edit"></em>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

export default ReportingTable;
