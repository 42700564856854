import {IChannels, IObjectiveScoring} from '../../../app/administrator/objective-scoring/interfaces/IObjectiveScoring';
import {ITargetGroups, ITargetGroupsPagination} from '../../../app/administrator/target-groups/interfaces/ITargetGroups';
import BaseApi from '../../base.api';

const TARGET_GROUPS_ROUTE = 'api/admin/target-groups';
const TARGET_GROUPS_PRICE_ROUTE = 'api/admin/price/price/all';

export class TargetGroupsApi extends BaseApi {
  public async getAllTargetGroups(pagination: ITargetGroupsPagination): Promise<any> {
    return await this.get(TARGET_GROUPS_ROUTE, undefined, pagination);
  }

  public async getTargetGroups(id: number): Promise<{data: ITargetGroups}> {
    return await this.get(`${TARGET_GROUPS_ROUTE}/${id}`);
  }

  public async createTargetGroups(data: any): Promise<any> {
    return await this.post(TARGET_GROUPS_ROUTE, data);
  }

  public async exportGroup(country: string): Promise<any> {
    return await this.get('/api/admin/price/download', '', {country}, true);
  }

  public async importGroup(data: any): Promise<any> {
    return await this.put('/api/admin/price/file/upload', data, '', true);
  }

  public async deleteTargetGroups(id: number): Promise<any> {
    return await this.delete(`${TARGET_GROUPS_ROUTE}/${id}`, '');
  }

  public async updateTargetGroups(id: number, data: ITargetGroups): Promise<{data: ITargetGroups}> {
    return await this.put(`${TARGET_GROUPS_ROUTE}/${id}`, data, '');
  }

  public async getAllTargetGroupPrices(country: string): Promise<{data: IObjectiveScoring}> {
    return await this.get(TARGET_GROUPS_PRICE_ROUTE, '', {country});
  }

  public async updateTargetGroupScoring(data: IChannels[], country: string): Promise<any> {
    return await this.put(
      `${TARGET_GROUPS_PRICE_ROUTE}?country=${country}`,
      {
        channels: data,
      },
      ''
    );
  }
}
