import {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {Card, CardBody} from 'reactstrap';
import {lineItemApi} from '../../../../api';
import {Block} from '../../../../components/block/Block';
import ChangeStep from './ChangeStep';
import {IStepperProps} from './index';

import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';

import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {IChartData} from '../../../../api/app/campaigns/line-item.api';
import {RootState} from '../../../../store';

const colors = [
  'hsl(0, 100%, 50%)',
  'hsl(30, 100%, 50%)',
  'hsl(60, 100%, 50%)',
  'hsl(90, 100%, 50%)',
  'hsl(120, 100%, 50%)',
  'hsl(150, 100%, 50%)',
  'hsl(180, 100%, 50%)',
  'hsl(210, 100%, 50%)',
  'hsl(240, 100%, 50%)',
  'hsl(270, 100%, 50%)',
  'hsl(300, 100%, 50%)',
  'hsl(330, 100%, 50%)',
  'hsl(15, 100%, 50%)',
  'hsl(45, 100%, 50%)',
  'hsl(75, 100%, 50%)',
  'hsl(105, 100%, 50%)',
  'hsl(135, 100%, 50%)',
  'hsl(165, 100%, 50%)',
  'hsl(195, 100%, 50%)',
  'hsl(225, 100%, 50%)',
  'hsl(255, 100%, 50%)',
  'hsl(285, 100%, 50%)',
  'hsl(315, 100%, 50%)',
  'hsl(345, 100%, 50%)',
  'hsl(0, 100%, 25%)',
  'hsl(60, 100%, 25%)',
  'hsl(120, 100%, 25%)',
  'hsl(180, 100%, 25%)',
  'hsl(240, 100%, 25%)',
  'hsl(300, 100%, 25%)',
  'hsl(30, 100%, 75%)',
  'hsl(90, 100%, 75%)',
  'hsl(150, 100%, 75%)',
  'hsl(210, 100%, 75%)',
  'hsl(270, 100%, 75%)',
  'hsl(330, 100%, 75%)',
  'hsl(15, 100%, 75%)',
  'hsl(105, 100%, 75%)',
  'hsl(195, 100%, 75%)',
  'hsl(285, 100%, 75%)',
  'hsl(345, 100%, 75%)',
  'hsl(0, 100%, 10%)',
  'hsl(120, 100%, 10%)',
  'hsl(240, 100%, 10%)',
  'hsl(60, 100%, 90%)',
  'hsl(180, 100%, 90%)',
  'hsl(300, 100%, 90%)',
  'hsl(10, 80%, 50%)',
  'hsl(100, 80%, 50%)',
  'hsl(190, 80%, 50%)',
  'hsl(280, 80%, 50%)',
  'hsl(20, 50%, 50%)',
  'hsl(110, 50%, 50%)',
  'hsl(200, 50%, 50%)',
  'hsl(290, 50%, 50%)',
  'hsl(40, 100%, 50%)',
  'hsl(130, 100%, 50%)',
  'hsl(220, 100%, 50%)',
  'hsl(310, 100%, 50%)',
  'hsl(80, 100%, 50%)',
  'hsl(170, 100%, 50%)',
  'hsl(260, 100%, 50%)',
  'hsl(350, 100%, 50%)',
  'hsl(45, 80%, 50%)',
  'hsl(135, 80%, 50%)',
  'hsl(225, 80%, 50%)',
  'hsl(315, 80%, 50%)',
  'hsl(70, 50%, 50%)',
  'hsl(160, 50%, 50%)',
  'hsl(250, 50%, 50%)',
  'hsl(340, 50%, 50%)',
  'hsl(90, 80%, 50%)',
  'hsl(180, 80%, 50%)',
  'hsl(270, 80%, 50%)',
  'hsl(0, 80%, 30%)',
  'hsl(60, 80%, 30%)',
  'hsl(120, 80%, 30%)',
  'hsl(180, 80%, 30%)',
  'hsl(240, 80%, 30%)',
  'hsl(300, 80%, 30%)',
  'hsl(30, 80%, 70%)',
  'hsl(90, 80%, 70%)',
  'hsl(150, 80%, 70%)',
  'hsl(210, 80%, 70%)',
  'hsl(270, 80%, 70%)',
  'hsl(330, 80%, 70%)',
];

const CampaignStep8 = ({campaignData}: IStepperProps) => {
  const {id} = useParams();
  const {priceResult} = useSelector((state: RootState) => state.enum);
  const [chartParams, setChartParams] = useState<{priceResult: string; view: string}>({priceResult: '', view: ''});
  const [chartData, setChartData] = useState<IChartData[]>();
  const [currentView, setCurrentView] = useState('MONTH');
  const randomizeColor = function (index: number) {
    return colors[index];
  };

  useEffect(() => {
    lineItemApi.getLineitemChart(id!, {...chartParams, view: currentView}).then((res) => {
      setChartData(res.data);
    });
  }, [chartParams, currentView]);
  return (
    <Block size="lg" className="mt-3">
      <ChangeStep
        currentCampaignStep={campaignData?.currentStep}
        status={campaignData?.status}
        campaignId={campaignData?.id}
        activeStep={4}
        name={campaignData?.name}
      />
      <Card className="card-bordered card-preview">
        <CardBody>
          {/* <div className="accordion">
            <div className="accordion-item">
              <div
                className={`accordion-head${accordionOpen ? ' collapsed' : ''} cursor-pointer`}
                onClick={() => setIsAccordionOpen(!accordionOpen)}
              >
                <h5 className="title">Chart</h5>
                <span className="accordion-icon"></span>
              </div> */}

          {/* <Collapse className="accordion-body" isOpen={accordionOpen ? true : false}> */}
          <div className="accordion-inner">
            <>
              <Tabs>
                <TabList className={'nav nav-tabs'}>
                  {priceResult.map((price, idx) => (
                    <Tab
                      key={idx}
                      className={'nav-link me-3 cursor-pointer'}
                      onClick={() => setChartParams((prev) => ({...prev, priceResult: price}))}
                    >
                      {price}
                    </Tab>
                  ))}
                </TabList>

                <ul className="d-flex gap-3 my-3">
                  <li onClick={() => setCurrentView('MONTH')}>
                    <h6 className={`cursor-pointer  ${currentView === 'MONTH' && 'text-primary'}`}>MONTH</h6>
                  </li>
                  <li onClick={() => setCurrentView('WEEK')}>
                    <h6 className={`cursor-pointer  ${currentView === 'WEEK' && 'text-primary'}`}>WEEK</h6>
                  </li>
                  <li onClick={() => setCurrentView('DAY')}>
                    <h6 className={`cursor-pointer ${currentView === 'DAY' && 'text-primary'}`}>DAY</h6>
                  </li>
                </ul>

                {priceResult.map((price, idx) => {
                  return (
                    <TabPanel key={price}>
                      {chartData && (
                        <Line
                          height={50}
                          data={{
                            labels: chartData?.[0] && chartData?.[0]?.range,

                            datasets: chartData?.map((item, idx) => {
                              const color = [`${randomizeColor(idx)}`];
                              return {
                                label: item.name,
                                data: item.data,
                                borderColor: color,
                                backgroundColor: color,
                              };
                            }),
                          }}
                        />
                      )}
                    </TabPanel>
                  );
                })}
              </Tabs>
              {/* <ul className="nav nav-tabs">
                          {priceResult.map((price, idx) => (
                            <li className="nav-item" onClick={() => setChartParams((prev) => ({...prev, priceResult: price}))}>
                              <a className="nav-link" data-bs-toggle="tab" href={`#tabItem${idx}`}>
                                {price}
                              </a>
                            </li>
                          ))}
                        </ul>
                        {priceResult.map((price, idx) => (
                          <div className="tab-content">
                            <div className="tab-pane" id="tabItem4">
                              <p>contnet</p>
                            </div>
                          </div>
                        ))} */}
            </>
          </div>
          {/* </Collapse> */}
          {/* </div>
          </div> */}
        </CardBody>
      </Card>
    </Block>
  );
};

export default CampaignStep8;
