import {IChannelUserDevice} from '../../../app/administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import {IChannelPrice} from '../../../app/administrator/channel-price/interfaces/IChannelPrice';
import {IChannel} from '../../../app/administrator/channels/interfaces/IChannel';
import {IPagination} from '../../../shared/IPagination';
import BaseApi from '../../base.api';

const CHANNEL_USER_ROUTE = '/api/admin/user-channel';

export class ChannelUserApi extends BaseApi {
  public async getChannelUsers(pagination: IPagination): Promise<{
    data: {
      count: number;
      perPage: number;
      data: [
        {
          id: number;
          channel: IChannel;
        }
      ];
    };
  }> {
    return await this.get(CHANNEL_USER_ROUTE, '', pagination);
  }

  public async getChannelUserId(userId: string): Promise<{
    data: [
      {
        channel: IChannel;
      }
    ];
  }> {
    return await this.get(`${CHANNEL_USER_ROUTE}/${userId}`, '');
  }

  public async getLoggedUserChannel(): Promise<{
    data: any[];
  }> {
    return await this.get(`${CHANNEL_USER_ROUTE}/loggedUser`, '');
  }

  public async updateChannelDescription(
    data: IChannelUserDevice[],
    channelId: string
  ): Promise<{
    data: IChannelUserDevice[];
  }> {
    return await this.put(
      `${CHANNEL_USER_ROUTE}/${channelId}`,
      {
        channelFormatDevice: data,
      },
      ''
    );
  }

  public async createChannelUser(
    userId: number,
    channels: number[]
  ): Promise<{
    data: {channels: IChannelPrice[]};
  }> {
    return await this.post(CHANNEL_USER_ROUTE, {
      channelUser: [
        {
          user: userId,
          channel: channels,
        },
      ],
    });
  }
}
