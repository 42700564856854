import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {productApi} from '../../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import {IProduct} from '../interfaces/IProduct';
import * as yup from 'yup';
import ProductForm from './ProductForm';
import {IBrand} from '../../brands/interfaces/IBrand';
import {useTranslation} from 'react-i18next';

export const productFormSchema = yup
  .object({
    name: yup.string().required('No name provided.').min(3, 'Name is too short - should be 3 chars minimum.'),
    brand: yup.number().typeError('No brand provided.').required(),
  })
  .required();
const ProductUpdate = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();
  const methods = useForm<IProduct>({
    resolver: yupResolver(productFormSchema),
  });

  const onFormSubmit = async (formData: IProduct) => {
    const {data} = await productApi.updateProduct(Number(id), formData);
    data && navigate('/app/management/products');
  };

  useEffect(() => {
    if (id) {
      const getProduct = async (productId: number) => {
        const {data} = await productApi.getProduct(+productId);
        methods?.reset({
          ...data,
          brand: (data.brand as unknown as IBrand)?.id,
        });
      };
      getProduct(+id);
    }
  }, [id, methods]);
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('management.products.productUpdate')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate('/app/management/products');
                }}
              >
                <Icon name="arrow-long-left" />
                {t('general.back')}
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <ProductForm register={methods?.register} errors={methods?.formState?.errors} />
              <Row>
                <Col>
                  <Row>
                    <Col className={'text-end'}>
                      <Button color="primary">
                        <Icon name="plus-sm" />
                        {t('general.update')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default ProductUpdate;
