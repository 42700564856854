import React, {SetStateAction, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Badge} from 'reactstrap';
import Icon from '../../../components/icon/Icon';
import {EmptyState} from '../../../components/shared/EmptyState';
import {IProduct} from '../../management/products/interfaces/IProduct';
import {IBrand} from '../../management/brands/interfaces/IBrand';
import {useTranslation} from 'react-i18next';
import {getCorrectCampaignStatus, getCorrectPaymentStatus} from '../../../shared/Functions';
import Button from '../../../components/button/Button';
import {ICampaign} from '../../campaigns/interfaces/ICampaigns';
import {useMultiInject} from '../../../di';
import ConfirmSuccessModal from '../../../components/modal/ConfirmSuccessModal';
import {campaignApi} from '../../../api';

interface ICampaignTableProps {
  campaignList: ICampaign[];
  setCampaignList: React.Dispatch<SetStateAction<ICampaign[]>>;
}

const CampaignOrderTable = ({campaignList, setCampaignList}: ICampaignTableProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {dateTime} = useMultiInject();
  const [selectedCampaign, setSelectedCampaign] = useState<ICampaign>();
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const copyCampaignHandler = async (selectedCampaignId: number) => {
    const {data} = await campaignApi.copyCampaign(selectedCampaignId);

    setCampaignList((prev) => [...prev, data]);
  };

  return (
    <div className="table-responsive">
      {!!campaignList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.id')}</th>
              <th scope="col">{t('general.campaign')}</th>
              <th scope="col">{t('general.company')}</th>
              <th scope="col">{t('general.brand')}</th>
              <th scope="col">{t('general.product')}</th>
              <th scope="col">{t('general.status')}</th>
              <th scope="col" className="text-center">
                {t('general.paymentStatus')}
              </th>
              <th scope="col">
                {t('general.startDate')} - {t('general.endDate')}
              </th>
              <th scope="col" className="text-end">
                {t('general.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {campaignList?.map((campaign) => {
              const {
                id,
                name,
                product,
                status,
                startDate,
                endDate,
                paymentStatus,
                company: {name: companyName},
              } = campaign;
              return (
                <>
                  <tr key={id}>
                    <th>{id}</th>
                    <td>{name}</td>
                    <td>{companyName ?? '-'}</td>
                    <td>{(product as unknown as IProduct)?.name ?? '-'}</td>
                    <td>{((product as unknown as ICampaign)?.brand as unknown as IBrand)?.name ?? '-'}</td>
                    <td>{getCorrectCampaignStatus(status) ?? '-'}</td>
                    <td className="text-center">
                      <Badge color={paymentStatus === null ? 'secondary' : paymentStatus === 'INVOICED' ? 'warning' : 'primary'}>
                        {getCorrectPaymentStatus(paymentStatus)}
                      </Badge>
                    </td>
                    <td>{`${dateTime.formatDate(startDate)} - ${dateTime.formatDate(endDate)}` ?? '-'}</td>
                    <td className="text-end d-flex">
                      <a
                        href="#1"
                        className="btn btn-trigger btn-icon me-2"
                        onClick={() => {
                          setShowDuplicateModal(true);
                          setSelectedCampaign(campaign);
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        aria-label="Quick View"
                        data-bs-original-title="Quick View"
                      >
                        <em className="icon ni ni-copy"></em>
                      </a>
                      <Button
                        color="warning"
                        size="sm"
                        onClick={() => {
                          navigate(`/app/orders/detail/${id}`);
                        }}
                      >
                        <Icon name="eye-fill" className="me-1" />
                        {t('general.detail')}
                      </Button>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}
      <ConfirmSuccessModal
        itemName={selectedCampaign?.name ?? '-'}
        actionName="duplicate"
        show={showDuplicateModal}
        setShow={() => setShowDuplicateModal(false)}
        onSave={() => copyCampaignHandler(selectedCampaign?.id ?? 0)}
      />
    </div>
  );
};

export default CampaignOrderTable;
