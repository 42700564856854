import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Controller, FieldErrorsImpl, FormState, UseFormRegister} from 'react-hook-form';
import {Col, FormGroup, Row} from 'reactstrap';
import {IFormat} from '../interfaces/IFormat';
import {adjustValues, ISelect2, themeSelect} from '../../../../shared/Select2';
import {IEvent} from '../../../../shared/interfaces/Event';
import Select, {MultiValue} from 'react-select';
import {usePagination} from '../../../../shared/hooks/usePagination';
import {useTranslation} from 'react-i18next';
import {typeApi} from '../../../../api';
import {IType} from '../../type/interfaces/IType';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store';
import FileInput from '../../../../shared/FileInput';
import {IPicture} from '../../../../shared/interfaces/Picture';

interface IFormatFormProps {
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
      description: string;
      CPM: string;
      CPV: string;
      CPC: string;
      CPE: string;
      awareness: string;
      branding: string;
      engagement: string;
      performance: string;
      type: number[];
    }>
  >;
  register: UseFormRegister<IFormat>;
  formState: FormState<IFormat>;
  isFormatCreate?: boolean;
  watch?: any;
  defaultState?: IFormat;
}

const FormatForm = ({errors, register, defaultState, isFormatCreate, formState, watch}: IFormatFormProps) => {
  const {t} = useTranslation();
  const {changeFilterHandler, pagination} = usePagination();
  const {objective, price} = useSelector((state: RootState) => state.enum);
  const [typeList, setTypeList] = useState<IType[]>([]);
  const defaultValues = formState.defaultValues as IFormat;
  const defaultType = useRef<MultiValue<{label: string | number; value: number}>>();
  const [selectedType, setSelectedType] = useState<MultiValue<{label: string | number; value: number}>>();
  defaultType.current = adjustValues(defaultState?.type as unknown as ISelect2[], 'name');

  useEffect(() => {
    async function getAllTypes() {
      try {
        const {data} = await typeApi.getAllTypes({
          ...pagination,
          perPage: 0,
        });
        setTypeList(data.data);
      } catch (err) {}
    }

    getAllTypes();
  }, [pagination]);

  useEffect(() => {
    if (!!defaultState?.type) {
      setSelectedType(defaultType?.current);
    }
  }, [defaultState?.type]);

  return (
    <Row>
      <Col md={4} className="d-flex align-items-center justify-content-center flex-column">
        {!isFormatCreate && (
          <>
            {!!defaultValues?.picture ? (
              <img className="h-max-375px mb-4" src={(defaultValues?.picture as IPicture)?.path} alt="Format logo" />
            ) : (
              <p>{t('general.formatNoLogo')}</p>
            )}
          </>
        )}
        <FileInput accept="image/*" isMultiple={false} name="picture" label="File Upload" />
      </Col>
      <Col md={8} className="">
        <Row>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="name">
                {t('general.name')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-type"
                  placeholder={t('administrator.formats.enterFormat') as string}
                  {...register('name')}
                  className="form-control-lg form-control"
                />
                {errors?.name && <p className="invalid">{errors?.name?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="name">
                {t('general.type')}
              </label>

              <div className="form-control-wrap">
                <Controller
                  {...register('type', {
                    valueAsNumber: true,
                  })}
                  render={({field: {onChange}}) => (
                    <Select
                      theme={themeSelect}
                      value={selectedType}
                      isMulti
                      className="form-react-select"
                      placeholder={t('administrator.formats.selectType')}
                      options={adjustValues(typeList as unknown as ISelect2[], 'name')}
                      onInputChange={(value) => {
                        const event: IEvent = {
                          target: {
                            name: 'name',
                            value: value,
                          },
                        };
                        changeFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
                      }}
                      onChange={(e) => {
                        setSelectedType(e);
                        onChange(e?.map((event) => event.value));
                      }}
                    />
                  )}
                  rules={{required: true}}
                />
                {errors?.type && <p className="invalid">{errors?.type?.message}</p>}
              </div>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <label className="form-label" htmlFor="description">
                {t('general.description')}
              </label>
              <div className="form-control-wrap">
                <textarea
                  placeholder={t('administrator.formats.enterDescription') as string}
                  {...register('description')}
                  className="form-control-lg form-control"
                />
                {errors?.description && <p className="invalid">{errors?.description?.message}</p>}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Col>
      {price?.map((item: 'CPM' | 'CPV' | 'CPC' | 'CPE') => (
        <Col md={3} key={item}>
          <FormGroup>
            <label className="form-label" htmlFor="costs">
              {watch('linearMedia') && item === 'CPM'
                ? t('administrator.channelFormats.fixedPrice')
                : t(`administrator.channelFormats.${item.toLowerCase()}`)}
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                onInput={(e: any) => {
                  e.preventDefault();
                  e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                }}
                id="reg-type"
                step="any"
                placeholder={`${t('administrator.channelFormats.enter')} ${item}`}
                {...register(item as unknown as 'CPM' | 'CPV' | 'CPC' | 'CPE', {
                  valueAsNumber: true,
                })}
                className="form-control-lg form-control"
                disabled={watch('linearMedia') && item !== 'CPM'}
              />
              {errors[item] && <p className="invalid">{errors?.[item]?.message}</p>}
            </div>
          </FormGroup>
        </Col>
      ))}
      {objective?.map((item: 'awareness' | 'branding' | 'engagement' | 'performance') => {
        return (
          <Col md={3} key={item}>
            <FormGroup>
              <label className="form-label" htmlFor="channelFormat">
                {t(`administrator.channelFormats.${item.toLowerCase()}`)}
              </label>
              <div className="form-control-wrap">
                <input
                  type="number"
                  onInput={(e: any) => {
                    e.preventDefault();
                    e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                  }}
                  id="reg-type"
                  step="any"
                  placeholder={`${t('administrator.channelFormats.enter')} ${item}`}
                  {...register(item as unknown as 'awareness' | 'branding' | 'engagement' | 'performance', {
                    valueAsNumber: true,
                  })}
                  className="form-control-lg form-control"
                />
                {errors[item] && <p className="invalid">{errors?.[item]?.message}</p>}
              </div>
            </FormGroup>
          </Col>
        );
      })}
      <Col md={3}>
        <div className="custom-control custom-switch mt-2" style={{zIndex: -0}}>
          <input type="checkbox" className="custom-control-input" id="status" {...register('status')} defaultChecked />
          <label className="custom-control-label form-label" htmlFor="status">
            Status
          </label>
        </div>
      </Col>
      <Col md={3}>
        <div className="custom-control custom-switch mt-2" style={{zIndex: -0}}>
          <input type="checkbox" className="custom-control-input" id="linearMedia" {...register('linearMedia')} />
          <label className="custom-control-label form-label" htmlFor="linearMedia">
            Linear media
          </label>
        </div>
      </Col>
    </Row>
  );
};

export default FormatForm;
