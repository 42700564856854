import {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';

interface IDropDown {
  id: number;
  submitPictures: (arg: any, arg2: any) => void;
}

const Step5Dropdown = ({submitPictures, id}: IDropDown) => {
  const {t} = useTranslation();
  const onDrop = useCallback((acceptedFiles: any) => {
    submitPictures(acceptedFiles, id);
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: {},
    onDrop,
  });

  return (
    <div
      {...getRootProps({
        // onClick: (event: any) => {
        //   currId.current = item.lineItem_id;
        // },
        role: 'button',
        'aria-label': 'drag and drop area',
      })}
      role="button"
      className="dropzone"
      id="file-input"
    >
      <input {...getInputProps()} />
      {isDragActive ? <p>{t('general.dropFiles')}</p> : <p>{t('general.dragFiles')}</p>}
    </div>
  );
};

export default Step5Dropdown;
