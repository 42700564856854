import React, {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {Card} from 'reactstrap';
import {campaignApi, companyApi} from '../../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import {Pagination} from '../../../../components/pagination/Pagination';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {getCorrectPaymentStatus, isUserAdmin} from '../../../../shared/Functions';
import {usePagination} from '../../../../shared/hooks/usePagination';
import {IEvent} from '../../../../shared/interfaces/Event';
import {adjustValues, ISelect2, themeSelect} from '../../../../shared/Select2';
import {RootState} from '../../../../store';
import {ICompany} from '../../../administrator/company/interfaces/ICompany';
import {ICampaign} from '../../../campaigns/interfaces/ICampaigns';
import CampaignOrderTable from '../../../requests/components/CampaignOrderTable';
import {useNavigate} from 'react-router-dom';

const OrderLists = ({status}: {status: string}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {paymentStatus} = useSelector((state: RootState) => state.enum);
  const {roles} = useSelector((state: RootState) => state.auth.user);
  const [campaignList, setCampaignList] = useState<ICampaign[]>([]);
  const {page, setTotalPages, totalPages, handlePages, pagination, changeFilterHandler} = usePagination();
  const {pagination: companyPagination, changeFilterHandler: changeCompanyFilterHandler} = usePagination();
  const [companyList, setCompanyList] = useState<ICompany[]>([]);

  useEffect(() => {
    const getCampaigns = async () => {
      const {data} = await campaignApi.getAllCampaigns({...pagination, status}, true);
      setCampaignList(data.data);
      setTotalPages(Math.ceil(data?.count / data?.perPage));
    };

    getCampaigns();
  }, [pagination, setTotalPages, status]);
  useEffect(() => {
    const getAllCompanies = async () => {
      const {
        data: {data},
      } = await companyApi.getAllCompanies({
        ...companyPagination,
      });
      setCompanyList(data);
    };
    isUserAdmin(roles) && getAllCompanies();
  }, [companyPagination, roles]);
  return (
    <>
      <Block size="lg" className="mt-3">
        <Tabs>
          <BlockHead className="d-flex align-items-center justify-content-between">
            <BlockHeadContent>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <BlockTitle tag="h4">{t(`general.${status ? status : 'all'}`)}</BlockTitle>
                </div>
              </div>
            </BlockHeadContent>
            <TabList className={'nav nav-tabs'} style={{borderBottom: 0}}>
              <Tab
                className={'nav-link me-3 cursor-pointer'}
                onClick={() => {
                  navigate('/app/orders');
                }}
              >
                All
              </Tab>
              <Tab
                className={'nav-link me-3 cursor-pointer'}
                onClick={() => {
                  navigate('/app/orders/draft');
                }}
              >
                Draft
              </Tab>
              <Tab
                className={'nav-link me-3 cursor-pointer'}
                onClick={() => {
                  navigate('/app/orders/pending');
                }}
              >
                Pending
              </Tab>
              <Tab
                className={'nav-link me-3 cursor-pointer'}
                onClick={() => {
                  navigate('/app/orders/in-progress');
                }}
              >
                In progress
              </Tab>
              <Tab
                className={'nav-link me-3 cursor-pointer'}
                onClick={() => {
                  navigate('/app/orders/on-air');
                }}
              >
                On air
              </Tab>

              <Tab
                className={'nav-link me-3 cursor-pointer'}
                onClick={() => {
                  navigate('/app/orders/finished');
                }}
              >
                Finished
              </Tab>
              <Tab
                className={'nav-link me-3 cursor-pointer'}
                onClick={() => {
                  navigate('/app/orders/rejected');
                }}
              >
                Rejected
              </Tab>
            </TabList>
            <div className="form-control-wrap w-40 mt-2 mt-lg-0 d-flex align-items-center justify-content-end">
              <select name="paymentStatus" className="form-select w-30 ms-2" onChange={changeFilterHandler}>
                <option value="">All</option>
                {paymentStatus?.map((status) => (
                  <option value={status} key={status}>
                    {getCorrectPaymentStatus(status)}
                  </option>
                ))}
              </select>
              {isUserAdmin(roles) && (
                <div className="form-control-wrap w-50 ms-2">
                  <Select
                    theme={themeSelect}
                    placeholder={t('general.searchByCompany')}
                    options={adjustValues(companyList as unknown as ISelect2[], 'name')}
                    isClearable
                    className="small-select2"
                    onInputChange={(value) => {
                      const event: IEvent = {
                        target: {
                          name: 'name',
                          value: value,
                        },
                      };
                      changeCompanyFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
                    }}
                    onChange={(event) => {
                      const combinedEvent: any = {
                        target: {
                          name: 'company',
                          value: event?.value,
                        },
                      };
                      changeFilterHandler(combinedEvent);
                    }}
                  />
                </div>
              )}
            </div>
          </BlockHead>

          <TabPanel key={status}>
            <Card className="card-bordered card-preview">
              <CampaignOrderTable campaignList={campaignList} setCampaignList={setCampaignList} />
            </Card>
          </TabPanel>
        </Tabs>
      </Block>
      <div className="d-flex justify-content-end">
        {totalPages <= 1 ? '' : <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />}
      </div>
    </>
  );
};

export default OrderLists;
