import React, {useState} from 'react';
import * as yup from 'yup';
import {Block, BlockBetween, BlockDes, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import {Button, Col, Form} from 'reactstrap';
import Icon from '../../../components/icon/Icon';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {IChangePassword} from '../interfaces/IChangePassword';
import ProfileChangePasswordForm from './ProfileChangePasswordForm';
import {authApi} from '../../../api';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {useTranslation} from 'react-i18next';

export const changePasswordSchema = yup
  .object({
    oldPassword: yup
      .string()
      .required('No password provided.')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    password: yup
      .string()
      .required('No password provided.')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    newPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], "Passwords don't match!")
      .required('No password provided.')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  })
  .required();

const ProfileChangePassword = () => {
  const {t} = useTranslation();
  const {token} = useSelector((state: RootState) => state.auth);
  const [passState, setPassState] = useState({
    oldPassword: false,
    password: false,
  });
  const methods = useForm<IChangePassword>({
    resolver: yupResolver(changePasswordSchema),
  });

  const submitForm = async (formData: IChangePassword) => {
    await authApi.changePassword(formData, token);
  };
  return (
    <>
      <Block size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">{t('myProfile.password.changePassword')}</BlockTitle>
            <BlockDes>
              <p>{t('myProfile.password.setPassword')}</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button className={'toggle btn btn-icon btn-trigger mt-n1 active d-none d-lg-block'}>
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </Block>

      <Block>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(submitForm)}>
            <ProfileChangePasswordForm
              passState={passState}
              setPassState={setPassState}
              register={methods.register}
              errors={methods.formState.errors}
            />
            <Col size="12">
              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                <li>
                  <Button color="primary" size="lg" type="submit">
                    {t('myProfile.password.changePassword')}
                  </Button>
                </li>
              </ul>
            </Col>
          </Form>
        </FormProvider>
      </Block>
    </>
  );
};

export default ProfileChangePassword;
