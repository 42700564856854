import BaseApi from '../../base.api';

const CREATIVE_ROUTE = '/api/admin/creative';
const PRODUCTION_PLAN_ROUTE = '/api/admin/production-plan';

export class CreativeApi extends BaseApi {
  public async getAllCreatives(id: number): Promise<any> {
    return await this.get(`${CREATIVE_ROUTE}/${id}`);
  }
  public async getAllProductionPlans(id: number): Promise<any> {
    return await this.get(`${PRODUCTION_PLAN_ROUTE}/${id}`);
  }

  public async createCreativeImages(data: any, id: number): Promise<any> {
    return await this.post(`${CREATIVE_ROUTE}/lineItem/${id}`, data, '', true);
  }
  public async createAdditionalUrls(data: any, id: number): Promise<any> {
    return await this.post(`${CREATIVE_ROUTE}/additionalUrl/lineItem/${id}`, data, '');
  }
  public async createProductionPlan(data: any, id: number): Promise<any> {
    return await this.post(`${PRODUCTION_PLAN_ROUTE}`, data, '', true);
  }

  public async updateLink(id: number, data: {url: string}): Promise<{data: any}> {
    return await this.put(`${CREATIVE_ROUTE}/url/lineItem/${id}`, data, '');
  }

  public async deleteCreativeImage(lineItemId: number, pictureId: number): Promise<any> {
    return await this.delete(`${CREATIVE_ROUTE}/${lineItemId}/${pictureId}`, '');
  }
  public async deleteAdditionalUrl(lineItemId: number, additionalUrlId: number): Promise<any> {
    return await this.delete(`${CREATIVE_ROUTE}/additionalUrl/${lineItemId}/${additionalUrlId}`, '');
  }

  public async deleteProductionImage(campaignId: number, pictureId: number): Promise<any> {
    return await this.delete(`${PRODUCTION_PLAN_ROUTE}/${campaignId}/${pictureId}`, '');
  }
}
