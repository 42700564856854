import React from 'react';
import {IDevicePrice} from '../interfaces/IDevicePrice';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store';

interface IDevicePriceTableProps {
  devicePriceList: IDevicePrice[];
  changePriceHandler: (event: React.ChangeEvent<HTMLInputElement>, deviceId: number) => void;
}

const DevicePriceTable = ({devicePriceList, changePriceHandler}: IDevicePriceTableProps) => {
  const {t} = useTranslation();
  const {price} = useSelector((state: RootState) => state.enum);
  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">{t('general.device')}</th>
            {price?.map((item: string) => (
              <th scope="col" className="text-center">
                {t(`administrator.channelFormats.${item?.toLowerCase()}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {devicePriceList?.map((devicePrice, index) => {
            const {prices, device, name} = devicePrice;
            return (
              <tr key={index}>
                <td>{name}</td>
                {price?.map((singlePrice) => {
                  return (
                    <td key={singlePrice}>
                      <input
                        type="number"
                        onInput={(e: any) => {
                          e.preventDefault();
                          e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                        }}
                        onChange={(event) => changePriceHandler(event, device)}
                        name={singlePrice}
                        value={prices?.[singlePrice] ?? 0}
                        className="form-control"
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DevicePriceTable;
