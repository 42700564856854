import React, {useEffect, useState} from 'react';
import {Controller, FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Col, FormGroup, Row} from 'reactstrap';
import {IRegion} from '../interfaces/IRegion';
import {RootState} from '../../../../store';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {themeSelect} from '../../../../shared/Select2';
interface IRegionFormProps {
  errors: Partial<
    FieldErrorsImpl<{
      name: string;
      country: any[];
    }>
  >;
  register: UseFormRegister<IRegion>;
  defaultState: IRegion;
}

const RegionForm = ({defaultState, errors, register}: IRegionFormProps) => {
  const {country} = useSelector((state: RootState) => state.enum);
  const [selectedCountries, setSelectedCountries] = useState<{label: string; value: string | number}[]>();
  const {t} = useTranslation();
  useEffect(() => {
    if (!!defaultState?.country) {
      setSelectedCountries(defaultState.country.map((item) => ({label: t(`general.countryList.${item}`) as string, value: item})));
    }
  }, [defaultState?.country]);
  return (
    <Row>
      <Col md={8}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <label className="form-label" htmlFor="name">
                {t('general.name')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="reg-type"
                  placeholder={t('administrator.regions.enterRegion') as string}
                  {...register('name')}
                  className="form-control-lg form-control"
                />
                {errors?.name && <p className="invalid">{errors?.name?.message}</p>}
              </div>
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <label className="form-label" htmlFor="quality">
                {t('general.country')}
              </label>

              <div className="form-control-wrap">
                <Controller
                  {...register('country', {
                    valueAsNumber: true,
                  })}
                  render={({field: {onChange}}) => (
                    <Select
                      theme={themeSelect}
                      value={selectedCountries}
                      className="form-react-select"
                      placeholder={t('administrator.channels.country')}
                      options={country.map((item) => ({label: t(`general.countryList.${item}`) as string, value: item as any}))}
                      onChange={(e) => {
                        setSelectedCountries(e as any);
                        onChange([e?.value]);
                      }}
                    />
                  )}
                />
                {errors?.country && <p className="invalid">{errors?.country?.message}</p>}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RegionForm;
