import React from 'react';
import {IQualityObjective} from '../interfaces/IQualityObjective';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store';

interface IQualityObjectiveTableProps {
  qualityObjectiveList: IQualityObjective[];
  changeObjectiveHandler: (event: React.ChangeEvent<HTMLInputElement>, qualityId: number) => void;
}

const QualityObjectiveTable = ({qualityObjectiveList, changeObjectiveHandler}: IQualityObjectiveTableProps) => {
  const {t} = useTranslation();
  const {objective} = useSelector((state: RootState) => state.enum);
  return (
    <div
      className="table-responsive
    "
    >
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">{t('general.quality')}</th>
            {objective?.map((item: string) => (
              <th scope="col" className="text-center">
                {t(`administrator.channelFormats.${item?.toLowerCase()}`)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {qualityObjectiveList?.map((qualityObjective, index) => {
            const {objectives, quality, name} = qualityObjective;
            return (
              <tr key={index}>
                <td>{name}</td>

                {objective?.map((singleObjective) => {
                  return (
                    <td key={singleObjective}>
                      <input
                        type="number"
                        onInput={(e: any) => {
                          e.preventDefault();
                          e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                        }}
                        onChange={(event) => changeObjectiveHandler(event, quality)}
                        name={singleObjective}
                        value={objectives?.[singleObjective] ?? 0}
                        className="form-control"
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default QualityObjectiveTable;
