import React, {SetStateAction, useState} from 'react';
import {ICompany} from '../interfaces/ICompany';
import {useNavigate} from 'react-router-dom';
import {companyApi} from '../../../../api';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import ConfirmDeleteModal from '../../../../components/modal/ConfirmDeleteModal';
import {useSelector} from 'react-redux';
import {EmptyState} from '../../../../components/shared/EmptyState';
import {RootState} from '../../../../store';
import {useTranslation} from 'react-i18next';

interface ICompanyTableProps {
  companyList: ICompany[];
  setCompanyList: React.Dispatch<SetStateAction<ICompany[]>>;
}

const CompanyTable = ({companyList, setCompanyList}: ICompanyTableProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    company: {id: currentCompanyId},
  } = useSelector((state: RootState) => state.auth.user);
  const [selectedCompany, setSelectedCompany] = useState<ICompany>();
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const deleteUserHandler = async () => {
    if (selectedCompany?.id) {
      const {
        data: {data},
      } = await companyApi.deleteCompany(selectedCompany?.id);
      setCompanyList(data);
    }
  };
  return (
    <div className="table-responsive">
      {!!companyList?.length ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">{t('general.id')}</th>
              <th scope="col">{t('general.name')}</th>
              <th scope="col">{t('general.email')}</th>
              <th scope="col" className="text-end">
                {t('general.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {companyList?.map((company) => {
              const {id, name, email} = company;
              return (
                <tr key={id}>
                  <th>{id}</th>
                  <td>{name}</td>
                  <td>{email}</td>
                  <td className="d-flex justify-content-end">
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() => {
                        navigate(`/app/admin/company/update/${id}`);
                      }}
                    >
                      <Icon name="edit" />
                      {t('general.edit')}
                    </Button>

                    {(currentCompanyId ?? 0) !== id && !company.isUsed && (
                      <Button
                        color="danger"
                        size="sm"
                        className="ms-1"
                        onClick={() => {
                          setIsDeleteModalOpened(true);
                          setSelectedCompany(company);
                        }}
                      >
                        <Icon name="trash" />
                        {t('general.delete')}
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyState />
      )}

      <ConfirmDeleteModal
        show={isDeleteModalOpened}
        setShow={setIsDeleteModalOpened}
        itemId={selectedCompany?.id as number}
        itemName={`${selectedCompany?.name} ${selectedCompany?.email}`}
        deleteHandler={deleteUserHandler}
      />
    </div>
  );
};

export default CompanyTable;
