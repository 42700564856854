import React, {useEffect, useState, useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Header from './header';
import {useSelector} from 'react-redux';
import {RootState} from '../store';

const Layout = ({children}: any) => {
  //Sidebar
  const [visibility, setVisibility] = useState(false);
  const {pathname} = useLocation();
  const [mobileView, setMobileView] = useState(false);
  const [themeState] = useState({
    main: 'default',
    header: 'light',
    skin: 'light',
  });
  const {token} = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    viewChange();
  }, []);

  // Stops scrolling on overlay
  useLayoutEffect(() => {
    if (visibility) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    }
    if (!visibility) {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  }, [visibility]);

  //Adds classes to body
  useEffect(() => {
    document.body.className = `nk-body bg-lighter npc-invest has-touch nk-nio-theme ${themeState.skin === 'dark' ? 'dark-mode' : ''}`;
  }, [themeState, visibility]);

  // function to toggle sidebar
  const toggleSidebar = (e: any) => {
    e.preventDefault();
    if (!visibility) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 992) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setVisibility(false);
    }
  };
  window.addEventListener('load', viewChange);
  window.addEventListener('resize', viewChange);
  const isContainerFluid =
    pathname.includes('/app/campaigns/3') ||
    pathname.includes('/app/admin/objective-scoring/update') ||
    pathname.includes('app/admin/target-group-pricing/update');
  return (
    <React.Fragment>
      <div className="nk-app-root">
        <div className="nk-wrap">
          {token ? (
            <>
              <Header
                visibility={visibility}
                toggleSidebar={toggleSidebar}
                mobileView={mobileView}
                theme={themeState.header}
                sticky={true}
              />
              <div className={`container${isContainerFluid ? '-fluid' : ''}`}>{children}</div>
            </>
          ) : (
            <>{children}</>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Layout;
