import React from 'react';
import {Link} from 'react-router-dom';

const Logo = () => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
      <img className="logo-light logo-img" src={`/images/${process.env.REACT_APP_PROJECT}/logo.png`} alt="logo" />
      <img className="logo-dark logo-img" src={`/images/${process.env.REACT_APP_PROJECT}/logo-rgb.png`} alt="logo" />
    </Link>
  );
};

export default Logo;
