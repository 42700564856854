import classNames from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import {isUserChannelUser} from '../../shared/Functions';
import {RootState} from '../../store';
import Menu from '../menu/Menu';
import MobileMenu from '../menu/MobileMenu';
import Toggle from '../sidebar/Sidebar';
import HeaderLanguages from './components/HeaderLanguages';
import Logo from './components/HeaderLogo';
import Notification from './components/HeaderNotification';
import User from './components/HeaderUser';

const Header = ({sticky, theme, visibility, toggleSidebar, mobileView, className, ...props}: any) => {
  const headerClass = classNames({
    'nk-header is-light': true,
    'nk-header-sticky': sticky,
    [`is-${theme}`]: theme !== 'white' && theme !== 'light',
    [`${className}`]: className,
  });
  const {roles} = useSelector((state: RootState) => state?.auth?.user);

  const isChannelUser = isUserChannelUser(roles);

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger mr-sm-2 d-lg-none">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={toggleSidebar} />
          </div>
          <div>
            <div className="nk-header-brand flex-column pt-0 pb-0">
              <Logo />
            </div>
            {process.env.REACT_APP_TESTING_MODE === 'true' && <h6 className="text-center">TEST</h6>}
          </div>
          <div className={`nk-header-menu ${mobileView ? 'mobile-menu' : ''}  ${visibility ? 'nk-header-active' : ''}`}>
            <div className="nk-header-mobile">
              <div className="nk-header-brand">
                <Logo />
              </div>
              <div className="nk-menu-trigger mr-n2">
                <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="arrow-left" click={toggleSidebar} />
              </div>
            </div>
            {mobileView ? <MobileMenu /> : <Menu />}
            {/*{window.location.pathname.split('/')[2] === 'invest' && mobileView ? (*/}
            {/*  <MobileMenu />*/}
            {/*) : window.location.pathname.split('/')[2] === 'invest' ? (*/}
            {/*  <InvestmentMenu />*/}
            {/*) : mobileView ? (*/}
            {/*  <MobileMenu />*/}
            {/*) : (*/}
            {/*  <Menu />*/}
            {/*)}*/}
          </div>
          {visibility && <div className="nk-header-overlay" onClick={toggleSidebar}></div>}
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {!isChannelUser && (
                <>
                  <li className="d-none d-md-flex">
                    <Link to="/app/campaigns/create" className="btn btn-white btn-dim btn-outline-primary">
                      <i className="ni ni-plus me-1"></i> Create Campaign
                    </Link>
                  </li>

                  <li className="notification-dropdown mr-n1">
                    <Notification />
                  </li>
                </>
              )}
              <li className="user-dropdown">
                <User />
              </li>
              <li>
                <HeaderLanguages />
              </li>
              {window.location.pathname.split('/')[2] === 'invest' && (
                <li className="hide-mb-sm">
                  <a href={`${process.env.PUBLIC_URL}/auth-login`} className="nk-quick-nav-icon">
                    <Icon name="signout" />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
