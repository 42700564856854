import _debounce from 'lodash/debounce';
import React, {SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'reactstrap';
import {ICreateSection} from '../../../../../api/app/campaigns/line-item.api';
import CustomModal from '../../../../../components/modal/Modal';

interface IProps {
  show: boolean;
  handleClose: () => void;
  state: {color: string; title: string};
  setCreateSectionState: React.Dispatch<SetStateAction<ICreateSection>>;
  onSave: () => void;
}

const CreateSectionModal: React.FC<IProps> = ({show, state, handleClose, onSave, setCreateSectionState}) => {
  const {t} = useTranslation();
  const changeHandler = _debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setCreateSectionState((prev) => ({...prev, [name]: value}));
  }, 300);

  // useEffect(() => {}, [state?.id]);
  return (
    <CustomModal
      size="lg"
      isModalOpened={show}
      setIsModalOpened={handleClose}
      title={t('general.createSection')}
      saveHandler={onSave}
      body={
        <Row>
          <Col md={6}>
            <input
              type="text"
              onChange={changeHandler}
              name="title"
              defaultValue={state?.title}
              className="form-control"
              placeholder={t('general.name') as string}
            />
          </Col>
          <Col md={6}>
            <input
              type="color"
              onChange={changeHandler}
              name="color"
              defaultValue={state?.color}
              className="form-control"
              placeholder={t('general.color') as string}
            />
          </Col>
        </Row>
      }
    />
  );
};

export default CreateSectionModal;
