import {IAiSettings, IAiSettingsPagination} from '../../../app/administrator/ai-settings/interfaces/IAiSettings';
import BaseApi from '../../base.api';

const AISETTINGS_ROUTE = '/api/admin/ai-settings';

export class AiSettingsApi extends BaseApi {
  public async getAllSettings(pagination: IAiSettingsPagination): Promise<any> {
    return await this.get(AISETTINGS_ROUTE, undefined, pagination);
  }

  public async getSettings(id: number): Promise<{data: IAiSettings}> {
    return await this.get(`${AISETTINGS_ROUTE}/${id}`);
  }

  public async createSettings(data: any): Promise<any> {
    return await this.post(AISETTINGS_ROUTE, data);
  }

  public async deleteSettings(id: number): Promise<any> {
    return await this.delete(`${AISETTINGS_ROUTE}/${id}`, '');
  }

  public async updateSettings(id: number, data: IAiSettings): Promise<{data: IAiSettings}> {
    return await this.put(`${AISETTINGS_ROUTE}/${id}`, data, '');
  }
}
