import React, {useState, useEffect} from 'react';
import {DropdownToggle, DropdownMenu, UncontrolledDropdown} from 'reactstrap';
import Icon from '../../../components/icon/Icon';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {notificationApi} from '../../../api';
import {INotification} from '../../../app/notifications/interfaces/INotification';
import {getAvatarInitials} from '../../../shared/Functions';

interface INotificationProps {
  notification_key: string;
  campaign_id: number;
  campaignName: string;
}

const NotificationItem = (props: INotificationProps) => {
  const {campaign_id, campaignName} = props;
  const navigate = useNavigate();
  const {t} = useTranslation();
  return (
    <div
      className="nk-notification-item dropdown-inner"
      key={campaign_id}
      onClick={() => {
        navigate(`/app/campaigns/1/${campaign_id}`);
      }}
    >
      <div className="nk-notification-icon">
        <div className="icon-circle">{getAvatarInitials(campaignName, campaignName?.charAt(1) ?? '')}</div>
      </div>
      <div className="nk-notification-content">
        <div className="nk-notification-text">
          {t('general.campaign')}
          <span className="fw-bold"> {campaignName} </span>
          {t('general.hasBeenCreated')}
        </div>
      </div>
    </div>
  );
};

const Notification = () => {
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationList] = useState<INotification[]>([]);
  const [isClosed, setIsClosed] = useState(true);
  const {t} = useTranslation();

  const handleClick = () => {
    setIsClosed(!isClosed);
    setNotificationCount(0);
  };

  useEffect(() => {
    notificationApi.getNotificationCount({page: 1, perPage: 10}).then((response) => {
      setNotificationList(response.data.data);
      setIsClosed(!isClosed);
    });
    notificationApi.getUnreadNotifications().then((response) => {
      setNotificationCount(+response.data.unreadNotifications);
    });
  }, [notificationCount]);

  return (
    <UncontrolledDropdown className="user-dropdown">
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon center-items-flex">
        <div
          className="icon-status-info center-items-flex"
          onClick={() => {
            handleClick();
          }}
        >
          <Icon name="bell fs-25px" />
          {notificationCount > 0 && (
            <div className="notification-position ">
              <span className="notification-number">{notificationCount}</span>
            </div>
          )}
        </div>
      </DropdownToggle>
      {!isClosed && (
        <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
          <div className="dropdown-head">
            <span className="sub-title nk-dropdown-title"></span>
            <a
              href="#markasread"
              onClick={(ev) => {
                ev.preventDefault();
              }}
            >
              {t('general.markAllAsRead')}
            </a>
          </div>
          <div className="dropdown-body">
            <div className="nk-notification">
              {notificationList?.map((notification, index) => {
                const {
                  message: {notification_key, campaign_id, campaignName},
                } = notification;
                return (
                  <NotificationItem key={index} notification_key={notification_key} campaign_id={campaign_id} campaignName={campaignName} />
                );
              })}
            </div>
          </div>
          <div className="dropdown-foot center">
            <a
              href="#viewall"
              onClick={(ev) => {
                ev.preventDefault();
                handleClick();
                navigate('/app/notifications');
              }}
            >
              View All
            </a>
          </div>
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  );
};

export default Notification;
