import React, {useEffect, useState} from 'react';
import CountUp from 'react-countup';
import {useTranslation} from 'react-i18next';
import {Progress} from 'reactstrap';
import {formatNumber, formatPrice} from '../../../../../shared/Functions';
import {EPrices} from '../../../../../shared/IPrices';
import {ILineItemProgressBar} from '../../../../administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import {ICampaign, ICampaignOverview} from '../../../interfaces/ICampaigns';

interface Props {
  campaignOverview: ICampaignOverview | undefined;
  campaignData: ICampaign;
}

const progressBars = ['awareness', 'branding', 'engagement', 'performance'];

const CampaignStepThreePreview = ({campaignOverview, campaignData}: Props) => {
  const {t} = useTranslation();
  const [firstData, setFirstData] = useState<ILineItemProgressBar>();
  const [isUpdated, setIsUpdated] = useState(false);
  useEffect(() => {
    if (campaignOverview) {
      if (firstData && !(JSON.stringify(firstData) === JSON.stringify(campaignOverview?.progressBar))) {
        setIsUpdated(true);
      } else {
        setFirstData(campaignOverview?.progressBar);
        setIsUpdated(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignOverview?.progressBar]);
  return (
    <>
      {progressBars?.map((bar) => (
        <div className="w-100 mb-2" key={bar}>
          <label className="form-label">{t(`campaigns.${bar}`)}</label>
          <Progress
            striped
            animated
            color="primary"
            className="progress-lg opacity-100 border-primary border-opacity-25"
            value={campaignOverview?.progressBar?.[bar as keyof ILineItemProgressBar]}
          />
        </div>
      ))}
      <div className="w-100 mb-2">
        {Object.keys(campaignOverview ?? {})
          .filter((key) => !['progressBar', 'totalBudget', 'performance'].includes(key))
          ?.map((stats) => {
            return (
              <div className="d-flex justify-content-between w-100 flex-column flex-xl-row" key={stats}>
                <span>{t(`campaigns.${stats}`)}:</span>
                <strong>
                  {isUpdated ? (
                    <CountUp
                      formattingFn={(e) => formatNumber(e, 0)}
                      end={Number(Number(campaignOverview?.[stats as keyof ICampaignOverview]) ?? 0)}
                      duration={1}
                    />
                  ) : (
                    <>{formatNumber(Number(campaignOverview?.[stats as keyof ICampaignOverview]) ?? 0, 0)}</>
                  )}
                </strong>
              </div>
            );
          })}
        <div className=" w-100 mt-1">
          <div className="d-flex flex-xl-column justify-content-center">
            <span>{t('campaigns.totalBudget')}: </span>
            <strong>
              {isUpdated ? (
                <>
                  <CountUp decimals={2} end={Number(campaignOverview?.totalBudget ?? 0)} duration={1} />
                  /
                  <CountUp decimals={2} end={Number(campaignData?.plannedBudget ?? 0)} duration={1} />
                </>
              ) : (
                <>
                  {formatPrice(Number(campaignOverview?.totalBudget ?? 0), 0)} / {formatPrice(Number(campaignData?.plannedBudget ?? 0), 0)}
                </>
              )}
            </strong>
          </div>
          <Progress
            striped
            animated
            color="primary"
            className="progress-lg opacity-100 border-primary border-opacity-25"
            value={campaignOverview?.totalBudget ?? 0}
            max={campaignData?.plannedBudget ?? 0}
          />
        </div>
      </div>
      <div className="w-100 mb-2">
        {Object.keys(EPrices)?.map((price) => {
          return (
            <div className="d-flex justify-content-between w-100 " key={price}>
              <span>{price}</span>
              <strong>
                {isUpdated ? (
                  <>
                    <CountUp decimals={2} end={(campaignOverview?.progressBar as unknown as any)?.[price]} duration={1} />
                    <span className="ml-1">€</span>
                  </>
                ) : (
                  <>{formatPrice(Number((campaignOverview?.progressBar as unknown as any)?.[price]), 2)}</>
                )}
              </strong>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CampaignStepThreePreview;
