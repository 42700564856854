import React, {useState} from 'react';
import {Modal} from 'reactstrap';
import Icon from '../../../components/icon/Icon';

const ImageContainer = ({path, mime}: any) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  return (
    <>
      {mime.includes('image') ? (
        <a
          className="gallery-image popup-image"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          href="#gallery"
        >
          <Icon name="eye-alt-fill" />

          <Modal isOpen={open} toggle={toggle} size="large">
            <button type="button" className="mfp-close" onClick={toggle}>
              ×
            </button>

            <img className="w-100 rounded-top" style={{height: '100%'}} src={path} alt="" />
          </Modal>
        </a>
      ) : mime.includes('video') ? (
        <a
          className="gallery-image popup-image"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          href="#gallery"
        >
          <Icon name="eye-alt-fill" />

          <Modal isOpen={open} toggle={toggle} size="large">
            <button type="button" className="mfp-close" onClick={toggle}>
              ×
            </button>

            <video autoPlay controls className="w-100 rounded-top" style={{height: '100%'}} src={path} />
          </Modal>
        </a>
      ) : (
        <a href={path} target="_blank" rel="noreferrer">
          <Icon name="eye-alt-fill" />
        </a>
      )}
    </>
  );
};

export default ImageContainer;
