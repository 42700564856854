import {IPicture} from '../../../shared/interfaces/Picture';
import {IPagination} from '../../../shared/IPagination';
import {
  IChannelDeviceFormatBuilder,
  ILineItemOverview,
  ILineItemProgressBar,
} from '../../administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import {IChannel} from '../../administrator/channels/interfaces/IChannel';
import {ICompany} from '../../administrator/company/interfaces/ICompany';
import {IDevice} from '../../administrator/devices/interfaces/IDevice';
import {IFormat} from '../../administrator/formats/interfaces/IFormat';
import {IQuality} from '../../administrator/quality/interfaces/IQuality';
import {ITargetGroups} from '../../administrator/target-groups/interfaces/ITargetGroups';
import {IType} from '../../administrator/type/interfaces/IType';
import {IUser} from '../../administrator/users/interfaces/IUser';

export interface ICountry {
  country: string;
  id: number;
}

export interface ILineItemPrice {
  price: string;
  priceValue: number;
  ais: number;
  lineItemOverview: {
    CPC: number;
    CPE: number;
    CPM: number;
    CPV: number;
    adImpressions: number;
    clicks: number;
    completedViews: number;
    engagement: number;
  };
}

export interface ICampaignOverview extends ILineItemOverview {
  performance: number;
  progressBar: ILineItemProgressBar;
  totalBudget: number;
}

export interface ILineItem {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  objective: string;
  price: string;
  priceAmount: string;
  priceValue: number;
  ais: number;
  fee?: number;
  channel: IChannel;
  type: IType;
  quality: IQuality[];
  format: IFormat;
  targetGroups: ITargetGroups;
  device?: IDevice[];
  lineItemDevice: [{device: IDevice}];
  lineItemDate: [{date: string}];
  valueFrequencyCap: number;
  creativeName: string;
  targeting: boolean;
}

export interface ICampaignStep2 extends IChannelDeviceFormatBuilder {
  startDate: string;
  endDate: string;
  describeMediaPlan: string;
  mediaPlan: boolean;
  plannedBudget: number;
  mediaPlanFile?: File;
  tax: boolean;
  taxValue: number;
}

export interface ICampaign {
  describeMediaPlan: string;
  name: string;
  id: number;
  picture: any;
  plannedBudget: number;
  mediaPlanFile: IPicture | undefined;
  strategyPresentation: IPicture | undefined;
  status: ICampaignStatusEnum;
  paymentStatus: ICampaignPaymentStatusEnum;
  company: ICompany;
  createdAt: string;
  product: number;
  currentStep: number;
  mediaPlan: boolean;
  lineItems: boolean;
  endSteps: boolean;
  openAi: boolean;
  updatedAt: string;
  country: string[];
  countries: ICountry[];
  user: number[];
  campaignUser: [
    {
      user: IUser;
    }
  ];
  startDate: string;
  description: string;
  endDate: string;
  brand: number;
}

export interface IUserDashboardCard {
  user: IUser;
}

export interface ICampaignPagination extends IPagination {
  name?: string;
  status?: string;
}

export enum ICampaignStatusEnum {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  DRAFT = 'DRAFT',
  REJECT = 'REJECT',
  ON_AIR = 'ON_AIR',
}

export enum ICampaignPaymentStatusEnum {
  'INVOICED' = 'INVOICED',
  'PAID' = 'PAID',
}
