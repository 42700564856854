const Terms = () => {
  return (
    <div className="">
      <h4 className="nk-block-title mt-5 mb-2">Terms and conditions</h4>
      <h6>Page under construction...</h6>
    </div>
  );
};

export default Terms;
