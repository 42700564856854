import {IQualityObjective} from '../../../app/administrator/quality-objective/interfaces/IQualityObjective';
import BaseApi from '../../base.api';

const QUALITY_OBJECTIVE_ROUTE = '/api/admin/quality/objective';

export class QualityObjectiveApi extends BaseApi {
  public async getQualityObjectives(): Promise<{data: {quality: IQualityObjective[]}}> {
    return await this.get(QUALITY_OBJECTIVE_ROUTE, '');
  }

  public async updateQualityObjectives(data: IQualityObjective[]): Promise<{data: {quality: IQualityObjective[]}}> {
    return await this.put(
      QUALITY_OBJECTIVE_ROUTE,
      {
        quality: data,
      },
      ''
    );
  }
}
