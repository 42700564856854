import React, {ChangeEvent, useEffect} from 'react';
import {adjustValues, ISelect2, themeSelect} from '../../../../shared/Select2';
import {IEvent} from '../../../../shared/interfaces/Event';
import Select from 'react-select';
import {useTranslation} from 'react-i18next';
import {usePagination} from '../../../../shared/hooks/usePagination';
import {companyApi} from '../../../../api';
import {IFiltersProps} from '../../company/components/CompanyFilters';

const UserFilters = ({changeFilterHandler, companyList, setCompanyList, isManagementList = false, pagination}: IFiltersProps) => {
  const {t} = useTranslation();
  const {changeFilterHandler: changeCompanyFilterHandler, pagination: companyPagination} = usePagination();

  useEffect(() => {
    setCompanyList &&
      companyApi.getAllCompanies(companyPagination).then((response) => {
        const {data} = response;
        setCompanyList(data.data);
      });
  }, [companyPagination, setCompanyList]);
  return (
    <div className="mt-3 row gx-0 gx-lg-2 align-items-center justify-content-end">
      <div className="form-control-wrap col-lg-2 col-12">
        <input
          type="email"
          onChange={changeFilterHandler}
          name="email"
          className="form-control me-2"
          placeholder={t('general.searchByEmail') as string}
        />
      </div>
      <div className="form-control-wrap  col-lg-2 col-12 me-md-0 my-2 my-lg-0">
        <input
          type="text"
          onChange={changeFilterHandler}
          name="name"
          className="form-control"
          placeholder={t('general.searchByName') as string}
        />
      </div>

      {!isManagementList && companyList && (
        <div className="form-control-wrap col-lg-2 col-12 mb-lg-0 mb-2">
          <Select
            theme={themeSelect}
            placeholder={t('general.searchByCompany')}
            options={adjustValues(companyList as unknown as ISelect2[], 'name')}
            isClearable
            className="small-select2"
            onInputChange={(value) => {
              const event: IEvent = {
                target: {
                  name: 'name',
                  value: value,
                },
              };
              changeCompanyFilterHandler(event as unknown as ChangeEvent<HTMLInputElement | HTMLSelectElement>);
            }}
            onChange={(event) => {
              const combinedEvent: any = {
                target: {
                  name: 'company',
                  value: event?.value,
                },
              };
              changeFilterHandler(combinedEvent);
            }}
          />
        </div>
      )}
      <div className="form-control-wrap col-lg-2 col-12 mt-lg-0">
        <select name="isApprover" onChange={changeFilterHandler} className="form-select">
          <option hidden>Filter by Approver</option>
          <option value="">{t('administrator.users.allUsers')}</option>
          <option value="true">{t('administrator.users.approver')}</option>
          <option value="false">{t('administrator.users.notApprover')}</option>
        </select>
      </div>
      <div className="form-control-wrap col-lg-2 col-12 mt-2 mt-lg-0">
        <select name="perPage" onChange={changeFilterHandler} defaultValue={pagination?.perPage} className="form-select">
          <option hidden>Per page</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
      </div>
    </div>
  );
};

export default UserFilters;
