import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import {Card} from 'reactstrap';
import {Pagination} from '../../../components/pagination/Pagination';
import ChannelTable from './components/ChannelTable';
import {channelsApi} from '../../../api';
import {IChannel} from './interfaces/IChannel';
import {usePagination} from '../../../shared/hooks/usePagination';
import ChannelFilters from './components/ChannelFilters';
import {useTranslation} from 'react-i18next';
import {ICountry} from '../objective-scoring';

interface IChannelProps {
  isMainChannel?: boolean;
}

const Channel = ({isMainChannel = true}: IChannelProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [channelList, setChannelList] = useState<IChannel[]>([]);
  const {changeFilterHandler, page, setTotalPages, totalPages, handlePages, pagination} = usePagination();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    label: t('general.allCountries'),
    value: '',
  });
  useEffect(() => {
    async function getAllChannels() {
      const {data} = await channelsApi.getAllChannels({...pagination, country: selectedCountry.value});
      setChannelList(data.data);
      setTotalPages(Math.ceil(data?.count / data?.perPage));
    }

    getAllChannels();
  }, [pagination, setTotalPages, selectedCountry]);
  return (
    <>
      <Block size="lg" className="mt-3">
        <BlockHead>
          <BlockHeadContent>
            <div className="row align-items-center gx-0 gx-lg-2">
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-12">
                <BlockTitle tag="h4">{t('administrator.channels.channelList')}</BlockTitle>
              </div>
              {isMainChannel && (
                <div className="col-xxl-2 col-xl-3 col-lg-4 col-12  text-end">
                  <Button
                    className="btn-block"
                    color="primary"
                    onClick={() => {
                      navigate('/app/admin/channels/create');
                    }}
                  >
                    <Icon name="plus-sm" />
                    {t('administrator.channels.addNew')}
                  </Button>
                </div>
              )}
            </div>
            <ChannelFilters setSelectedCountry={setSelectedCountry} changeFilterHandler={changeFilterHandler} pagination={pagination} />
          </BlockHeadContent>
        </BlockHead>
        <Card className="card-bordered card-preview">
          <ChannelTable
            channelList={channelList}
            setChannelList={setChannelList}
            navigateTo={isMainChannel ? '/app/admin/channels/update' : '/app/admin/channel/device-format'}
            isMainChannel={isMainChannel}
          />
        </Card>
      </Block>

      <div className="d-flex justify-content-end">
        {totalPages <= 1 ? '' : <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />}
      </div>
    </>
  );
};

export default Channel;
