import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Card, CardBody, Col, Form, Row} from 'reactstrap';
import * as yup from 'yup';
import {channelUserApi} from '../../../../api';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../../components/block/Block';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import {ISingleChannel} from '../interfaces/IChannelUser';
import ChannelUserForm from './ChannelUserForm';

export const channelUserSchema = yup
  .object({
    channel: yup.array().min(1).typeError('No channel provided.').required('No channel provided'),
  })
  .required();
const ChannelUserUpdate = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id} = useParams();
  const methods = useForm<ISingleChannel>({
    resolver: yupResolver(channelUserSchema),
  });

  const onFormSubmit = async (state: ISingleChannel) => {
    const {data} = await channelUserApi.createChannelUser(Number(id), state.channel);
    if (data) {
      navigate(-1);
    }
  };
  return (
    <Block size="lg" className="mt-3">
      <BlockHead>
        <BlockHeadContent>
          <div className="row align-items-center gx-0 gx-lg-2">
            <div className="col-md-10 col-12">
              <BlockTitle tag="h4">{t('administrator.channelUser.assignChannelUser')}</BlockTitle>
            </div>
            <div className="col-md-2 col-12 text-end">
              <Button
                className="btn-block"
                color="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Icon name="arrow-long-left" />
                Back
              </Button>
            </div>
          </div>
        </BlockHeadContent>
      </BlockHead>
      <Card className="card-bordered card-preview">
        <CardBody>
          <FormProvider {...methods}>
            <Form onSubmit={methods?.handleSubmit(onFormSubmit)} className="py-3">
              <ChannelUserForm reset={methods?.reset} register={methods?.register} errors={methods?.formState?.errors} />
              <Row>
                <Col>
                  <Row>
                    <Col className={'text-end'}>
                      <Button color="primary">
                        <Icon name="plus-sm" />
                        {t('general.assign')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </CardBody>
      </Card>
    </Block>
  );
};

export default ChannelUserUpdate;
