import React, {SetStateAction, useState} from 'react';
import {FormProvider} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Col, Form, Row} from 'reactstrap';
import Button from '../../../../../components/button/Button';
import Icon from '../../../../../components/icon/Icon';
import {IListSubmitData} from '../CampaignStep3';
import CampaignStepThreeDropdowns from './CampaignStepThreeDropdowns';
import CampaignStepThreeTable from './CampaignStepThreeTable';
import {ILineItemStep1} from './LineItemModal';

const LineItemStep1 = ({
  methods,
  setListSubmitData,
  onFormSubmit,
  channels,
  selectedLineItem,
  setCurrStep,
  handleSelectChannel,
  campaignData,
  setSelectedCountry,
  selectedCountry,
}: ILineItemStep1) => {
  const {t} = useTranslation();
  const [isCountryValid, setIsCountryValid] = useState<undefined | boolean>();
  return (
    <Row>
      <Col xs={12}>
        <FormProvider {...methods}>
          <Form onSubmit={methods?.handleSubmit(onFormSubmit)}>
            <h5>{t('general.filtering')}</h5>
            <div className="row">
              <CampaignStepThreeDropdowns
                countries={campaignData!.country}
                errors={methods?.formState?.errors}
                register={methods?.register as any}
                setSelectedCountry={setSelectedCountry!}
                methods={methods}
                selectedCountry={selectedCountry}
                setIsCountryValid={setIsCountryValid}
                isCountryValid={isCountryValid}
              />
              <div className="col-12 d-flex justify-content-end mb-3">
                <Button color="primary" type="submit">
                  <Icon name="list-thumb-alt" className="pe-1" />
                  {t('general.generate')}
                </Button>
              </div>
            </div>
          </Form>
        </FormProvider>
      </Col>
      <Col xs={12}>
        <h5>{t('general.list')}</h5>
        <CampaignStepThreeTable
          setCurrStep={setCurrStep}
          selectedPrice={methods.watch('price') as string}
          selectedTargetGroup={methods.watch('targetGroup') as number}
          setListSubmitData={setListSubmitData as React.Dispatch<SetStateAction<IListSubmitData>>}
          hasData={!!channels?.length}
          selectedLineItem={selectedLineItem}
          methods={methods}
          handleSelectChannel={handleSelectChannel}
          channels={channels}
          setIsCountryValid={setIsCountryValid}
          selectedCountry={selectedCountry as string}
        />
      </Col>
    </Row>
  );
};

export default LineItemStep1;
