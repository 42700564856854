import React, {useCallback, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

interface IFileInputProps {
  accept: string;
  isMultiple?: boolean;
  name: string;
  label: string;
}

const FileInput = (props: IFileInputProps) => {
  const {t} = useTranslation();
  const {name, accept, isMultiple} = props;
  const {register, unregister, setValue, watch} = useFormContext();
  const files = watch(name);
  const onDrop = useCallback(
    (droppedFiles: any) => {
      setValue(name, droppedFiles, {shouldValidate: true});
    },
    [setValue, name]
  );

  // const RenderFormatIcon = ({file}: {file: File}) => {
  //   switch (file.type.split('/')[0]) {
  //     case 'video':
  //       return (
  //         <>
  //           <em
  //             className="icon ni ni-video  h-100 w-100 d-flex align-items-center justify-content-center display-1"
  //             style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
  //           ></em>
  //           {file.name}
  //         </>
  //       );

  //     case 'image':
  //       return (
  //         <img
  //           src={URL.createObjectURL(file)}
  //           alt={file.name}
  //           style={{
  //             height: '200px',
  //           }}
  //         />
  //       );
  //     default:
  //       return (
  //         <>
  //           <em
  //             className="icon ni ni-file  h-100 w-100 d-flex align-items-center justify-content-center display-1"
  //             style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
  //           ></em>
  //           {file.name}
  //         </>
  //       );
  //   }
  const RenderFormatIcon = ({file, fileMime}: {file: File; fileMime: string}) => {
    switch (true) {
      case fileMime.includes('excel') || fileMime.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet'):
        return (
          <>
            {' '}
            <em
              className="icon ni ni-file-xls  h-100 w-100 d-flex align-items-center justify-content-center display-1"
              style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
            ></em>{' '}
            {file.name}
          </>
        );

      case fileMime.includes('word'):
        return (
          <>
            {' '}
            <em
              className="icon ni ni-file-doc  h-100 w-100 d-flex align-items-center justify-content-center display-1"
              style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
            ></em>{' '}
            {file.name}
          </>
        );

      case fileMime.includes('zip'):
        return (
          <>
            {' '}
            <em
              className="icon ni ni-file-zip  h-100 w-100 d-flex align-items-center justify-content-center display-1"
              style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
            ></em>{' '}
            {file.name}
          </>
        );

      case fileMime.includes('pdf'):
        return (
          <>
            {' '}
            <em
              className="icon ni ni-file-pdf  h-100 w-100 d-flex align-items-center justify-content-center display-1"
              style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
            ></em>
            {file.name}
          </>
        );

      case fileMime.includes('text'):
        return (
          <>
            {' '}
            <em
              className="icon ni ni-file-text  h-100 w-100 d-flex align-items-center justify-content-center display-1"
              style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
            ></em>{' '}
            {file.name}
          </>
        );

      case fileMime.includes('video'):
        return (
          <>
            {' '}
            <em
              className="icon ni ni-video  h-100 w-100 d-flex align-items-center justify-content-center display-1"
              style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
            ></em>{' '}
            {file.name}
          </>
        );

      case fileMime.includes('image'):
        return (
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            style={{
              height: '200px',
            }}
          />
        );

      default:
        return (
          <>
            {' '}
            <em
              className="icon ni ni-file  h-100 w-100 d-flex align-items-center justify-content-center display-1"
              style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
            ></em>{' '}
            {file.name}
          </>
        );
    }

    // switch (fileMime) {
    //   case 'video':
    //     return (
    //       <em
    //         className="icon ni ni-video  h-100 w-100 d-flex align-items-center justify-content-center display-1"
    //         style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
    //       ></em>
    //     );
    //     break;
    //   case 'image':
    //     return <CardImg className="h-100" top style={{maxHeight: '75px', objectFit: 'cover'}} src={`${file.path}`} alt="" />;
    //     break;
    //   case 'application':
    //     return (
    //       <em
    //         className="icon ni ni-file  h-100 w-100 d-flex align-items-center justify-content-center display-1"
    //         style={{minHeight: '75px', maxHeight: '75px', objectFit: 'cover'}}
    //       ></em>
    //     );
    //     break;
    // }
  };
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    multiple: isMultiple,
    accept: {
      [accept]: [],
    },
  });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);
  return (
    <div {...getRootProps()} role="button" className="dropzone " id={name}>
      <input {...props} {...getInputProps()} />
      <div className={' ' + (isDragActive ? ' ' : ' ')}>
        {!files?.length && <span>{t('general.dropFile')} ...</span>}
        {!!files?.length && (
          <>
            {files.map((file: File) => {
              //  [
              //   'application/pdf',
              //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              //   'application/msword',
              // ].includes(file.type);
              return (
                <div key={file.name} className="bg-muted">
                  {/* <img
                    src={
                      isFile
                        ? 'https://d9-wret.s3.us-west-2.amazonaws.com/assets/palladium/production/s3fs-public/thumbnails/image/file.jpg'
                        : URL.createObjectURL(file)
                    }
                    alt={file.name}
                    style={{
                      height: '200px',
                    }}
                  /> */}

                  <RenderFormatIcon file={file} fileMime={file.type} />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default FileInput;
