import React, {useEffect, useState} from 'react';
import {companyApi, userApi} from '../../../api';
import {IUser} from './interfaces/IUser';
import {Pagination} from '../../../components/pagination/Pagination';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import {Card} from 'reactstrap';
import UserTable from './components/UserTable';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import {useNavigate} from 'react-router-dom';
import UserFilters from './components/UserFilters';
import {usePagination} from '../../../shared/hooks/usePagination';
import {ICompany} from '../company/interfaces/ICompany';
import {useTranslation} from 'react-i18next';

const Users = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [userList, setUserList] = useState<IUser[]>([]);
  const {changeFilterHandler, page, setTotalPages, totalPages, handlePages, pagination} = usePagination();
  const [companyList, setCompanyList] = useState<ICompany[]>([]);

  const deleteUserHandler = async (selectedUser: IUser) => {
    if (selectedUser?.id) {
      const {
        data: {data},
      } = await userApi.deleteUser(selectedUser?.id);
      setUserList(data);
    }
  };
  useEffect(() => {
    const getAllCompanies = async () => {
      const {
        data: {data},
      } = await companyApi.getAllCompanies({
        page: 1,
        perPage: 0,
      });
      setCompanyList(data);
    };
    getAllCompanies();
  }, []);

  useEffect(() => {
    userApi.getAllUsers(pagination).then((response) => {
      const {data} = response;
      setUserList(data.data);
      setTotalPages(Math.ceil(data?.count / data?.perPage));
    });
  }, [pagination, setTotalPages]);
  return (
    <>
      <Block size="lg" className="mt-3">
        <BlockHead>
          <BlockHeadContent>
            <div className="row align-items-center gx-0 gx-lg-2">
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-12">
                <BlockTitle tag="h4">{t('administrator.users.userList')}</BlockTitle>
              </div>
              <div className="col-xxl-2 col-xl-3 col-lg-4 col-12  text-end">
                <Button
                  className="btn-block"
                  color="primary"
                  onClick={() => {
                    navigate('/app/admin/users/create');
                  }}
                >
                  <Icon name="plus-sm" />
                  {t('administrator.users.addNewUser')}
                </Button>
              </div>
            </div>
            <UserFilters
              changeFilterHandler={changeFilterHandler}
              companyList={companyList}
              setCompanyList={setCompanyList}
              pagination={pagination}
            />
          </BlockHeadContent>
        </BlockHead>
        <Card className="card-bordered card-preview">
          <UserTable
            userList={userList}
            setUserList={setUserList}
            navigateRoute="/app/admin/users/update/"
            deleteUserHandler={deleteUserHandler}
          />
        </Card>
      </Block>

      <div className="d-flex justify-content-end">
        {totalPages <= 1 ? '' : <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />}
      </div>
    </>
  );
};

export default Users;
