import React from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from '../../components/error/NotFound';
import {AdminRoute} from '../../routes/RouteGuards';
import ChannelDeviceFormat from './channel-device-format';
import ChannelPrice from './channel-price';
import ChannelUser from './channel-user';
import ChannelUserUpdate from './channel-user/components/ChannelUserUpdate';
import Channels from './channels';
import Channel from './channels';
import ChannelCreate from './channels/components/ChannelCreate';
import ChannelUpdate from './channels/components/ChannelUpdate';
import Company from './company';
import CompanyCreate from './company/components/CompanyCreate';
import CompanyUpdate from './company/components/CompanyUpdate';
import DevicePrice from './device-price';
import Devices from './devices';
import DeviceCreate from './devices/components/DeviceCreate';
import DeviceUpdate from './devices/components/DeviceUpdate';
import Format from './formats';
import FormatCreate from './formats/components/FormatCreate';
import FormatUpdate from './formats/components/FormatUpdate';
import UpdateObjectiveScoring from './objective-scoring';
import Qualities from './quality';
import QualityObjective from './quality-objective';
import QualityPrice from './quality-price';
import QualityCreate from './quality/components/QualityCreate';
import QualityUpdate from './quality/components/QualityUpdate';
import Regions from './region';
import RegionCreate from './region/components/RegionCreate';
import RegionUpdate from './region/components/RegionUpdate';
import UpdateTargetGroupScoring from './target-group-pricing';
import TargetGroups from './target-groups';
import TargetGroupsCreate from './target-groups/components/TargetGroupsCreate';
import TargetGroupsUpdate from './target-groups/components/TargetGroupsUpdate';
import Types from './type';
import TypeCreate from './type/components/TypeCreate';
import TypeUpdate from './type/components/TypeUpdate';
import Users from './users';
import UserCreate from './users/components/UserCreate';
import UserUpdate from './users/components/UserUpdate';
import Prompt from './prompts';
import PromptCreate from './prompts/components/PromptCreate';
import PromptUpdate from './prompts/components/PromptUpdate';
import AiSettings from './ai-settings';
import AiSettingsCreate from './ai-settings/components/AiSettingsCreate';
import AiSettingsUpdate from './ai-settings/components/AiSettingsUpdate';

export function AdminRoutes() {
  return (
    <Routes>
      <Route path="/users" element={<AdminRoute component={Users} />} />
      <Route path="/users/create" element={<AdminRoute component={UserCreate} />} />
      <Route path="/users/update/:id" element={<AdminRoute component={UserUpdate} />} />
      <Route path="/types" element={<AdminRoute component={Types} />} />
      <Route path="/types/create" element={<AdminRoute component={TypeCreate} />} />
      <Route path="/types/update/:id" element={<AdminRoute component={TypeUpdate} />} />
      <Route path="/prompts" element={<AdminRoute component={Prompt} />} />
      <Route path="/prompts/create" element={<AdminRoute component={PromptCreate} />} />
      <Route path="/prompts/update/:id" element={<AdminRoute component={PromptUpdate} />} />
      <Route path="/ai-settings" element={<AdminRoute component={AiSettings} />} />
      <Route path="/ai-settings/create" element={<AdminRoute component={AiSettingsCreate} />} />
      <Route path="/ai-settings/update/:id" element={<AdminRoute component={AiSettingsUpdate} />} />
      <Route path="/company" element={<AdminRoute component={Company} />} />
      <Route path="/company/create" element={<AdminRoute component={CompanyCreate} />} />
      <Route path="/company/update/:id" element={<AdminRoute component={CompanyUpdate} />} />
      <Route path="/format" element={<AdminRoute component={Format} />} />
      <Route path="/format/create" element={<AdminRoute component={FormatCreate} />} />
      <Route path="/format/update/:id" element={<AdminRoute component={FormatUpdate} />} />
      <Route path="/qualities" element={<AdminRoute component={Qualities} />} />
      <Route path="/quality/create" element={<AdminRoute component={QualityCreate} />} />
      <Route path="/quality/update/:id" element={<AdminRoute component={QualityUpdate} />} />
      <Route path="/channels" element={<AdminRoute component={Channels} />} />
      <Route path="/channels/create" element={<AdminRoute component={ChannelCreate} />} />
      <Route path="/channels/update/:id" element={<AdminRoute component={ChannelUpdate} />} />
      <Route path="/target-groups" element={<AdminRoute component={TargetGroups} />} />
      <Route path="/target-groups/create" element={<AdminRoute component={TargetGroupsCreate} />} />
      <Route path="/target-groups/update/:id" element={<AdminRoute component={TargetGroupsUpdate} />} />
      <Route path="/devices" element={<AdminRoute component={Devices} />} />
      <Route path="/devices/create" element={<AdminRoute component={DeviceCreate} />} />
      <Route path="/devices/update/:id" element={<AdminRoute component={DeviceUpdate} />} />
      <Route path="/channel-price" element={<AdminRoute component={ChannelPrice} />} />
      <Route path="/device-price" element={<AdminRoute component={DevicePrice} />} />
      <Route path="/quality-objective" element={<AdminRoute component={QualityObjective} />} />
      <Route path="/quality-price" element={<AdminRoute component={QualityPrice} />} />
      <Route path="/objective-scoring" element={<AdminRoute component={UpdateObjectiveScoring} />} />
      <Route path="/target-group-pricing" element={<AdminRoute component={UpdateTargetGroupScoring} />} />
      <Route path="/channel/device-format" element={<AdminRoute component={() => <Channel isMainChannel={false} />} />} />
      <Route path="/channel/device-format/:channelId" element={<AdminRoute component={ChannelDeviceFormat} />} />
      <Route path="/channel-users" element={<AdminRoute component={ChannelUser} />} />
      <Route path="/channel-users/assign/:id" element={<AdminRoute component={ChannelUserUpdate} />} />
      <Route path="/regions" element={<AdminRoute component={Regions} />} />
      <Route path="/regions/create" element={<AdminRoute component={RegionCreate} />} />
      <Route path="/regions/update/:id" element={<AdminRoute component={RegionUpdate} />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
