import {Col, FormGroup, Row} from 'reactstrap';
import React from 'react';
import {getCorrectCountry} from '../../../shared/Functions';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store';
import {IUserData} from '../../../shared/interfaces/User';
import {FieldErrorsImpl, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import FileInput from '../../../shared/FileInput';
import ImageHandler from '../../../shared/ImageHandler';

interface IProfileForm {
  errors: Partial<
    FieldErrorsImpl<{
      firstName: string;
      lastName: string;
      company: number;
      email: string;
      roles: string[];
      password: string;
      contact: Partial<
        FieldErrorsImpl<{
          phone: string;
          country: string;
          city: string;
          zip: string;
          address: string;
        }>
      >;
    }>
  >;
  register: UseFormRegister<IUserData>;
  currentState: IUserData;
}

const ProfileUpdateForm = ({register, errors, currentState}: IProfileForm) => {
  const {t} = useTranslation();
  const {country} = useSelector((state: RootState) => state.enum);
  return (
    <Row className="gy-4">
      <Col md="12">
        <label className="form-label" htmlFor="first-name">
          {t('general.profilePhoto')}
        </label>
      </Col>
      {!!currentState?.profilePicture?.id && (
        <Col md={6}>
          <ImageHandler className="h-max-375px mb-4" path={currentState?.profilePicture?.path} />
        </Col>
      )}
      <Col md={!!currentState?.profilePicture?.id ? 6 : 12}>
        <FileInput accept="image/*" isMultiple={false} name="file" label="File Upload" />
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-label" htmlFor="first-name">
            {t('general.firstName')}
          </label>
          <input
            type="text"
            id="first-name"
            className="form-control"
            placeholder={t('general.firstName') as string}
            {...register('firstName')}
          />
          {errors?.firstName && <p className="invalid">{errors?.firstName?.message}</p>}
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-label" htmlFor="last-name">
            {t('general.lastName')}
          </label>
          <input
            type="text"
            id="last-name"
            className="form-control"
            placeholder={t('general.lastName') as string}
            {...register('lastName')}
          />
          {errors?.lastName && <p className="invalid">{errors?.lastName?.message}</p>}
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-label" htmlFor="email">
            {t('general.email')}
          </label>
          <input type="text" id="email" className="form-control" {...register('email')} placeholder={t('general.email') as string} />
          {errors?.email && <p className="invalid">{errors?.email?.message}</p>}
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-label" htmlFor="phone">
            {t('general.phoneNumber')}
          </label>
          <input
            type="phone"
            id="phone"
            className="form-control"
            placeholder={t('general.phoneNumber') as string}
            {...register('contact.phone')}
          />
          {errors.contact?.phone && <span className="invalid">{errors?.contact?.phone?.message}</span>}
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-label" htmlFor="address">
            {t('general.address')}
          </label>
          <input
            type="text"
            id="address"
            className="form-control"
            placeholder={t('general.address') as string}
            {...register('contact.address')}
          />
          {errors.contact?.address && <span className="invalid">{errors?.contact?.address?.message}</span>}
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-label" htmlFor="city">
            {t('general.city')}
          </label>
          <input type="text" id="city" className="form-control" placeholder={t('general.city') as string} {...register('contact.city')} />
          {errors.contact?.city && <span className="invalid">{errors?.contact?.city?.message}</span>}
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <label className="form-label" htmlFor="country">
            {t('general.country')}
          </label>
          <div className="form-control-wrap ">
            <div className="form-control-select">
              <select className="form-control " id="default-06" {...register('contact.country')}>
                <option selected disabled>
                  {t('general.enterCountry')}
                </option>
                {country?.map((item) => (
                  <option value={item} key={item}>
                    {getCorrectCountry(item, t)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {errors.contact?.country && <span className="invalid">{errors?.contact?.country?.message}</span>}
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-label" htmlFor="zip">
            {t('general.zip')}
          </label>
          <input type="text" id="zip" className="form-control" placeholder={t('general.zip') as string} {...register('contact.zip')} />
          {errors.contact?.zip && <span className="invalid">{errors?.contact?.zip?.message}</span>}
        </FormGroup>
      </Col>
    </Row>
  );
};

export default ProfileUpdateForm;
