import React from 'react';

const LoginSlider = () => {
  return (
    <img
      src={`/images/${process.env.REACT_APP_PROJECT}/modern-monitor-elegant-table1.jpg`}
      className=" login-bg-image"
      alt="Login Background"
    />
  );
};

export default LoginSlider;
