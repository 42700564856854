import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Block, BlockHead, BlockHeadContent, BlockTitle} from '../../../components/block/Block';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import {Card} from 'reactstrap';
import {Pagination} from '../../../components/pagination/Pagination';
import RegionTable from './components/RegionTable';
import {regionApi} from '../../../api';
import {IRegion} from './interfaces/IRegion';
import {usePagination} from '../../../shared/hooks/usePagination';
import RegionFilters from './components/RegionFilters';
import {useTranslation} from 'react-i18next';
import {ICountry} from '../objective-scoring';

const Regions = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [regionList, setRegionList] = useState<IRegion[]>([]);
  const {changeFilterHandler, page, setTotalPages, totalPages, handlePages, pagination} = usePagination();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    label: t('general.allCountries'),
    value: '',
  });
  useEffect(() => {
    async function getAllRegions() {
      const {data} = await regionApi.getAllRegions({...pagination, country: selectedCountry.value});
      setRegionList(data.data);
      setTotalPages(Math.ceil(data?.count / data?.perPage));
    }

    getAllRegions();
  }, [pagination, setTotalPages, selectedCountry]);
  return (
    <>
      <Block size="lg" className="mt-3">
        <BlockHead>
          <BlockHeadContent>
            <div className="row align-items-center gx-0 gx-lg-2">
              <div className="col-xxl-10 col-xl-9 col-lg-8 col-12">
                <BlockTitle tag="h4">{t('administrator.regions.regionList')}</BlockTitle>
              </div>
              <div className="col-xxl-2 col-xl-3 col-lg-4 col-12  text-end">
                <Button
                  className="btn-block"
                  color="primary"
                  onClick={() => {
                    navigate('/app/admin/regions/create');
                  }}
                >
                  <Icon name="plus-sm" />
                  {t('administrator.regions.addNew')}
                </Button>
              </div>
            </div>
            <RegionFilters changeFilterHandler={changeFilterHandler} pagination={pagination} setSelectedCountry={setSelectedCountry} />
          </BlockHeadContent>
        </BlockHead>
        <Card className="card-bordered card-preview">
          <RegionTable regionList={regionList} setRegionList={setRegionList} />
        </Card>
      </Block>

      <div className="d-flex justify-content-end">
        {totalPages <= 1 ? '' : <Pagination page={page} totalPages={totalPages} handlePagination={handlePages} />}
      </div>
    </>
  );
};

export default Regions;
