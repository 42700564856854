import React from 'react';
import PageContainer from '../../layout/page-container/PageContainer';
import {PreviewCard} from '../../components/preview/Preview';
import {Block, BlockContent, BlockDes, BlockHead, BlockTitle} from '../../components/block/Block';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import ForgotPasswordForm from './components/ForgotPasswordForm';
import LoginSlider from '../login/components/LoginSlider';

const ForgotPassword = () => {
  const {t} = useTranslation();
  return (
    <div className="row">
      <div className="col-md-6 bg-white d-flex align-items-center justify-content-center">
        <LoginSlider />
      </div>

      <div className="col-md-6">
        <PageContainer>
          <Block className="nk-block-middle nk-auth-body  wide-xs">
            <div className="brand-logo pb-4 text-center">
              <Link to={process.env.PUBLIC_URL + '/'} className="logo-link">
                <img className="logo-light logo-img logo-img-lg" src={`/images/${process.env.REACT_APP_PROJECT}/logo.png`} alt="logo" />
                <img
                  className="logo-dark logo-img logo-img-lg"
                  src={`/images/${process.env.REACT_APP_PROJECT}/logo-rgb.png`}
                  alt="logo-dark"
                />
              </Link>
            </div>
            <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h5">{t('forgotPassword.resetPassword')}</BlockTitle>
                  <BlockDes>
                    <p>{t('forgotPassword.resetPasswordInfo')}</p>
                  </BlockDes>
                </BlockContent>
              </BlockHead>
              {/* ==================== Login Form ==================== */}
              <ForgotPasswordForm />
              {/* ==================== Login Form ==================== */}
              <div className="form-note-s2 text-center pt-4">
                <Link to={`${process.env.PUBLIC_URL}/`}>
                  <strong>{t('forgotPassword.returnToLogin')}</strong>
                </Link>
              </div>
            </PreviewCard>
          </Block>
        </PageContainer>
      </div>
    </div>
  );
};
export default ForgotPassword;
