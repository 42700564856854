import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {isUserAdmin, isUserChannelUser} from '../../shared/Functions';
import {RootState} from '../../store';
import {channelUserMenu, menu} from './InvestmentMenu';

const MobileMenu = () => {
  const [activeSubMenu, setActiveSubMenu] = useState('');
  const {roles} = useSelector((state: RootState) => state?.auth?.user);
  const isChannelUser = isUserChannelUser(roles);
  const isAdmin = isUserAdmin(roles);
  const getCorrectMenuArr = () => {
    return isChannelUser ? channelUserMenu : menu;
  };
  return (
    <ul className="nk-menu nk-menu-main ui-s2">
      {getCorrectMenuArr()?.map((item: any) => {
        if (!isAdmin && item.onlyAdmin) return null;
        return (
          <React.Fragment key={item?.link}>
            <li
              key={item?.link}
              className={`nk-menu-item ${!item?.isFirstLevel ? 'has-sub' : ''} ${activeSubMenu === item?.text ? 'active' : ''}`}
            >
              {item?.isFirstLevel ? (
                <Link to={item?.link} className="nk-menu-link">
                  <span className="nk-menu-text">{item?.text}</span>
                </Link>
              ) : (
                <>
                  <Link
                    to={item?.link}
                    onClick={() => setActiveSubMenu(item?.text)}
                    className={`nk-menu-link nk-menu-toggle ${activeSubMenu === item?.text ? 'active' : ''}`}
                  >
                    <span className="nk-menu-text">{item?.text}</span>
                  </Link>
                  <div className={`nk-menu-wrap ${activeSubMenu === item?.text ? 'd-block' : 'd-none'}`}>
                    <ul className="nk-menu-sub">
                      {item?.subMenu?.map((subMenu: any) => {
                        return (
                          <li className="nk-menu-item" key={subMenu?.link}>
                            <Link to={subMenu?.link} className="nk-menu-link">
                              <span className="nk-menu-text">{subMenu?.text}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              )}
            </li>
          </React.Fragment>
        );
      })}
    </ul>
  );
};

export default MobileMenu;
