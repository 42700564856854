import Lottie from 'lottie-react';
import searchIcon from '../../shared/icons/searchIcon.json';
import React from 'react';

export const EmptyState = () => {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column w-100 py-5">
      <Lottie animationData={searchIcon} loop={true} style={{width: 100}} />
      <h5 className="mt-2">No Result Found</h5>
      <p className="text-muted">We've searched and we did not find any data for you search.</p>
    </div>
  );
};
