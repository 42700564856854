import {capitalize} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Progress} from 'reactstrap';
import {formatNumber, formatPrice, truncateString} from '../../../../../shared/Functions';
import {
  IChannelDeviceFormatBuild,
  ILineItemOverview,
} from '../../../../administrator/channel-device-format/interfaces/IChannelDeviceFormat';
import {TooltipItem} from '../../../../../components/tooltip/Tooltips';
import Icon from '../../../../../components/icon/Icon';

interface Props {
  isFormDisabled: boolean;
  prices: {
    ais: number;
    priceValue: number;
    model: 'CPM' | 'CPC' | 'CPV' | undefined;
  };
  changePricesHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showInputFields?: boolean;
  lineItem?: IChannelDeviceFormatBuild;
  targetGroup?: any;
}

const lineItemOverviewKeys = ['impressions', 'clicks', 'engagement', 'completedViews'];

const UpdateLineItemPreview = ({isFormDisabled, lineItem, prices, targetGroup, changePricesHandler, showInputFields = true}: Props) => {
  const {t} = useTranslation();
  const shouldBold = (model: string, key: string) => {
    if (model === 'CPM' && key === 'adImpressions') return 'fw-bold';
    if (model === 'CPC' && key === 'clicks') return 'fw-bold';
  };
  const hasChannelCPM = !!lineItem?.channel?.CPM;

  const dataRender = () => {
    if (lineItem?.format?.linearMedia) {
      return (
        <div
          className={`d-${!hasChannelCPM ? 'none' : 'flex'} justify-content-between w-100 ${shouldBold(
            prices?.model ?? '',
            'impressions'
          )} align-items-center`}
        >
          <span>{t('general.contacts')}</span>

          <div className="d-flex align-items-center">
            <strong>{formatNumber(lineItem?.['impressions' as keyof IChannelDeviceFormatBuild] ?? 0, 0)}</strong>
            {lineItem?.channel?.estimated && (
              <TooltipItem
                titleClasses="btn p-0"
                id={'impressions'}
                classes="p-0 m-0"
                title={<Icon name="info" />}
                item={{
                  placement: 'top',
                  text: 'Estimated',
                }}
              />
            )}
          </div>
        </div>
      );
    } else {
      return lineItemOverviewKeys?.map((overviewKey) => {
        return (
          <div
            className={`d-${!hasChannelCPM && overviewKey === 'impressions' ? 'none' : 'flex'} justify-content-between w-100 ${shouldBold(
              prices?.model ?? '',
              overviewKey
            )} align-items-center`}
            key={overviewKey}
          >
            <span>{t(`campaigns.${overviewKey}`)}</span>

            <div className="d-flex align-items-center">
              <strong>{formatNumber(lineItem?.[overviewKey as keyof IChannelDeviceFormatBuild] ?? 0, 0)}</strong>
              {lineItem?.channel?.estimated && (
                <TooltipItem
                  titleClasses="btn p-0"
                  id={overviewKey}
                  classes="p-0 m-0"
                  title={<Icon name="info" />}
                  item={{
                    placement: 'top',
                    text: 'Estimated',
                  }}
                />
              )}
            </div>
          </div>
        );
      });
    }
  };

  return (
    <>
      {showInputFields && (
        <>
          <div className="col-12 ">
            <div className="form-group">
              <label className="form-label">{t('campaigns.price')}</label>
              <div className="form-control-wrap d-flex align-items-center">
                <input
                  type="number"
                  onInput={(e: any) => {
                    e.preventDefault();
                    e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                  }}
                  value={Number(prices?.priceValue ?? 0)}
                  className="form-controlgi"
                  name="priceValue"
                  onChange={changePricesHandler}
                />
                <span className="ms-2">{formatPrice(Number(prices?.priceValue ?? 0))}</span>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">{t('campaigns.ais')}</label>
              <div className="form-control-wrap d-flex align-items-center">
                <input
                  type="number"
                  onInput={(e: any) => {
                    e.preventDefault();
                    e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                  }}
                  value={Number(prices?.ais ?? 0)}
                  className="form-control"
                  name="ais"
                  onChange={changePricesHandler}
                />
                <span className="ms-2">{formatPrice(prices?.ais ?? 0)}</span>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="col-6">
        <div className="w-100">
          <div className="d-flex justify-content-between w-100">
            <span>{t('campaigns.priceModel')}</span>
            <strong className="d-flex align-items-center">
              {prices.model}: {formatPrice(lineItem?.lineItemOverview?.[prices?.model as keyof ILineItemOverview] ?? 0)}
              {lineItem?.channel?.estimated && (
                <TooltipItem
                  titleClasses="btn p-0"
                  id={prices.model}
                  classes="p-0 m-0"
                  title={<Icon name="info fs-5" />}
                  item={{
                    placement: 'top',
                    text: 'Estimated',
                  }}
                />
              )}
            </strong>
          </div>
          <div className="d-flex justify-content-between w-100">
            <span>{t('campaigns.investment')}</span>
            <strong>{formatPrice(Number(prices?.priceValue ?? 0))}</strong>
          </div>
          <div className="d-flex justify-content-between w-100">
            <span>{t('general.objective')}</span>
            <strong>{capitalize(lineItem?.objective ?? '')}</strong>
          </div>
          <div className="d-flex justify-content-between w-100">
            <span>{t('general.targetGroup')}</span>
            <strong>{truncateString(targetGroup ?? '', 20)}</strong>
          </div>

          <label className="form-label mt-2">{t('administrator.channelFormats.enterAwareness')}</label>
          <Progress
            striped
            animated
            max={100}
            color="primary"
            className={`${isFormDisabled ? 'progress-lg opacity-25' : 'progress-lg opacity-100 border-primary border-opacity-25'}`}
            value={Number(lineItem?.awarenessBar ?? 0)}
          />
          <label className="form-label mt-2">{t('administrator.channelFormats.enterBranding')}</label>
          <Progress
            striped
            animated
            color="primary"
            max={100}
            className={`${isFormDisabled ? 'progress-lg opacity-25' : 'progress-lg opacity-100 border-primary border-opacity-25'}`}
            value={Number(lineItem?.brandingBar ?? 0)}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="w-100">
          {dataRender()}

          {/*  */}
          <label className={`form-label  ${lineItem?.format?.linearMedia ? '' : 'mt-2'}`}>
            {t('administrator.channelFormats.enterEngagement')}
          </label>
          <Progress
            striped
            animated
            max={100}
            color="primary"
            className={`${isFormDisabled ? 'progress-lg opacity-25' : 'progress-lg opacity-100 border-primary border-opacity-25'}`}
            value={Number(lineItem?.engagementBar ?? 0)}
          />
          <label className="form-label mt-2">{t('administrator.channelFormats.enterPerformance')}</label>
          <Progress
            striped
            animated
            max={100}
            color="primary"
            className={`${isFormDisabled ? 'progress-lg opacity-25' : 'progress-lg opacity-100 border-primary border-opacity-25'}`}
            value={Number(lineItem?.performanceBar ?? 0)}
          />
        </div>
      </div>
    </>
  );
};

export default UpdateLineItemPreview;
