import {Provider, useInjection} from 'inversify-react';
import {Container} from 'inversify';
import Api from '../services/api';
import DateTime from '../services/dateTime';

export const useMultiInject = () => {
  const api = useInjection(Api);
  const dateTime = useInjection(DateTime);
  return {
    api,
    dateTime,
  };
};

const ContainerProvider = (props: any) => {
  return (
    <Provider
      container={() => {
        const container = new Container();
        container.bind(Api).toSelf();
        container.bind(DateTime).toSelf();

        return container;
      }}
    >
      {props.children}
    </Provider>
  );
};

export default ContainerProvider;
