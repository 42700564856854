import {capitalize} from 'lodash';
import React from 'react';
import {IObjectiveScoring} from '../interfaces/IObjectiveScoring';
import {useTranslation} from 'react-i18next';

interface IObjectiveScoringUpdateTableProps {
  objectiveList: IObjectiveScoring;
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>, channelId: number, indexToReplace: number, objective: string) => void;
}

const ObjectiveScoringUpdateTable = ({objectiveList, changeHandler}: IObjectiveScoringUpdateTableProps) => {
  const {t} = useTranslation();
  return (
    <div className="table-container">
      <div className="table-responsive max-h-800px ">
        <table className="table table-striped table-hover">
          <thead className="position-sticky top-0 bg-white shadow" style={{zIndex: 100}}>
            <tr>
              <th scope="col" className="bg-white" style={{minWidth: 30, position: 'sticky', left: 0, zIndex: 10000}}>
                {t('general.channel')}
              </th>
              {objectiveList?.groups?.map((group) => (
                <th scope="col" key={group?.id}>
                  {group?.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {objectiveList?.channels?.map((channel) => {
              return (
                <tr key={`${channel?.channelId}-${channel?.objective}`}>
                  <td className="bg-white" style={{minWidth: 30, position: 'sticky', left: 0, zIndex: 10}}>
                    <div>{channel?.channelName}</div>
                    <div className="text-muted small">
                      {channel?.quality} - {capitalize(channel?.objective)}
                    </div>
                  </td>
                  {channel?.scoring?.map((price, index) => (
                    <td key={index}>
                      <input
                        type="number"
                        onInput={(e: any) => {
                          e.preventDefault();
                          e.target.value = e.target.value.replaceAll(/[^0-9/.]/g, '');
                        }}
                        className="form-control w-min-100px"
                        onChange={(event) => changeHandler(event, channel?.channelId, index, channel?.objective)}
                        value={price ?? 0}
                      />
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ObjectiveScoringUpdateTable;
